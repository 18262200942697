import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { components, assets, hooks } from '@ElementsCapitalGroup/enium-ui'
import { TABLET_BREAKPOINT } from 'common/constants'

const { Dropdown } = components
const { PlusIcon, TrashIcon, DotsGridIcon } = assets
const { useMediaQuery } = hooks

const StipulationElement = ({
  element,
  index,
  onElementChange,
  onElementRemove,
  onNewElement,
  canBeRemoved = true,
  stipulationDefinitions,
  selected,
  errors,
  isInitialStipulation,
  isCommercialStipulation,
}) => {
  const selectedArr = useMemo(() => {
    return isInitialStipulation
      ? selected.initialStipulations
      : isCommercialStipulation
      ? selected.commercialStipulations
      : selected.finalStipulations
  }, [selected, isCommercialStipulation])

  const transformedStipulationDefinitions = stipulationDefinitions.map(
    (item) => ({
      id: item.id,
      label: item.value,
    })
  )

  const handleDropdownChange = (event) => {
    const value = event.target.value

    onElementChange({ ...element, definition: value.id }, index)
  }

  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  return (
    <div className="stipulations__element">
      {!isMobileView ? (
        <>
          <DotsGridIcon
            sx={{
              marginRight: 3,
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          />
          <Dropdown
            key={selectedArr}
            options={transformedStipulationDefinitions}
            value={
              transformedStipulationDefinitions.find(
                (itm) => itm.id === selectedArr?.[index]
              ) ||
              selectedArr?.[index] ||
              ''
            }
            error={!!errors?.definition}
            helperText={errors?.definition}
            onChange={handleDropdownChange}
            label={'Stipulation Definition'}
            searchable={true}
            sx={{ width: '260px' }}
          />
          <div className="stipulations__icons">
            {canBeRemoved && (
              <TrashIcon
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={(ev) => {
                  ev.stopPropagation()
                  onElementRemove(index)
                }}
              />
            )}

            <PlusIcon
              sx={{
                marginLeft: canBeRemoved ? 3 : 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={onNewElement}
            />
          </div>
        </>
      ) : (
        <div className="stipulations__icons-wrapper">
          <div className="stipulations__icons">
            <DotsGridIcon
              sx={{
                marginRight: 3,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
            {canBeRemoved && (
              <TrashIcon
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={(ev) => {
                  ev.stopPropagation()
                  onElementRemove(index)
                }}
              />
            )}

            <PlusIcon
              sx={{
                marginLeft: canBeRemoved ? 3 : 0,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={onNewElement}
            />
          </div>

          <Dropdown
            key={selectedArr?.[index]}
            options={transformedStipulationDefinitions}
            value={transformedStipulationDefinitions.find(
              (itm) => itm.id === selectedArr?.[index]
            )}
            onChange={handleDropdownChange}
            error={!!errors?.definition}
            helperText={errors?.definition}
            sx={{ width: '260px' }}
          />
        </div>
      )}
    </div>
  )
}

StipulationElement.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    guid: PropTypes.string,
    definition: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onElementChange: PropTypes.func.isRequired,
  onElementRemove: PropTypes.func.isRequired,
  onNewElement: PropTypes.func.isRequired,
  canBeRemoved: PropTypes.bool,
  errors: PropTypes.object,
  stipulationDefinitions: PropTypes.array.isRequired,
  selected: PropTypes.object,
  isInitialStipulation: PropTypes.bool,
  isCommercialStipulation: PropTypes.bool,
}

export default StipulationElement
