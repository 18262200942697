import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'

import { v4 as uuidv4 } from 'uuid'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import { VALIDATION_TYPES, validate } from 'components/validator'
import { ReactComponent as UsersSvg } from 'assets/users.svg'

import { history } from 'components/history'
import { setLoading } from 'modules/global/actions'
import { useStore } from 'store'
import TextField from 'components/input'
import TextArea from 'components/textarea'
import {
  components,
  assets,
  hooks,
  unstable_components,
} from '@ElementsCapitalGroup/enium-ui'
import { generateEmptyTemplate } from './utils'
import DecisionList from './decision-list'
import {
  fetchComparators,
  fetchCriteria,
  updateTemplate as updateTemplateOnServer,
  deleteTemplate,
  getTemplate,
  createTemplate,
} from './actions'

import './style.scss'
import CopyDecisionsModal from './modal-copy-decisions'

const { Dropdown, IconButton, Breadcrumbs } = components
const { Unstable_Grid: Grid } = unstable_components
const { Dialog } = components
const { CheckIcon, TrashIcon, CopyIcon } = assets
const { useBoolean } = hooks

const AusTemplatesSettings = ({ isCreate }) => {
  const { dispatch, state: store } = useStore()
  const [state, setState] = useState({
    comparators: [],
    criteria: [],
    filters: {},
  })
  const [templateToRender, setTemplateToRender] = useState({
    ...generateEmptyTemplate(),
  })
  const { comparators, criteria } = state
  const { lenders } = store.orgManagement
  const [errors, setErrors] = useState({})
  const [isOpen, { off, on }] = useBoolean()
  const [isCopyModalOpen, { off: copyModalOff, on: copyModalOn }] = useBoolean()
  const navigate = useNavigate()

  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const { id } = useParams()

  useEffect(() => {
    setLoading(dispatch, true)
    if (!isCreate) {
      getTemplate(id)
        .then((res) => {
          setTemplateToRender(res)
        })
        .finally(() => {
          handleFetchCriterisAndComparators()
        })
    } else {
      handleFetchCriterisAndComparators()
    }
  }, [isCreate])

  const handleFetchCriterisAndComparators = () => {
    Promise.all([fetchCriteria(), fetchComparators()])
      .then(([criteria, comparators]) => {
        setState({ ...state, criteria, comparators })
      })
      .finally(() => {
        setLoading(dispatch, false)
      })
  }

  const updateTemplate = (template) => {
    setTemplateToRender(template)
  }

  /** Validate template handler */
  const validateTemplate = (template) => {
    // Validate basic template criteria
    let [isValid, errors] = validate(TEMPLATE_ERROR_MAP, template)
    // Validate decisions
    errors.decisions = []

    /* Inner recursive function to recursively validate Decision elements */
    const validateDecisions = (decisions) => {
      let [isValid, errors] = [true, decisions.map(() => ({}))]
      decisions.forEach((elem, idx) => {
        const [innerValid, innerErrors] = validate(DECISION_ERROR_MAP, elem)
        if (!innerValid) {
          isValid = false
          errors[idx] = innerErrors
        }
        if (elem.decisions) {
          const [childValid, childErrors] = validateDecisions(elem.decisions)
          if (!childValid) {
            isValid = false
            errors[idx] = { ...errors[idx], decisions: childErrors }
          }
        }
      })
      return [isValid, errors]
    }

    const [validDecisions, decisionErrs] = validateDecisions(template.decisions)
    if (!validDecisions) {
      isValid = false
      errors = { ...errors, decisions: decisionErrs }
    }
    return [isValid, errors]
  }

  /** Add new Template handler */
  const addNewTemplate = async () => {
    const [isValid, errors] = validateTemplate(templateToRender)
    if (!isValid) {
      return setErrors(errors)
    }

    // Save it to the server
    setLoading(dispatch, true)
    const success = await createTemplate(templateToRender, dispatch)
      .then(() => navigate('/admin/aus-templates'))
      .finally(() => setLoading(dispatch, false))

    if (!success) {
      return
    }

    setTemplateToRender({ ...generateEmptyTemplate() })
    setErrors({})
  }

  /** Edit template handler */
  const editTemplate = () => {
    const [isValid, validationErrors] = validateTemplate(templateToRender)

    if (isValid) {
      setLoading(dispatch, true)

      updateTemplateOnServer(templateToRender, dispatch)
        .then(() => navigate('/admin/aus-templates'))
        .finally(() => setLoading(dispatch, false))
    } else {
      // Set the validation errors in the state
      setErrors(validationErrors)
    }
  }
  /** Triggered when a template is removed */

  const removeTemplate = () => {
    deleteTemplate(templateToRender.id, dispatch).finally(() => {
      setLoading(dispatch, false)
      history.push('/admin/aus-templates')
    })
  }

  const updateTemplateField = (field, value) => {
    setTemplateToRender({
      ...templateToRender,
      [field]: value,
    })
  }

  const onCopyDecisions = (template) => {
    getTemplate(template.id)
      .then((template) => {
        templateToRender.decisions = templateToRender.decisions.concat(
          template.decisions.map((el) => ({ ...el, id: uuidv4() }))
        )
        // Update the template
        updateTemplate(templateToRender)
        copyModalOff()
      })
      .catch(console.error)
  }

  const handleDropdownChange = (event) => {
    const lender = event.target.value

    setTemplateToRender((prevTemplate) => ({
      ...prevTemplate,
      lender: lender.id,
    }))
  }

  const formattedLenders = useMemo(() => {
    return lenders.map((el) => ({ id: el.guid, label: el.name }))
  }, [lenders])

  const items = useMemo(() => {
    const baseItems = [
      { icon: <UsersSvg /> },
      { label: 'AUS Templates', href: '/admin/aus-templates' },
      { label: isCreate ? 'Add New Template' : 'Edit Template' },
    ]

    return baseItems
  }, [isCreate])

  return (
    <div>
      <Breadcrumbs
        sx={{ marginBottom: '32px' }}
        items={items}
        onClick={() => {}}
      />
      <div className="admin-template__decisions-header">
        <span className="admin-template__decisions-title">
          Template Details
        </span>
        <div
          style={{
            display: 'flex',
            marginTop: isMobileView ? '12px' : 0,
            width: isMobileView ? '100%' : 'inherit',
          }}
        >
          {!isCreate && (
            <IconButton
              variant="outlined"
              rounded
              onClick={on}
              sx={{ marginRight: 2, color: 'black' }}
            >
              <TrashIcon />
            </IconButton>
          )}

          {!isCreate && !isMobileView && (
            <Button
              variant={BUTTON_VARIANTS.OUTLINED}
              color={BUTTON_COLORS.INHERIT}
              startIcon={<CopyIcon />}
              sx={{ mr: 2 }}
              onClick={copyModalOn}
            >
              Copy from Template
            </Button>
          )}

          <Button
            onClick={() => (isCreate ? addNewTemplate() : editTemplate())}
            startIcon={<CheckIcon />}
            fullWidth={isMobileView}
          >
            {isCreate ? 'Create Template' : 'Save Changes'}
          </Button>
        </div>
        {!isCreate && isMobileView && (
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            startIcon={<CopyIcon />}
            fullWidth
            sx={{ mt: 2 }}
            onClick={copyModalOn}
          >
            Copy from Template
          </Button>
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={off}
        title="Are you sure you want to remove this?"
        actions={
          <>
            <Button
              onClick={() => {
                off()
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                removeTemplate()
                off()
              }}
              color="primary"
            >
              Confirm
            </Button>
          </>
        }
      />
      <Grid container gap={24}>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            value={templateToRender?.title}
            validate={() => errors.title}
            onChange={(value) => updateTemplateField('title', value)}
            label={'Template Title'}
            fullWidth
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <Dropdown
            onChange={handleDropdownChange}
            value={
              formattedLenders?.find(
                (itm) => itm.id === templateToRender?.lender
              ) ||
              templateToRender?.lender ||
              ''
            }
            key={templateToRender}
            error={!!errors.lender}
            helperText={errors.lender}
            label={'Lender'}
            disabled={!formattedLenders?.length}
            options={formattedLenders}
          />
        </Grid>
        <Grid item>
          <TextArea
            label={'Description'}
            rows={isMobileView ? 2 : 4}
            fullWidth
            resize="vertical"
            onChange={(value) => updateTemplateField('description', value)}
            value={templateToRender?.description}
          />
        </Grid>
      </Grid>
      <CopyDecisionsModal
        isOpen={isCopyModalOpen}
        onClose={copyModalOff}
        onCopy={onCopyDecisions}
      />
      <TemplateContent
        template={templateToRender}
        criteria={criteria}
        errors={errors}
        setTemplateToRender={setTemplateToRender}
        comparators={comparators}
      />
    </div>
  )
}

AusTemplatesSettings.propTypes = {
  isCreate: PropTypes.bool,
}

const TemplateContent = ({
  template,
  errors,
  setTemplateToRender,
  criteria,
  comparators,
}) => {
  return (
    <>
      <div className="admin-template__decisions">
        <span className="admin-template__decisions-title">AUS Conditions</span>
        <DecisionList
          list={template?.decisions || []}
          onListChange={(decisions) => {
            setTemplateToRender((prevState) => ({
              ...prevState,
              decisions,
            }))
          }}
          parentId={template?.id}
          errors={errors.decisions || {}}
          criteria={criteria}
          comparators={comparators}
        />
      </div>
    </>
  )
}

TemplateContent.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    description: PropTypes.string,
    lender: PropTypes.string,
    version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    decisions: PropTypes.array,
    expanded: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.object,
  criteria: PropTypes.array,
  comparators: PropTypes.array,
  setTemplateToRender: PropTypes.func.isRequired,
}

const TEMPLATE_ERROR_MAP = {
  title: VALIDATION_TYPES.REQUIRED,
  lender: VALIDATION_TYPES.REQUIRED,
}

const DECISION_ERROR_MAP = {
  field: VALIDATION_TYPES.POSITIVE_SAFE_INTEGER,
  comparator: VALIDATION_TYPES.REQUIRED,
  value: VALIDATION_TYPES.REQUIRED,
}

export default AusTemplatesSettings
