import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import { ExpandableCard } from 'components/expandable-card'
import CreditReportsContent from './credit-reports-content'
import { expandableCard } from '../common/styles'

import './style.scss'

const CreditReports = ({ data }) => {
  const isDesktopView = useMediaQuery(`(min-width:${DESKTOP_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const { t: translate } = useTranslation()

  const collapsibleItems = [<CreditReportsContent key={0} data={data} />]

  return (
    <div className="uw-hub-page__credit-reports">
      <ExpandableCard
        headerProps={expandableCard.headerProps(isMobileView)}
        titleTypographyProps={expandableCard.titleProps(isMobileView)}
        title={translate('uwHub.creditReports.title')}
        collapsibleItems={collapsibleItems}
        isCollapsable={true}
        isCollapsed={!isDesktopView}
        alwaysCollapsible={true}
        collapsibleItemsStyles={{
          sx: {
            padding: !isDesktopView ? '16px' : '24px',
          },
        }}
      />
    </div>
  )
}

CreditReports.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CreditReports
