import React, { useState } from 'react'
import { listFundingHistory } from 'modules/automated-funding/actions'
import { AF_PAGE_SIZE } from 'modules/automated-funding/utils/constants'
import FundingHistoryModule from 'modules/automated-funding/funding-history'
import FundingBreadcrumbs from 'modules/automated-funding/utils/breadcrumbs'

const AFPFundingHistoryPage = () => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    list: [],
    totalPages: 0,
    fundingDetails: {
      fundedBy: { friendlyName: 'Mocked for now' },
      totalFunding: 0,
      totalDeposit: 0,
      totalFee: 0,
    },
  })

  const fetchList = (query = {}) => {
    setLoading(true)
    listFundingHistory(query)
      .then((result) => {
        result &&
          setState({
            ...state,
            list: result.dailyFundingLoanApplications,
            totalPages: Math.ceil(result.itemCount / AF_PAGE_SIZE),
          })
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="admin-page afp">
      <FundingBreadcrumbs
        items={[{ id: 'history', label: 'Funding History' }]}
      />
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">Funding History</div>
          <div>View all your funding history in one place.</div>
        </div>
      </div>
      <div className="admin-page__content">
        <FundingHistoryModule
          list={state.list}
          pageCount={state.totalPages}
          fundingDetails={state.fundingDetails}
          onFetchList={fetchList}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default AFPFundingHistoryPage
