import PropTypes from 'prop-types'
import { useState } from 'react'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'

import { TABLET_BREAKPOINT } from 'common/constants'
import { useMediaQuery } from 'common/hooks'

const { Card, Divider, List, ListItem, IconButton, Collapse } = components
const { ChevronDownIcon, ChevronUpIcon } = assets

export const ExpandableCard = ({
  title,
  items = [],
  collapsibleItems = [],
  isCollapsable = false,
  isCollapsed = false,
  alwaysCollapsible = false,
  actionButtons,
  actionButtonsClassName = '',
  collapsibleItemsStyles,
  headerProps,
  titleTypographyProps,
  expandableListStyle = {},
  ...rest
}) => {
  const [expand, setExpand] = useState(!isCollapsed)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const renderCollapsibleItems = () =>
    collapsibleItems?.map((item, index) => (
      <ListItem
        divider
        key={`collapsible-${index}`}
        {...collapsibleItemsStyles}
      >
        {item}
      </ListItem>
    ))

  return (
    <Card
      sx={{
        borderRadius: 1.5,
      }}
      headerProps={{
        sx: isMobileView
          ? {
              paddingTop: 1.5,
              paddingBottom: 1.5,
              paddingLeft: 2,
              paddingRight: 2,
              '&.MuiCardHeader-root': {
                flexWrap: 'wrap',
              },
              '& .MuiCardHeader-action': {
                width: '100%',
                marginTop: '10px',
              },
              '& .MuiIconButton-root': {
                marginLeft: 'auto',
              },
            }
          : {
              paddingTop: 1.5,
              paddingBottom: 1.5,
              paddingLeft: 3,
              paddingRight: 3,
              backgroundColor: (theme) => theme.palette.grey[50],
            },
        action: (
          <div className={actionButtonsClassName}>
            {actionButtons}
            {isCollapsable &&
            collapsibleItems?.length &&
            (isMobileView || alwaysCollapsible) ? (
              <IconButton size="small" onClick={() => setExpand(!expand)}>
                {expand ? (
                  <ChevronUpIcon fontSize="small" />
                ) : (
                  <ChevronDownIcon fontSize="small" />
                )}
              </IconButton>
            ) : null}
          </div>
        ),
        ...headerProps,
      }}
      title={title}
      titleTypographyProps={{
        variant: 'body2',
        fontWeight: 500,
        color: (theme) => theme.palette.grey[600],
        ...titleTypographyProps,
      }}
      raised={false}
      variant="outlined"
      slots={{
        before: <Divider />,
      }}
      contentProps={{
        sx: {
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      }}
      {...rest}
    >
      <List disablePadding sx={expandableListStyle}>
        {items.map((item, index) => (
          <ListItem divider key={index}>
            {item}
          </ListItem>
        ))}

        {!isMobileView && !alwaysCollapsible && renderCollapsibleItems()}

        {(isMobileView || alwaysCollapsible) && (
          <Collapse in={expand}>{renderCollapsibleItems()}</Collapse>
        )}
      </List>
    </Card>
  )
}

ExpandableCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.array,
  collapsibleItems: PropTypes.array,
  isCollapsable: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  actionButtons: PropTypes.node,
  alwaysCollapsible: PropTypes.bool,
  collapsibleItemsStyles: PropTypes.object,
  actionButtonsClassName: PropTypes.string,
  headerProps: PropTypes.object,
  titleTypographyProps: PropTypes.object,
  expandableListStyle: PropTypes.object,
}
