import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from 'components/button'
import DeleteModal from 'components/modal/delete-modal'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import StatusComponent, { STATUSES } from 'components/status'
import { ExpandableCard } from 'components/expandable-card'

import styles from './style.module.scss'

const LenderAgreementCard = ({
  isLenderAgreementAccepted,
  agreementText,
  onAgreementChange,
  loading,
  collapsed,
  viewOnly,
}) => {
  const { t: translate } = useTranslation()
  const [declineModalOpen, setDeclineModalOpen] = useState(false)

  const collapsibleItems = [
    <div className={styles.cardContent} key={0}>
      <div className={styles.cardContentContainer}>
        <div
          className={styles.cardText}
          dangerouslySetInnerHTML={{ __html: agreementText }}
        />
      </div>
      <AccessWrapper claims={CLAIMS.CAN_ACCEPT_AGREEMENTS}>
        <div className="form-buttons__glendale">
          <Button
            size={BUTTON_SIZES.SMALL}
            sx={{ ml: '8px' }}
            onClick={() => onAgreementChange(true)}
            loading={loading}
            disabled={viewOnly}
          >
            Accept Lender Agreement
          </Button>
          <Button
            size={BUTTON_SIZES.SMALL}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.ERROR}
            onClick={() => setDeclineModalOpen(true)}
            disabled={loading || viewOnly}
          >
            {translate('buttons.decline')}
          </Button>
        </div>
      </AccessWrapper>

      <DeleteModal
        isOpen={declineModalOpen}
        setModalOpen={setDeclineModalOpen}
        onSubmitModal={() => {
          setDeclineModalOpen(false)
          onAgreementChange(false)
        }}
        title={translate('loanApplication.step3.declineLenderAgreement.title')}
        confirmationQuestion={translate(
          'loanApplication.step3.declineLenderAgreement.text',
          { phone_number: process.env.REACT_APP_ENIUM_PHONE_NO }
        )}
        confirmButtonText={translate('buttons.decline')}
      />
    </div>,
  ]
  const isSuccessful = isLenderAgreementAccepted === true

  return (
    <ExpandableCard
      sx={{ mb: '16px' }}
      headerProps={{
        sx: {
          backgroundColor: '#ffffff',
        },
      }}
      expandableListStyle={{
        '& .MuiListItem-root': {
          padding: '0',
        },
      }}
      items={[]}
      collapsibleItems={collapsibleItems}
      isCollapsable={true}
      isCollapsed={collapsed}
      alwaysCollapsible={true}
      title={
        <div className={styles.cardTitleBigger}>
          <span>{translate('loanApplication.step3.lenderAgreementTitle')}</span>

          <div className={styles.status}>
            <StatusComponent
              status={isSuccessful ? STATUSES.ACTIVE : STATUSES.INACTIVE}
              label={
                isSuccessful
                  ? translate('global.accepted')
                  : translate('global.declined')
              }
            />
          </div>
        </div>
      }
    />
  )
}

LenderAgreementCard.propTypes = {
  isLenderAgreementAccepted: PropTypes.bool,
  agreementText: PropTypes.string.isRequired,
  onAgreementChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  collapsed: PropTypes.bool,
  viewOnly: PropTypes.bool,
}

export default LenderAgreementCard
