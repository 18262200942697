import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { assets } from '@ElementsCapitalGroup/enium-ui'

import Loader from 'components/loader/index'
import { getPropertyTitle } from 'modules/loan-application/actions'
import { formatDate } from 'common/date'
import { dateFormatUS } from 'common/constants'

import './index.scss'

const { ArrowDownIcon, ArrowUpIcon, BarChartSquareUpIcon } = assets

const PropertyTitle = ({ propertyTitleReportId, hideHeader = false }) => {
  const { t: translate } = useTranslation()
  const [propertyTitleData, setPropertyTitleData] = useState({})
  const [expandedItems, setExpandedItems] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getPropertyTitle(propertyTitleReportId)
      .then((res) => setPropertyTitleData(res.data))
      .finally(() => setLoading(false))
  }, [])

  const toggleExpand = (index) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [index]: !prevExpandedItems[index],
    }))
  }

  const _renderPropertyTitles = (
    propertyTitleData,
    parentKey = '',
    isParentExpanded = false
  ) => {
    return Object.entries(propertyTitleData).map(
      ([propertyTitleName, propertyTitleDetails], key) => {
        const currentKey = parentKey ? `${parentKey}-${key}` : `${key}`
        const hasChildren = typeof propertyTitleDetails === 'object'
        const isExpanded = expandedItems[currentKey] || isParentExpanded
        const noObjectChildren = !Object.values(propertyTitleDetails).some(
          (propertyTitleChildDetails) =>
            typeof propertyTitleChildDetails === 'object'
        )

        const toggleExpandHandler = (e) => {
          e.stopPropagation()
          toggleExpand(currentKey)
        }

        return (
          <div
            key={key}
            className={cx('property-title', {
              'property-title__item': hasChildren,
            })}
          >
            <div className={!hasChildren && 'property-title__wrapper'}>
              <div
                key={key}
                className={cx({
                  'property-title__name': hasChildren,
                  'property-title__name-child': !hasChildren,
                })}
                onClick={hasChildren ? toggleExpandHandler : undefined}
              >
                {propertyTitleName}
                {hasChildren &&
                  (isExpanded ? (
                    <ArrowUpIcon
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={toggleExpandHandler}
                    />
                  ) : (
                    <ArrowDownIcon
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={toggleExpandHandler}
                    />
                  ))}
              </div>
              {hasChildren && isExpanded ? (
                <div
                  className={cx({
                    'property-title__wrap property-title__wrap--3':
                      noObjectChildren,
                  })}
                >
                  {Object.entries(propertyTitleDetails).map(
                    (
                      [propertyTitleChildName, propertyTitleChildDetails],
                      idx
                    ) => {
                      const hasChildren =
                        typeof propertyTitleChildDetails === 'object'
                      return (
                        <div
                          key={idx}
                          className={cx('property-title__children', {
                            'property-title__children--full': hasChildren,
                          })}
                        >
                          <div
                            className={cx('property-title__child', {
                              'property-title__child--regular': !hasChildren,
                            })}
                          >
                            {propertyTitleChildName}
                          </div>
                          <div
                            className={cx('property-title__wrap', {
                              'property-title__wrap--1': noObjectChildren,
                            })}
                          >
                            {typeof propertyTitleChildDetails !== 'object' ? (
                              <>
                                {propertyTitleChildName?.includes('Date')
                                  ? formatDate(
                                      propertyTitleChildDetails,
                                      dateFormatUS
                                    )
                                  : propertyTitleChildDetails}
                              </>
                            ) : (
                              _renderPropertyTitles(
                                propertyTitleChildDetails,
                                currentKey,
                                true
                              )
                            )}
                          </div>
                        </div>
                      )
                    }
                  )}
                </div>
              ) : isExpanded ? (
                <span>
                  {propertyTitleName.includes('Date')
                    ? formatDate(propertyTitleName, dateFormatUS)
                    : propertyTitleDetails}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        )
      }
    )
  }

  return (
    <>
      <div className="property-title-modal">
        {!hideHeader && (
          <div className="property-title__title">
            <BarChartSquareUpIcon sx={{ mr: 1 }} />
            {translate(
              'loanApplication.ecgToolbox.sidebar.propertyTitleReport'
            )}
          </div>
        )}
        {propertyTitleData != null && (
          <div style={{ paddingBottom: '16px' }}>
            {_renderPropertyTitles(propertyTitleData)}
          </div>
        )}
        {loading && <Loader center />}
        {(propertyTitleData == null ||
          Object.keys(propertyTitleData).length === 0) &&
          !loading && (
            <span className="notes">
              {translate(
                'loanApplication.ecgToolbox.propertyTitle.notAvailable'
              )}
            </span>
          )}
      </div>
    </>
  )
}

PropertyTitle.propTypes = {
  propertyTitleReportId: PropTypes.string.isRequired,
  hideHeader: PropertyTitle.bool,
}

export default PropertyTitle
