import React, { useEffect } from 'react'
import UwHubModule from 'modules/uw-hub'
import { useNavigate, useParams } from 'react-router-dom'
import { init } from 'modules/uw-hub/actions'
import { useStore } from 'store'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { NOT_FOUND_ROUTE } from '../routes'

const UwHubPage = () => {
  const { dispatch, state } = useStore()
  const { navigate } = useNavigate()
  const { userData } = state.session
  const { loanApplicationId } = useParams()

  useEffect(() => {
    init(dispatch, loanApplicationId)
  }, [])

  useEffect(() => {
    if (!hasAccess(userData, CLAIMS.CAN_VIEW_UW_HUB)) {
      navigate(NOT_FOUND_ROUTE, { replace: true })
    }
  }, [userData.claims])

  return <UwHubModule />
}

export default UwHubPage
