import React, { useEffect, useState } from 'react'
import Api from 'easy-fetch-api'
import PropTypes from 'prop-types'
import { APPLICANT_TYPE } from 'common/constants'
import { useTranslation } from 'react-i18next'
import { getIdVerificationByLoanApplication } from 'modules/loan-application/utils'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import Loader from 'components/loader'
import ModalSideApplicant from './modal-side-applicant'
import ApplicantVerification from './applicant-verification'
import { ApplicantIdVerification } from './applicant-id-verification'
import './index.scss'

const { Tabs } = components
const { FileShieldIcon } = assets

const IdentityVerification = ({
  loanApplicationId,
  borrower,
  coBorrower,
  hideTitle = false,
}) => {
  const [loading, setLoading] = useState(true)
  const [borowerVerification, setBorrowerVerification] = useState(null)
  const [coborrowerVerification, setCoborrowerVerification] = useState(null)
  const [borrowerIdVerificationResults, setBorrowerIdVerificationResults] =
    useState(null)
  const [coBorrowerIdVerificationResults, setCoBorrowerIdVerificationResults] =
    useState(null)
  const { t: translate } = useTranslation()

  const borrowerFullName = `${borrower?.firstName || ''} ${
    borrower?.lastName || ''
  }`

  const coborrowerFullName = `${coBorrower?.firstName || ''} ${
    coBorrower?.lastName || ''
  }`

  useEffect(() => {
    const fetchVerification = (applicantId, setter) => {
      if (!applicantId) {
        return Promise.resolve(null)
      }

      return Api.get({
        url: `/LoanApplication/id-verification`,
        query: { applicantId },
      }).then((rawVerification) => {
        setter(rawVerification)
      })
    }

    setLoading(true)

    Promise.all([
      fetchVerification(borrower?.applicantId, setBorrowerVerification),
      fetchVerification(coBorrower?.applicantId, setCoborrowerVerification),
    ]).finally(() => setLoading(false))
  }, [borrower, coBorrower])

  useEffect(() => {
    getIdVerificationByLoanApplication(loanApplicationId).then((results) => {
      results.forEach((result) => {
        if (result.applicantId === borrower?.applicantId) {
          setBorrowerIdVerificationResults({
            firstName: result.firstName,
            lastName: result.lastName,
            idNumber: result.idNumber,
            issuedOn: result.issuedOn,
            expiresOn: result.expiresOn,
            dateOfBirth: result.dateOfBirth,
            address: result.address,
          })
        } else if (result.applicantId === coBorrower?.applicantId) {
          setCoBorrowerIdVerificationResults({
            firstName: result.firstName,
            lastName: result.lastName,
            idNumber: result.idNumber,
            issuedOn: result.issuedOn,
            expiresOn: result.expiresOn,
            dateOfBirth: result.dateOfBirth,
            address: result.address,
          })
        }
      })
    })
  }, [loanApplicationId, borrower, coBorrower])

  const borrowerChildren = {
    label: 'Borrower',
    value: 0,
    children: (
      <div>
        {borowerVerification && (
          <ApplicantVerification
            applicantTypeId={APPLICANT_TYPE.BORROWER}
            verificationResults={borowerVerification}
          />
        )}
        {borrowerIdVerificationResults && (
          <ApplicantIdVerification
            verificationResults={borrowerIdVerificationResults}
          />
        )}
        {borowerVerification && (
          <ModalSideApplicant
            keyMessages={borowerVerification.verificationSummary.keyMessages}
            explanations={borowerVerification.idVisionAlert.explanation}
            name={borrowerFullName}
          />
        )}
      </div>
    ),
  }

  const items =
    coborrowerVerification ||
    coBorrowerIdVerificationResults ||
    coborrowerVerification
      ? [
          borrowerChildren,
          {
            label: 'Co-Borrower',
            value: 1,
            children: (
              <div>
                {coborrowerVerification && (
                  <ApplicantVerification
                    applicantTypeId={APPLICANT_TYPE.COBORROWER}
                    verificationResults={coborrowerVerification}
                  />
                )}
                {coBorrowerIdVerificationResults && (
                  <ApplicantIdVerification
                    verificationResults={coBorrowerIdVerificationResults}
                  />
                )}
                {coborrowerVerification && (
                  <ModalSideApplicant
                    keyMessages={
                      coborrowerVerification.verificationSummary.keyMessages
                    }
                    explanations={
                      coborrowerVerification.idVisionAlert.explanation
                    }
                    name={coborrowerFullName}
                  />
                )}
              </div>
            ),
          },
        ]
      : [borrowerChildren]

  return (
    <>
      {!hideTitle && (
        <p className="income-estimator__title" style={{ marginBottom: 0 }}>
          <FileShieldIcon sx={{ mr: 1 }} />
          <strong>
            {translate('loanApplication.ecgToolbox.sidebar.idVerification')}
          </strong>
        </p>
      )}
      <Tabs
        panelProps={{
          sx: {
            padding: '0 0 0',
          },
        }}
        items={items}
      />
      <div>
        <div>{loading ? <Loader size={100} center noLogo={true} /> : null}</div>
      </div>
    </>
  )
}

IdentityVerification.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  borrower: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    applicantId: PropTypes.string,
  }).isRequired,
  coBorrower: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    applicantId: PropTypes.string,
  }),
  hideTitle: PropTypes.bool,
}

export default IdentityVerification
