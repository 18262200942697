import Cookie from 'js-cookie'
import {
  MESSAGES_TABLE,
  SYSTEM_CONFIG_ID,
  SYSTEM_CONFIG_TABLE,
  USER_TO_MESSAGE_TABLE,
} from './constants'
import { firebaseDb } from './firebase'

export const ACTIONS = {
  LOAD_ALL_DATA: 'outage.loadAllData',
  LOAD_MESSAGES: 'outage.loadMessages',
  SET_LOADING: 'outage.setLoading',
  CONFIG_UPDATED: 'outage.configUpdated',
  DISMISS_LOCALLY: 'outage.dismissLocally',
}

const DISMISSED_MESSAGE_COOKIE = 'enium-dismissed-msg'

/**
 * Load firebase data related to outage messages
 * Returns an object {list: Array, userAssociations: Array, config: Object} where
 *    list - is the list of all messages
 *    userAssociations - users to messages (dismissed flags for users)
 *    config - holds the currently-active ones
 * @return {Promise<Object>}
 */
export const loadFirebaseData = (dispatch) => {
  dispatch({ type: ACTIONS.SET_LOADING, loading: true })
  const promises = [
    firebaseDb.collection(MESSAGES_TABLE).get(),
    firebaseDb.collection(USER_TO_MESSAGE_TABLE).get(),
    firebaseDb.collection(SYSTEM_CONFIG_TABLE).doc(SYSTEM_CONFIG_ID).get(),
  ]
  return Promise.all(promises)
    .then(([listSnapshot, userAssociationSnapshot, configDoc]) => {
      const list = listSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      const userAssociations = userAssociationSnapshot.docs.map((doc) =>
        doc.data()
      )
      const config = configDoc.data()
      dispatch({ type: ACTIONS.LOAD_ALL_DATA, list, userAssociations, config })
      return { list, userAssociations, config }
    })
    .finally(() => dispatch({ type: ACTIONS.SET_LOADING, loading: false }))
}

export const loadMessages = (dispatch) =>
  firebaseDb
    .collection(MESSAGES_TABLE)
    .get()
    .then((listSnapshot) => {
      const list = listSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      dispatch({ type: ACTIONS.LOAD_MESSAGES, list })
    })

/**
 * Update the config (aka the displayed Alert or Banner)
 * First we fetch the list of messages because if the Alert/Banner was just added we won't have it in the list
 * @return {Promise<void>}
 */
export const updateConfig = async (dispatch, config) => {
  await loadMessages(dispatch)
  dispatch({ type: ACTIONS.CONFIG_UPDATED, config })
}

export const addMessage = (message) =>
  firebaseDb
    .collection(MESSAGES_TABLE)
    .add({ ...message, updatedAt: new Date().getTime() })

export const dismissMessage = (dispatch, messageId, email) => {
  if (!messageId) {
    return
  }
  dispatch({ type: ACTIONS.SET_LOADING, loading: true })

  if (!email) {
    dismissMessageForUnauthenticatedUser(messageId)
    dispatch({ type: ACTIONS.SET_LOADING, loading: false })
    return
  }

  return firebaseDb
    .collection(USER_TO_MESSAGE_TABLE)
    .add({ messageId, email, dismissed: true })
    .then(() => loadFirebaseData(dispatch))
    .catch((err) => {
      console.error('Dismiss Message error', err)
    })
    .finally(() => dispatch({ type: ACTIONS.SET_LOADING, loading: false }))
}

/** Session-cookie in order to temporarily dismiss outage notifications for non-authenticated users */
const dismissMessageForUnauthenticatedUser = (msgId) => {
  Cookie.set(DISMISSED_MESSAGE_COOKIE, msgId)
}

export const isMessageLocallyDismissed = (msgId) =>
  Cookie.get(DISMISSED_MESSAGE_COOKIE) === msgId

export const toggleOutageMessage = (fieldName, valueToSet) =>
  firebaseDb
    .collection(SYSTEM_CONFIG_TABLE)
    .doc(SYSTEM_CONFIG_ID)
    .update({ [fieldName]: valueToSet })

export const listenForConfigChanges = (callback) => {
  return firebaseDb
    .collection(SYSTEM_CONFIG_TABLE)
    .doc(SYSTEM_CONFIG_ID)
    .onSnapshot(callback)
}
