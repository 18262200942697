import React, { forwardRef, useMemo } from 'react'
import { components } from '@ElementsCapitalGroup/enium-ui'
import PropTypes from 'prop-types'
import Table from 'components/table'
import StatusComponent from 'components/status'
import { useStore } from 'store'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'

import { ORGS_PAGE_SIZE, STATUS_ID_MAPPING } from './constants'

const { createColumnHelper } = components
const columnHelper = createColumnHelper()

const OrganizationsTable = forwardRef(
  (
    {
      title,
      organizations,
      orgCount,
      fetchData,
      dataLoading,
      handleSortByChanged,
      onRowClick,
      onEdit,
      onRemove,
      noDataText,
      noDataSubText,
      noDataActionText,
      noDataActionOnClick,
    },
    tableRef
  ) => {
    const { state } = useStore()
    const canDeleteOrg = hasAccess(
      state.session.userData,
      CLAIMS.CAN_DELETE_ORGANIZATIONS
    )

    const columns = useMemo(() => {
      return [
        columnHelper.accessor('name', {
          header: 'Organization Name',
          enableSorting: false,
        }),
        columnHelper.accessor('type', {
          header: 'Type',
          cell: ({ row }) => row.original.type.friendlyName,
          enableSorting: true,
        }),
        columnHelper.accessor('status', {
          header: 'Status',
          cell: ({ row }) => {
            const status = row.original.status
            return (
              <>
                <StatusComponent
                  status={STATUS_ID_MAPPING[status.id]}
                  label={status.friendlyName}
                />
              </>
            )
          },
          enableSorting: true,
        }),
        columnHelper.accessor('userCount', {
          header: 'Users',
          enableSorting: true,
        }),
        columnHelper.accessor('subOrgCount', {
          header: 'Sub Organizations',
          enableSorting: true,
        }),
      ]
    }, [organizations])

    return (
      <Table
        ref={tableRef}
        title={title || 'Organizations'}
        data={organizations}
        totalPages={Math.ceil(orgCount / ORGS_PAGE_SIZE)}
        columns={columns}
        hasCheckboxes={true}
        hasPagination={true}
        onSortingChange={handleSortByChanged}
        onRowClick={onRowClick}
        onRowsEdit={onEdit}
        onRowsRemove={onRemove}
        fetchData={fetchData}
        pageSize={ORGS_PAGE_SIZE}
        dataLoading={dataLoading}
        multiRowSelection={true}
        emptyTitle={noDataText}
        emptyDescription={noDataSubText}
        emptyActionButtonLabel={noDataActionText}
        emptyActionButtonOnClick={noDataActionOnClick}
        hideRemoveButton={!canDeleteOrg}
      />
    )
  }
)

OrganizationsTable.propTypes = {
  title: PropTypes.string,
  organizations: PropTypes.array.isRequired,
  orgCount: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool,
  handleSortByChanged: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  noDataText: PropTypes.string,
  noDataSubText: PropTypes.string,
  noDataActionText: PropTypes.string,
  noDataActionOnClick: PropTypes.func,
}

export default OrganizationsTable
