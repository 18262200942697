import { useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import { useTranslation } from 'react-i18next'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'

import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import { ExpandableCard } from 'components/expandable-card'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { downloadMultipleAttachments } from 'modules/loan-application/actions'
import SolarContractsContent from './solar-contracts-content'
import { expandableCard } from '../common/styles'

import './style.scss'

const { LoadingIndicator } = components
const { FileSearchIcon } = assets

const SolarContracts = ({ loanApplicationId, data }) => {
  const isDesktopView = useMediaQuery(`(min-width:${DESKTOP_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const { dispatch } = useStore()
  const { t: translate } = useTranslation()
  const [downloading, setDownloading] = useState(false)

  const downloadSolarContractAttachments = () => {
    setDownloading(true)
    downloadMultipleAttachments(
      dispatch,
      loanApplicationId,
      data.attachmentIds
    ).finally(() => setDownloading(false))
  }

  const collapsibleItems = [
    <SolarContractsContent
      key={0}
      data={data}
      loanApplicationId={loanApplicationId}
      downloadSolarContractAttachments={() =>
        downloadSolarContractAttachments()
      }
      downloadingSolarContract={downloading}
    />,
  ]

  return (
    <div className="uw-hub-page__solar-contracts">
      <ExpandableCard
        headerProps={expandableCard.headerProps(isMobileView, true)}
        titleTypographyProps={expandableCard.titleProps(isMobileView)}
        title={
          <div className="uw-hub-page__solar-contracts-title">
            <span>{translate('uwHub.solarContracts.title')}</span>
            {data.attachmentIds.length > 0 && !isMobileView && (
              <Button
                onClick={() => downloadSolarContractAttachments()}
                variant={BUTTON_VARIANTS.OUTLINED}
                color={BUTTON_COLORS.INHERIT}
                startIcon={
                  downloading ? (
                    <LoadingIndicator size={14} />
                  ) : (
                    <FileSearchIcon />
                  )
                }
                disabled={downloading}
                sx={{
                  marginRight: '12px',
                }}
              >
                {translate('uwHub.solarContracts.previewSolarContract')}
              </Button>
            )}
          </div>
        }
        collapsibleItems={collapsibleItems}
        isCollapsable={true}
        isCollapsed={false}
        alwaysCollapsible={true}
        collapsibleItemsStyles={{
          sx: {
            padding: !isDesktopView ? '16px' : '24px',
          },
        }}
      />
    </div>
  )
}

SolarContracts.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default SolarContracts
