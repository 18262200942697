import PropTypes from 'prop-types'
import { useState } from 'react'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'

import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import TextArea from 'components/textarea'
import Loader from 'components/loader'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'

const { Dialog } = components

const DeclineDialog = ({ open, loading, onClose, onDecline }) => {
  const { t: translate } = useTranslation()
  const [reason, setReason] = useState('')
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const handleOnChange = (value) => {
    setReason(value)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={translate('uwHub.common.uwApproval.declineDialog.title')}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '500px',
          margin: isMobileView ? '5px' : '32px',
        },
      }}
      actions={
        <>
          <Button
            onClick={() => {
              setReason('')
              onClose()
            }}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            fullWidth
          >
            {translate('buttons.cancel')}
          </Button>
          <Button
            onClick={() => onDecline(reason)}
            fullWidth
            disabled={!reason.length}
          >
            {translate(`buttons.submit`)}
          </Button>
        </>
      }
    >
      {loading ? (
        <Loader size={100} center noLogo={true} />
      ) : (
        <>
          <TextArea
            rows={4}
            resize={false}
            fullWidth={true}
            value={reason}
            label={translate('uwHub.common.uwApproval.declineDialog.reason')}
            onChange={handleOnChange}
          />
        </>
      )}
    </Dialog>
  )
}

DeclineDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
}

export default DeclineDialog
