import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button from 'components/button'
import { getStates } from 'modules/organizations/actions'

import { styles } from '../../organizations/organization-details/style'

const { Chip, Dropdown } = components

const LoanDocumentStates = ({ loanDocumentStates, onSubmitStates }) => {
  const [allStates, setAllStates] = useState(null)
  const [selectedStates, setSelectedStates] = useState([])

  const onRemoveState = (stateId) => () => {
    setSelectedStates((prev) => prev.filter((state) => state.id !== stateId))
  }
  const onStateChecked = (ev) => setSelectedStates(ev.target.value)

  useEffect(() => {
    getStates().then((states) => {
      setAllStates(states)
      setSelectedStates(
        states
          .filter((state) =>
            loanDocumentStates?.some((orgState) => orgState.id === state.id)
          )
          .map((state) => ({ ...state, label: state.name }))
      )
    })
  }, [])

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={styles.orgDetailsTitle}>Associate States</h1>
      </div>

      <div style={styles.loanAssociation.searchBar}>
        <Dropdown
          label="Select States"
          multiple={true}
          searchable={true}
          options={allStates?.map((el) => ({ ...el, label: el.name })) || []}
          value={selectedStates}
          onChange={onStateChecked}
          sx={styles.loanAssociation.dropdown}
        />
        <Button
          disabled={!allStates}
          onClick={() => onSubmitStates(selectedStates)}
          sx={styles.loanAssociation.submitButton}
        >
          Submit
        </Button>
      </div>

      {!!allStates && (
        <>
          {selectedStates.map((state) => (
            <Chip
              key={state.id}
              label={state.name}
              variant="outlined-squared"
              onDelete={onRemoveState(state.id)}
              sx={{ marginRight: '12px' }}
            />
          ))}
        </>
      )}
    </div>
  )
}

LoanDocumentStates.propTypes = {
  loanDocumentStates: PropTypes.array.isRequired,
  onSubmitStates: PropTypes.func.isRequired,
}

export default LoanDocumentStates
