import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import DraggableItem from './draggable-element'
import './style.scss'

/**
 * Generic Draggable List component
 *
 * @param {Object} options
 * @param {Array} options.list
 * @param {Function} options.onListChange - callback for when the list order gets changed
 * @param {Function} options.renderElement - how do individually render each list item
 * @param {String} options.type - type of elements that can be dropped in this DND list
 * @return {*}
 * @constructor
 */
const DraggableList = ({ list, onListChange, renderElement, type }) => {
  // On card move adjust the list
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = list[dragIndex]
      const newList = [...list]
      // Remove the currently-dragged item from the list
      newList.splice(dragIndex, 1)
      // Add it back starting at the index it's hovering over
      newList.splice(hoverIndex, 0, dragCard)

      onListChange(newList, dragIndex, hoverIndex)
    },
    [list]
  )

  if (!list) {
    return null
  }

  return (
    <div className="dnd-list">
      {list.map((element, index) => (
        <DraggableItem
          key={element?.id || element?.guid}
          index={index}
          moveCard={moveCard}
          type={type}
        >
          {renderElement(element, index)}
        </DraggableItem>
      ))}
    </div>
  )
}

DraggableList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      guid: PropTypes.any,
    })
  ),
  onListChange: PropTypes.func.isRequired,
  renderElement: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default DraggableList
