import React from 'react'
import PropTypes from 'prop-types'

import DecisionElement from './decision-element'
import { generateEmptyDecision } from './utils'

/** Recursive component to render the Decision List */
const DecisionList = ({
  list,
  criteria = [],
  comparators = [],
  onListChange,
  unNestFrom,
  depth = 1,
  errors,
}) => {
  /** Triggered when attributes of an element change */

  const onItemChange = (element) => {
    const newList = list.map((el) => (el.id === element.id ? element : el))
    onListChange(newList)
  }

  /** Triggered when the sub-list from an element changes */
  const onSubListChange = (id, decisions) => {
    const newList = list.map((el) => (el.id === id ? { ...el, decisions } : el))
    onListChange(newList)
  }

  /** Remove a decision element from inside a template */
  const onItemRemove = (index) => {
    const newList = [...list]
    newList.splice(index, 1)
    onListChange(newList)
  }

  /** Add a new decision element under the current one */
  const onItemAdd = (index) => {
    const newList = [...list]
    newList.splice(index + 1, 0, generateEmptyDecision())
    onListChange(newList)
  }

  /** Nest an item under its predecessor */
  const onNestUnder = (element, index) => {
    const newList = [...list]
    const parentElement = newList[index - 1]
    // Append it to the parent's decision list
    if (!parentElement.decisions) {
      parentElement.decisions = []
    }
    parentElement.decisions.push({ ...element })
    // Remove it from the current list
    newList.splice(index, 1)

    onListChange(newList)
  }

  /** Un-nest an item from it's parent */
  const onUnNestFrom = (parentList, parentIndex, childEl, childIndex) => {
    const newList = [...parentList]
    // Remove it from the parent's list
    newList[parentIndex].decisions.splice(childIndex, 1)
    // Append the item to the list
    newList.splice(parentIndex + 1, 0, { ...childEl })

    onListChange(newList)
  }

  return (
    <div style={{ marginTop: '24px' }}>
      {list.map((itm, index) => {
        return (
          <DecisionElement
            index={index}
            element={itm}
            errors={errors[index]}
            key={index}
            onElementChange={onItemChange}
            onElementRemove={() => onItemRemove(index)}
            onNewElement={() => onItemAdd(index)}
            canBeRemoved={list.length > 1}
            nestUnder={() => onNestUnder(itm, index)}
            unNestFrom={unNestFrom}
            criteria={criteria}
            onListChange={onListChange}
            comparators={comparators}
          >
            {itm.decisions && (
              <DecisionList
                list={itm.decisions}
                onListChange={(newList) => onSubListChange(itm.id, newList)}
                unNestFrom={(childEl, childIdx) =>
                  onUnNestFrom(list, index, childEl, childIdx)
                }
                depth={depth + 1}
                parentId={itm.id}
                errors={errors[index]?.decisions || {}}
                criteria={criteria}
                comparators={comparators}
              />
            )}
          </DecisionElement>
        )
      })}
    </div>
  )
}

DecisionList.propTypes = {
  list: PropTypes.array.isRequired,
  criteria: PropTypes.array,
  comparators: PropTypes.array,
  onListChange: PropTypes.func.isRequired,
  unNestFrom: PropTypes.func,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  depth: PropTypes.number,
  errors: PropTypes.object.isRequired,
}

export default DecisionList
