import { reverseObject } from '../../common/utils'

export const LOAN_APP_TYPES = {
  RESIDENTIAL: 'Residential',
  COMMERCIAL: 'Commercial',
}

export const LOAN_APP_TYPES_ID = {
  RESIDENTIAL: 1,
  COMMERCIAL: 2,
}

export const STEP_PATH = {
  RESIDENTIAL: 'new-loan-app',
  COMMERCIAL: 'new-commercial-loan-app',
}

export const STEPS_MAP = {
  NEW_LOAN_APPLICATION: 0,
  SELECT_LOAN: 1,
  LENDER_AGREEMENT: 2,
  LOAN_DOCUMENTS: 3,
  NTP: 4,
  PTO_SUBMISSION: 5,
  CONGRATS: 6,
}

export const STEPS_MAP_COMMERCIAL = {
  NEW_LOAN_APPLICATION: 0,
  SELECT_LOAN: 1,
  LENDER_AGREEMENT: 2,
  COMMERCIAL_NTP: 3,
  CONGRATS: 4,
}

export const STEPS_MAP_REVERSED = reverseObject(STEPS_MAP)
export const STEPS_MAP_REVERSED_COMMERCIAL = reverseObject(STEPS_MAP_COMMERCIAL)

// To see the STATES TO STEPS mapping check {@see /modules/loan-application/states/index.js}

export const STATES_ORDER = {
  ApplicationStarted: 0,
  EvaluationFailed: 1,
  ApplicationEvaluated: 1,
  LoanSelected: 2,
  AgreementsAccepted: 3,
  ApplicationAuthorized: 4,
  DocumentsGenerated: 5,
  DocumentsSigned: 6,
  NTPSubmitted: 7,
  NTPRejected: 26,
  NTPReSubmitted: 27,
  NTPApproved: 8,
  IFRequested: 9,
  IFGranted: 10,
  FNTPSubmitted: 11,
  FNTPRejected: 28,
  FNTPReSubmitted: 29,
  FNTPApproved: 12,
  FFRequested: 13,
  FFGranted: 14,
  PTOSubmitted: 23,
  PTODeclined: 24,
  PTO: 25, // Congratulation step
  ApplicationExpired: 20,
}

export const STATES_ORDER_COMMERCIAL = {
  ApplicationStarted: 0,
  EvaluationFailed: 1,
  ApplicationEvaluated: 1,
  LoanSelected: 2,
  AgreementsAccepted: 3,
  ApplicationAuthorized: 4,
  CommercialDocumentationReview: 21,
  CommercialStipulationsApproved: 22,
  ApplicationExpired: 20,
}

export const LOAN_APPLICATION_STATES_IDS = {
  APPLICATION_STARTED: 0,
  APPLICATION_EVALUATED: 1,
  LOAN_SELECTED: 2,
  AGREEMENTS_ACCEPTED: 3,
  APPLICATION_AUTHORIZED: 4,
  DOCUMENTS_GENERATED: 5,
  DOCUMENTS_SIGNED: 6,
  NTP_SUBMITTED: 7,
  NTP_APPROVED: 8,
  IF_REQUESTED: 9,
  IF_GRANTED: 10,
  FNTP_SUBMITTED: 11,
  FNTP_APPROVED: 12,
  FF_REQUESTED: 13,
  FF_GRANTED: 14,
  EVALUATION_FAILED: 15,
  APPLICATION_EXPIRED: 20,
  COMMERCIAL_DOCUMENTATION_REVIEW: 21, // CommercialDocumentationReview - 21
  COMMERCIAL_STIPULATIONS_APPROVED: 22, // CommercialStipulationsApproved - 22
  PTO_SUBMITTED: 23,
  PTO_DECLINED: 24,
  PTO: 25, // Congratulation step
  INITIAL_NTP_REJECTED: 26,
  INITIAL_NTP_RESUBMITTED: 27,
  FINAL_NTP_REJECTED: 28,
  FINAL_NTP_RESUBMITTED: 29,
}

export const STATE_ID_TO_NAME = {
  ...reverseObject(STATES_ORDER),
  15: 'EvaluationFailed',
}

export const STATE_ID_TO_NAME_COMMERCIAL = {
  ...reverseObject(STATES_ORDER_COMMERCIAL),
  15: 'EvaluationFailed',
}

export const STEP_URLS = {
  NEW_LOAN_APPLICATION: {
    path: STEP_PATH.RESIDENTIAL,
    idx: 0,
  },
  SELECT_LOAN: {
    path: 'select-loan',
    idx: 1,
  },
  LENDER_AGREEMENT: {
    path: 'lender-agreement',
    idx: 2,
  },
  LOAN_DOCUMENTS: { path: 'loan-documents', idx: 3 },
  NTP: { path: 'ntp', idx: 4 },
  PTO_SUBMISSION: { path: 'pto-submission', idx: 5 },
  CONGRATS: { path: 'congratulations', idx: 6 },
}

export const STEP_URLS_COMMERCIAL = {
  NEW_LOAN_APPLICATION: {
    path: STEP_PATH.COMMERCIAL,
    idx: 0,
  },
  SELECT_LOAN: {
    path: 'select-loan',
    idx: 1,
  },
  LENDER_AGREEMENT: {
    path: 'lender-agreement',
    idx: 2,
  },
  COMMERCIAL_NTP: { path: 'commercial-ntp', idx: 3 },
  CONGRATS: { path: 'congratulations', idx: 4 },
}
export const IDENTIFICATION_INFO = {
  number: '', // ID Number
  idIssueState: {},
  expirationDate: '',
  issuanceDate: '',
  dateOfBirth: '',
  motherMaidenName: '',
  identificationDataId: undefined,
  applicantId: undefined,
  idType: {},
}

export const INCOME_INFO = {
  borrowerActualIncome: 0,
  borrowerStatedIncome: 0,
  borrowerActualDTI: 0,
  borrowerStatedDTI: 0,
  borrowerFico: 0,
  coBorrowerActualIncome: 0,
  coBorrowerStatedIncome: 0,
  coBorrowerActualDTI: 0,
  coBorrowerStatedDTI: 0,
  coBorrowerFico: 0,
  combinedActualIncome: 0,
  combinedrActualDTI: 0,
  combinedStatedIncome: 0,
  combinedStatedDTI: 0,
  combinedFico: 0,
}
export const SOLAR_INFO = {
  pricePerWatt: 0,
  systemSize: 0,
  batteryCount: 0,
  insuranceType: '',
  nonSolarAmount: 0,
}

export const ACH_FORM = {
  achId: null,
  achVerificationAttempts: 0,
  status: {},
  firstName: '',
  lastName: '',
  routingNumber: '',
  accountNumber: '',
  bankName: '',
  accountTypeId: '',
  preferredWithdrawDay: null,
}

export const ACH_STATUS = {
  NOT_STARTED: 0,
  COMPLETE: 1,
  INCOMPLETE: 2,
  NEEDS_REVIEW: 3,
}

export const LOAN_DECISION_STATUS = {
  FAIL: 0,
  PASS: 1,
}

export const AGREEMENT_TYPES = {
  LENDER: 'lender',
  ACH: 'ach',
  MEMBERSHIP: 'membership',
}

export const AMORTIZATION_TYPES = {
  UNDEFINED: 0,
  NONE: 1,
  SIMPLE: 2,
  MINIMUM_PAYMENT: 3,
  INTEREST_RATE_FEE: 4,
  INTEREST_RATE_DISCOUNT: 5,
  MINIMUM_PAYMENT_WITH_DISCOUNTED_INTREST_RATE: 6,
  WORST_CASE: 7,
}

export const DOCUMENT_ROLE_TYPES = {
  ANY: 0,
  BORROWER: 1,
  COBORROWER: 2,
}

export const DOCUMENT_RECIPIENT_STATUS = {
  DECLINED: 4,
  COMPLETED: 5,
}

export const ENVELOPE_STATUS = { COMPLETED: 3 }

export const DEFAULT_LOAN_FORM_DATA = {
  loanApplicationId: '',
  salesOrganizationId: '',
  salesOrganizationName: '',
  salesRepresentativeId: '',
  salesRepresentativeName: '',
  serviceAddress: {},
  selectedLoanSignatures: [],
  borrowerGeneralDetails: {
    billingAddress: {},
  },
  coBorrowerGeneralDetails: {
    billingAddress: {},
  },
  incomeData: {},
  propertyTitleReportId: '',
  isOnHold: false,
  loanAmountDecreased: false,
  loanAmountIncreased: false,
  cancelled: false,
  creditExpired: false,
  hasReversal: false,
  agingTier: {},
  isDuplicateAddress: false,
  isDuplicateSsn: false,
  bypassAddressValidation: false,
  loanApplicationNumber: '',
  selectedLoanProductId: '',
  selectedLoanProduct: {},
}

export const DEFAULT_COMMERCIAL_LOAN_FORM_DATA = {
  loanApplicationId: '',
  salesOrganizationId: '',
  salesOrganizationName: '',
  salesRepresentativeId: '',
  salesRepresentativeName: '',
  serviceAddress: {},
  building: {},
  businessOwners: [],
}

export const LOAN_STATUSES_COLOR_CLASSNAME = {
  Active: 'loan-status--active',
  Canceled: 'loan-status--canceled',
  OnHold: 'loan-status--on-hold',
  Declined: 'loan-status--declined',
  LoanComplete: 'loan-status--loan-complete',
  ActionRequired: 'loan-status--action-required',
}

export const LOAN_APPLICATION_STATUSES = {
  Active: 0,
  LoanComplete: 1,
  Declined: 2,
  OnHold: 3,
  Canceled: 4,
  ActionRequired: 5,
}

export const STIPULATION_CATEGORY_IDS = {
  ID_SCAN: 1,
  VERIFICATION_OF_INCOME: 2,
}

export const PTO_STATUS_IDS = {
  SUBMITTED: 1,
  APPROVED: 2,
  DECLINED: 3,
}

export const PULL_TYPES = {
  SOFT: 2,
  HARD: 1,
}

export const EVALUATION_IDS = {
  FAILED: 0,
  APPROVED: 1,
  BRANCH: 2,
}
