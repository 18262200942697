import { merge } from 'lodash'
import i18n from 'i18next'
import Api from 'easy-fetch-api'
import { decode } from 'js-base64'

import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'

import { addTranslations, switchLanguage } from './i18n'

const translationsHost = process.env.REACT_APP_HOST_TRANSLATIONS
const ENGLISH_LANGAGE_ID = 0

/** Load the global english translations for the entire website */
export const loadGlobalTranslations = async () => {
  const language = await getDefaultLanguageAbbreviation()

  return fetchTranslation(language).then((translations) =>
    addTranslations(language, translations)
  )
}

/**
 * Loads the Translation(s) for a certain Loan App & switches the language to it
 * Gets the default translation + the org-specific one and merges them
 *
 * @param {Object} options
 * @param {String} [options.language] one of {@see LANGUAGES}
 * @param {String} options.orgId
 * @return Promise
 */
export const loadTranslationsForLoanApp = async ({ language, orgId }) => {
  const languageKey = getTranslationKeyForOrg(language, orgId)

  // If the translation was already loaded skip loading it again
  if (i18n.languages.includes(languageKey)) {
    return
  }

  const defaultTranslation = await fetchTranslation(language)
  const orgSpecificTranslation = (await fetchTranslation(language, orgId)) || {}

  // Store the translation for this language
  addTranslations(
    languageKey,
    merge(defaultTranslation, orgSpecificTranslation)
  )
  await switchLanguage(languageKey)
}

export const getTranslationKeyForOrg = (language, orgId) =>
  `${language}_${orgId}`

export const resetLanguage = async () => {
  const language = await getDefaultLanguageAbbreviation()
  switchLanguage(language)
}

/** Helper method to load 1 translation file */
export const fetchTranslation = (language, orgId) => {
  const fileName = orgId ? `${orgId}/${language}` : language
  return fetch(`${translationsHost}/${fileName}.json`)
    .then((res) => res.json())
    .catch(() => console.error('No translation for', language, orgId || ''))
}

export const fetchLanguages = async () => {
  return Api.get({
    url: '/Organizations/languages',
  }).catch((error) => console.error(error))
}

const getDefaultLanguageAbbreviation = async () => {
  const languages = await fetchLanguages()

  if (!languages?.length) {
    return
  }

  return languages.find((l) => l.id === ENGLISH_LANGAGE_ID).abbreviation
}

export const getTranslations = async (languageId, orgId) => {
  const query = {
    languageId: languageId,
  }

  if (orgId) {
    query.organizationId = orgId
  }

  try {
    const result = await Api.get({
      url: `/Translations`,
      query: query,
    })

    const decodedTranslations = decode(result?.content)
    return JSON.parse(decodedTranslations)
  } catch (e) {
    console.error('No translation for', languageId, orgId || '')
  }
}

export const editTranslation = async (data, dispatch) => {
  try {
    await Api.post({
      url: `/Translations`,
      data,
    })
  } catch (e) {
    showNotification(dispatch, {
      type: NOTIFICATION_TYPES.NEGATIVE,
      title: `Failed to edit translation`,
    })

    throw e
  }
}
