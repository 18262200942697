import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'

import { TABLET_BREAKPOINT } from 'common/constants'
import { useMediaQuery } from 'common/hooks'
import { ExpandableCard } from 'components/expandable-card'
import { formatAddress } from './columns'

const { Button } = components

const PropertyTitleMobile = ({ details, viewFullDetails }) => {
  const { t: translate } = useTranslation()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const collapsibleItems = [
    <div key={0} className="uw-hub-page__property-title-item">
      <span>{translate('uwHub.propertyTitle.ownerName')}:</span>
      <span>{details.ownerName}</span>
    </div>,
    <div key={1} className="uw-hub-page__property-title-item">
      <span>{translate('uwHub.propertyTitle.propertyType')}:</span>
      <span>{details.propertyType}</span>
    </div>,
    <div key={1} className="uw-hub-page__property-title-item">
      <span>{translate('uwHub.propertyTitle.landUse')}:</span>
      <span>{details.landUse}</span>
    </div>,
    <div key={2} className="uw-hub-page__property-title-item">
      <span>{translate('uwHub.propertyTitle.address')}:</span>
      <span>{formatAddress(details.address)}</span>
    </div>,
    <div key={3} style={{ width: '100%' }}>
      <Button
        color="inherit"
        variant="outlined"
        onClick={viewFullDetails}
        sx={{
          width: '100%',
        }}
      >
        {translate('uwHub.propertyTitle.viewFullDetailsButton')}
      </Button>
    </div>,
  ]

  return (
    <ExpandableCard
      headerProps={{
        sx: {
          backgroundColor: '#ffffff',
          padding: '16px',
        },
      }}
      titleTypographyProps={{
        sx: {
          fontSize: '0.875rem',
          fontWeight: '700',
        },
      }}
      title={translate('uwHub.propertyTitle.title')}
      collapsibleItems={collapsibleItems}
      isCollapsable={true}
      isCollapsed={isMobileView}
      alwaysCollapsible={true}
      collapsibleItemsStyles={{
        sx: {
          padding: !isMobileView ? 0 : '12px 16px',
        },
      }}
    />
  )
}

PropertyTitleMobile.propTypes = {
  details: PropTypes.object.isRequired,
  viewFullDetails: PropTypes.func.isRequired,
}

export default PropertyTitleMobile
