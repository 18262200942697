import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import { dateFormatUS } from 'common/constants'
import Table from 'components/table'
import { useMemo } from 'react'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Status, { STATUSES } from 'components/status'

const verificationFields = [
  'firstName',
  'lastName',
  'issuedOn',
  'expiresOn',
  'dateOfBirth',
  'address',
]

const { createColumnHelper } = components

export const ApplicantIdVerification = ({ verificationResults }) => {
  const columnHelper = createColumnHelper()
  const { t: translate } = useTranslation()

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('data', {
        header: translate(
          'loanApplication.ecgToolbox.identityVerification.colData'
        ),
      }),
      columnHelper.accessor('loanAppData', {
        id: 'loanAppData',
        header: translate(
          'loanApplication.ecgToolbox.identityVerification.loanAppData'
        ),
      }),
      columnHelper.accessor('scannedData', {
        id: 'scannedData',
        header: translate(
          'loanApplication.ecgToolbox.identityVerification.scannedData'
        ),
      }),
      columnHelper.accessor('verification', {
        id: 'verification',
        header: translate(
          'loanApplication.ecgToolbox.identityVerification.verification'
        ),
        cell: ({ row }) => {
          return (
            <Status
              status={
                row.original.verification === 'Valid'
                  ? STATUSES.ACTIVE
                  : STATUSES.INACTIVE
              }
              size="small"
              label={row.original.verification}
            />
          )
        },
      }),
    ]
  }, [])

  const formatTableValue = (value) => {
    if (!value) {
      return '-'
    }

    if (moment(value).isValid()) {
      return moment(value).format(dateFormatUS)
    }
    return value
  }

  const dataForTable = useMemo(() => {
    return verificationFields.map((field) => {
      const loanAppData = formatTableValue(
        verificationResults[field]?.loanApplicationValue
      )

      const scannedData = formatTableValue(
        verificationResults[field]?.scannedValue
      )

      const verification = translate(
        `loanApplication.ecgToolbox.identityVerification.idVerificationStatus.${
          verificationResults[field]?.valid ? 'valid' : 'notValid'
        }`
      )

      return {
        data: translate(
          `loanApplication.ecgToolbox.identityVerification.verificationSummaryFields.${field}`
        ),
        loanAppData,
        scannedData,
        verification,
      }
    })
  }, [verificationResults])

  return (
    <div className="applicant-verification__padding">
      <Table
        title={translate(
          'loanApplication.ecgToolbox.identityVerification.idScanningSummary'
        )}
        data={dataForTable}
        columns={columns}
      />
    </div>
  )
}

ApplicantIdVerification.propTypes = {
  verificationResults: PropTypes.shape({
    firstName: PropTypes.object.isRequired,
    lastName: PropTypes.object.isRequired,
    issuedOn: PropTypes.object.isRequired,
    expiresOn: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
  }),
}
