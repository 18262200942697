import React, { useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useClickOutside } from 'common/hooks'
import Checkbox from 'components/checkbox'
import { ReactComponent as DownArrow } from 'assets/down-arrow.svg'
import { ReactComponent as CheckIcon } from 'assets/check-icon-slim.svg'

import './index.scss'

function DropdownMultiple({
  selectedItems = [],
  setSelectedItems,
  label,
  options = [],
  className,
  error,
  placeholder,
  customSvg,
  disabled,
  isSearchable,
  hideSelectAll,
  customBtn,
}) {
  const ref = useRef()
  const searchRef = useRef()
  const { t: translate } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const [search, setSearch] = useState('')
  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.value.toLowerCase().includes(search.toLowerCase())
      ),
    [search, options]
  )
  const wrapperClass = cx('dropdown__wrapper', className, {
    'dropdown__wrapper--has-error': !!error,
    'dropdown__wrapper--disabled': disabled || options.length === 0,
  })

  const onClickOutside = () => setIsExpanded(false)
  useClickOutside(ref, onClickOutside)

  const CustomBtn = customBtn
    ? React.cloneElement(customBtn, {
        onClick: () => !disabled && onExpanded(!isExpanded),
      })
    : null

  /** Triggered when an item is toggled */
  const onOptionCheck = (option) => () => {
    const items = [...selectedItems]
    const idxOfItem = items.findIndex(({ id }) => id === option.id)
    // If option is not selected, select it
    if (idxOfItem === -1) {
      items.push({ ...option })
    } else {
      // Else remove it
      items.splice(idxOfItem, 1)
    }
    setSelectedItems(items)
  }

  /** Triggered when the dropdown is expanded/collapsed */
  const onExpanded = (expanded) => {
    if (expanded) {
      setTimeout(() => searchRef && searchRef.current?.focus(), 100)
    }
    setIsExpanded(expanded)
  }

  const onSelectAll = () => setSelectedItems([...filteredOptions])
  const onSelectNone = () => setSelectedItems([])
  const hasItems = selectedItems.length > 0

  return (
    <div ref={ref} className={wrapperClass}>
      {label && <span className="dropdown__label">{label}</span>}
      <div className="dropdown dropdown--multi" role="listbox" tabIndex={0}>
        {CustomBtn ? (
          CustomBtn
        ) : (
          <div
            className="dropdown__btn"
            onClick={() => !disabled && onExpanded(!isExpanded)}
          >
            <div
              className={cx('dropdown__selected', {
                'dropdown--multi__has-items': hasItems,
              })}
            >
              {hasItems && <CheckIcon />}
              <span>{placeholder || 'Select an option'}</span>
            </div>
            <div></div>
            {customSvg ? customSvg : <DownArrow />}
          </div>
        )}

        <div
          className={cx('dropdown__content', {
            'dropdown__content--expanded': isExpanded,
          })}
        >
          {isSearchable && (
            <input
              ref={searchRef}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="dropdown__search"
              placeholder="Search for options"
            />
          )}

          {isExpanded && (
            <div>
              {!hideSelectAll && (
                <div className="dropdown--multi__actions">
                  <span onClick={onSelectAll}>Select All</span> |{' '}
                  <span onClick={onSelectNone}>Select None</span>
                </div>
              )}

              {filteredOptions.map((option, key) => {
                const displayedValue = option.translationKey
                  ? translate(option.translationKey)
                  : option.value
                const isChecked = selectedItems.some(
                  (el) => el.id === option.id
                )

                const diplayedItem = (
                  <span
                    className={option.labelClassName && option.labelClassName}
                  >
                    {displayedValue}
                  </span>
                )

                return (
                  <Checkbox
                    label={diplayedItem}
                    onClick={onOptionCheck(option)}
                    checked={isChecked}
                    key={key}
                    role="option"
                    aria-selected="true"
                    tabIndex={0}
                    className="dropdown__item"
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  )
}

const optionPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // ID of the Option
  value: PropTypes.any, // Value for the option (what gets displayed)
  translationKey: PropTypes.any, // Value for the option translation key (determines what gets displayed)
})

DropdownMultiple.propTypes = {
  selectedItems: PropTypes.arrayOf(optionPropType),
  setSelectedItems: PropTypes.func.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(optionPropType),
  className: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  customSvg: PropTypes.node,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  hideSelectAll: PropTypes.bool,
  customBtn: PropTypes.node,
}

export default DropdownMultiple
