import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Proptypes from 'prop-types'
import Checkbox from 'components/checkbox'
import Modal from 'components/modal'
import './style.scss'

const TERMS_OF_USE_COUNT = 20
const PRIVACY_POLICIES_COUNT = 11

/**
 * Component that holds 3 modals
 *    - Acknowledgement & Auth - Mandatory
 *    - T&C and Privacy Policy - Mandatory
 *    - Electronic Record and Signature Disclosure - Optional
 *
 * The results from these modals are passed on the onConfirm param in an array of 2 items, one for each possible applicant {@see DISCLOSURE_STATUSES}
 */
const TermsAndConditionsModals = ({
  isTermsOpened,
  setTermsOpened,
  onConfirm,
  hasCoBorrower = false,
  isAuthAgreed,
  setIsAuthAgreed,
  isTCAgreed,
  setIsTCAgreed,
  isElectronicAgreed,
  setIsElectronicAgreed,
}) => {
  const [isAuthOpened, setIsAuthOpened] = useState(true)
  const [isElectronicOpened, setIsElectronicOpened] = useState(false)
  const [isTCOpened, setIsTCOpened] = useState(false)
  const [isCoBorrowerAgreed, setIsCoBorrowerAgreed] = useState(true)
  const { t: translate, i18n } = useTranslation()

  const phoneNumber = translate(
    'loanApplication.step1.termsAndConditions.phoneNumber'
  )
  const email = translate('loanApplication.step1.termsAndConditions.email')
  const termsOfUse = Array.from(Array(TERMS_OF_USE_COUNT).keys())
    .map((index) => {
      const key = `loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.termsOfUse.info.${index}`

      return i18n.exists(key) ? translate(key) : null
    })
    .filter((termOfUse) => termOfUse)
  const privacyPolicies = Array.from(Array(PRIVACY_POLICIES_COUNT).keys())
    .map((index) => {
      const key = `loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.privacyPolicy.info.${index}`

      return i18n.exists(key) ? translate(key) : null
    })
    .filter((termOfUse) => termOfUse)

  /** Triggered on last modal - submits the values from all Disclosure modals */
  const submitTermsAndConditions = () => {
    const disclosureStatuses = [
      {
        ...DISCLOSURE_STATUSES,
        electronicRecordAndSignatureDisclosureAccepted: isElectronicAgreed,
      },
      {
        ...DISCLOSURE_STATUSES,
        electronicRecordAndSignatureDisclosureAccepted: isCoBorrowerAgreed,
      },
    ]
    onConfirm(disclosureStatuses)
    closeModals()
  }

  const closeModals = () => {
    // Reset the inner states
    setIsAuthOpened(true)
    setIsAuthAgreed(false)
    setIsTCOpened(false)
    setIsTCAgreed(false)
    setIsElectronicOpened(false)
    setIsElectronicAgreed(false)
    setIsCoBorrowerAgreed(false)
    // And mark the modals as closed
    setTermsOpened(false)
  }

  return (
    <div className="t-and-c">
      {/* Acknowledgement & Authorization */}
      <Modal
        buttonEnd
        isConfirmButtonDisabled={!isAuthAgreed}
        isOpen={isTermsOpened && isAuthOpened}
        onClose={closeModals}
        confirmButton={translate(
          'loanApplication.step1.termsAndConditions.agreeButton'
        )}
        onConfirm={() => {
          setIsAuthOpened(false)
          setIsTCOpened(true)
        }}
      >
        <div className="applicant-info__modal">
          <div className="applicant-info__modal-title">
            {translate(
              'loanApplication.step1.termsAndConditions.acknowledgementAndAuthorization.title'
            )}
          </div>
          <div className="applicant-info__modal-text">
            <div
              dangerouslySetInnerHTML={{
                __html: translate(
                  'loanApplication.step1.termsAndConditions.acknowledgementAndAuthorization.info'
                ),
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: translate(
                  'loanApplication.step1.termsAndConditions.acknowledgementAndAuthorization.contactInfo',
                  { phoneNumber, email }
                ),
              }}
            ></div>
          </div>
          <Checkbox
            onClick={() => setIsAuthAgreed(!isAuthAgreed)}
            checked={isAuthAgreed}
            label={translate(
              'loanApplication.step1.termsAndConditions.acknowledgementAndAuthorization.checkbox'
            )}
            uncentered
          />
        </div>
      </Modal>

      {/* Terms and Conditions Modal*/}
      <Modal
        buttonEnd
        isConfirmButtonDisabled={!isTCAgreed}
        isOpen={isTermsOpened && isTCOpened}
        onClose={closeModals}
        confirmButton={translate(
          'loanApplication.step1.termsAndConditions.agreeButton'
        )}
        onConfirm={() => {
          setIsTCOpened(false)
          setIsElectronicOpened(true)
        }}
      >
        <div className="applicant-info__modal">
          <div className="applicant-info__modal-title">
            {translate(
              'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.title'
            )}
          </div>
          <div className="applicant-info__modal-text">
            <div className="t-and-c__block">
              <div
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.welcomeText'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.termsOfUse.title'
                )}
              </div>
              <div className="t-and-c__description">
                <div
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.termsOfUse.info.text'
                    ),
                  }}
                ></div>
              </div>
              {termsOfUse.map((termOfUse, index) => {
                return (
                  <div className="t-and-c__description" key={index}>
                    <div dangerouslySetInnerHTML={{ __html: termOfUse }}></div>
                  </div>
                )
              })}
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.privacyPolicy.title'
                )}
              </div>
              {privacyPolicies.map((privacyPolicy, index) => {
                return (
                  <div className="t-and-c__description" key={index}>
                    <div
                      dangerouslySetInnerHTML={{ __html: privacyPolicy }}
                    ></div>
                  </div>
                )
              })}
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.applicantCustomerIdentificationProgram.title'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.applicantCustomerIdentificationProgram.info'
                  ),
                }}
              ></div>

              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.contactInfo',
                    { phoneNumber, email }
                  ),
                }}
              ></div>
            </div>
          </div>
          <Checkbox
            onClick={() => setIsTCAgreed(!isTCAgreed)}
            checked={isTCAgreed}
            label={translate(
              'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.checkbox'
            )}
            uncentered
          />
        </div>
      </Modal>

      {/* Electronic Record and Signature Disclosure */}
      <Modal
        buttonEnd
        isOpen={isTermsOpened && isElectronicOpened}
        onClose={closeModals}
        confirmButton={translate(
          'loanApplication.step1.termsAndConditions.submitButton'
        )}
        onConfirm={submitTermsAndConditions}
      >
        <div className="applicant-info__modal">
          <div className="applicant-info__modal-title">
            {translate(
              'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.title'
            )}
          </div>
          <div className="applicant-info__modal-text">
            <div className="t-and-c__block">
              <div
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.description'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.paperCopiesTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.paperCopiesInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.withdrawingConsentTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.withdrawingConsentInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.consequencesTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.consequencesInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.electronicDisclosuresTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.electronicDisclosuresInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.telephoneDisclosureTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.telephoneDisclosureInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.howToContactTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.howToContactInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toAdviseTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toAdviseInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toRequestPaperCopiesTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toRequestPaperCopiesInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toWithdrawConsentTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toWithdrawConsentInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.requiredHardwareAndSoftwareTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.requiredHardwareAndSoftwareInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.browsersTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.browsersInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.mobileSigningTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.mobileSigningInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.securitySettingsTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.securitySettingsInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.acknowledgementTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.acknowledgementInfo'
                  ),
                }}
              ></div>
            </div>

            {hasCoBorrower && (
              <div className="checkbox__label" style={{ marginBottom: '20px' }}>
                {translate('loanApplication.common.borrower')}:
              </div>
            )}
            <Checkbox
              onClick={() => setIsElectronicAgreed(true)}
              checked={isElectronicAgreed}
              label={translate(
                'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.agreeCheckbox'
              )}
              uncentered
            />
            <Checkbox
              onClick={() => setIsElectronicAgreed(false)}
              checked={!isElectronicAgreed}
              label={translate(
                'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.doNotAgreeCheckbox'
              )}
              uncentered
              style={{ marginTop: '20px' }}
            />

            {hasCoBorrower && (
              <>
                <div
                  className="checkbox__label"
                  style={{ marginBottom: '20px', marginTop: '30px' }}
                >
                  {translate('loanApplication.common.coborrower')}:
                </div>
                <Checkbox
                  onClick={() => setIsCoBorrowerAgreed(true)}
                  checked={isCoBorrowerAgreed}
                  label={translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.agreeCheckbox'
                  )}
                  uncentered
                />
                <Checkbox
                  onClick={() => setIsCoBorrowerAgreed(false)}
                  checked={!isCoBorrowerAgreed}
                  label={translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.doNotAgreeCheckbox'
                  )}
                  uncentered
                  style={{ marginTop: '20px' }}
                />
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

const DISCLOSURE_STATUSES = {
  acknowledgementAndAuthorizationDisclosureAccepted: true,
  termsAndConditionsDisclosureAccepted: true,
  electronicRecordAndSignatureDisclosureAccepted: false,
}

export default TermsAndConditionsModals

TermsAndConditionsModals.propTypes = {
  onConfirm: Proptypes.func.isRequired,
  isTermsOpened: Proptypes.bool,
  setTermsOpened: Proptypes.func.isRequired,
  hasCoBorrower: Proptypes.bool,
  isAuthAgreed: Proptypes.bool.isRequired,
  setIsAuthAgreed: Proptypes.func.isRequired,
  isTCAgreed: Proptypes.bool.isRequired,
  setIsTCAgreed: Proptypes.func.isRequired,
  isElectronicAgreed: Proptypes.bool.isRequired,
  setIsElectronicAgreed: Proptypes.func.isRequired,
}
