import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import TextField, { INPUT_TYPES } from 'components/input'
import { formatToPercentage } from 'modules/loan-application/utils'

const IncomeDetails = ({
  hasCoBorrower,
  incomeDetails,
  setIncomeDetails,
  areInputsDisabled,
  canEditUWFields,
  canUpdateActualDTIFIelds,
  areFieldsDisabledAfterIfRequestedState,
}) => {
  const { t: translate } = useTranslation()
  const handleOnChange = (name, value) => {
    setIncomeDetails({ ...incomeDetails, [name]: value })
  }
  const labels = {
    borrower: translate('loanApplication.step1.borrower'),
    coBorrower: translate('loanApplication.step1.coBorrower'),
    combined: translate('loanApplication.step1.combined'),
    actualIncome: translate('loanApplication.step1.income.actualIncome'),
    statedIncome: translate('loanApplication.step1.income.statedIncome'),
    actualDTI: translate('loanApplication.step1.income.actualDTI'),
    statedDTI: translate('loanApplication.step1.income.statedDTI'),
    fico: translate('loanApplication.step1.income.fico'),
  }

  return (
    <>
      <div
        className="applicant-info__form-title"
        style={{ marginBottom: '24px' }}
      >
        {translate('loanApplication.step1.income.title')}
      </div>
      <div className="loan-form__grid">
        {/* Borrower */}
        <TextField
          label={`${labels.borrower} ${labels.statedIncome}`}
          value={parseFloat(incomeDetails.borrowerStatedIncome).toFixed(2)}
          onChange={() => {}}
          prefix="$"
          type={INPUT_TYPES.CURRENCY}
          fixedDecimalScale={true}
          disabled={true}
        />
        <TextField
          label={`${labels.borrower} ${labels.actualIncome}`}
          value={incomeDetails.borrowerActualIncome}
          onChange={(val) => handleOnChange('borrowerActualIncome', val)}
          type={INPUT_TYPES.CURRENCY}
          fixedDecimalScale={true}
          prefix="$"
          disabled={!canEditUWFields}
        />
        <TextField
          label={`${labels.borrower} ${labels.statedDTI}`}
          value={formatToPercentage(incomeDetails.borrowerStatedDTI)}
          type={INPUT_TYPES.PERCENTAGE}
          disabled={true}
        />
        <TextField
          label={`${labels.borrower} ${labels.actualDTI}`}
          value={incomeDetails.borrowerActualDTI}
          onChange={(val) => handleOnChange('borrowerActualDTI', val)}
          fixedDecimalScale={true}
          type={INPUT_TYPES.PERCENTAGE}
          disabled={!canEditUWFields}
        />
        <TextField
          label={`${labels.borrower} ${labels.fico}`}
          value={incomeDetails.borrowerFico}
          type="number"
          disabled={true}
        />

        {/* Co-Borrower */}
        {hasCoBorrower && (
          <>
            <TextField
              label={`${labels.coBorrower} ${labels.statedIncome}`}
              value={parseFloat(incomeDetails.coBorrowerStatedIncome).toFixed(
                2
              )}
              onChange={() => {}}
              type={INPUT_TYPES.CURRENCY}
              fixedDecimalScale={true}
              className="grid-column-start"
              prefix="$"
              disabled={true}
            />
            <TextField
              label={`${labels.coBorrower} ${labels.actualIncome}`}
              value={incomeDetails.coBorrowerActualIncome}
              onChange={(val) => handleOnChange('coBorrowerActualIncome', val)}
              type={INPUT_TYPES.CURRENCY}
              fixedDecimalScale={true}
              prefix="$"
              disabled={!canEditUWFields}
            />
            <TextField
              label={`${labels.coBorrower} ${labels.statedDTI}`}
              value={formatToPercentage(incomeDetails.coBorrowerStatedDTI)}
              type={INPUT_TYPES.PERCENTAGE}
              disabled={true}
            />
            <TextField
              label={`${labels.coBorrower} ${labels.actualDTI}`}
              value={incomeDetails.coBorrowerActualDTI}
              onChange={(val) => handleOnChange('coBorrowerActualDTI', val)}
              type={INPUT_TYPES.PERCENTAGE}
              disabled={!canEditUWFields}
            />
            <TextField
              label={`${labels.coBorrower} ${labels.fico}`}
              value={incomeDetails.coBorrowerFico}
              onChange={() => {}}
              type="number"
              disabled={true}
            />
          </>
        )}

        <TextField
          label={`${labels.combined} ${labels.statedIncome}`}
          value={parseFloat(incomeDetails.combinedStatedIncome).toFixed(2)}
          onChange={() => {}}
          type={INPUT_TYPES.CURRENCY}
          fixedDecimalScale={true}
          className="grid-column-start"
          prefix="$"
          disabled={true}
        />
        <TextField
          label={`${labels.combined} ${labels.actualIncome}`}
          onChange={(val) => handleOnChange('combinedActualIncome', val)}
          value={incomeDetails.combinedActualIncome}
          type={INPUT_TYPES.CURRENCY}
          fixedDecimalScale={true}
          prefix="$"
          disabled={!canEditUWFields}
        />
        <TextField
          label={`${labels.combined} ${labels.statedDTI}`}
          value={formatToPercentage(incomeDetails.combinedStatedDTI)}
          type={INPUT_TYPES.PERCENTAGE}
          disabled={true}
        />
        <TextField
          label={`${labels.combined} ${labels.actualDTI}`}
          onChange={(val) => handleOnChange('combinedActualDTI', val)}
          value={incomeDetails.combinedActualDTI}
          type={INPUT_TYPES.PERCENTAGE}
          disabled={!canEditUWFields}
        />
        <TextField
          label={`${labels.combined} ${labels.fico}`}
          value={incomeDetails.combinedFico}
          onChange={() => {}}
          type="number"
          disabled={true}
        />
      </div>
    </>
  )
}

IncomeDetails.propTypes = {
  areInputsDisabled: PropTypes.bool.isRequired,
  incomeDetails: PropTypes.object.isRequired,
  setIncomeDetails: PropTypes.func.isRequired,
  hasCoBorrower: PropTypes.bool,
  canEditUWFields: PropTypes.bool,
  canUpdateActualDTIFIelds: PropTypes.bool,
  areFieldsDisabledAfterIfRequestedState: PropTypes.bool,
}

export default IncomeDetails
