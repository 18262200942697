import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import Checkbox from 'components/checkbox'
import TextField from 'components/input'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { customSchemaType } from 'common/prop-types'

import { SCHEMA_TYPES, ACTION_TYPES } from './constants'
import './style.scss'

const { Dropdown, Button: EniumButton, Divider } = components

const FormBuilder = ({ schema, initialState = {}, actions, viewOnly }) => {
  const { t: translate, i18n } = useTranslation()
  const [state, setState] = useState({})
  const [errors, setErrors] = useState({})
  const ref = useRef()

  /** Initialize the state */
  useEffect(() => {
    const newState = {}
    Object.entries(schema.fields || {}).forEach(([field, defaultValue]) => {
      if (typeof initialState[field] !== 'undefined') {
        newState[field] = initialState[field]
      } else {
        newState[field] = defaultValue
      }
    })

    setState(newState)
  }, [JSON.stringify(initialState)])

  /** On mount calculate the max-height of the scrolling area based on the sticky ones */
  useEffect(() => {
    const scrollableArea = ref.current.querySelector('.scrollable')
    if (!scrollableArea) {
      return
    }
    const stickySections = Array.from(ref.current.querySelectorAll('.sticky'))
    const totalHeight = stickySections.reduce(
      (total, crt) => total + crt.offsetHeight,
      0
    )
    scrollableArea.style.height = `calc(100% - ${totalHeight}px)`
  }, [])

  const onFieldChange = (fieldName, value) => {
    setState((prev) => ({ ...prev, [fieldName]: value }))
    if (errors[fieldName]) {
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: undefined }))
    }
  }

  /**
   * Renders a Section from within the Form
   * This method is recursive - in case the Section is of type WRAPPER it goes another level down
   *
   * @private
   */
  const _renderSection = (section) => {
    const { type, text, label, renderCondition, field, textKey, labelKey } =
      section
    const labelTranslated = labelKey ? translate(labelKey) : label
    const textTranslated = textKey ? translate(textKey) : text
    const options = section?.options?.map((o) => {
      return i18n.exists(o) ? translate(o) : o
    })

    // If there is a render condition defined but not met, return null
    if (renderCondition && !renderCondition(state, translate)) {
      return null
    }

    switch (type) {
      case SCHEMA_TYPES.WRAPPER:
        return section.sections.map(_renderSections)

      case SCHEMA_TYPES.CHECKBOX:
        return (
          <Checkbox
            uncentered={true}
            checked={state[field] === true || state[field] === 'true'}
            label={labelTranslated}
            onClick={() => onFieldChange(field, !state[field])}
            disabled={viewOnly}
          />
        )

      case SCHEMA_TYPES.HTML:
        return <div dangerouslySetInnerHTML={{ __html: textTranslated }}></div>

      case SCHEMA_TYPES.PARAGRAPH:
        return (
          <div
            className="form__html"
            dangerouslySetInnerHTML={{ __html: textTranslated }}
          ></div>
        )

      case SCHEMA_TYPES.INPUT: {
        return (
          <TextField
            label={labelTranslated}
            value={state[field] || ''}
            onChange={(val) => onFieldChange(field, val)}
            validate={() => errors[field]}
            disabled={viewOnly}
            fullWidth
            placeholder={labelTranslated}
          />
        )
      }

      case SCHEMA_TYPES.DROPDOWN: {
        return (
          <Dropdown
            label={labelTranslated}
            fullWidth
            options={options.map((o) => ({ id: o, label: o }))}
            value={{ id: state[field] || '', label: state[field] || '' }}
            onChange={(e) => onFieldChange(field, e.target.value.id)}
            error={errors[field]}
            disabled={viewOnly}
          />
        )
      }

      case SCHEMA_TYPES.BUTTONS:
        const validate = schema.methods?.validate

        return (
          <div className="form-buttons">
            <Divider />
            {section.buttons.map((buttonObj, idx) => {
              const { action, disabled, className, color, variant, textColor } =
                buttonObj
              const labelTranslated = buttonObj.labelKey
                ? translate(buttonObj.labelKey)
                : buttonObj.label

              if (!action || !actions[action]) {
                return null
              }

              const isDisabled = disabled ? disabled(state) : false
              return (
                <EniumButton
                  className={cx(className)}
                  color={color}
                  variant={variant}
                  disabled={isDisabled || viewOnly}
                  key={idx}
                  sx={{
                    color: textColor,
                  }}
                  onClick={() => {
                    if (action === ACTION_TYPES.SUBMIT && validate) {
                      const [isValid, errors] = validate(state, translate)
                      setErrors(errors)
                      if (!isValid) {
                        return
                      }
                    }
                    const isFieldShown = schema.methods?.isFieldShown
                    const userInput = gatherAllUserInput(
                      schema.sections,
                      state,
                      isFieldShown,
                      translate
                    )
                    actions[action](userInput)
                  }}
                >
                  {labelTranslated}
                </EniumButton>
              )
            })}
          </div>
        )
      default:
        return textTranslated
    }
  }

  const _renderSections = (section, idx) => {
    const { type, extraClass, disabled, renderCondition } = section
    const isDisabled = disabled ? disabled(state) : false
    const className = cx(`form__${type}`, extraClass, {
      disabled: isDisabled,
      hidden: renderCondition && !renderCondition(state, translate),
    })

    return (
      <section key={idx} className={className}>
        {_renderSection(section)}
      </section>
    )
  }

  return (
    <div ref={ref} className="form">
      {schema.sections.map(_renderSections)}
    </div>
  )
}

/**
 * Recursive method to get all user-input data from the form
 * Goes through all sections and extracts an Object of the form {field: {question: String, answer: any}}
 *
 * If the section is a WRAPPER it recursively goes through its children
 * If there's a "isFieldShown" method it uses it to filter the fields to gather
 *
 * @param {Array} sections - sections to iterate
 * @param {Object} state - current state (user inputted fields)
 * @param {Function} [isFieldShown] - optional method to filter fields by
 * @return {Object} { <fieldName>: {question: String(the label), answer: any} }
 */
function gatherAllUserInput(sections, state, isFieldShown, translate) {
  let questionsAndAnswers = {}
  sections.forEach((section) => {
    const { type, label, field, labelKey } = section
    if (
      [
        SCHEMA_TYPES.INPUT,
        SCHEMA_TYPES.DROPDOWN,
        SCHEMA_TYPES.CHECKBOX,
      ].includes(type)
    ) {
      if (isFieldShown && !isFieldShown(state, field, translate)) {
        return
      }
      questionsAndAnswers[field] = {
        question: labelKey ? translate(labelKey) : label,
        answer: state[field],
      }
    } else if (type === SCHEMA_TYPES.WRAPPER) {
      questionsAndAnswers = {
        ...questionsAndAnswers,
        ...gatherAllUserInput(section.sections, state, isFieldShown, translate),
      }
    }
  })
  return questionsAndAnswers
}

FormBuilder.propTypes = {
  schema: customSchemaType.isRequired,
  // Key-value object with initial value for fields (useful when in edit mode)
  initialState: PropTypes.object,
  // Mapping of actions that are mapped to the buttons (eg. { onSubmit: () => {}, onDecline: () => {} }
  actions: PropTypes.object,
  viewOnly: PropTypes.bool,
}

export default FormBuilder
