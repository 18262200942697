import React from 'react'
import PropTypes from 'prop-types'
import TextField from 'components/input'
import { useTranslation } from 'react-i18next'
import { components, unstable_components } from '@ElementsCapitalGroup/enium-ui'

const { Paper } = components
const { Unstable_Grid: Grid } = unstable_components

const AddressDetails = ({
  addressDetails,
  setAddressDetails,
  errors,
  setErrors,
  coBorrower,
  hasSameAddress,
  areInputsDisabled,
}) => {
  const { t: translate } = useTranslation()
  const borrowerField = coBorrower ? 'coBorrower' : 'borrower'
  const handleOnChange = (value, name) => {
    setAddressDetails({ ...addressDetails, [name]: value })
    setErrors({
      ...errors,
      [borrowerField]: { ...errors[borrowerField], [name]: null },
    })
  }

  const err = coBorrower ? errors.coBorrower : errors.borrower

  return (
    <Paper className="paper">
      <Grid container gap={24}>
        <Grid item className="applicant-info__form-title">
          {translate('loanApplication.step1.billingAddress')}
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            fullWidth
            label={translate('loanApplication.step1.address.address')}
            validate={() => err.addressFirstLine}
            value={addressDetails?.addressFirstLine || ''}
            onChange={(val) => handleOnChange(val, 'addressFirstLine')}
            disabled={hasSameAddress || areInputsDisabled}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            fullWidth
            label={translate('loanApplication.step1.address.address2')}
            validate={() => err.addressSecondLine}
            value={addressDetails?.addressSecondLine || ''}
            onChange={(val) => handleOnChange(val, 'addressSecondLine')}
            disabled={hasSameAddress || areInputsDisabled}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            fullWidth
            label={translate('loanApplication.step1.address.city')}
            validate={() => err.city}
            value={addressDetails?.city || ''}
            onChange={(val) => handleOnChange(val, 'city')}
            disabled={hasSameAddress || areInputsDisabled}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            fullWidth
            label={translate('loanApplication.step1.address.state')}
            validate={() => err.state}
            value={addressDetails?.state || ''}
            onChange={(val) => handleOnChange(val?.toUpperCase(), 'state')}
            disabled={hasSameAddress || areInputsDisabled}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            fullWidth
            label={translate('loanApplication.step1.address.zipCode')}
            validate={() => err.zipCode}
            value={addressDetails?.zipCode || ''}
            onChange={(val) => handleOnChange(val, 'zipCode')}
            disabled={hasSameAddress || areInputsDisabled}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            fullWidth
            label={translate('loanApplication.step1.address.county')}
            validate={() => err.county}
            value={addressDetails?.county || ''}
            onChange={(val) => handleOnChange(val, 'county')}
            disabled={hasSameAddress || areInputsDisabled}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

AddressDetails.propTypes = {
  addressDetails: PropTypes.object.isRequired,
  setAddressDetails: PropTypes.func.isRequired,
  billing: PropTypes.bool,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  coBorrower: PropTypes.bool,
  hasSameAddress: PropTypes.bool,
  areInputsDisabled: PropTypes.bool,
}

export default AddressDetails
