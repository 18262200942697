import { colors, theme } from '@ElementsCapitalGroup/enium-ui'

const {
  lightTheme: { typography },
} = theme

const headerWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '24px',
  marginBottom: '8px',
  flexWrap: 'wrap',
}

const header = {
  fontSize: '1.875rem',
  fontWeight: 600,
  color: colors.grey[900],
}
const subHeader = {
  fontSize: typography.body1.fontSize,
  color: colors.grey[600],
}

const buttonsWrapper = {
  display: 'grid',
  gridGap: '12px',
  gridTemplateColumns: 'repeat(3,auto)',
  marginTop: '4px',
}

export const styles = {
  headerWrapper,
  header,
  subHeader,
  buttonsWrapper,
}
