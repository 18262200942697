import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/modal'
import { useTranslation } from 'react-i18next'

/** Warning Module showed before Submission of Loan App */
const WarningModal = ({ onConfirm, warnings, setWarnings }) => {
  const { t: translate } = useTranslation()
  const onClose = () => setWarnings({})
  const translations = {
    warningCreditReport: translate(
      'loanApplication.step1.warningModal.warningCreditReport'
    ),
    warningNewMembershipApp: translate(
      'loanApplication.step1.warningModal.warningNewMembershipApp'
    ),
  }

  return (
    <Modal
      onConfirm={() => {
        onConfirm()
        onClose()
      }}
      confirmButton={translate('buttons.submit')}
      cancelButton={translate('buttons.cancel')}
      isOpen={Object.keys(warnings).length > 0}
      onClose={onClose}
    >
      <div className="applicant-info__modal">
        <div className="applicant-info__modal-title">
          {translate('global.warning')}
        </div>
      </div>
      {warnings.credit && (
        <div className="applicant-info__modal-text">
          {translations.warningCreditReport}
        </div>
      )}
      {warnings.membership && (
        <div className="applicant-info__modal-text">
          {translations.warningNewMembershipApp}
        </div>
      )}
    </Modal>
  )
}

WarningModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  warnings: PropTypes.object.isRequired,
  setWarnings: PropTypes.func.isRequired,
}

export default WarningModal
