export const STIPULATION_DEFINITION_IDS = {
  ValidPhotoIdFrontId: '20cd7fa0-dcf3-4681-9b0f-cc9d806c3c84',
  ValidPhotoIdBackId: 'a6e71c34-6992-4325-8fe1-d434a58bd44f',
}

// todo - get from API /ntp-types
export const NTP_SECTIONS_IDS = {
  INITIAL: 1,
  FINAL: 2,
  COMMERCIAL: 3,
}
