export const NOTIFICATION_TYPES = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  WARNING: 'warning',
}

export const SERVER_TO_CLIENT_MAP = {
  Positive: NOTIFICATION_TYPES.POSITIVE,
  Negative: NOTIFICATION_TYPES.NEGATIVE,
  Warning: NOTIFICATION_TYPES.WARNING,
}

export const NOTIFICATION_ENTITY_TYPE_IDS = {
  LOAN_APPLICATION: 0
}
