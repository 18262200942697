import React, { useEffect } from 'react'
import AdminWrapper from 'modules/admin'
import AusTemplates from 'modules/aus-templates'
import { useStore } from 'store'
import { fetchLenders } from 'modules/organizations/actions'

const AdminAusTemplates = () => {
  const { state, dispatch } = useStore()
  const { lenders } = state.orgManagement

  useEffect(() => {
    if (!lenders.length) {
      fetchLenders(dispatch)
    }
  }, [])

  return (
    <AdminWrapper>
      <AusTemplates />
    </AdminWrapper>
  )
}

export default AdminAusTemplates
