import React from 'react'
import PropTypes from 'prop-types'
import Input from 'components/input'
import InputDate from 'components/input-date'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import SelfEmployedSections from './sections'
import { SELF_EMPLOYED_STATE, YEARS } from './constants'
import { styles } from '../style'
import CashFlowSummary from './summary'

const { Card, Dropdown } = components
const { PlusIcon, TrashIcon } = assets

const SelfEmployedCalculator = ({ state, setState }) => {
  const { borrowerDetails, years, sections } = state

  const onBorrowerFieldChange = (fieldKey) => (value) => {
    setState({
      ...state,
      borrowerDetails: {
        ...borrowerDetails,
        [fieldKey]: value,
      },
    })
  }

  const onSectionChange = (sectionIndex, newSectionValue) => {
    setState({
      ...state,
      sections: sections.map((section, index) =>
        index === sectionIndex ? { ...newSectionValue } : section
      ),
    })
  }

  const onSectionAdd = (sectionType, sectionIdx) => {
    const newSections = [...sections]
    newSections.splice(sectionIdx + 1, 0, {
      ...SELF_EMPLOYED_STATE.sections.find(
        (section) => section.type === sectionType
      ),
    })
    setState({ ...state, sections: newSections })
  }

  const onSectionRemove = (sectionIdx) => {
    setState({
      ...state,
      sections: sections.filter((_, idx) => idx !== sectionIdx),
    })
  }

  const onYearAdd = (index) => (ev) => {
    const newYears = [...years]
    newYears[index] = ev.target.value?.id
    setState({ ...state, years: newYears })
  }

  const onYearRemove = () => {
    setState({
      ...state,
      years: years.slice(0, -1),
      sections: sections.map((section) => {
        return {
          ...section,
          rowValues: Object.keys(section.rowValues).reduce((obj, rowId) => {
            return {
              ...obj,
              [rowId]: { 0: section.rowValues[rowId][0] },
            }
          }, {}),
        }
      }),
    })
  }

  return (
    <div style={styles.incomePage}>
      <div style={styles.section}>
        <Card title="Borrower Details" sx={styles.card}>
          <div className="align-center">
            <Input
              label="Borrower Name"
              placeholder="Enter your borrower name"
              value={borrowerDetails.name}
              onChange={onBorrowerFieldChange('name')}
              style={{ marginRight: '24px', width: '300px' }}
            />
            <InputDate
              label="Date of Completion"
              value={borrowerDetails.dateOfCompletion}
              onChange={onBorrowerFieldChange('dateOfCompletion')}
              style={{ width: '300px' }}
            />
          </div>
        </Card>

        <div style={styles.selfEmployed.years}>
          <span style={styles.selfEmployed.yearsLabel}>Enter the year(s)</span>
          {years.map((year, index) => (
            <div key={index} style={{ width: '216px' }}>
              <Dropdown
                label="Year"
                value={{ id: year, label: year }}
                options={YEARS.filter(
                  (el) =>
                    !years.some((year, idx) => idx !== index && year === el)
                ).map((year) => ({
                  label: year,
                  id: year,
                }))}
                onChange={onYearAdd(index)}
              />
            </div>
          ))}
          {years.length === 1 ? (
            <div
              style={{ ...styles.plusTab, background: 'white' }}
              onClick={() => setState({ ...state, years: [...years, ''] })}
            >
              <PlusIcon sx={styles.plusTabSvg} />
            </div>
          ) : (
            <div
              style={{ ...styles.plusTab, background: 'white' }}
              onClick={onYearRemove}
            >
              <TrashIcon sx={styles.plusTabSvg} />
            </div>
          )}
        </div>

        <SelfEmployedSections
          years={years}
          sections={sections}
          onSectionChange={onSectionChange}
          onSectionAdd={onSectionAdd}
          onSectionRemove={onSectionRemove}
        />

        <CashFlowSummary sections={sections} years={years} />
      </div>
    </div>
  )
}

SelfEmployedCalculator.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
}

export default SelfEmployedCalculator
