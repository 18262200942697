import React, { useState, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import { useTranslation } from 'react-i18next'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { downloadFileBase64 } from 'common/utils'
import { attachSortBy } from 'common/table'
import { LOAN_STATUSES_COLOR_CLASSNAME } from 'modules/loan-application/constants'
import SearchAndFilterModule from 'components/search-filter-module'
import { FILTER_TYPES, getFilter, setFilter } from 'common/filters'

import { filterLoanApplications, exportToCsv } from './actions'
import { LOAN_APPS_PAGE_SIZE } from './constants'
import { useColumns } from './useColumns'
import LoanApplicationsTable from './loan-applications-table'
import { copyLoanAppsToClipboard } from './clipboard'
import { LoanAppsHeader } from './header'
import { hasObjectValues } from './utils'

import './style.scss'

const LoanApplicationsModule = ({
  loanAppStatuses,
  salesOrganizations,
  salesReps,
  onCreate,
  prerequisiteDataLoading,
}) => {
  const tableRef = useRef()
  const { t: translate } = useTranslation()
  const [activeFilters, setActiveFilters] = useState({})
  const [selectedLoanTypeTab, setSelectedLoanTypeTab] = useState({})
  const selectedTabRef = useRef()
  selectedTabRef.current = selectedLoanTypeTab
  const [activeSortBy, setActiveSortBy] = useState([
    { key: 'dateCreated', value: null },
    { key: 'dateLastModified', value: null },
    { key: 'customer', value: null },
    { key: 'loanApplicationStateId', value: null },
    { key: 'loanApplicationStatusId', value: null },
    { key: 'salesOrganizationName', value: null },
    { key: 'expirationDate', value: null },
  ])
  const [activeTableFilters, setActiveTableFilters] = useState(
    JSON.parse(getFilter(FILTER_TYPES.LOAN_APPS))?.activeTableFilters || {}
  )

  const { state, dispatch } = useStore()
  const [isDataLoading, setDataLoading] = useState(true)
  const { userData } = state.session
  const { list, loansApplicationsStates, loanApplicationTypes, itemCount } =
    state.loanApplications
  const { lenderOrganizations } = state.orgManagement
  const searchFilterRef = useRef()
  const activeTableFiltersRef = useRef(activeTableFilters)
  const activeFiltersRef = useRef()
  const initialDataLoadingCounter = useRef(0)

  /* initialDataLoadingCounter - Dirty solution to prevent persist filters, without reworking the whole filters module & table component.
  #Explanation:
     The LoanApplicationsTable & SearchAndFilterModule are intertwined. The table component needs to reset it's state to work correctly by resetComponentState() on load.
     This triggers an update of the filters which also affects the SearchAndFilterModule, and thus calls the fetch function multiple times.
     Another problem is that we need to prefill the saved filters from LS, if there are any. While setting the filters that also makes the SearchAndFilterModule & Table to update and retrigger.
  #Solution:
     We 'wait' for them to finish the initial load by increasing thus counter. First we make the LS filters a priority, and call fetchInitialData with the filters. Only then handleFiltersChanged is allowed to trigger, when the counter is increased.
  */

  const [currentTableItems, setCurrentTableItems] = useState([])

  const loanTypes = useMemo(
    () =>
      loanApplicationTypes.map((l) => ({
        id: l.friendlyName,
        label: l.friendlyName,
      })),
    [loanApplicationTypes]
  )

  useEffect(() => {
    if (!loanTypes.length) {
      return
    }

    if (loanTypes[0].label !== selectedLoanTypeTab.label) {
      setSelectedLoanTypeTab(loanTypes[0])
    }
  }, [loanTypes])

  useEffect(() => {
    activeTableFiltersRef.current = activeTableFilters
  }, [activeTableFilters])

  useEffect(() => {
    if (!selectedLoanTypeTab.label) {
      return
    }

    fetchInitialData()
  }, [selectedLoanTypeTab])

  useEffect(() => {
    activeFiltersRef.current = activeFilters // keep ref up to date
    const savedFilters = getFilter(FILTER_TYPES.LOAN_APPS)

    if (
      initialDataLoadingCounter.current === INITIAL_LOADING_COUNTER.DEFAULT &&
      savedFilters &&
      hasObjectValues(savedFilters) &&
      !hasObjectValues(activeFilters) &&
      savedFilters !== JSON.stringify(activeFilters)
    ) {
      setActiveFilters(JSON.parse(savedFilters))
    }
  }, [activeFilters])

  useEffect(() => {
    window.onbeforeunload = function () {
      // on refresh page
      persistFiltersOnPageLeave()
    }

    return () => {
      persistFiltersOnPageLeave()
      window.onbeforeunload = null
    }
  }, [])

  const persistFiltersOnPageLeave = () => {
    setFilter(FILTER_TYPES.LOAN_APPS, {
      ...activeFiltersRef.current,
      activeTableFilters: activeTableFiltersRef.current,
    })

    activeFiltersRef.current = null
    initialDataLoadingCounter.current = INITIAL_LOADING_COUNTER.DEFAULT
    searchFilterRef && searchFilterRef.current?.resetComponentState() // for SearchAndFilterModule
    searchFilterRef.current = null
  }

  const fetchInitialData = () => {
    const savedFilters = getFilter(FILTER_TYPES.LOAN_APPS)
    const savedFiltersObj = savedFilters ? JSON.parse(savedFilters) : {}

    setDataLoading(true)
    searchFilterRef && searchFilterRef.current?.resetComponentState() // for SearchAndFilterModule
    setActiveFilters(savedFiltersObj || {})

    filterLoanApplications(
      dispatch,
      selectedLoanTypeTab.label,
      savedFiltersObj || {},
      0,
      LOAN_APPS_PAGE_SIZE
    )
      .then((newItems) => {
        setCurrentTableItems(newItems)
      })
      .finally(() => {
        setDataLoading(false)
        initialDataLoadingCounter.current++
      })
  }

  const fetchDataForPage = ({ pageIndex, pageSize }) => {
    const completeFilters = attachSortBy(activeFilters, activeSortBy)
    setDataLoading(true)

    return filterLoanApplications(
      dispatch,
      selectedTabRef.current.label,
      completeFilters,
      pageIndex,
      pageSize
    )
      .then((newItems) => {
        setCurrentTableItems(newItems)
        return newItems
      })
      .finally(() => {
        setDataLoading(false)
        initialDataLoadingCounter.current++
      })
  }

  const loanStatuses = useMemo(() => {
    return loanAppStatuses.map((s) => {
      const labelClassName = `loan-status ${
        LOAN_STATUSES_COLOR_CLASSNAME[s.value]
      }`
      return {
        ...s,
        labelClassName: labelClassName,
        label: translate(s.translationKey),
      }
    })
  }, [loanAppStatuses])

  const filterOptions = useMemo(() => {
    const extraOptions = [
      {
        key: 'LoanApplicationStateId',
        placeholder: 'Application Stage',
        options: loansApplicationsStates.map((el) => ({
          id: el.id,
          label: el.friendlyName,
          value: el.friendlyName,
        })),
        isMultiSelect: true,
      },
    ]

    if (hasAccess(userData, CLAIMS.CAN_VIEW_ORGANIZATIONS)) {
      extraOptions.push({
        key: 'OrganizationId',
        secondaryKey: 'v2OrganizationId',
        secondaryValue: 'v2Guid',
        placeholder: 'Organization',
        options: salesOrganizations.map((el) => ({
          id: el.guid,
          v2Guid: el.v2Guid,
          label: el.name,
          value: el.name,
        })),
        isMultiSelect: true,
      })
    }
    if (hasAccess(userData, CLAIMS.CAN_VIEW_LENDERS)) {
      extraOptions.push({
        key: 'lenderId',
        secondaryKey: 'v2LenderId',
        secondaryValue: 'v2Guid',
        placeholder: 'Lender',
        options: lenderOrganizations.map((el) => ({
          id: el.lenderId,
          v2Guid: el.v2Guid,
          label: el.name,
          value: el.name,
        })),
        isMultiSelect: true,
      })
    }
    if (hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING) && salesReps?.length) {
      extraOptions.push({
        key: 'salesRepresentativeId',
        secondaryKey: 'v2SalesRepresentativeId',
        secondaryValue: 'v2Guid',
        placeholder: 'Sales Rep',
        options: salesReps.map((el) => ({
          id: el.guid,
          v2Guid: el.v2Guid,
          label: el.name,
          value: el.name,
        })),
        isMultiSelect: true,
      })
    }

    extraOptions.push({
      key: 'loanApplicationStatusId',
      placeholder: 'Status',
      options: loanStatuses.map((el) => ({ ...el, value: el.label })),
      isMultiSelect: true,
    })
    return extraOptions
  }, [
    userData,
    loansApplicationsStates,
    salesOrganizations,
    salesReps,
    lenderOrganizations,
    loanStatuses,
    activeFilters,
    setActiveFilters,
  ])

  /** On Filter Change generic handler */
  const handleFiltersChanged = (filters) => {
    if (initialDataLoadingCounter.current === INITIAL_LOADING_COUNTER.DEFAULT) {
      // don't trigger before fetchInitialData
      return
    }

    setDataLoading(true)

    const completeFilters = attachSortBy(filters, activeSortBy)

    filterLoanApplications(
      dispatch,
      selectedLoanTypeTab.label,
      completeFilters,
      0,
      LOAN_APPS_PAGE_SIZE
    )
      .then((newItems) => {
        setCurrentTableItems(newItems)
        return newItems
      })
      .finally(() => {
        setDataLoading(false)
        setActiveFilters(filters)
        setTablePageIndex(0)
      })
  }

  /** On Sort handler */
  const handleSortByChanged = (key, value) => {
    const updatedSortBy = [...activeSortBy]

    updatedSortBy.forEach((sortElement) =>
      sortElement.key === key ? (sortElement.value = value) : { ...sortElement }
    )
    setActiveSortBy(updatedSortBy)

    const completeFilters = attachSortBy(activeFilters, updatedSortBy)
    setDataLoading(true)
    filterLoanApplications(
      dispatch,
      selectedLoanTypeTab.label,
      completeFilters,
      0,
      LOAN_APPS_PAGE_SIZE
    )
      .then((newItems) => {
        setCurrentTableItems(newItems)
        return newItems
      })
      .finally(() => {
        setDataLoading(false)
        setTablePageIndex(0)
      })
  }

  const setTablePageIndex = (pageIndex) => {
    tableRef.current?.setPageIndex(pageIndex)
  }

  const handleDownloadAttachment = () => {
    const filters = { ...activeFilters }

    if (filters.activeTableFilters) {
      delete filters.activeTableFilters
    }

    exportToCsv(filters).then((res) => res && downloadFileBase64(res))
  }

  const getVisibleColumns = (columns) => {
    setActiveTableFilters(columns)
  }

  const columns = useColumns(handleSortByChanged)

  return (
    <>
      <div style={{ marginBottom: '32px' }}>
        <LoanAppsHeader
          onExport={handleDownloadAttachment}
          onCreate={onCreate}
          onCopyToClipboard={() => copyLoanAppsToClipboard(list, dispatch)}
        />
        {initialDataLoadingCounter.current >=
          INITIAL_LOADING_COUNTER.LS_FILLED_AND_INITIAL_DATA_FETCHED && (
          <SearchAndFilterModule
            ref={searchFilterRef}
            fullWidth={true}
            placeholder="Search for applications"
            callback={handleFiltersChanged}
            fieldsToSearch={['multiple']}
            noDefaultExtraOptions
            extraOptions={filterOptions}
            hasDateFilter={true}
            isSearchable={true}
            initialFiltersIds={activeFilters}
            loading={isDataLoading}
          />
        )}

        <LoanApplicationsTable
          ref={tableRef}
          columns={columns}
          list={currentTableItems}
          itemCount={itemCount}
          isCommercialList={false}
          fetchData={fetchDataForPage}
          onSortingChange={handleSortByChanged}
          loading={isDataLoading || prerequisiteDataLoading}
          emptyTitle="No Applications Found"
          columnsVisibilityData={activeTableFilters}
          emptyDescription="We couldn't find any data matching your criteria."
          getVisibleColumns={getVisibleColumns}
          emptyActionButtonLabel="Reset search & filters"
          emptyActionButtonOnClick={() => {
            searchFilterRef && searchFilterRef.current?.resetComponentState()
            fetchInitialData()
          }}
        />
      </div>
    </>
  )
}

LoanApplicationsModule.propTypes = {
  loanAppStatuses: PropTypes.array.isRequired,
  salesOrganizations: PropTypes.array.isRequired,
  salesReps: PropTypes.array.isRequired,
  isCommercialApp: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  prerequisiteDataLoading: PropTypes.bool,
}

export default LoanApplicationsModule

const INITIAL_LOADING_COUNTER = {
  DEFAULT: 0,
  LS_FILLED_AND_INITIAL_DATA_FETCHED: 1,
}
