import jwt_decode from 'jwt-decode'
import { CLAIMS_PREFIX } from 'common/claims'

/**
 * Decode the JWT Token to retrieve User Data, Claims & Organization Info
 *
 * @param {String} token
 * @return {Object} object containing relevant user & organization data
 */
export function decodeJwtToken(token) {
  if (!token) {
    return {}
  }
  const decodedToken = jwt_decode(token)
  const userData = {
    email: decodedToken.email,
  }

  // Extract enium-related claims - the only ones we care about
  const claims = Object.keys(decodedToken).reduce((claims, key) => {
    if (key.indexOf(CLAIMS_PREFIX) === 0) {
      const field = key.split(`${CLAIMS_PREFIX}.`)[1]
      // Check if it's a top-level user field or a claim
      if (TOP_LEVEL_FIELDS[field]) {
        userData[TOP_LEVEL_FIELDS[field]] = decodedToken[key]
      } else {
        claims[key] = decodedToken[key]
      }
    }
    return claims
  }, {})

  if (Object.entries(claims).length === 0) {
    return { ...userData }
  }

  userData.role = claims[`${CLAIMS_PREFIX}.role`]
  userData.roleId = claims[`${CLAIMS_PREFIX}.roleid`].toLowerCase()
  userData.roleTypeId = parseInt(claims[`${CLAIMS_PREFIX}.roletypeid`])
  userData.organizationId = userData.organizationId.toLowerCase()
  userData.isSuperAdmin = !!claims[SUPER_ADMIN_CLAIM]
  return { ...userData, claims }
}

const TOP_LEVEL_FIELDS = {
  firstname: 'firstName',
  lastname: 'lastName',
  organizationid: 'organizationId',
  organizationname: 'organizationName',
}

export const SUPER_ADMIN_CLAIM = `${CLAIMS_PREFIX}.can_do_everything`
