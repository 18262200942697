import React from 'react'
import { EditTranslations } from 'modules/edit-translations'
import AdminWrapper from 'modules/admin'

export const EditTranslationsPage = () => {
  return (
    <AdminWrapper>
      <EditTranslations />
    </AdminWrapper>
  )
}
