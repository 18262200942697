import moment from 'moment'
import { copyToClipboard, formatAddress, formatSSN } from 'common/utils'
import { APPLICANT_TYPE } from 'common/constants'

export function copyUWToClipboard(
  dispatch,
  translate,
  borrower,
  coBorrower,
  loanFormData,
  serviceAddress,
  isHardCreditPulled,
  creditInfo
) {
  const {
    combinedStatedIncome,
    borrowerStatedDTI,
    coBorrowerStatedDTI,
    combinedStatedDTI,
    combinedDebts,
  } = loanFormData.incomeData

  const borrowerCredit = creditInfo?.find(
    (el) => el.applicantType?.id === APPLICANT_TYPE.BORROWER
  )
  const coBorrowerCredit = creditInfo?.find(
    (el) => el.applicantType?.id === APPLICANT_TYPE.COBORROWER
  )
  const borrowerEstimate = isHardCreditPulled
    ? borrowerCredit?.hardEstimatedIncome?.annualEstimate
    : borrowerCredit?.softEstimatedIncome?.annualEstimate
  const coBorrowerEstimate = isHardCreditPulled
    ? coBorrowerCredit?.hardEstimatedIncome?.annualEstimate
    : coBorrowerCredit?.softEstimatedIncome?.annualEstimate

  const ficoField = isHardCreditPulled ? 'hardFico' : 'softFico'
  let fico = borrowerCredit?.[ficoField]
  if (coBorrowerCredit && coBorrowerCredit?.[ficoField] > fico) {
    fico = coBorrowerCredit?.[ficoField]
  }

  const estimatedIncomeLabel = isHardCreditPulled
    ? translate('loanApplication.ecgToolbox.creditReports.hardAnnualEstimate')
    : translate('loanApplication.ecgToolbox.creditReports.softAnnualEstimate')

  let htmlText = `
    <strong>${translate(
      'loanApplication.step1.address.serviceAddress'
    )}:</strong> ${formatAddress(serviceAddress)}<br/>
    <strong>${translate('userDetails.fullName')}:</strong> ${
    borrower.firstName
  } ${borrower.lastName}<br/>
    <strong>${translate('userDetails.ssn')}:</strong> ${formatSSN(
    borrower.last4SSN
  )}<br/>
    <strong>${translate('userDetails.dob')}:</strong> ${moment(
    borrower.dateOfBirth
  ).format('YYYY-MM-DD')}<br/>
    <strong>Highest Fico:</strong> ${fico}<br/>
    <strong>Stated Combined Income:</strong> $${parseFloat(
      combinedStatedIncome
    ).toFixed(2)}<br/>
    <strong>${estimatedIncomeLabel}:</strong> $${(
    borrowerEstimate || 0
  ).toFixed(2)}<br/>
    <strong>Applicant DTI:</strong> ${parseFloat(borrowerStatedDTI).toFixed(
      2
    )}%<br/>
    <strong>Combined DTI:</strong> ${parseFloat(combinedStatedDTI).toFixed(
      2
    )}%<br/>
    <strong>Combined Debts:</strong> $${parseFloat(combinedDebts).toFixed(
      2
    )}<br/>
    <strong>${translate('loanApplication.step1.loanAmount')}:</strong> $${
    borrower.loanAmount
  }<br/>
    <strong>Loan Application Number:</strong> ${
      loanFormData.loanApplicationNumber
    }<br/>
    <strong>Employment Income:</strong> $${borrower.yearlyIncome}<br/>
    <strong>${translate('userDetails.employer')}:</strong> ${
    borrower.employer
  }<br/>
    <strong>${translate('userDetails.occupation')}:</strong> ${
    borrower.jobTitle
  }
      `

  if (coBorrower && borrower.hasCoBorrower) {
    htmlText += `
    <br/><br/>
    <strong>CoBorrower Details</strong><br/>
    <strong>${translate('userDetails.fullName')}:</strong> ${
      coBorrower.firstName
    } ${coBorrower.lastName}<br/>
    <strong>${translate('userDetails.ssn')}:</strong> ${
      coBorrower.last4SSN
    }<br/>
    <strong>${translate('userDetails.dob')}:</strong> ${moment(
      coBorrower.dateOfBirth
    ).format('YYYY-MM-DD')}<br/>
    <strong>Applicant DTI:</strong> ${parseFloat(coBorrowerStatedDTI).toFixed(
      2
    )}%<br/>
     <strong>${estimatedIncomeLabel}:</strong> $${(
      coBorrowerEstimate || 0
    ).toFixed(2)}<br/>
    <strong>Employment Income:</strong> $${coBorrower.yearlyIncome}<br/>
    <strong>${translate('userDetails.employer')}:</strong> ${
      coBorrower.employer
    }<br/>
    <strong>${translate('userDetails.occupation')}:</strong> ${
      coBorrower.jobTitle
    }
    `
  }

  copyToClipboard(htmlText, dispatch)
}
