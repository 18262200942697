import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from 'components/button'
import DeleteModal from 'components/modal/delete-modal'
import FormBuilder from 'components/form-builder'
import { SCHEMA_TYPES } from 'components/form-builder/constants'
import Loader from 'components/loader'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { customSchemaType } from 'common/prop-types'
import { useStore } from 'store'
import { ExpandableCard } from 'components/expandable-card'
import StatusComponent, { STATUSES } from 'components/status'

import styles from './style.module.scss'

const { Dialog } = components

const MAX_LENGTH = 2000

const MembershipCard = ({
  membershipData,
  isMembershipAccepted,
  onAgreementChange,
  loading,
  disabled,
  collapsed,
  customSchema,
  viewOnly,
}) => {
  const { t: translate } = useTranslation()
  const [declineModalOpen, setDeclineModalOpen] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [fullTextModal, setFullTextModal] = useState(false)
  const [customMembershipModal, setCustomMembershipModal] = useState(false)
  const { state } = useStore()
  const canAcceptAgreements = hasAccess(
    state.session.userData,
    CLAIMS.CAN_ACCEPT_AGREEMENTS
  )

  const agreementText = membershipData?.text
  const title = translate('loanApplication.step3.membership.title')
  const customSchemaTitle = customSchema?.titleKey
    ? translate(customSchema.titleKey)
    : customSchema?.title

  /** Create a sneak-peak schema that contains a brief of the actual custom schema */
  const schemaSneakPeak = useMemo(() => {
    if (!customSchema) {
      return null
    }
    const sneakPeak = { sections: [] }
    let currentCount = 0
    const _countSection = (section) => {
      if (section.text || section.textKey) {
        const textTranslated = section.textKey
          ? translate(section.textKey)
          : section.text

        currentCount += textTranslated.toString().length
        sneakPeak.sections.push({ ...section, text: textTranslated })
        if (currentCount >= MAX_LENGTH) {
          sneakPeak.sections[sneakPeak.sections.length - 1].text += '...'
          return false
        }
      } else if (section.type === SCHEMA_TYPES.WRAPPER) {
        section.sections.every(_countSection)
      }
      return true
    }
    customSchema.sections.every(_countSection)
    return sneakPeak
  }, [customSchema])

  /** Decide whether to show the show more text */
  useEffect(() => {
    if (!customSchema) {
      setShowMore(agreementText?.length > MAX_LENGTH)
    }
  }, [agreementText, customSchema])

  /** Compute the initial state based on the data from the server, if any */
  const initialMembershipState = useMemo(() => {
    return Object.entries(membershipData?.data || {}).reduce(
      (prev, [field, data]) => {
        prev[field] = data.answer
        return prev
      },
      {}
    )
  }, [membershipData?.data])

  const text = showMore
    ? `${agreementText.substring(0, MAX_LENGTH)}...`
    : agreementText

  const _renderContent = () => (
    <div style={{ width: '100%' }}>
      {customSchema && customSchema.titleKey.includes('glendale') ? (
        <FormBuilder schema={schemaSneakPeak} />
      ) : (
        !customSchema && (
          <div
            className="form-text padding-24"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        )
      )}
      {/* Render the actual contents (custom schema or plain text) */}

      {/* Show More */}
      {customSchema && customSchema.titleKey.includes('glendale') && (
        <div className="form-buttons__glendale">
          <Button
            size={BUTTON_SIZES.SMALL}
            sx={{ ml: '8px' }}
            onClick={() => setCustomMembershipModal(true)}
            loading={loading}
            disabled={viewOnly}
          >
            {translate('loanApplication.step3.membership.read')}
          </Button>
          <Button
            size={BUTTON_SIZES.SMALL}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.ERROR}
            onClick={() => setDeclineModalOpen(true)}
            disabled={loading || viewOnly}
          >
            {translate('buttons.decline')}
          </Button>
        </div>
      )}

      {/* Buttons */}
      {!customSchema && canAcceptAgreements && (
        <div className="form-buttons">
          {showMore ? (
            <Button
              size={BUTTON_SIZES.SMALL}
              sx={{ ml: '8px' }}
              onClick={() => setFullTextModal(true)}
              loading={loading}
              disabled={viewOnly}
            >
              {translate('loanApplication.step3.membership.read')}
            </Button>
          ) : (
            <Button
              size={BUTTON_SIZES.SMALL}
              onClick={() => onAgreementChange(true)}
              loading={loading}
              disabled={viewOnly}
            >
              {translate('loanApplication.step3.membership.agreed')}
            </Button>
          )}
          <Button
            size={BUTTON_SIZES.SMALL}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.ERROR}
            onClick={() => setDeclineModalOpen(true)}
            disabled={loading || viewOnly}
          >
            {translate('buttons.decline')}
          </Button>
        </div>
      )}

      {/* Full text Modal (plain text) */}
      <Dialog
        open={fullTextModal}
        onClose={() => setFullTextModal(false)}
        title={title}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '1000px',
            padding: 0,
            '& .MuiDialogContent-root': {
              padding: 0,
            },
          },
        }}
      >
        <div
          style={{ padding: '0 24px 16px' }}
          className="applicant-info__modal-text"
          dangerouslySetInnerHTML={{
            __html: agreementText,
          }}
        ></div>
        <div className={styles.cardButtonsWrapper}>
          <Button
            size={BUTTON_SIZES.SMALL}
            onClick={() => {
              onAgreementChange(true)
              setFullTextModal(false)
            }}
            loading={loading}
            disabled={viewOnly}
          >
            {translate('loanApplication.step3.membership.agreed')}
          </Button>

          <Button
            size={BUTTON_SIZES.SMALL}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.ERROR}
            onClick={() => setFullTextModal(false)}
            loading={loading}
            disabled={viewOnly}
          >
            Cancel
          </Button>
        </div>
      </Dialog>

      {/* Full custom form text Modal */}
      {customSchema && customSchema.titleKey.includes('glendale') && (
        <Dialog
          open={customMembershipModal}
          onClose={() => setCustomMembershipModal(false)}
          title={customSchemaTitle || title}
          PaperProps={{
            sx: {
              maxWidth: '100%',
              width: '1000px',
              padding: 0,
              '& .MuiDialogContent-root': {
                padding: 0,
              },
            },
          }}
          titleProps={{
            boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.1)',
          }}
        >
          <FormBuilder
            schema={customSchema}
            actions={
              canAcceptAgreements
                ? {
                    onSubmit: (state) => {
                      // For "Membership" we group everything under a "data" object
                      onAgreementChange(true, { data: { ...state } })
                      setCustomMembershipModal(false)
                    },
                    onDecline: () => {
                      setDeclineModalOpen(true)
                    },
                    onClose: () => {
                      setCustomMembershipModal(false)
                    },
                  }
                : {}
            }
            initialState={initialMembershipState}
            viewOnly={viewOnly}
          />
        </Dialog>
      )}

      {customSchema && !customSchema.titleKey.includes('glendale') && (
        <FormBuilder
          schema={customSchema}
          actions={
            canAcceptAgreements
              ? {
                  onSubmit: (state) => {
                    // For "Membership" we group everything under a "data" object
                    onAgreementChange(true, { data: { ...state } })
                    setCustomMembershipModal(false)
                  },
                  onDecline: () => {
                    setDeclineModalOpen(true)
                  },
                }
              : {}
          }
          initialState={initialMembershipState}
          viewOnly={viewOnly}
        />
      )}

      {/* Decline modal */}
      <DeleteModal
        isOpen={declineModalOpen}
        setModalOpen={setDeclineModalOpen}
        onSubmitModal={() => {
          setDeclineModalOpen(false)
          onAgreementChange(false)
          setCustomMembershipModal(false)
        }}
        title={translate('loanApplication.step3.membership.decline')}
        confirmationQuestion={translate('loanApplication.step3.areYouSure')}
        confirmButtonText={translate('buttons.decline')}
      />
    </div>
  )

  const collapsibleItems = [
    <>{loading ? <Loader center={true} /> : _renderContent()}</>,
  ]
  return (
    <ExpandableCard
      sx={{ mb: '16px' }}
      headerProps={{
        sx: {
          backgroundColor: '#ffffff',
        },
      }}
      expandableListStyle={{
        '& .MuiListItem-root': {
          padding: '0',
        },
      }}
      items={[]}
      collapsibleItems={collapsibleItems}
      isCollapsable={true}
      isCollapsed={true}
      alwaysCollapsible={true}
      title={
        <div className={styles.cardTitleBigger}>
          <span>{translate('loanApplication.step3.membership.title')}</span>

          <div className={styles.status}>
            <StatusComponent
              status={
                isMembershipAccepted ? STATUSES.ACTIVE : STATUSES.INACTIVE
              }
              label={
                isMembershipAccepted
                  ? translate('global.accepted')
                  : translate('global.declined')
              }
            />
          </div>
        </div>
      }
    />
  )
}

MembershipCard.propTypes = {
  membershipData: PropTypes.object,
  isMembershipAccepted: PropTypes.bool,
  onAgreementChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  agreementText: PropTypes.string,
  collapsed: PropTypes.bool,
  customSchema: customSchemaType,
  viewOnly: PropTypes.bool,
}

export default MembershipCard
