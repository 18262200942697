import { getLoanApplicationUrl } from 'common/utils'

export const extractUrl = (row, canViewLoans, isCommercialApp) => {
  const { loanApplicationId, stateId, isPtoNotMandatory } = row.original

  const url = getLoanApplicationUrl(
    stateId,
    loanApplicationId,
    canViewLoans,
    isCommercialApp,
    isPtoNotMandatory
  )

  return { url }
}

export const hasObjectValues = (obj) =>
  obj && Object.values(obj).some((el) => el && el.length)
