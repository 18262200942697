import React, { useCallback, useEffect, useState } from 'react'
import NotificationsScreen from 'modules/notifications-screen'
import { fetchNotifications } from 'modules/notifications-screen/actions'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'

import { useStore } from 'store'
import { PAGE_SIZE } from 'components/lazy-loader'

const Notifications = () => {
  const { state } = useStore()
  const { userData } = state.session
  const [notifications, setNotifications] = useState(null)
  const [page, setPage] = useState(0)

  useEffect(() => {
    fetchNotifications(
      userData.associations?.[0]?.userId,
      userData.roleTypeId,
      userData.organizationId,
      page,
      PAGE_SIZE
    ).then((res) => {
      setNotifications(res)
    })
  }, [])

  const fetchNextPage = useCallback(
    (nextPage, pageSize) => {
      return fetchNotifications(
        userData.associations?.[0]?.userId,
        userData.roleTypeId,
        userData.organizationId,
        nextPage,
        pageSize
      ).then((newItems) => {
        setNotifications(notifications.concat(newItems))
        setPage(nextPage)
        return newItems
      })
    },
    [JSON.stringify(notifications)]
  )

  return (
    <AccessWrapper claims={CLAIMS.CAN_VIEW_NOTIFICATIONS}>
      <>
        <NotificationsScreen
          notifications={notifications}
          page={page}
          fetchNextPage={fetchNextPage}
        />
      </>
    </AccessWrapper>
  )
}

export default Notifications
