import { v4 as uuidv4 } from 'uuid'

// Helper method to render a new empty decision element
export function generateEmptyDecision() {
  return {
    guid: uuidv4(),
    description: '',
    friendlyName: '',
    name: '',
  }
}
