import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { assets, colors, components } from '@ElementsCapitalGroup/enium-ui'
import LoanCalculatorResults from 'components/loan-calculator/loan-calculator-results'
import Button, { BUTTON_SIZES } from 'components/button'
import { useTranslation } from 'react-i18next'
import { CLAIMS } from 'common/claims'
import { AccessWrapper, hasAccess } from 'common/access'
import { loanCardStyle, resultsTopHover } from './styles'
import LoanCardChip from './loan-card-chip'
import { EVALUATION_IDS } from '../../constants'

const { ChevronRightIcon, AlertTriangleIcon } = assets
const { Tooltip, IconButton } = components

const SelectLoanCard = ({
  isSelected,
  isFailed,
  isSpecialBank,
  isDifferentLoanProduct,
  loan,
  loanProduct,
  onLoanSelected,
  calculations,
  onDTIClicked,
  onContinueClicked,
  onAusDetailsClicked,
  userData,
}) => {
  const { t: translate } = useTranslation()
  const [hover, setHover] = useState(false)
  const canSelectLoan = hasAccess(userData, CLAIMS.CAN_SELECT_LOAN_PRODUCT)
  const showOverriddenAlert = loan.hasOverriddenDecisions
  const canSeeOverriddenDecision = hasAccess(
    userData,
    CLAIMS.CAN_SEE_OVERRIDDEN_EVALUATION
  )
  const hasFailedDecisions = loan.evaluatedLoanDecisions?.find(
    (el) =>
      (el.decisionResultId === EVALUATION_IDS.FAILED && !el.reason) ||
      (el.decisionResultId === EVALUATION_IDS.BRANCH &&
        el.evaluatedChildDecisions.find(
          (child) =>
            child.decisionResultId === EVALUATION_IDS.FAILED && !child.reason
        ))
  )

  return (
    <div
      style={{
        ...loanCardStyle.card,
        ...(hover ? { ...loanCardStyle.hoverCard } : {}),
        ...(isSelected ? { ...loanCardStyle.selectedCard } : {}),
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => canSelectLoan && !isFailed && onLoanSelected()}
    >
      <div style={loanCardStyle.title}>
        {loanProduct.name}
        {showOverriddenAlert && canSeeOverriddenDecision && (
          <Tooltip
            title={translate(
              'loanApplication.step2.decisionsOverriddenTooltip'
            )}
          >
            <IconButton size={BUTTON_SIZES.SMALL}>
              <AlertTriangleIcon
                sx={{
                  width: '20px',
                  color: colors.red[500],
                  marginLeft: '4px',
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        <LoanCardChip isFailed={isFailed} />
      </div>
      <div style={loanCardStyle.results}>
        <LoanCalculatorResults
          isSpecialBank={isSpecialBank}
          results={calculations}
          monthlyWrapperStyles={
            hover ? resultsTopHover : loanCardStyle.resultsTop
          }
        />
        <div style={loanCardStyle.resultsBottom}>
          <div style={loanCardStyle.resultsBottomItem}>
            <div style={loanCardStyle.resultsBottomItemHeader}>
              Promo Period
            </div>
            {calculations.promoPeriod || 0} months
          </div>
          <div style={loanCardStyle.resultsBottomItem}>
            <div style={loanCardStyle.resultsBottomItemHeader}>Promo Type</div>
            {translate(
              `loanApplication.step2.promotionalType.${loanProduct.promotionalType.id}`
            )}
          </div>
          <div style={loanCardStyle.resultsBottomItem}>
            <div style={loanCardStyle.resultsBottomItemHeader}>APR</div>
            {calculations.apr}%
          </div>
        </div>

        <div style={loanCardStyle.resultsFooter}>
          <AccessWrapper claims={CLAIMS.CAN_VIEW_DTI}>
            <div
              className="fake-link"
              style={loanCardStyle.links}
              onClick={onDTIClicked}
            >
              {translate('loanApplication.step2.viewDTI')}
            </div>
          </AccessWrapper>
          <AccessWrapper claims={CLAIMS.CAN_VIEW_DETAILED_EVALUATION}>
            <div
              className="fake-link"
              style={{ ...loanCardStyle.links, marginLeft: '12px' }}
              onClick={onAusDetailsClicked}
            >
              {translate('loanApplication.step2.ausDetails')}
            </div>
          </AccessWrapper>
          {canSelectLoan && isSelected && !hasFailedDecisions && (
            <Button
              style={loanCardStyle.button}
              endIcon={<ChevronRightIcon />}
              onClick={() => onContinueClicked(loan)}
            >
              {isDifferentLoanProduct
                ? translate('loanApplication.step2.changeLoan')
                : translate('loanApplication.step2.continueToAgreements')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

SelectLoanCard.propTypes = {
  isSelected: PropTypes.bool,
  isFailed: PropTypes.bool,
  isSpecialBank: PropTypes.bool,
  isDifferentLoanProduct: PropTypes.bool,
  loan: PropTypes.object.isRequired,
  loanProduct: PropTypes.object.isRequired,
  onLoanSelected: PropTypes.func.isRequired,
  calculations: PropTypes.object.isRequired,
  onDTIClicked: PropTypes.func.isRequired,
  onContinueClicked: PropTypes.func.isRequired,
  onAusDetailsClicked: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
}

export default SelectLoanCard
