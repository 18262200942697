import Api from 'easy-fetch-api'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'

/** Get list of templates */
export const fetchTemplate = (stipulationTemplateId) =>
  Api.get({
    url: `/StipulationTemplate/${stipulationTemplateId}`,
  }).catch(console.error)

// /** Create a Template */
export const createTemplate = (data, dispatch) =>
  Api.post({
    url: `/StipulationTemplate/create-stipulation-template`,
    data: data,
  })
    .then((response) => {
      showNotification(dispatch, {
        message: `Successfully created stipulation template ${data.name}`,
      })
      return response
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        message: `An error occurred while creating stipulation template ${data.name}. Please refresh the page and try again. ${error.title}`,
      })
    )

// /** Update a Template */
export const updateTemplate = (data, dispatch) =>
  Api.put({
    url: `/StipulationTemplate/update-stipulation-template`,
    data: data,
  })
    .then((response) => {
      showNotification(dispatch, {
        message: `Successfully updated stipulation template ${data.name}`,
      })
      return response
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        message: `An error occurred while updating stipulation template ${data.name}. Please refresh the page and try again. ${error.title}`,
      })
    )

// /** Delete a Template */
export const deleteTemplate = (templateId, dispatch) =>
  Api.delete({
    url: `/StipulationTemplate/${templateId}`,
  })
    .then((response) => {
      showNotification(dispatch, {
        message: `Successfully deleted stipulation template ${response.name}`,
      })
      return response
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        message: `An error occurred while deleting stipulation template. Please refresh the page and try again. ${error.title}`,
      })
    )

/** Get list of Stipulation Definitions */
export const fetchDefinitions = () =>
  Api.get({ url: '/StipulationTemplate/list-stipulation-definitions' }).then(
    (list) =>
      list.map((el) => ({
        id: el.guid,
        value: el.friendlyName,
      }))
  )

/** Get list of templates */
export const fetchStipulationsGuidAndName = () => {
  return Api.get({ url: '/StipulationTemplate/list-name-guid' }).catch(
    (error) => console.error(error)
  )
}
