import React from 'react'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import { useNavigate } from 'react-router-dom'
import { BUTTON_VARIANTS, BUTTON_COLORS } from 'components/button'
import '../styles/404.scss'

const { Button, FeaturedIcon, FeaturedIconColors, FeaturedIconSizes } =
  components
const { SearchLgIcon, ArrowLeftIcon } = assets

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <div className="not-found">
      <FeaturedIcon
        icon={<SearchLgIcon />}
        color={FeaturedIconColors.PRIMARY}
        size={FeaturedIconSizes.XL}
        variant={'ripple'}
      />
      <span className="not-found__title">Page not found</span>
      <span className="not-found__subtitle">
        The page you are looking for doesnt exist.<br></br>
        Here are some helpful links:
      </span>
      <div>
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          color={BUTTON_COLORS.INHERIT}
          onClick={() => navigate('/')}
          sx={{ mr: 1 }}
          size={'xlarge'}
          startIcon={<ArrowLeftIcon />}
        >{`Go back`}</Button>
        <Button size={'xlarge'} onClick={() => navigate('/')}>
          Take me home
        </Button>
      </div>
    </div>
  )
}

export default NotFound
