import Button from 'components/button/index'
import React from 'react'

import '../styles/release-notes.scss'

export const ReleaseNotesPage = () => {
  return (
    <div className="release-notes">
      <div className="release-notes__container">
        <div className="release-notes-content">
          <h1>Release Notes</h1>
          <a
            href="https://enium.notion.site/Enium-Portal-Release-Notes-01670d4274fb47c986c9fd01626ed1e2"
            target="_blank"
            rel="noreferrer"
          >
            <Button>View</Button>
          </a>
        </div>
      </div>
    </div>
  )
}
