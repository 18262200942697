import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TextField, { INPUT_TYPES } from 'components/input'

const SolarDetails = ({ solarDetails, setSolarDetails, canEditFields }) => {
  const { t: translate } = useTranslation()
  const handleOnChange = (name, value) => {
    setSolarDetails({ ...solarDetails, [name]: value })
  }

  return (
    <>
      <div
        className="applicant-info__form-title"
        style={{ marginBottom: '24px' }}
      >
        {translate('loanApplication.step1.solarDetails.solar')}
      </div>
      <div className="loan-form__grid">
        <TextField
          label={translate('loanApplication.step1.solarDetails.ppw')}
          onChange={(val) => handleOnChange('pricePerWatt', val)}
          value={solarDetails.pricePerWatt}
          type="currency"
          fixedDecimalScale={true}
          prefix="$"
          disabled={!canEditFields}
        />
        <TextField
          label={translate('loanApplication.step1.solarDetails.sizeKw')}
          onChange={(val) => handleOnChange('systemSize', val)}
          value={solarDetails.systemSize}
          type="number"
          decimalScale={3}
          disabled={!canEditFields}
        />
        <TextField
          label={translate('loanApplication.step1.solarDetails.batteryCount')}
          value={solarDetails.batteryCount}
          type="number"
          onChange={(val) => handleOnChange('batteryCount', val)}
          disabled={!canEditFields}
        />
        <TextField
          label={translate('loanApplication.step1.solarDetails.insuranceType')}
          value={solarDetails.insuranceType}
          onChange={(val) => handleOnChange('insuranceType', val)}
          disabled={!canEditFields}
        />
        <TextField
          type={INPUT_TYPES.CURRENCY}
          label={translate('loanApplication.step1.solarDetails.nonSolarAmount')}
          value={solarDetails.nonSolarAmount}
          onChange={(val) => handleOnChange('nonSolarAmount', val)}
          prefix="$"
          disabled={!canEditFields}
        />
      </div>
    </>
  )
}

SolarDetails.propTypes = {
  solarDetails: PropTypes.object.isRequired,
  setSolarDetails: PropTypes.func.isRequired,
  canEditFields: PropTypes.bool,
}

export default SolarDetails
