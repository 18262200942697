const textArea = {
  marginTop: '24px',
  display: 'flex',
  flexDirection: 'column',
}

const button = {
  marginTop: '24px',
}

const orderRequests = {
  marginTop: '35px',
}

const orderRequestsText = {
  fontWeight: '700',
}

const orderRequestsTextWrapper = {
  marginTop: '12px',
}

export const styles = {
  textArea,
  button,
  orderRequests,
  orderRequestsText,
  orderRequestsTextWrapper,
}
