import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/loader'
import { components, unstable_components } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'
import { getEvaluationSessions } from 'modules/loan-application/actions'
import { AMORTIZATION_TYPES } from 'modules/loan-application/constants'
import {
  calculateApr,
  calculateMonthlyPaymentNoPaydown,
  calculateRate,
} from 'modules/loan-application/utils'
import { styles } from './styles'

const LoanProductModal = ({ setIsLoanProductModalOpen, loanData }) => {
  const { Dialog } = components
  const { Unstable_Grid: Grid } = unstable_components
  const [loading, setLoading] = useState(true)
  const [selectedLoan, setSelectedLoan] = useState({})
  const { t: translate } = useTranslation()

  useEffect(() => {
    fetchEvaluationSessions()
  }, [])

  const fetchEvaluationSessions = () => {
    setLoading(true)
    getEvaluationSessions(loanData.loanApplicationId)
      .then((result) => {
        if (result?.length) {
          const currentEvaluation =
            result[result.length - 1]?.evaluatedLoanProducts
          setSelectedLoan(
            currentEvaluation.find(
              (itm) => itm.loanProduct.name === loanData.loanName
            )
          )
        }
      })
      .finally(() => setLoading(false))
      .catch(console.error)
  }

  const memoizedDefaults = useMemo(() => {
    const selectedLoanCalculations = selectedLoan.calculatedFinancingDetails
    if (!Object.keys(selectedLoan).length) {
      return {}
    }
    const defaultPaymentPeriod = selectedLoanCalculations?.paymentPeriods.find(
      (item) => item.amortizationType.id === AMORTIZATION_TYPES.SIMPLE
    )
    const defaultPaymentPromotionalPeriod =
      defaultPaymentPeriod?.paymentMonths.find(
        (item) => item.isPromotionalPeriod
      )
    const defaultPaymentNoPromotionalPeriod =
      defaultPaymentPeriod?.paymentMonths.find(
        (item) => !item.isPromotionalPeriod
      )

    const worstCasePaymentPeriod =
      selectedLoanCalculations?.paymentPeriods.find(
        (item) => item.amortizationType.id === AMORTIZATION_TYPES.WORST_CASE
      )
    const worstCaseNoPromotionalPeriod =
      worstCasePaymentPeriod?.paymentMonths.find(
        (item) => !item.isPromotionalPeriod
      )

    const noPaydown =
      worstCaseNoPromotionalPeriod?.monthlyPayment ||
      defaultPaymentNoPromotionalPeriod?.monthlyPayment

    const paymentWOAch = selectedLoanCalculations?.paymentPeriods
      .find(
        (el) => el.amortizationType.id === AMORTIZATION_TYPES.INTEREST_RATE_FEE
      )
      ?.paymentMonths.find((el) => !el.isPromotionalPeriod)?.monthlyPayment

    const paymentWithAch = selectedLoanCalculations?.paymentPeriods
      .find(
        (el) =>
          el.amortizationType.id === AMORTIZATION_TYPES.INTEREST_RATE_DISCOUNT
      )
      ?.paymentMonths.find((el) => !el.isPromotionalPeriod)?.monthlyPayment

    const achDiscount = selectedLoan.loanProduct.interestRateDiscount

    return {
      defaultPaymentPeriod,
      defaultPaymentPromotionalPeriod,
      defaultPaymentNoPromotionalPeriod,
      worstCasePaymentPeriod,
      worstCaseNoPromotionalPeriod,
      noPaydown,
      paymentWOAch,
      paymentWithAch,
      achDiscount,
    }
  }, [selectedLoan])

  const gridItems = [
    {
      translationSubKey: 'termInMonths',
      value: selectedLoan?.calculatedFinancingDetails?.termInMonths || 'N/A',
    },
    {
      translationSubKey: 'rate',
      value: memoizedDefaults?.achDiscount
        ? `${translate('loanApplication.step2.asLowAs')} ${calculateRate(
            selectedLoan?.calculatedFinancingDetails,
            selectedLoan?.loanProduct?.lenderId,
            memoizedDefaults.achDiscount
          )}%`
        : `${calculateRate(
            selectedLoan?.calculatedFinancingDetails,
            selectedLoan?.loanProduct?.lenderId
          )}%`,
    },
    {
      translationSubKey: 'promotionalPeriod',
      value: memoizedDefaults?.defaultPaymentPromotionalPeriod?.months || '0',
    },
    {
      translationSubKey: 'promotionalPayment',
      value:
        memoizedDefaults.defaultPaymentPromotionalPeriod?.monthlyPayment || '0',
    },
    {
      translationSubKey: 'promotionalTypeName',
      value: selectedLoan?.loanProduct?.promotionalType?.friendlyName,
    },
    {
      translationSubKey: 'apr',
      value: calculateApr(
        selectedLoan?.calculatedFinancingDetails,
        selectedLoan?.loanProduct?.lenderId
      ),
    },
    {
      translationSubKey: 'noPaydown',
      value: calculateMonthlyPaymentNoPaydown(
        memoizedDefaults?.paymentWithAch,
        memoizedDefaults?.noPaydown,
        selectedLoan?.loanProduct?.lenderId
      ),
    },
    {
      translationSubKey: 'withAutoPay',
      value: `$${memoizedDefaults?.paymentWithAch}` || 'N/A',
    },
    {
      translationSubKey: 'withoutAutoPay',
      value: `$${memoizedDefaults?.paymentWOAch}` || 'N/A',
    },
  ]

  return (
    <Dialog
      open={true}
      onClose={() => setIsLoanProductModalOpen(false)}
      title="Payment Information"
      PaperProps={{
        sx: {
          maxWidth: '900px',
        },
      }}
    >
      {loading ? (
        <Loader size={100} center noLogo={true} />
      ) : (
        <div style={styles.loanProductPaper}>
          <span style={styles.loanTitle}>{loanData.loanName}</span>
          <Grid container gap={12} style={styles.loanGrid}>
            {gridItems.map((item, index) => (
              <Grid key={index} item desktop={4} tablet={6} mobile={8}>
                <div style={styles.loanDataWrapper}>
                  <span style={styles.loanDataTitle}>
                    {translate(
                      `loanApplication.step2.${item.translationSubKey}`
                    )}
                  </span>
                  <span style={styles.loanDataValue}>{item.value}</span>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </Dialog>
  )
}

LoanProductModal.propTypes = {
  loanData: PropTypes.object.isRequired,
  setIsLoanProductModalOpen: PropTypes.func.isRequired,
}

export default LoanProductModal
