import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import { useDropzone } from 'react-dropzone'
import cx from 'classnames'
import { getBase64 } from 'common/utils'
import Cookie from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { COOKIE_NAME } from 'common/constants'

import './index.scss'

const { Paper, FeaturedIcon, FeaturedIconColors, ProgressIndicator } =
  components
const { UploadCloudIcon } = assets

function FileUpload({
  onChange,
  multiple = false,
  acceptedFileTypes,
  onDropRejected,
  uploadFunctionSettings,
  acceptedFileTypesHelperText = 'ZIP, SVG, DOC, DOCX, PNG, JPG, JPEG ,HEIC, HEIF, PSD, PDF or WEBP (max. 30MB)',
}) {
  const [names, setNames] = React.useState([])
  const [percentageUploaded, setPercentageUploaded] = React.useState(0)

  const onDrop = useCallback(async (acceptedFiles) => {
    for (const file of acceptedFiles) {
      const content = await getBase64(file)
      const files = []
      files.push({
        content,
        name: file.name,
        mimeType: file.type,
      })

      setNames(files.map((file) => file.name))

      if (!uploadFunctionSettings) {
        onChange(multiple ? files : files[0])
        return
      }

      await uploadFilesProgress(
        uploadFunctionSettings.method,
        uploadFunctionSettings.url,
        { ...uploadFunctionSettings.data, ...files[0] },
        onProgress,
        () => {
          uploadFunctionSettings.cb && uploadFunctionSettings.cb()
          setNames([])
          setPercentageUploaded(0)
        }
      )
    }
  }, [])

  const onProgress = (progress) => {
    const value = Math.round(progress * 100)
    setPercentageUploaded(value)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    onDropRejected: onDropRejected ? onDropRejected : () => {},
  })

  return (
    <div
      {...getRootProps()}
      className={cx('file-upload', { 'file-upload--active': isDragActive })}
    >
      <Paper className={'paper upload-card'}>
        <FeaturedIcon
          icon={<UploadCloudIcon />}
          color={FeaturedIconColors.DISABLED}
          variant={'ripple'}
        />
        <span>
          {names.length > 0 ? (
            <span className="upload-card__cta">
              {names.map((n, key) => (
                <React.Fragment key={key}>{n}</React.Fragment>
              ))}
            </span>
          ) : (
            <>
              <span className="upload-card__cta">Click to upload</span> or drag
              and drop
            </>
          )}
        </span>
        <span
          className="upload-card__file-extensions"
          style={{ width: '100%' }}
        >
          {percentageUploaded ? (
            <ProgressIndicator
              value={percentageUploaded}
              variant="determinate"
              label={`${percentageUploaded}%`}
            />
          ) : (
            <>{acceptedFileTypesHelperText}</>
          )}
        </span>
      </Paper>
      <input {...getInputProps()} />
    </div>
  )
}

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  acceptedFileTypes: PropTypes.object,
  acceptedFileTypesHelperText: PropTypes.string,
  onDropRejected: PropTypes.func,
  uploadFunctionSettings: PropTypes.shape({
    method: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    data: PropTypes.object,
    cb: PropTypes.func,
  }),
}

export default FileUpload

const uploadFilesProgress = (
  method = 'PUT', // 'POST' or 'PUT
  url,
  files,
  onProgress,
  uploadFinishedCallback
) => {
  const cookie = Cookie.get(COOKIE_NAME)
  const xhr = new XMLHttpRequest()
  return new Promise((resolve, reject) => {
    xhr.upload.addEventListener('progress', (e) => {
      onProgress(e.loaded / e.total)
    })
    xhr.addEventListener('load', () =>
      resolve({ status: xhr.status, body: xhr.responseText })
    )
    xhr.addEventListener('error', () => reject(new Error('File upload failed')))
    xhr.addEventListener('abort', () =>
      reject(new Error('File upload aborted'))
    )
    xhr.open(method, url, true)

    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('x-requestid', uuidv4())
    xhr.setRequestHeader('Authorization', `Bearer ${cookie}`)

    xhr.send(new Blob([JSON.stringify(files)]))
  }).then(() => uploadFinishedCallback && uploadFinishedCallback())
}
