import { useState } from 'react'
import { JSONTree } from 'react-json-tree'
import { PropTypes } from 'prop-types'

import { ReactComponent as EditIcon } from 'assets/edit-icon.svg'

import { EditModal } from './modals/edit-modal'

import './styles.scss'

export const JsonViewer = ({ translationJson, onSubmitEdit }) => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)

  const [editModalData, setEditModalData] = useState({
    keyPath: '',
    translationKey: '',
    content: '',
  })

  const handleOnCloseEditModal = () => {
    setIsEditModalVisible(false)
  }

  const handleOnEdit = (path, value) => () => {
    const translationPath = path.reverse()
    const translationKey = translationPath.pop()
    const keyPath = translationPath.join('.')

    setEditModalData({
      keyPath,
      translationKey,
      content: value,
    })
    setIsEditModalVisible(true)
  }

  const handleOnSubmitEdit = async (path, key, data) => {
    onSubmitEdit(path, key, data)
    setIsEditModalVisible(false)
  }

  return (
    <div className="json-wrapper">
      <JSONTree
        className={'json-viewer'}
        data={translationJson}
        theme={{
          tree: {
            background: 'transparent',
            padding: 20,
            borderRadius: 20,
          },
          valueLabel: {
            textDecoration: 'underline',
          },
        }}
        hideRoot={true}
        shouldExpandNode={() => true}
        valueRenderer={(raw, value, ...keyPath) => {
          return (
            <div className="json-viewer__value">
              {value}{' '}
              <EditIcon
                className="json-viewer__value-edit_icon"
                onClick={handleOnEdit(keyPath, value)}
              />
            </div>
          )
        }}
        labelRenderer={(key, parentKey, x, rootKey) => {
          return <div style={{ marginLeft: '4px' }}>{key[0]}:</div>
        }}
      />

      {isEditModalVisible && (
        <EditModal
          isOpen={true}
          onClose={handleOnCloseEditModal}
          onSubmit={handleOnSubmitEdit}
          content={editModalData.content}
          keyPath={editModalData.keyPath}
          translationKey={editModalData.translationKey}
        />
      )}
    </div>
  )
}

JsonViewer.propTypes = {
  translationJson: PropTypes.object.isRequired,
  onSubmitEdit: PropTypes.func.isRequired,
}
