import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Table from 'components/table'
import Loader from 'components/loader'
import EmptyList from 'components/empty-list'
import Status, { STATUSES } from 'components/status'
import './index.scss'

const { Tabs, createColumnHelper, Chip } = components

const NotificationsScreen = ({ notifications }) => {
  const columnHelper = createColumnHelper()
  const navigate = useNavigate()

  const getFilteredNotifications = (type) =>
    notifications?.filter((item) => item.message.notificationType.name === type)

  const columns = useMemo(
    () => [
      columnHelper.accessor('message', {
        id: 'message',
        header: 'Message',
        cell: (info) => info.row.original.message.message,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: 'Status',
        cell: (info) => (
          <Status
            label={info.row.original.message.notificationType.friendlyName}
            status={
              info.row.original.message.notificationType.name === 'Positive'
                ? STATUSES.ACTIVE
                : info.row.original.message.notificationType.name === 'Negative'
                ? STATUSES.NEGATIVE
                : STATUSES.ON_HOLD
            }
          />
        ),
      }),
    ],
    []
  )

  const tabItems = [
    { label: 'All', value: 1 },
    { label: 'Success', value: 2, type: 'Positive' },
    { label: 'Warnings', value: 3, type: 'Warning' },
    { label: 'Errors', value: 4, type: 'Negative' },
  ]

  const tabs = tabItems.map(({ label, value, type }) => ({
    label: (
      <div>
        {label}{' '}
        <Chip
          label={
            type
              ? getFilteredNotifications(type)?.length
              : notifications?.length
          }
          sx={{ marginLeft: '6px' }}
        />
      </div>
    ),
    value,
    children:
      notifications?.length > 0 ? (
        <Table
          title={'Notifications'}
          data={type ? getFilteredNotifications(type) : notifications}
          columns={columns}
          onRowClick={(ev, row) => {
            navigate(`/loan-application/${row?.original.entityId}`)
          }}
        />
      ) : (
        <EmptyList
          title="No Notifications Found"
          description="We couldn't find any data matching your criteria."
        />
      ),
  }))

  if (!notifications) {
    return (
      <div className="notifications">
        <Loader center={true} />
      </div>
    )
  }

  return (
    <div className="admin-page">
      <div className="notifications__header-wrapper">
        <div>
          <div className="admin-page__header">Notifications</div>
          <div>You have new actions to review</div>
        </div>
      </div>
      <Tabs
        items={tabs}
        listProps={{
          variant: 'scrollable',
        }}
        panelProps={{
          sx: {
            marginTop: '32px',
            padding: 0,
          },
        }}
      />
    </div>
  )
}

NotificationsScreen.propTypes = {
  notifications: PropTypes.array,
  page: PropTypes.number.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
}

export default NotificationsScreen
