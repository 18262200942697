import React, { useEffect } from 'react'
import LoanProducts from 'modules/loan-product'
import { useStore } from 'store'
import { fetchLenders } from 'modules/organizations/actions'

const LoanProductPage = () => {
  const { state, dispatch } = useStore()
  const { lenders } = state.orgManagement

  useEffect(() => {
    if (lenders.length < 1) {
      fetchLenders(dispatch)
    }
  }, [])

  return <LoanProducts />
}

export default LoanProductPage
