import { colors } from '@ElementsCapitalGroup/enium-ui'

const borderPrimary = '#D0D5DD'

const paper = {
  boxShadow: 'none',
  border: `1px solid ${borderPrimary}`,
  padding: '16px',
  borderRadius: '12px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#F9FAFB',
  marginBottom: '24px',
}

const wrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  flexWrap: 'wrap',
  alignItems: 'center',
}

const attachmentInfoWrapper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: '12px',
  marginTop: '12px',
}

const attachmentInfo = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '4px',
  cursor: 'pointer',
  overflow: 'hidden',
  marginLeft: '12px',
}

const attachmentName = {
  fontSize: '0.875rem',
  color: colors.grey[700],
  fontWeight: 500,
  maxWidth: '200px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

const attachmentDate = { fontSize: '0.75rem', color: colors.grey[600] }

export const styles = {
  paper,
  wrapper,
  attachmentInfo,
  attachmentInfoWrapper,
  attachmentName,
  attachmentDate,
}
