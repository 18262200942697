import React from 'react'
import { resetPassword } from 'modules/session/actions'
import SetAndResetPassword from 'modules/set-reset-password'
import { history } from 'components/history'

const ResetPasswordPage = () => {
  const decodedUri = decodeURIComponent(history.location.search).split('&')
  const passwordResetId = decodedUri[0]?.replace('?resetPasswordId=', '')

  const onSubmit = (password) => resetPassword(password, passwordResetId)

  return (
    <SetAndResetPassword
      onSubmit={onSubmit}
      title="Welcome to Enium"
      supportingText="Let’s reset the password for your account."
      submittedVerbiage={{
        titleSuccess: 'Password reset',
        titleFailed: 'Set password failed',
        textSuccess:
          'Your password has been successfully reset. Click below to log in magically.',
        textFailed: 'Your password could not be set. Please try again later.',
      }}
    />
  )
}

export default ResetPasswordPage
