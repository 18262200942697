/**
 * This file will hold all the filters that need to be persisted.
 * Currently it's just Loan Apps filters but if we need to expand it's easier to have them all in a single file
 */
export const FILTER_TYPES = {
  LOAN_APPS: 'enium-v3.loan-apps-filters',
}

export const getFilter = (filterType) => localStorage.getItem(filterType)

export const setFilter = (filterType, objValue) =>
  localStorage.setItem(filterType, JSON.stringify(objValue))

export const clearAllFilters = () => {
  Object.values(FILTER_TYPES).forEach((filterType) => {
    localStorage.removeItem(filterType)
  })
}
