import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { scrollToClosestError } from 'common/utils'
import LateRevFee from '../utils/late-rev-fee'

const ExpandedRowDealerFees = ({
  data,
  onSaveFees,
  onCancel,
  lateFees,
  revFees,
}) => {
  const expandedRef = useRef(null)
  const { dealerId, organizationName } = data
  const [localLateFees, setLocalLateFees] = useState([])
  const [localRevFees, setLocalRevFees] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    setLocalLateFees(cloneDeep(lateFees))
  }, [JSON.stringify(lateFees)])

  useEffect(() => {
    setLocalRevFees(cloneDeep(revFees))
  }, [JSON.stringify(revFees)])

  /** Validate form and save */
  const validateAndSave = () => {
    setErrors({})
    const errors = {
      reversalFees: {},
      lateFees: {},
    }
    let isValid = true
    localLateFees.forEach((lateFee, index) => {
      if (!lateFee.numberOfDays || !lateFee.percentageApplied) {
        errors.lateFees[index] = 'Both fields are required'
        isValid = false
      }
    })
    localRevFees.forEach((revFee, index) => {
      if (!revFee.numberOfDays || !revFee.percentageApplied) {
        errors.reversalFees[index] = 'Both fields are required'
        isValid = false
      }
    })

    if (isValid) {
      onSaveFees(dealerId, localLateFees, localRevFees)
    } else {
      setErrors(errors)
      scrollToClosestError(expandedRef.current)
    }
  }

  return (
    <div className="afp__expanded" ref={expandedRef}>
      <div className="afp__expanded__header">
        <div className="afp__expanded__title">
          <p>{organizationName}</p>
        </div>
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          color={BUTTON_COLORS.INHERIT}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button onClick={validateAndSave}>Save Changes</Button>
      </div>
      <div className="afp__grid-inputs">
        <div>
          <div className="afp__dam__subtitle">Rev Fee</div>
          <LateRevFee
            state={localRevFees}
            onStateChange={setLocalRevFees}
            errors={errors.reversalFees}
            clearErrors={() => setErrors({ ...errors, reversalFees: {} })}
          />
        </div>
        <div>
          <div className="afp__dam__subtitle">Late Fee</div>
          <LateRevFee
            state={localLateFees}
            onStateChange={setLocalLateFees}
            errors={errors.lateFees}
            clearErrors={() => setErrors({ ...errors, lateFees: {} })}
          />
        </div>
      </div>
    </div>
  )
}

ExpandedRowDealerFees.propTypes = {
  data: PropTypes.object.isRequired,
  onSaveFees: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  lateFees: PropTypes.array.isRequired,
  revFees: PropTypes.array.isRequired,
}

export default ExpandedRowDealerFees
