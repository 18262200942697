import { v4 as uuidv4 } from 'uuid'

/** Map an AUS Template to the client's format */
export const mapToClientFormat = (template) => {
  const {
    decisioningTemplateId,
    name,
    loanDecisions,
    lenderId,
    version,
    ...rest
  } = template
  return {
    id: decisioningTemplateId,
    title: name,
    decisions: loanDecisions.length
      ? loanDecisions.map(mapDecisionToClientFormat)
      : [generateEmptyDecision()],
    lender: lenderId,
    version: isNaN(parseInt(version)) ? 1 : parseInt(version),
    ...rest,
  }
}

/** Map a decision element to the client's format */
export const mapDecisionToClientFormat = (decision) => {
  return {
    id: decision.id || uuidv4(),
    field: decision.criteriaId,
    index: decision.index,
    comparator: decision.comparitorId,
    decisions: decision.childDecisions.map(mapDecisionToClientFormat),
    value: decision.value,
  }
}

/**
 * Map an AUS Template to the server's format
 * @param {Object} template - client template to be mapped
 * @return {{loanDecisions, name, lenderId, decisionTemplateId}}
 */
export const mapToServerFormat = (template) => {
  const { id, title, decisions, description, lender, version } = template
  return {
    decisioningTemplateId: id,
    lenderId: lender,
    name: title,
    description,
    loanDecisions: decisions.map(mapDecisionToServerFormat),
    version,
  }
}

/** Map a decision element to the server's format */
export const mapDecisionToServerFormat = (decision, index) => {
  return {
    criteriaId: decision.field,
    comparitorId: decision.comparator,
    index,
    childDecisions:
      decision.decisions?.map((decision, idx) =>
        mapDecisionToServerFormat(decision, idx)
      ) || [],
    value: decision.value,
    ...DEFAULT_RESULT_OBJECT, // The server needs this dummy result object although it computes the result itself
  }
}

// Helper method to render a new empty decision element
export function generateEmptyDecision() {
  return {
    id: uuidv4(),
    value: '',
    field: '',
    comparator: '',
  }
}

// Helper method to render a new empty Template
export function generateEmptyTemplate() {
  return {
    id: uuidv4(),
    title: '',
    lender: '',
    description: '',
    version: 1,
    decisions: [{ ...generateEmptyDecision() }],
  }
}

export const DEFAULT_RESULT_OBJECT = {
  resultId: 0,
  result: {
    id: 0,
    name: 'Decline',
    friendlyName: 'Decline',
  },
}
