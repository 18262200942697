import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button from 'components/button'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'

import { getStates } from '../../actions'
import { styles } from '../style.js'

const { Chip, Dropdown, Card } = components

const OrganizationStates = ({
  orgData,
  onEditOrganization,
  dispatch,
  setLoading,
}) => {
  const [allStates, setAllStates] = useState(null)
  const [somethingChanged, setSomethingChanged] = useState(false)
  const [selectedStates, setSelectedStates] = useState([])

  useEffect(() => {
    setLoading(true)
    getStates()
      .then((states) => {
        setAllStates(states)
        setSelectedStates(
          states
            .filter((state) =>
              orgData.organizationStates.some(
                (orgState) => orgState.id === state.id
              )
            )
            .map((state) => ({ ...state, label: state.name }))
        )
      })
      .finally(() => setLoading(false))
  }, [])

  const onRemoveState = (stateId) => () => {
    if (!somethingChanged) {
      setSomethingChanged(true)
    }
    setSelectedStates((prev) => prev.filter((state) => state.id !== stateId))
  }
  const onStateChecked = (ev) => {
    if (!somethingChanged) {
      setSomethingChanged(true)
    }
    setSelectedStates(ev.target.value)
  }

  const onSaveStates = () => {
    setLoading(true)
    onEditOrganization({
      ...orgData,
      organizationStatesIds: selectedStates.map((state) => state.id),
    })
      .then(() =>
        showNotification(dispatch, {
          message: 'States association saved successfully',
        })
      )
      .catch(() =>
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          message: 'Error saving states association',
        })
      )
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <div style={styles.loanAssociation.searchBar}>
        <Dropdown
          label="Select States"
          multiple={true}
          searchable={true}
          options={allStates?.map((el) => ({ ...el, label: el.name })) || []}
          value={selectedStates}
          onChange={onStateChecked}
          sx={styles.loanAssociation.dropdown}
        />

        <Button
          disabled={!allStates || !somethingChanged}
          onClick={onSaveStates}
          sx={styles.loanAssociation.submitButton}
        >
          Submit
        </Button>
      </div>

      {!!allStates && selectedStates.length > 0 && (
        <Card>
          <h1 style={styles.users.orgDetailsTitle}>Associated States</h1>
          {selectedStates.map((state) => (
            <Chip
              key={state.id}
              label={state.name}
              variant="outlined-squared"
              onDelete={onRemoveState(state.id)}
              sx={styles.loanAssociation.chip}
            />
          ))}
        </Card>
      )}
    </div>
  )
}

OrganizationStates.propTypes = {
  orgData: PropTypes.object.isRequired,
  onEditOrganization: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default OrganizationStates
