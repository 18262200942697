import React from 'react'
import PropTypes from 'prop-types'

import { generateEmptyDecision } from './utils'
import StipulationElement from './stipulation-element'

const StipulationList = ({
  list,
  onListChange,
  errors,
  stipulationDefinitions,
  selected,
  setSelected,
  initialStipulation,
  commercialStipulation,
}) => {
  const onItemChange = (element, idx) => {
    const newList = list.map((el) =>
      el.guid === element.guid ? { ...element, guid: element.definition } : el
    )
    onListChange(newList)

    if (initialStipulation) {
      setSelected((prev) => {
        prev.initialStipulations[idx] = element.definition
        return prev
      })
    } else if (commercialStipulation) {
      setSelected((prev) => {
        prev.commercialStipulations[idx] = element.definition
        return prev
      })
    } else {
      setSelected((prev) => {
        prev.finalStipulations[idx] = element.definition
        return prev
      })
    }
  }

  const onItemRemove = (index) => {
    const newList = list.filter((_, i) => i !== index)
    onListChange(newList)

    // Update the state based on the new list
    if (initialStipulation) {
      setSelected((prev) => {
        prev.initialStipulations = newList.map((el) => el.definition)
        return prev
      })
    } else if (commercialStipulation) {
      setSelected((prev) => {
        prev.commercialStipulations = newList.map((el) => el.definition)
        return prev
      })
    } else {
      setSelected((prev) => {
        prev.finalStipulations = newList.map((el) => el.definition)
        return prev
      })
    }
  }

  const onItemAdd = (index) => {
    const newList = [...list]
    newList.splice(index + 1, 0, generateEmptyDecision())
    onListChange(newList)
  }

  return (
    <div>
      {list.map((itm, index) => {
        return (
          <StipulationElement
            key={index}
            index={index}
            stipulationDefinitions={stipulationDefinitions
              .filter((option) => {
                const stipulationsList = initialStipulation
                  ? selected.initialStipulations
                  : commercialStipulation
                  ? selected.commercialStipulations
                  : selected.finalStipulations

                const foundIndex = stipulationsList?.findIndex(
                  (el) => el === option.id
                )

                return (
                  foundIndex === index ||
                  (!selected?.initialStipulations?.includes(option.id) &&
                    !selected?.finalStipulations?.includes(option.id) &&
                    !selected?.commercialStipulations?.includes(option.id))
                )
              })
              .map((s) => ({
                ...s,
                translationKey: `loanApplication.ntpStepsCommon.stipulations.${s.id}.name`,
              }))}
            element={itm}
            errors={errors[index]}
            onElementChange={onItemChange}
            onElementRemove={() => onItemRemove(index)}
            onNewElement={() => onItemAdd(index)}
            canBeRemoved={list.length > 1}
            selected={selected}
            isInitialStipulation={initialStipulation}
            isCommercialStipulation={commercialStipulation}
            setSelected={setSelected}
          />
        )
      })}
    </div>
  )
}

StipulationList.propTypes = {
  list: PropTypes.array.isRequired,
  onListChange: PropTypes.func.isRequired,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  depth: PropTypes.number,
  errors: PropTypes.array.isRequired,
  stipulationDefinitions: PropTypes.array.isRequired,
  selected: PropTypes.object,
  setSelected: PropTypes.func.isRequired,
  initialStipulation: PropTypes.bool,
  commercialStipulation: PropTypes.bool,
}

export default StipulationList
