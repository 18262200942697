import React, { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TextField from 'components/input'
import Checkbox from 'components/checkbox'
import Textarea from 'components/textarea'
import InputDate from 'components/input-date'
import Button, { BUTTON_VARIANTS } from 'components/button'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { ERRORS } from 'components/validator'
import { useMediaQuery } from 'common/hooks'
import {
  dateFormatServer,
  DESKTOP_BREAKPOINT,
  dobDateFormat,
} from 'common/constants'
import {
  updateFundingInfo,
  requestFundReversal,
  fundingReversal,
} from 'modules/loan-application/actions'
import { formatIntToCurrency } from 'modules/loan-application/utils'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { useStore } from 'store'
import './index.scss'

const { Dialog } = components

const { RefreshCWIcon, CheckIcon, BankIcon } = assets
const FundingInfo = ({
  loanApplicationId,
  loanFormData,
  finalFundsGranted,
  onClose = () => {},
  dispatch,
}) => {
  const { t: translate } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [isFundReversalModalOpen, setIsFundReversalModalOpen] = useState(false)
  const [fundingInfo, setFundingInfo] = useState({
    initialFundingGrantedDate: '',
    initialFundingAmount: '',
    initialNetFundingAmount: '',
    finalNetFundingAmount: '',
    requestFundReversal: false,
    fundingReversalReason: '',
    fundingReversalRequestDate: moment().format(dateFormatServer),
    fundsReversed: false,
    fundsReversedDate: moment().format(dateFormatServer),
    fundsReversalDisabled: true,
    finalFundingGrantedDate: '',
    finalGrossFundingAmount: '',
    loanPaymentDueDate: moment().format(dateFormatServer),
  })
  const [errors, setErrors] = useState({})
  const loanAmount = loanFormData.borrowerGeneralDetails.loanAmount
  const { initialFundingAmount, finalFundingAmount } = loanFormData
  const { state } = useStore()
  const canEditLoanFunding = hasAccess(
    state.session.userData,
    CLAIMS.CAN_EDIT_LOAN_FUNDING
  )

  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  const totalFunded = finalFundsGranted
    ? initialFundingAmount + finalFundingAmount
    : initialFundingAmount
  const reversalGrantedDate = loanFormData.fundingReversalDate

  /** On loanFormData update, update the state*/
  useEffect(() => {
    const {
      initialFundingAmount,
      initialFundingGrantedDate,
      initialNetFundingAmount,
      finalNetFundingAmount,
      fundingReversalReason,
      fundingReversalRequestDate,
      fundingReversalDate,
      useInitialFundingThirdParty,
      amountPaidToThirdParty,
      dateOfAmountPaidToThirdParty,
      finalFundingGrantedDate,
      finalFundingAmount,
      finalGrossFundingAmount,
      loanPaymentDueDate,
    } = loanFormData

    const toUpdate = {
      initialFundingAmount,
      amountPaidToThirdParty,
      useInitialFundingThirdParty,
    }

    if (
      initialFundingGrantedDate &&
      moment(initialFundingGrantedDate).isValid()
    ) {
      toUpdate.initialFundingGrantedDate = moment(
        initialFundingGrantedDate,
        moment.ISO_8601
      ).format(dateFormatServer)
    }

    if (finalFundingGrantedDate && moment(finalFundingGrantedDate).isValid()) {
      toUpdate.finalFundingGrantedDate = moment(
        finalFundingGrantedDate,
        moment.ISO_8601
      ).format(dateFormatServer)
    }

    if (
      dateOfAmountPaidToThirdParty &&
      moment(dateOfAmountPaidToThirdParty).isValid()
    ) {
      toUpdate.dateOfAmountPaidToThirdParty = moment(
        dateOfAmountPaidToThirdParty,
        moment.ISO_8601
      ).format(dateFormatServer)
    }

    if (initialNetFundingAmount) {
      toUpdate.initialNetFundingAmount = initialNetFundingAmount
    }

    if (finalNetFundingAmount) {
      toUpdate.finalNetFundingAmount = finalNetFundingAmount
    }

    if (fundingReversalReason) {
      toUpdate.requestFundReversal = true
      toUpdate.fundingReversalReason = fundingReversalReason
      toUpdate.fundingReversalRequestDate = moment(
        fundingReversalRequestDate,
        moment.ISO_8601
      ).format(dateFormatServer)
      toUpdate.fundsReversalDisabled = false
    }

    if (fundingReversalDate) {
      toUpdate.fundsReversedDate = moment(
        fundingReversalDate,
        moment.ISO_8601
      ).format(dateFormatServer)
      toUpdate.fundsReversed = true
    }

    if (loanPaymentDueDate) {
      toUpdate.loanPaymentDueDate = moment(
        loanPaymentDueDate,
        moment.ISO_8601
      ).format(dateFormatServer)
    }

    if (finalGrossFundingAmount || finalFundingAmount) {
      toUpdate.finalGrossFundingAmount =
        finalGrossFundingAmount ?? finalFundingAmount
    }

    setFundingInfo({ ...fundingInfo, ...toUpdate })
  }, [loanFormData])

  /** Triggered on Update Funding Info click */
  const onUpdate = () => {
    setLoading(true)
    updateFundingInfo(dispatch, loanApplicationId, fundingInfo)
      .then(onClose)
      .finally(() => setLoading(false))
  }

  /** Triggered on clicking the Funding Reversal Request button */
  const onFundingReversal = () => {
    const reason = fundingInfo.fundingReversalReason
    if (!reason) {
      return setErrors({ fundingReversalReason: ERRORS.REQUIRED })
    }
    setLoading(true)
    requestFundReversal(
      dispatch,
      loanApplicationId,
      reason,
      fundingInfo.fundingReversalRequestDate
    ).finally(() => {
      setIsFundReversalModalOpen(false)
      setLoading(false)
    })
  }

  /** Triggered on clicking the Grant Reversal funds button */
  const onReversalGranted = () => {
    if (!fundingInfo.fundsReversedDate) {
      return
    }
    setLoading(true)
    fundingReversal(
      dispatch,
      loanApplicationId,
      fundingInfo.fundsReversedDate
    ).finally(() => setLoading(false))
  }

  return (
    <div>
      <div>
        <div className="fund-info__header">
          <div className="fund-info__title">
            <BankIcon sx={{ mr: 1 }} />
            {translate('loanApplication.ecgToolbox.fundingInfo.title')}
          </div>
          {!isTabletView && (
            <div>
              <Button
                variant={'outlined'}
                style={{ border: 0 }}
                onClick={() => {
                  setIsFundReversalModalOpen(true)
                }}
                loading={loading}
                startIcon={<RefreshCWIcon />}
              >
                {translate(
                  'loanApplication.ecgToolbox.fundingInfo.requestFundReversal'
                )}{' '}
              </Button>
              {canEditLoanFunding && (
                <Button
                  variant={'outlined'}
                  style={{ border: 'none' }}
                  onClick={onUpdate}
                  loading={loading}
                  startIcon={<CheckIcon />}
                >
                  {translate(
                    'loanApplication.ecgToolbox.fundingInfo.updateFundingInfo'
                  )}
                </Button>
              )}
            </div>
          )}
        </div>

        <div className="fund-info__wrapper">
          <div>
            <span className="fund-info__loan-title">
              {translate('loanApplication.ecgToolbox.fundingInfo.loanAmount')}:
            </span>
            <span className="fund-info__loan-value">
              {formatIntToCurrency(loanAmount)}
            </span>
          </div>
          <div>
            <span className="fund-info__loan-title">
              {translate(
                'loanApplication.ecgToolbox.fundingInfo.totalFundedToDate'
              )}
              :
            </span>
            <span className="fund-info__loan-value">
              {formatIntToCurrency(totalFunded)}
            </span>
          </div>
        </div>
        <div className="fund-info__paper">
          <div className="fund-info__input-wrapper">
            <TextField
              value={fundingInfo.finalGrossFundingAmount}
              label={translate(
                'loanApplication.ecgToolbox.fundingInfo.finalGrossFundingAmount'
              )}
              onChange={(finalGrossFundingAmount) =>
                setFundingInfo({
                  ...fundingInfo,
                  finalGrossFundingAmount,
                })
              }
              type="currency"
              fixedDecimalScale={true}
              style={{
                width: !isTabletView ? '320px' : '100%',
                marginRight: isTabletView ? 0 : '16px',
                marginTop: isTabletView ? '12px' : 0,
              }}
              disabled={!canEditLoanFunding}
            />
            <InputDate
              value={fundingInfo.initialFundingGrantedDate}
              label={translate(
                'loanApplication.ecgToolbox.fundingInfo.initialFundingDate'
              )}
              onChange={(initialFundingGrantedDate) =>
                setFundingInfo({
                  ...fundingInfo,
                  initialFundingGrantedDate,
                })
              }
              serverDateFormat={dateFormatServer}
              style={{
                width: !isTabletView ? '320px' : '100%',
                marginRight: isTabletView ? 0 : '16px',
                marginTop: isTabletView ? '12px' : 0,
              }}
              disabled={!canEditLoanFunding}
            />
          </div>

          <div className="fund-info__input-wrapper">
            <TextField
              value={fundingInfo.initialFundingAmount}
              label={translate(
                'loanApplication.ecgToolbox.fundingInfo.initialFundingAmount'
              )}
              type="currency"
              fixedDecimalScale={true}
              onChange={(initialFundingAmount) =>
                setFundingInfo({ ...fundingInfo, initialFundingAmount })
              }
              style={{
                width: !isTabletView ? '320px' : '100%',
                marginRight: isTabletView ? 0 : '16px',
                marginTop: isTabletView ? '12px' : 0,
              }}
              disabled={!canEditLoanFunding}
            />
            <TextField
              value={fundingInfo.initialNetFundingAmount}
              label={translate(
                'loanApplication.ecgToolbox.fundingInfo.initialNetFundedAmount'
              )}
              onChange={(initialNetFundingAmount) =>
                setFundingInfo({ ...fundingInfo, initialNetFundingAmount })
              }
              type="currency"
              style={{
                width: !isTabletView ? '320px' : '100%',
                marginRight: isTabletView ? 0 : '16px',
                marginTop: isTabletView ? '12px' : 0,
              }}
              fixedDecimalScale={true}
              disabled={!canEditLoanFunding}
            />
          </div>

          <div className="fund-info__input-wrapper">
            <TextField
              value={fundingInfo.finalNetFundingAmount}
              label={translate(
                'loanApplication.ecgToolbox.fundingInfo.finalNetFundingAmount'
              )}
              onChange={(finalNetFundingAmount) =>
                setFundingInfo({ ...fundingInfo, finalNetFundingAmount })
              }
              type="currency"
              fixedDecimalScale={true}
              style={{
                width: !isTabletView ? '320px' : '100%',
                marginRight: isTabletView ? 0 : '16px',
                marginTop: isTabletView ? '12px' : 0,
              }}
              disabled={!canEditLoanFunding}
            />
          </div>

          <div className="fund-info__input-wrapper">
            <TextField
              value={fundingInfo.amountPaidToThirdParty}
              label={translate(
                'loanApplication.ecgToolbox.fundingInfo.amountPaidToThirdParty'
              )}
              onChange={(amountPaidToThirdParty) =>
                setFundingInfo({ ...fundingInfo, amountPaidToThirdParty })
              }
              type="currency"
              fixedDecimalScale={true}
              style={{
                width: !isTabletView ? '320px' : '100%',
                marginRight: isTabletView ? 0 : '16px',
                marginTop: isTabletView ? '12px' : 0,
              }}
              disabled={!canEditLoanFunding}
            />

            <InputDate
              value={fundingInfo.dateOfAmountPaidToThirdParty}
              label={translate(
                'loanApplication.ecgToolbox.fundingInfo.dateOfAmountPaidToThirdParty'
              )}
              onChange={(dateOfAmountPaidToThirdParty) =>
                setFundingInfo({
                  ...fundingInfo,
                  dateOfAmountPaidToThirdParty,
                })
              }
              serverDateFormat={dateFormatServer}
              style={{
                width: !isTabletView ? '320px' : '100%',
                marginRight: isTabletView ? 0 : '16px',
                marginTop: isTabletView ? '12px' : 0,
              }}
              disabled={!canEditLoanFunding}
            />
          </div>

          <InputDate
            value={fundingInfo.loanPaymentDueDate}
            label={translate(
              'loanApplication.ecgToolbox.fundingInfo.loanPaymentDueDate'
            )}
            onChange={(loanPaymentDueDate) =>
              setFundingInfo({
                ...fundingInfo,
                loanPaymentDueDate,
              })
            }
            serverDateFormat={dateFormatServer}
            style={{
              width: !isTabletView ? '320px' : '100%',
              marginRight: isTabletView ? 0 : '16px',
              marginTop: '20px',
            }}
            disabled={!canEditLoanFunding}
          />

          {fundingInfo.finalFundingGrantedDate && (
            <div className="fund-info__loan-wrapper">
              <span className="input__label">
                {translate(
                  'loanApplication.ecgToolbox.fundingInfo.finalFundingDate'
                )}
              </span>

              <span className="fund-info__loan-amount">
                {fundingInfo.finalFundingGrantedDate}
              </span>
            </div>
          )}

          <div className="fund-info__checkbox">
            <Checkbox
              checked={fundingInfo.useInitialFundingThirdParty}
              label={translate(
                'loanApplication.ecgToolbox.fundingInfo.useInitialFundingThirdParty'
              )}
              onClick={() =>
                setFundingInfo({
                  ...fundingInfo,
                  useInitialFundingThirdParty:
                    !fundingInfo.useInitialFundingThirdParty,
                })
              }
              uncentered={true}
              disabled={!canEditLoanFunding}
            />

            {loanFormData.fundingReversalRequestDate ? (
              <div className="fund-info__reversed__text">
                <div>
                  Reversal requested on:{' '}
                  {moment(fundingInfo.fundingReversalRequestDate).format(
                    dobDateFormat
                  )}
                </div>
                {reversalGrantedDate ? (
                  <div>
                    Reversal granted on:{' '}
                    {moment(reversalGrantedDate).format(dobDateFormat)}
                  </div>
                ) : null}
              </div>
            ) : null}

            <Checkbox
              onClick={() =>
                setFundingInfo({
                  ...fundingInfo,
                  fundsReversed: !fundingInfo.fundsReversed,
                })
              }
              checked={fundingInfo.fundsReversed}
              label={translate(
                'loanApplication.ecgToolbox.fundingInfo.fundsReversed'
              )}
              uncentered
              disabled={
                fundingInfo.fundsReversalDisabled || !canEditLoanFunding
              }
            ></Checkbox>

            {fundingInfo.fundsReversed && (
              <div className="fund-info__reversed">
                <InputDate
                  value={fundingInfo.fundsReversedDate}
                  onChange={(fundsReversedDate) =>
                    setFundingInfo({ ...fundingInfo, fundsReversedDate })
                  }
                  serverDateFormat={dateFormatServer}
                  style={{
                    width: isTabletView ? '100%' : '320px',
                    marginBottom: '16px',
                    marginRight: '12px',
                  }}
                  disabled={!canEditLoanFunding}
                  minDate={moment(
                    fundingInfo.fundingReversalRequestDate
                  ).toDate()}
                  calendarPlacement="top-end"
                />

                <Button
                  onClick={onReversalGranted}
                  disabled={
                    loading ||
                    !canEditLoanFunding ||
                    !fundingInfo.fundsReversedDate
                  }
                  style={{ width: isTabletView ? '100%' : '320px' }}
                >
                  {translate(
                    reversalGrantedDate
                      ? 'loanApplication.ecgToolbox.fundingInfo.updateFundReversal'
                      : 'loanApplication.ecgToolbox.fundingInfo.grantFundReversal'
                  )}
                </Button>
                <hr />
              </div>
            )}
          </div>
        </div>

        {isTabletView && (
          <div className="fund-info__buttons">
            <Button
              variant={'outlined'}
              style={{ border: 0 }}
              onClick={() => {
                setIsFundReversalModalOpen(true)
              }}
              loading={loading}
              startIcon={<RefreshCWIcon />}
            >
              {translate(
                'loanApplication.ecgToolbox.fundingInfo.requestFundReversal'
              )}
            </Button>
            {canEditLoanFunding && (
              <Button
                variant="outlined"
                style={{ border: 0 }}
                onClick={onUpdate}
                loading={loading}
                startIcon={<CheckIcon />}
              >
                {translate(
                  'loanApplication.ecgToolbox.fundingInfo.updateFundingInfo'
                )}
              </Button>
            )}
          </div>
        )}
      </div>
      <Dialog
        open={isFundReversalModalOpen}
        onClose={() => setIsFundReversalModalOpen(false)}
        title={'Request Fund Reversal'}
        PaperProps={{
          sx: {
            minWidth: isTabletView ? '100%' : '550px',
            margin: isTabletView ? '16px' : 0,
          },
        }}
      >
        <div>
          <div>
            <Textarea
              value={fundingInfo.fundingReversalReason}
              onChange={(value) => {
                setFundingInfo({
                  ...fundingInfo,
                  fundingReversalReason: value,
                })
                setErrors({ ...errors, fundingReversalReason: null })
              }}
              label={translate('loanApplication.ecgToolbox.fundingInfo.reason')}
              className="select-loan__reason"
              validate={() => errors.fundingReversalReason}
              disabled={!canEditLoanFunding}
              fullWidth={true}
              rows={10}
            />
          </div>
          <InputDate
            value={fundingInfo.fundingReversalRequestDate}
            label={translate('loanApplication.ecgToolbox.fundingInfo.date')}
            serverDateFormat={dateFormatServer}
            onChange={(fundingReversalRequestDate) =>
              setFundingInfo({
                ...fundingInfo,
                fundingReversalRequestDate,
              })
            }
            style={{ marginTop: '16px' }}
            disabled={!canEditLoanFunding}
            fullWidth={true}
            maxDate={new Date()}
          />
        </div>
        <div className="fund-info__buttons">
          <div className="fund-info__reversed-button">
            <Button
              onClick={() => setIsFundReversalModalOpen(false)}
              variant={BUTTON_VARIANTS.OUTLINED}
              disabled={loading || !canEditLoanFunding}
              color={'inherit'}
              fullWidth
              sx={{
                marginRight: '16px',
              }}
            >
              Cancel
            </Button>
          </div>
          <Button
            className="fund-info__button"
            onClick={onFundingReversal}
            disabled={loading || !canEditLoanFunding}
            fullWidth
          >
            Send Request
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

FundingInfo.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  loanFormData: PropTypes.object.isRequired,
  finalFundsGranted: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
}

export default FundingInfo
