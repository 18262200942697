import React from 'react'
import PropTypes from 'prop-types'
import { components, assets, hooks } from '@ElementsCapitalGroup/enium-ui'
import { TABLET_BREAKPOINT } from 'common/constants'
import TextField, { INPUT_TYPES } from 'components/input'

const { Dropdown, Checkbox } = components
const { TrashIcon } = assets
const { useMediaQuery } = hooks

const PaymentDetailsElement = ({
  element,
  index,
  onElementChange,
  onElementRemove,
  canBeRemoved = true,
  children,
  financingPrincipleTypes,
  financingInterestTypes,
  errors = {},
}) => {
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const handleTextFieldChange = (name, value) => {
    onElementChange({
      ...element,
      [name]: value,
    })
  }

  const handleDropdownChange = (name, event, isDropdown) => {
    const {
      target: { value },
    } = event

    onElementChange(
      {
        ...element,
        [name]: value.id,
      },
      isDropdown
    )
  }
  return (
    <div className="loan-product__financial">
      <div className="loan-product__textfields-wrapper--no-margin">
        <span className="loan-product__financial-title">Payment Period</span>
        {canBeRemoved && (
          <TrashIcon
            className="admin__icons-trash"
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={(ev) => {
              ev.stopPropagation()
              onElementRemove(index)
            }}
          />
        )}
      </div>
      <div className="loan-product__textfields-wrapper">
        <div className="loan-product__dropdown">
          <Dropdown
            options={financingPrincipleTypes}
            label="Principal Type"
            fullWidth
            value={
              financingPrincipleTypes.find(
                (itm) => itm.id === element.principalTypeId
              ) || ''
            }
            error={!!errors.principalTypeId}
            helperText={errors.principalTypeId}
            onChange={(value) =>
              handleDropdownChange('principalTypeId', value, true)
            }
            sx={{ mr: 3 }}
          />
        </div>
        <div className="loan-product__dropdown">
          <Dropdown
            options={financingInterestTypes}
            label="Interest Type"
            fullWidth
            value={
              financingInterestTypes.find(
                (itm) => itm.id === element.interestTypeId
              ) || ''
            }
            error={!!errors.interestTypeId}
            helperText={errors.interestTypeId}
            onChange={(value) =>
              handleDropdownChange('interestTypeId', value, true)
            }
          />
        </div>
        <TextField
          label="Interest Rate"
          value={element.interestRate}
          fullWidth
          type={INPUT_TYPES.PERCENTAGE}
          style={{ marginTop: isMobileView ? 16 : '3px' }}
          validate={() => errors.interestRate}
          onChange={(value) => handleTextFieldChange('interestRate', value)}
        />
      </div>
      <div className="loan-product__textfields-wrapper">
        <TextField
          label="Interest APR *"
          type="number"
          fullWidth
          value={element.interestAPR}
          validate={() => errors.interestAPR}
          onChange={(value) => handleTextFieldChange('interestAPR', value)}
          style={{
            marginRight: isMobileView ? 0 : 24,
            marginTop: isMobileView ? 10 : 5,
          }}
        />
        <TextField
          label="Amount"
          type="currency"
          fullWidth
          value={element.amount}
          validate={() => errors.amount}
          onChange={(value) => handleTextFieldChange('amount', value)}
          style={{
            marginRight: isMobileView ? 0 : 24,
            marginTop: isMobileView ? 16 : 5,
          }}
        />
        <TextField
          label="Months"
          type="number"
          fullWidth
          style={{ marginTop: isMobileView ? 16 : 5 }}
          value={element.months || ''}
          validate={() => errors.months}
          onChange={(value) => handleTextFieldChange('months', value)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: isMobileView ? 'start' : 'center',
          marginTop: isMobileView ? '14px' : '16px',
          marginBottom: '20px',
          flexDirection: isMobileView ? 'column' : 'row',
        }}
      >
        <TextField
          label="Order"
          type="number"
          value={element.order || ''}
          validate={() => errors.order}
          onChange={(value) => handleTextFieldChange('order', value)}
          style={{ marginRight: isMobileView ? 0 : 24 }}
        />
        <div className="loan-product__checkbox-wrapper">
          <Checkbox
            label="Is a promotional period"
            checked={element.isPromotionalPaymentPeriod}
            onClick={() =>
              onElementChange({
                ...element,
                isPromotionalPaymentPeriod: !element.isPromotionalPaymentPeriod,
              })
            }
          />
        </div>
      </div>

      {children ? <div className="aus-decision__child">{children}</div> : null}
    </div>
  )
}

PaymentDetailsElement.propTypes = {
  element: PropTypes.object,
  index: PropTypes.number.isRequired,
  onElementChange: PropTypes.func.isRequired,
  onElementRemove: PropTypes.func.isRequired,
  financingPrincipleTypes: PropTypes.array.isRequired,
  financingInterestTypes: PropTypes.array.isRequired,
  canBeRemoved: PropTypes.bool,
  children: PropTypes.node,
  errors: PropTypes.object,
}

export default PaymentDetailsElement
