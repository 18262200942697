import React, { useEffect, useState } from 'react'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Textarea from 'components/textarea'
import Loader from 'components/loader'
import { useStore } from 'store'
import {
  getChangeOrderRequestTypes,
  getOrderRequests,
  submitChangeOrderRequest,
} from '../../actions'

import { styles } from './styles'

const { Dropdown, Button } = components

const { SwitchHorizontalIcon } = assets

const ChangeOrderRequest = ({ loanApplicationId }) => {
  const { t: translate } = useTranslation()
  const [selectedOrderRequest, setSelectedOrderRequest] = useState('')
  const [ordersLoading, setOrdersLoading] = useState(false)
  const [orderRequests, setOrderRequests] = useState([])
  const [changeOrderRequestTypes, setChangeOrderRequestTypes] = useState([])
  const [reason, setReason] = useState('')
  const [successMessage, showSuccessMessage] = useState(false)
  const { dispatch } = useStore()

  useEffect(() => {
    if (loanApplicationId) {
      getChangeOrderRequestTypes().then((res) =>
        setChangeOrderRequestTypes(res)
      )
      getAllOrderRequests()
    }
  }, [loanApplicationId])

  const getAllOrderRequests = () => {
    setOrdersLoading(true)
    getOrderRequests(loanApplicationId)
      .then((res) => {
        setOrderRequests(res)
      })
      .finally(() => setOrdersLoading(false))
  }

  const handleChangeOrderRequest = () => {
    setOrdersLoading(true)
    submitChangeOrderRequest(
      loanApplicationId,
      selectedOrderRequest,
      reason,
      dispatch
    )
      .then((res) => {
        getAllOrderRequests()
        if (res) {
          showSuccessMessage(true)
        }
      })
      .finally(() => {
        setOrdersLoading(false)
        setSelectedOrderRequest('')
        setReason('')
      })
  }

  const _renderContent = () => {
    if (ordersLoading) {
      return <Loader size={100} center noLogo={true} />
    }
    if (successMessage) {
      return (
        <div>
          <div className="history-ecg__title">
            <SwitchHorizontalIcon sx={{ mr: 1 }} />
            {translate(
              'loanApplication.ecgToolbox.changeOrderRequestModal.title'
            )}{' '}
          </div>
          <div>
            {translate(
              'loanApplication.ecgToolbox.changeOrderRequestModal.thankYouText'
            )}
            <a
              href={
                translate(
                  'loanApplication.ecgToolbox.changeOrderRequestModal.tel'
                ) +
                translate(
                  'loanApplication.ecgToolbox.changeOrderRequestModal.formattedPhoneNumber'
                )
              }
            >
              {translate(
                'loanApplication.ecgToolbox.changeOrderRequestModal.formattedPhoneNumber'
              )}
            </a>{' '}
            (
            {translate(
              'loanApplication.ecgToolbox.changeOrderRequestModal.tel'
            )}{' '}
            <a
              href={
                translate(
                  'loanApplication.ecgToolbox.changeOrderRequestModal.tel'
                ) +
                translate(
                  'loanApplication.ecgToolbox.changeOrderRequestModal.phoneNumber'
                )
              }
            >
              {translate(
                'loanApplication.ecgToolbox.changeOrderRequestModal.phoneNumber'
              )}
            </a>
            ).
          </div>
          <Button
            onClick={() => showSuccessMessage(false)}
            style={styles.orderRequests}
          >
            {translate(
              'loanApplication.ecgToolbox.changeOrderRequestModal.understandButtonText'
            )}
          </Button>
        </div>
      )
    }
    return (
      <>
        <div className="history-ecg__title">
          <SwitchHorizontalIcon sx={{ mr: 1 }} />
          {translate(
            'loanApplication.ecgToolbox.changeOrderRequestModal.title'
          )}{' '}
        </div>
        <Dropdown
          options={changeOrderRequestTypes.map((item) => {
            return {
              id: item.id,
              label: item.value,
            }
          })}
          label="Input to change"
          value={
            changeOrderRequestTypes
              .map((item) => {
                return {
                  id: item.id,
                  label: item.value,
                }
              })
              .find((itm) => itm.id === selectedOrderRequest) || ''
          }
          onChange={(ev) => {
            setSelectedOrderRequest(ev.target.value.id)
          }}
        />
        <div style={styles.textArea}>
          <Textarea
            label={translate('loanApplication.ecgToolbox.fundingInfo.reason')}
            value={reason}
            onChange={setReason}
          />
        </div>
        <Button
          onClick={handleChangeOrderRequest}
          disabled={selectedOrderRequest === ''}
          style={styles.button}
        >
          Submit
        </Button>
        <div style={styles.orderRequests}>
          {orderRequests.length > 0 && (
            <>
              <span className="history-ecg__title">Past Order Requests</span>
              <div>
                {orderRequests.map((item, key) => (
                  <div style={styles.orderRequestsTextWrapper} key={key}>
                    <span style={styles.orderRequestsText}>
                      {translate(
                        'loanApplication.ecgToolbox.changeOrderRequestModal.dateCreated'
                      )}
                    </span>{' '}
                    {moment(item.dateCreated).format('MM-DD-YYYY HH:mm')}
                    <div>
                      <span style={styles.orderRequestsText}>
                        {translate(
                          'loanApplication.ecgToolbox.changeOrderRequestModal.createdBy'
                        )}
                      </span>{' '}
                      {item.createdBy}
                    </div>
                    <div>
                      <span style={styles.orderRequestsText}>
                        {translate(
                          'loanApplication.ecgToolbox.changeOrderRequestModal.changeOrderRequestTypeName'
                        )}
                      </span>{' '}
                      {translate(
                        `loanApplication.ecgToolbox.changeOrderRequestModal.changeOrderRequestType.${item.changeOrderRequestType.id}`
                      )}
                    </div>
                    <div>
                      <span style={styles.orderRequestsText}>
                        {translate(
                          'loanApplication.ecgToolbox.fundingInfo.reason'
                        )}
                      </span>{' '}
                      {item.reason}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </>
    )
  }

  return <div>{_renderContent()}</div>
}

ChangeOrderRequest.propTypes = {
  loanApplicationId: PropTypes.string,
}

export default ChangeOrderRequest
