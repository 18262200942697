import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { useNavigate } from 'react-router-dom'

const { Breadcrumbs } = components
const { BankIcon } = assets

const FundingBreadcrumbs = ({ items = [], onItemClick }) => {
  const navigate = useNavigate()
  const breadcrumbs = useMemo(() => {
    const breadcrumbs = [
      { icon: <BankIcon /> },
      { id: 'funding', label: 'Funding' },
    ]
    return [...breadcrumbs, ...items]
  }, [JSON.stringify(items)])

  return (
    <Breadcrumbs
      sx={{ marginBottom: '32px' }}
      items={breadcrumbs}
      lastItemClickable={false}
      onClick={(ev, item) => {
        if (onItemClick) {
          onItemClick(item)
        } else if (item.id === 'funding') {
          navigate('/afp/daily')
        }
      }}
    />
  )
}

FundingBreadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ),
  onItemClick: PropTypes.func,
}

export default FundingBreadcrumbs
