import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  components,
  unstable_components,
  assets,
  providers,
} from '@ElementsCapitalGroup/enium-ui'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { isEmpty } from 'lodash'
import { ReactComponent as WhiteClipperIcon } from 'assets/white-clipper.svg'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { scrollToClosestError } from 'common/utils'
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from 'components/button'
import { ERRORS, validate, validateSSN } from 'components/validator'
import Modal from 'components/modal'
import {
  LOAN_PAGE_ERROR_MAP,
  USER_GENERAL_DETAILS,
  ADDRESS_DETAILS,
  GENERAL_ERRORS,
  SERVICE_ADDRESS_ERROR_MAP,
  GENERAL_ADDRESS_ERRORS,
  ORGANIZATION_TYPE_IDS,
  SPECIAL_FIELDS_ERRORS,
  SPECIAL_FIELDS_DETAILS,
  SPECIAL_FIELDS_ERROR_MAP,
  APPLICANT_TYPE,
  ADDRESS_TYPES_TRANSLATIONS,
  LOAN_PAGE_ERROR_MAP_WITH_EMPLOYER_FIELDS,
  DESKTOP_BREAKPOINT,
} from 'common/constants'
import Loader from 'components/loader'
import {
  resetPrefillData,
  setLoading as setGlobalLoading,
  showNotification,
} from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import {
  getAllOrganizations,
  getLanguageByOrgId,
  getStates,
} from 'modules/organizations/actions'

import AddressDetails from 'modules/loan-application/create-loan-app/address-details'
import GeneralDetails from 'modules/loan-application/create-loan-app/general-details'
import AdditionalIncomeDetails from 'modules/loan-application/create-loan-app/additional-income-details'
import IdentificationInfo from 'modules/loan-application/create-loan-app/identification-details'
import SolarDetails from 'modules/loan-application/create-loan-app/solar-details'
import IncomeDetails from 'modules/loan-application/create-loan-app/income-details'
import { TermsAndConditions } from 'components/terms-and-conditions'
import WarningModal from 'modules/loan-application/create-loan-app/warning-modal'
import LoanAppTopSection from 'modules/loan-application/create-loan-app/top-section'
import { fetchLoanProductsForOrganization } from 'modules/loan-product/actions'
import {
  getAdditionalIncomeTypes,
  getCommunicationMethods,
  submitLoanApp,
  getEmploymentStatuses,
  getCitizenships,
  getMLAs,
  getIDTypes,
  checkOrganizationHasSpecialFields,
  getCreditReport,
  getResidencyOccupationTypes,
  initLoanFormDataBasedOnQueryParams,
} from 'modules/loan-application/actions'
import {
  SOLAR_INFO,
  IDENTIFICATION_INFO,
  INCOME_INFO,
  STATES_ORDER,
  LOAN_APPLICATION_STATES_IDS,
} from 'modules/loan-application/constants'
import {
  formDataToServerFormat,
  initializeStripeIDVerification,
  mapLoanProductsForClient,
  validateAddress,
} from 'modules/loan-application/utils'
import { loadTranslationsForLoanApp } from 'modules/translations'
import { useEffectOnUpdate, useMediaQuery, useQuery } from 'common/hooks'

import { copyUWToClipboard } from './clipboard'
import './index.scss'
import NewLoanHeader from './header'

const { Paper } = components
const { Unstable_Grid: Grid } = unstable_components
const { PlusIcon, ScanIcon, TrashIcon } = assets
const { ThemeProvider } = providers

const CreateLoanApp = ({
  loanApplicationId,
  loanFormData,
  organizations,
  isHardCreditPulled,
  userData,
  currentState,
  dispatch,
  globalState,
}) => {
  const { state: locationState } = useLocation()
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const navigate = useNavigate()
  const [borrowerVerificationSessionId, setBorrowerVerificationSessionId] =
    useState(null)
  const [coBorrowerVerificationSessionId, setCoBorrowerVerificationSessionId] =
    useState(null)
  const [hideEmploymentFields, setHideEmploymentFields] = useState(undefined)
  const [hideEmploymentFieldsCoBorrower, setHideEmploymentFieldsCoBorrower] =
    useState(undefined)
  const [hideIncomeFieldCoBorrower, setHideIncomeFieldCoBorrower] =
    useState(undefined)
  const [hideIncomeField, setHideIncomeField] = useState(undefined)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const isEdit = !!loanApplicationId
  const isECG = hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING)
  const query = useQuery()
  const canCreateEdit = useMemo(
    () =>
      hasAccess(
        userData,
        isEdit
          ? CLAIMS.CAN_EDIT_LOAN_APPLICATIONS
          : CLAIMS.CAN_CREATE_LOAN_APPLICATIONS
      ),
    [userData]
  )
  const canViewAdditionalFields = useMemo(
    () =>
      isEdit &&
      hasAccess(userData, [
        CLAIMS.CAN_VIEW_ADDITIONAL_LOAN_APPLICATION_FIELDS,
        CLAIMS.CAN_EDIT_UW_FIELDS_IN_ANY_STATE,
      ]),
    [userData, isEdit]
  )

  const canUpdateActualDTIFIelds = useMemo(
    () => isEdit && hasAccess(userData, CLAIMS.CAN_EDIT_ACTUAL_DTI),
    [userData]
  )

  const canEditUWFields = useMemo(
    () => isEdit && hasAccess(userData, CLAIMS.CAN_EDIT_UW_FIELDS_IN_ANY_STATE),
    [userData]
  )

  const canUpdateOnlyUWFields = useMemo(
    () =>
      isEdit &&
      hasAccess(userData, CLAIMS.CAN_UPDATE_ONLY_UW_FIELDS) &&
      !hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING),
    [userData]
  )

  const areFieldsDisabledAfterIfRequestedState = useMemo(
    () =>
      STATES_ORDER[currentState] >= STATES_ORDER.IFRequested &&
      STATES_ORDER[currentState] !==
        LOAN_APPLICATION_STATES_IDS.INITIAL_NTP_REJECTED &&
      STATES_ORDER[currentState] !==
        LOAN_APPLICATION_STATES_IDS.INITIAL_NTP_RESUBMITTED &&
      !hasAccess(
        userData,
        CLAIMS.CAN_UPDATE_APPLICATION_FIRST_STEP_AFTER_IF_REQUESTED
      ),
    [userData]
  )

  const areInputsDisabled =
    canUpdateOnlyUWFields || areFieldsDisabledAfterIfRequestedState

  const hasOrgsAccess = useMemo(
    () => hasAccess(userData, CLAIMS.CAN_VIEW_ORGANIZATIONS),
    [userData]
  )

  const loanPageErrorMap = useMemo(() => {
    if (hideEmploymentFields) {
      return LOAN_PAGE_ERROR_MAP
    }

    return LOAN_PAGE_ERROR_MAP_WITH_EMPLOYER_FIELDS
  }, [hideEmploymentFields])

  const loanPageErrorMapCoBorrower = useMemo(() => {
    if (hideEmploymentFieldsCoBorrower) {
      return LOAN_PAGE_ERROR_MAP
    }

    return LOAN_PAGE_ERROR_MAP_WITH_EMPLOYER_FIELDS
  }, [hideEmploymentFieldsCoBorrower])

  const [stateList, setStateList] = useState([])

  const [loading, setLoading] = useState(true)
  const [copyToClipboardLoading, setCopyToClipboardLoading] = useState(false)
  const [communicationMethods, setCommunicationMethods] = useState([])
  const [idTypes, setIdTypes] = useState([])
  const [additionalIncomeTypes, setAdditionalIncomeTypes] = useState([])
  const [employmentStatuses, setEmploymentStatuses] = useState([])
  const [residencyOccupation, setResidencyOccupation] = useState([])
  const [citizenships, setCitizenships] = useState([])
  const [militaryAffiliation, setMilitaryAffiliation] = useState([])
  const [organization, setOrganization] = useState(
    !hasOrgsAccess ? userData.organizationId : null
  )
  const [areTermsConfirmed, setAreTermsConfirmed] = useState(false)
  const [bypassValidateAddress, setBypassValidateAddress] = useState(false)
  const [salesOrganizations, setSalesOrganizations] = useState([])
  const [isCorporateOrg, setIsCorporateOrg] = useState(false)
  const [borrowerGeneralDetails, setBorrowerGeneralDetails] = useState({
    ...USER_GENERAL_DETAILS,
  })
  const [coBorrowerGeneralDetails, setCoBorrowerGeneralDetails] = useState({
    ...USER_GENERAL_DETAILS,
  })
  const [specialFieldsDetails, setSpecialFieldsDetails] = useState({
    ...SPECIAL_FIELDS_DETAILS,
  })
  const createLoanAppForm = useRef(null)
  const borrowerServerSSN = useRef('')
  const borrowerEmployer = useRef('')
  const borrowerJobTitle = useRef('')
  const coBorrowerRef = useRef(null)
  const hasServerCoBorrower = useRef(false)
  const [serviceAddress, setServiceAddress] = useState({ ...ADDRESS_DETAILS })
  const [borrowerAddress, setBorrowerAddress] = useState({
    ...ADDRESS_DETAILS,
  })
  const [coBorrowerAddress, setCoBorrowerAddress] = useState({
    ...ADDRESS_DETAILS,
  })
  const [borrowerIdentification, setBorrowerIdentification] = useState({
    ...IDENTIFICATION_INFO,
  })
  const [coBorrowerIdentification, setCoBorrowerIdentification] = useState({
    ...IDENTIFICATION_INFO,
  })
  const [incomeData, setIncomeData] = useState({ ...INCOME_INFO })
  const [solarData, setSolarData] = useState({ ...SOLAR_INFO })
  const [loanSignatures, setLoanSignatures] = useState([])

  const [allLoanSignatures, setAllLoanSignatures] = useState([])
  const [warnings, setWarnings] = useState({})
  const [isDeleteCoborrowerModalOpen, setIsDeleteCoborrowerModalOpen] =
    useState(false)
  const [organizationHasSpecialFields, setOrganizationHasSpecialFields] =
    useState(false)

  const [
    isDeleteAdditionalIncomeOpenCoBorrower,
    setIsDeleteAdditionalIncomeOpenCoBorrower,
  ] = useState(false)
  const [
    isDeleteAdditionalIncomeOpenBorrower,
    setIsDeleteAdditionalIncomeOpenBorrower,
  ] = useState(false)

  const areTermsRequired =
    !isEdit ||
    (borrowerGeneralDetails.hasCoBorrower && !hasServerCoBorrower.current)

  const warningsAccepted = useRef(false)
  const { t: translate } = useTranslation()
  const translations = {
    copyToClipboard: translate('loanApplication.step1.copyToClipboard'),
    searchLoanProducts: translate('loanApplication.step1.searchLoanProducts'),
    saveLoanApp: translate('loanApplication.step1.saveLoanApp'),
    sameBillingAddress: translate(
      'loanApplication.step1.address.sameBillingAddress'
    ),
    borrower: translate('loanApplication.step1.borrower'),
    coBorrower: translate('loanApplication.step1.coBorrower'),
    additionalIncome: translate(
      'loanApplication.step1.additionalIncome.additionalIncome'
    ),
    addCoBorrower: translate('loanApplication.step1.addCoBorrower'),
    deleteIncomeFor: translate(
      'loanApplication.step1.deleteAdditionalIncome.deleteIncomeFor'
    ),
    deleteIncomeAreYouSure: translate(
      'loanApplication.step1.deleteAdditionalIncome.deleteIncomeAreYouSure'
    ),
    deleteCoBorrower: translate('loanApplication.step1.deleteCoBorrower'),
    confirm: translate('buttons.confirm'),
    cancel: translate('buttons.cancel'),
    delete: translate('buttons.delete'),
  }

  const [allErrors, setAllErrors] = useState({
    borrower: GENERAL_ERRORS,
    coBorrower: GENERAL_ERRORS,
    serviceAddress: { ...GENERAL_ADDRESS_ERRORS },
    loanSignatures: null,
    borrowerIdentification: {},
    coBorrowerIdentification: {},
    specialFields: SPECIAL_FIELDS_ERRORS,
  })

  const BORROWER_LOAN_PAGE_ERROR_MAP =
    borrowerGeneralDetails.hasAdditionalIncome
      ? loanPageErrorMap
      : (({ additionalIncomeTypeId, additionalIncomeAmount, ...o }) => o)(
          loanPageErrorMap
        )

  const COBORROWER_LOAN_PAGE_ERROR_MAP =
    coBorrowerGeneralDetails.hasAdditionalIncome
      ? (({ loanAmount, ...o }) => o)(loanPageErrorMapCoBorrower)
      : (({
          additionalIncomeTypeId,
          additionalIncomeAmount,
          loanAmount,
          ...o
        }) => o)(loanPageErrorMapCoBorrower)

  /** Prefill loan app based on document verification with Stripe (borrower) */
  useEffect(() => {
    const prefilledData = globalState.borrowerPrefillData
    if (!isEmpty(prefilledData)) {
      setBorrowerGeneralDetails({
        ...borrowerGeneralDetails,
        firstName: prefilledData?.firstName,
        lastName: prefilledData?.lastName,
        dateOfBirth: prefilledData?.dateOfBirth,
      })

      const stateAbbr = prefilledData?.address?.state || ''
      const stateId = stateList.find((el) => el.abbreviation === stateAbbr)?.id

      setBorrowerIdentification({
        ...borrowerIdentification,
        idType: prefilledData?.idType,
        issuanceDate: prefilledData?.issuanceDate,
        expirationDate: prefilledData?.expirationDate,
        number: prefilledData?.idNumber,
        dateOfBirth: prefilledData?.dateOfBirth,
        idIssueState: stateId,
      })
    }
  }, [globalState.borrowerPrefillData])

  /** Prefill loan app based on document verification with Stripe (co-borrower) */
  useEffect(() => {
    const prefilledData = globalState.coBorrowerPrefillData
    if (!isEmpty(prefilledData)) {
      setCoBorrowerGeneralDetails({
        ...coBorrowerGeneralDetails,
        firstName: prefilledData?.firstName,
        lastName: prefilledData?.lastName,
        dateOfBirth: prefilledData?.dateOfBirth,
      })

      const stateAbbr = prefilledData?.address?.state || ''
      const stateId = stateList.find((el) => el.abbreviation === stateAbbr)?.id

      setCoBorrowerIdentification({
        ...coBorrowerIdentification,
        idType: prefilledData?.idType,
        issuanceDate: prefilledData?.issuanceDate,
        expirationDate: prefilledData?.expirationDate,
        number: prefilledData?.idNumber,
        dateOfBirth: prefilledData?.dateOfBirth,
        idIssueState: stateId,
      })
    }
  }, [globalState.coBorrowerPrefillData])

  /**
   * Prefill loan app based on query params
   * {@see https://enium.notion.site/Pre-fill-Application-Documentation-ba88ce4e8d8348cc8e33a675eb0afa60}
   */
  useEffect(() => {
    if (
      !isEdit &&
      citizenships.length &&
      militaryAffiliation.length &&
      employmentStatuses.length &&
      residencyOccupation.length &&
      communicationMethods.length &&
      additionalIncomeTypes.length
    ) {
      initLoanFormDataBasedOnQueryParams(
        dispatch,
        query,
        citizenships,
        militaryAffiliation,
        employmentStatuses,
        residencyOccupation,
        communicationMethods,
        additionalIncomeTypes
      )
    }
  }, [
    citizenships,
    militaryAffiliation,
    employmentStatuses,
    residencyOccupation,
    communicationMethods,
    additionalIncomeTypes,
  ])

  /** Prefill loan signatures & loan amount set via the Loan Calculator */
  useEffect(() => {
    if (locationState?.selectedLoan?.loanAmount) {
      loadDataFromLoanCalculator(locationState.selectedLoan)
    }
  }, [locationState, allLoanSignatures])

  /**
   * On mount
   * - get loan application data
   * - get info for the Dropdowns if not already fetched
   * - get Organizations
   *
   * On unmount, clear prefill data from Stripe
   */
  useEffect(() => {
    const promises = []
    if (!communicationMethods.length) {
      promises.push(getCommunicationMethods().then(setCommunicationMethods))
    }
    if (!idTypes.length) {
      promises.push(getIDTypes().then(setIdTypes))
    }
    if (!additionalIncomeTypes.length) {
      promises.push(getAdditionalIncomeTypes().then(setAdditionalIncomeTypes))
    }
    if (!employmentStatuses.length) {
      promises.push(getEmploymentStatuses().then(setEmploymentStatuses))
    }
    if (!citizenships.length) {
      promises.push(getCitizenships().then(setCitizenships))
    }
    if (!militaryAffiliation.length) {
      promises.push(getMLAs().then(setMilitaryAffiliation))
    }
    if (hasOrgsAccess) {
      promises.push(getAllOrganizations(dispatch))
    }
    if (!stateList.length) {
      promises.push(getStates().then(setStateList))
    }
    if (!residencyOccupation.length) {
      promises.push(getResidencyOccupationTypes().then(setResidencyOccupation))
    }
    Promise.all(promises).finally(() => setLoading(false))

    // On unmount, clear prefill data from Stripe
    return () => {
      resetPrefillData(dispatch)
    }
  }, [])

  /** On organization list change, filter the sales organization and set the isCorporate flag */
  useEffect(() => {
    if (!organizations?.length) {
      return
    }
    const userOrg = userData.organizationId
    setSalesOrganizations(
      organizations.filter(
        (org) => org.type.id === ORGANIZATION_TYPE_IDS.DEALER
      )
    )
    const isCorporate =
      organizations.find((org) => org.guid === userOrg)?.type.id !==
      ORGANIZATION_TYPE_IDS.DEALER
    setIsCorporateOrg(isCorporate)

    // Also update Organization if we are in Create New mode
    if (!isEdit) {
      setOrganization(!isCorporate ? userOrg : null)
    }
  }, [organizations, isEdit])

  /** For New Loan app fetch the Signatures from the API (for existing ones they will be retrieved directly in the object) */
  useEffect(() => {
    // For ECG the Org has to be selected from a dropdown - for Sales it can default to the one assigned to them
    const org = hasOrgsAccess
      ? organization
      : organization || userData.organizationId

    if (org && !isEdit) {
      setLoanSignatures([])
      fetchLoanProductsForOrganization(org).then((res) => {
        const loanProducts = mapLoanProductsForClient(res)
        setAllLoanSignatures(loanProducts)
        loadDataFromLoanCalculator(loanProducts)
      })
    }
  }, [hasOrgsAccess, organization, isEdit, userData.organizationId])

  /** On Loan Form Data update (GET from server in edit mode) -> update details */
  useEffect(() => {
    if (!Object.keys(loanFormData).length || !loanFormData.loanApplicationId) {
      return
    }

    const borrower = loanFormData.borrowerGeneralDetails
    const coBorrower = loanFormData.coBorrowerGeneralDetails
    const signatures = loanFormData.selectedLoanSignatures
    setAllLoanSignatures(signatures)
    setLoanSignatures(signatures.filter((el) => el.isSelected))
    setBorrowerGeneralDetails(borrower)
    borrowerServerSSN.current = borrower.last4SSN
    borrowerEmployer.current = borrower.employer
    borrowerJobTitle.current = borrower.jobTitle
    setServiceAddress(loanFormData.serviceAddress)

    if (loanFormData?.salesOrganizationId) {
      setOrganization(loanFormData.salesOrganizationId)
    }

    if (coBorrower) {
      setCoBorrowerGeneralDetails(coBorrower)
      hasServerCoBorrower.current = true
    }
    if (borrower?.billingAddress) {
      setBorrowerAddress(borrower.billingAddress)
    }
    if (coBorrower?.billingAddress) {
      setCoBorrowerAddress(coBorrower.billingAddress)
    }
    if (borrower?.identificationData) {
      setBorrowerIdentification(borrower.identificationData)
    }
    if (coBorrower?.identificationData) {
      setCoBorrowerIdentification(coBorrower.identificationData)
    }
    if (loanFormData.solarData) {
      setSolarData(loanFormData.solarData)
    }
    if (loanFormData.incomeData) {
      setIncomeData(loanFormData.incomeData)
    }

    if (loanFormData.netAmount) {
      // organization has special fields
      setSpecialFieldsDetails({
        batteryOnly: loanFormData.batteryOnly,
        netAmount: loanFormData.netAmount,
        downPayment: loanFormData.downPayment,
      })
    }

    if (loanFormData.bypassAddressValidation) {
      setBypassValidateAddress(loanFormData.bypassAddressValidation)
    }
  }, [loanFormData])

  useEffect(() => {
    let orgId = null
    if (!loanFormData?.salesOrganizationId) {
      orgId = organization
    } else if (loanFormData?.salesOrganizationId) {
      orgId = loanFormData?.salesOrganizationId
    }

    if (!orgId) {
      return
    }

    checkOrganizationHasSpecialFields(orgId).then((res) => {
      setOrganizationHasSpecialFields(res)
    })
  }, [loanFormData, organization, organizations])

  /** On "prefilledFromQueryParams" change, update the borrower/co-borrower form data, prefilled from Query Params */
  useEffectOnUpdate(() => {
    setServiceAddress({ ...loanFormData.serviceAddress })
    setBorrowerGeneralDetails({ ...loanFormData.borrowerGeneralDetails })
    setCoBorrowerGeneralDetails({ ...loanFormData.coBorrowerGeneralDetails })
  }, [loanFormData.prefilledFromQueryParams])

  const isValidAddressAPI = async (address, addressType) => {
    let isAddressValid = true
    try {
      const errors = await validateAddress(address)

      if (errors?.length) {
        isAddressValid = false
        errors.forEach((e) => {
          showNotification(dispatch, {
            type: NOTIFICATION_TYPES.NEGATIVE,
            title: `${addressType} address validation failed for ${translate(
              ADDRESS_TYPES_TRANSLATIONS[e]
            )} `,
          })
        })
      }
    } catch {
      return false
    }

    return isAddressValid
  }

  const handleHideEmploymentFields = (value, isCoBorrower) => {
    if (isCoBorrower) {
      setHideEmploymentFieldsCoBorrower(value)
    } else {
      setHideEmploymentFields(value)
    }
  }

  const handleHideIncomeField = (value, isCoBorrower) => {
    if (isCoBorrower) {
      setHideIncomeFieldCoBorrower(value)
    } else {
      setHideIncomeField(value)
    }
  }

  /** Attempts to read locationState for Loan Signature & Loan Amount previously set via the Loan Calculator and prefill the data */
  const loadDataFromLoanCalculator = (locationState) => {
    const { loanAmount, signature } = locationState
    if (loanAmount) {
      setBorrowerGeneralDetails({
        ...borrowerGeneralDetails,
        loanAmount,
      })
    }
    const foundSignature = allLoanSignatures.find(
      (el) => el.value === signature
    )
    if (foundSignature) {
      setLoanSignatures([foundSignature])
    }
  }

  /** Validate & Submit Create Loan App Form */
  const onSubmit = async () => {
    setIsSubmitDisabled(true)
    // Validate Borrower
    let [isValid, borrowerErrors] = validate(BORROWER_LOAN_PAGE_ERROR_MAP, {
      ...borrowerGeneralDetails,
      ...(borrowerGeneralDetails.sameWithServiceAddress
        ? { ...serviceAddress }
        : { ...borrowerAddress }),
    })
    // Validate Borrower's SSN
    if (!validateSSN(borrowerGeneralDetails.last4SSN, !isEdit)) {
      borrowerErrors.last4SSN = isEdit
        ? translate('loanApplication.step1.errors.invalidSsn')
        : translate('loanApplication.step1.errors.invalidSsnLength')
    }
    let errors = { borrower: borrowerErrors }

    // Validate service address using Validation API
    if (
      isValid &&
      serviceAddress.state !== 'PR' &&
      serviceAddress.state !== 'VI' &&
      !bypassValidateAddress &&
      !(await isValidAddressAPI(
        serviceAddress,
        translate('loanApplication.step1.service')
      ))
    ) {
      isValid = false
    }

    // Validate Co-Borrower (if needed)
    if (borrowerGeneralDetails.hasCoBorrower) {
      const [isCoBorrowerValid, coBorrowerErrors] = validate(
        COBORROWER_LOAN_PAGE_ERROR_MAP,
        {
          ...coBorrowerGeneralDetails,
          ...(coBorrowerGeneralDetails.sameWithServiceAddress
            ? { ...serviceAddress }
            : { ...coBorrowerAddress }),
        }
      )
      // Validate CoBorrower's SSN
      if (!validateSSN(coBorrowerGeneralDetails.last4SSN, !isEdit)) {
        coBorrowerErrors.last4SSN = isEdit
          ? translate('loanApplication.step1.errors.invalidSsn')
          : translate('loanApplication.step1.errors.invalidSsnLength')
      }
      if (!isCoBorrowerValid) {
        errors.coBorrower = coBorrowerErrors
        isValid = false
      }

      if (
        !errors.borrowerErrors?.last4SSN &&
        !errors.coBorrower?.last4SSN &&
        coBorrowerGeneralDetails.last4SSN === borrowerGeneralDetails.last4SSN
      ) {
        errors = {
          ...errors,
          borrower: {
            ...errors.borrower,
            last4SSN: translate('loanApplication.step1.errors.sameSsn'),
          },
          coBorrower: {
            ...errors.coBorrower,
            last4SSN: translate('loanApplication.step1.errors.sameSsn'),
          },
        }
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: translate('loanApplication.step1.errors.sameSsn'),
        })
        isValid = false
      }
    }

    // Validate Address
    const [isServiceAddressValid, serviceAddressErrors] = validate(
      SERVICE_ADDRESS_ERROR_MAP,
      { ...serviceAddress }
    )
    if (!isServiceAddressValid) {
      errors.serviceAddress = serviceAddressErrors
      isValid = false
    }

    // Validate Organization
    if (!organization && hasOrgsAccess) {
      isValid = false
      errors.organization = ERRORS.REQUIRED
    }

    // Validate State selection
    if (
      isServiceAddressValid &&
      !stateList.some((state) => state.abbreviation === serviceAddress.state)
    ) {
      isValid = false
      errors.serviceAddress = {
        state: translate('loanApplication.step1.errors.invalidState'),
      }
    }

    // Validate yearly income
    if (!hideIncomeField) {
      const borrowerIncome = parseFloat(borrowerGeneralDetails.yearlyIncome)
      const coBorrowerIncome = borrowerGeneralDetails.hasCoBorrower
        ? parseFloat(coBorrowerGeneralDetails.yearlyIncome)
        : 0
      if (borrowerIncome + coBorrowerIncome === 0) {
        isValid = false
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: translate(
            'loanApplication.step1.errors.atLeastOneYearlyIncome'
          ),
        })
      }
    }

    // Validate special fields
    if (organizationHasSpecialFields) {
      const [areSpecialFieldsValid, specialFieldsErrors] = validate(
        SPECIAL_FIELDS_ERROR_MAP,
        {
          ...specialFieldsDetails,
        }
      )

      if (!areSpecialFieldsValid) {
        errors.specialFields = specialFieldsErrors
        isValid = false
      }
    }

    // If not valid, return
    if (!isValid) {
      setAllErrors({ ...allErrors, ...errors })
      scrollToClosestError(createLoanAppForm.current)
      setIsSubmitDisabled(false)
      return
    }

    // If some critical fields changed, show a warning and return
    if (isEdit && !warningsAccepted.current) {
      const warnings = {}
      if (borrowerServerSSN.current !== borrowerGeneralDetails.last4SSN) {
        warnings.credit = true
      }
      if (
        borrowerEmployer.current !== borrowerGeneralDetails.employer ||
        borrowerJobTitle.current !== borrowerGeneralDetails.jobTitle
      ) {
        warnings.membership = true
      }
      if (Object.keys(warnings).length > 0) {
        return setWarnings(warnings)
      }
    }

    // If New Loan App show the T&C; if Edit Loan App only show T&C if a co-borrower was added
    submitLoanApplication()
  }

  /** Triggered when the user approves of the T&C and saves the form on the BE */
  const submitLoanApplication = (disclosureStatuses) => {
    const allLoanSignaturesSelected = [...allLoanSignatures].map((s) => ({
      ...s,
      isSelected: true,
    }))

    let submitPayload = {
      loanApplicationId: loanApplicationId,
      salesOrganizationId: organization,
      acknowledgementAndAuthorizationDisclosureAccepted: true,
      termsAndConditionsDisclosureAccepted: true,
      electronicRecordAndSignatureDisclosureAccepted: true,
      bypassAddressValidation: bypassValidateAddress,
      ...formDataToServerFormat({
        borrowerGeneralDetails: {
          ...borrowerGeneralDetails,
          loanApplicationId: loanApplicationId,
          idVerificationSessionId: borrowerVerificationSessionId,
        },
        addressDetails: borrowerAddress,
        coBorrowerGeneralDetails: {
          ...coBorrowerGeneralDetails,
          loanApplicationId: loanApplicationId,
          idVerificationSessionId: coBorrowerVerificationSessionId,
        },
        coBorrowerAddressDetails: coBorrowerAddress,
        allLoanSignatures,
        selectedLoanSignatures: loanSignatures.length
          ? loanSignatures
          : allLoanSignaturesSelected,
        serviceAddress,
        isEdit,
        solarData,
        incomeData,
        borrowerIdentification: {
          ...borrowerIdentification,
          idTypeId:
            globalState.borrowerPrefillData?.idType?.id ||
            borrowerIdentification?.idType?.id,
          idIssueStateId:
            globalState.borrowerPrefillData?.idIssueState?.id ||
            borrowerIdentification?.idIssueState?.id,
          issuanceDate:
            globalState.borrowerPrefillData?.issuanceDate ||
            borrowerIdentification?.issuanceDate,
          expirationDate:
            globalState.borrowerPrefillData?.expirationDate ||
            borrowerIdentification?.expirationDate,
          number:
            globalState.borrowerPrefillData?.idNumber ||
            borrowerIdentification?.number,
          dateOfBirth:
            globalState.borrowerPrefillData?.dateOfBirth ||
            borrowerIdentification?.dateOfBirth,
        },
        coBorrowerIdentification: {
          ...coBorrowerIdentification,
          idTypeId:
            globalState.coBorrowerPrefillData?.idType?.id ||
            coBorrowerIdentification?.idType?.id,
          idIssueStateId:
            globalState.coBorrowerPrefillData?.idIssueState?.id ||
            coBorrowerIdentification?.idIssueState?.id,
          issuanceDate:
            globalState.coBorrowerPrefillData?.issuanceDate ||
            coBorrowerIdentification?.issuanceDate,
          expirationDate:
            globalState.coBorrowerPrefillData?.expirationDate ||
            coBorrowerIdentification?.expirationDate,
          number:
            globalState.coBorrowerPrefillData?.idNumber ||
            coBorrowerIdentification?.number,
          dateOfBirth:
            globalState.coBorrowerPrefillData?.dateOfBirth ||
            coBorrowerIdentification?.dateOfBirth,
        },
        disclosureStatuses,
      }),
    }
    if (organizationHasSpecialFields) {
      submitPayload = {
        ...submitPayload,
        netAmount: specialFieldsDetails.netAmount,
        downPayment: specialFieldsDetails.downPayment,
        batteryOnly: specialFieldsDetails.batteryOnly,
      }
    }

    setGlobalLoading(dispatch, true)
    submitLoanApp(submitPayload, dispatch, navigate).finally(() => {
      setGlobalLoading(dispatch, false)
      setIsSubmitDisabled(false)
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        title: translate('global.successfullySaved'),
      })
    })
  }

  /** Triggered when an address is selected from the ReactPlaces component */
  const onAddressSelected = (addressObj) => {
    if (addressObj) {
      if (serviceAddress.addressId) {
        addressObj.addressId = serviceAddress.addressId
      }

      setServiceAddress(addressObj)
    }
    const newErrors = {
      ...allErrors,
      serviceAddress: { ...GENERAL_ADDRESS_ERRORS },
      borrower: { ...allErrors.borrower, ...GENERAL_ADDRESS_ERRORS },
      coBorrower: { ...allErrors.coBorrower, ...GENERAL_ADDRESS_ERRORS },
    }
    setAllErrors(newErrors)
  }

  const hasServiceAddrErrors = useMemo(
    () => Object.values(allErrors.serviceAddress || {}).some((el) => !!el),
    [allErrors?.serviceAddress]
  )

  /** When has co-borrower checkbox is checked, update the state and scroll the Co-Borrower into view */
  const onCoBorrowerCheck = () => {
    const hasCoBorrower = !borrowerGeneralDetails.hasCoBorrower
    setBorrowerGeneralDetails({ ...borrowerGeneralDetails, hasCoBorrower })

    if (hasCoBorrower) {
      setTimeout(() => {
        coBorrowerRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 50)
    }
  }

  if (loading) {
    return (
      <div className="lender-agreement">
        <Loader size={130} center={true} />
      </div>
    )
  }

  // Delete co-borrower information locally
  const handleRemoveCoborrower = () => {
    setBorrowerGeneralDetails({
      ...borrowerGeneralDetails,
      hasCoBorrower: false,
    })
    setCoBorrowerAddress({ ...ADDRESS_DETAILS })
    setCoBorrowerGeneralDetails({ ...USER_GENERAL_DETAILS })
    setIsDeleteCoborrowerModalOpen(false)
    setCoBorrowerIdentification({ ...IDENTIFICATION_INFO })
  }

  const handleRemoveAdditionalIncome = (borrower) => {
    if (borrower) {
      delete borrowerGeneralDetails.additionalIncomeTypeId
      delete borrowerGeneralDetails.additionalIncomeAmount
      setIsDeleteAdditionalIncomeOpenBorrower(false)
      setBorrowerGeneralDetails({
        ...borrowerGeneralDetails,
        hasAdditionalIncome: false,
      })
    } else {
      setIsDeleteAdditionalIncomeOpenCoBorrower(false)
      delete coBorrowerGeneralDetails.additionalIncomeTypeId
      delete borrowerGeneralDetails.additionalIncomeAmount
      setCoBorrowerGeneralDetails({
        ...coBorrowerGeneralDetails,
        hasAdditionalIncome: false,
      })
    }
  }

  const handleSetOrg = async (orgId) => {
    setOrganization(orgId)

    if (orgId && !isECG) {
      const language = await getLanguageByOrgId(orgId)
      // Load custom translations for the App (if found)
      loadTranslationsForLoanApp({
        language: language.abbreviation,
        orgId: orgId,
      })
    }
  }

  const onCopyUWToClipboardClick = () => {
    setCopyToClipboardLoading(true)
    getCreditReport(
      loanApplicationId,
      loanFormData?.selectedLoanProduct?.lenderId ?? ''
    )
      .then((creditInfo) => {
        copyUWToClipboard(
          dispatch,
          translate,
          borrowerGeneralDetails,
          coBorrowerGeneralDetails,
          loanFormData,
          serviceAddress,
          isHardCreditPulled,
          creditInfo
        )
      })
      .finally(() => setCopyToClipboardLoading(false))
  }

  const toggleBypassAddressValidation = () => {
    setBypassValidateAddress(!bypassValidateAddress)
  }

  const handleOnClickScan = (isBorrower) => () => {
    initializeStripeIDVerification(
      dispatch,
      isBorrower
        ? borrowerGeneralDetails?.applicantId
        : coBorrowerGeneralDetails?.applicantId,
      isBorrower ? 0 : 1
    ).then((sessionId) => {
      if (isBorrower) {
        setBorrowerVerificationSessionId(sessionId)
      } else {
        setCoBorrowerVerificationSessionId(sessionId)
      }
    })
  }

  return (
    <div className="applicant-info">
      {!isEdit && <NewLoanHeader />}

      <div className="applicant-info__form-general" ref={createLoanAppForm}>
        <Grid flexDirection="column" container gap={24}>
          <ThemeProvider>
            {/* Borrower Info */}
            <Paper className="paper">
              <div className={cx('applicant-info__form-title', 'scan-icon')}>
                {translate('loanApplication.common.borrowerDetails')}

                <div>
                  {isEdit && (
                    <Button
                      onClick={onCopyUWToClipboardClick}
                      size={BUTTON_SIZES.SMALL}
                      sx={{ mr: '8px' }}
                      loading={copyToClipboardLoading}
                      startIcon={<WhiteClipperIcon />}
                    >
                      {translations.copyToClipboard}
                    </Button>
                  )}
                  <Button
                    variant={BUTTON_VARIANTS.OUTLINED}
                    color={BUTTON_COLORS.INHERIT}
                    size={BUTTON_SIZES.SMALL}
                    startIcon={<ScanIcon />}
                    onClick={handleOnClickScan(true)}
                    data-tip
                    data-for="scan-borrower"
                  >
                    {translate('global.scanID')}
                  </Button>
                </div>
              </div>
              <GeneralDetails
                areInputsDisabled={areInputsDisabled}
                generalDetails={borrowerGeneralDetails}
                setGeneralDetails={setBorrowerGeneralDetails}
                errors={allErrors}
                setErrors={setAllErrors}
                coBorrower={false}
                communicationMethods={communicationMethods}
                employmentStatuses={employmentStatuses}
                citizenships={citizenships}
                militaryAffiliation={militaryAffiliation}
                userData={userData}
                residencyOccupation={residencyOccupation}
                onChangeHideEmploymentFields={handleHideEmploymentFields}
                hideEmploymentFields={hideEmploymentFields}
                onChangeHideIncomeField={handleHideIncomeField}
                hideIncomeField={hideIncomeField}
              />

              <div style={{ marginTop: '24px' }}>
                <Button
                  startIcon={
                    borrowerGeneralDetails.hasAdditionalIncome ? (
                      <TrashIcon />
                    ) : (
                      <PlusIcon />
                    )
                  }
                  variant={
                    isTabletView
                      ? BUTTON_VARIANTS.CONTAINED
                      : BUTTON_VARIANTS.TEXT
                  }
                  color={
                    isTabletView
                      ? BUTTON_COLORS.SECONDARY
                      : BUTTON_COLORS.PRIMARY
                  }
                  sx={{
                    marginRight: { xs: 0, sm: '12px', lg: 0 },
                    width: {
                      xs: '100%',
                      sm: 'auto',
                    },
                  }}
                  disabled={areInputsDisabled}
                  onClick={() =>
                    setBorrowerGeneralDetails((prevInputData) => {
                      return {
                        ...prevInputData,
                        hasAdditionalIncome:
                          !borrowerGeneralDetails.hasAdditionalIncome,
                      }
                    })
                  }
                >
                  {!borrowerGeneralDetails.hasAdditionalIncome
                    ? translate('loanApplication.step1.addAdditionalIncome')
                    : translate('loanApplication.step1.removeAdditionalIncome')}
                </Button>

                <Button
                  startIcon={
                    borrowerGeneralDetails.hasCoBorrower ? (
                      <TrashIcon />
                    ) : (
                      <PlusIcon />
                    )
                  }
                  variant={
                    isTabletView
                      ? BUTTON_VARIANTS.CONTAINED
                      : BUTTON_VARIANTS.TEXT
                  }
                  color={
                    isTabletView
                      ? BUTTON_COLORS.SECONDARY
                      : BUTTON_COLORS.PRIMARY
                  }
                  sx={{
                    marginRight: { xs: 0, sm: '12px', lg: 0 },
                    marginTop: { xs: '16px', sm: 0 },
                    width: {
                      xs: '100%',
                      sm: 'auto',
                    },
                  }}
                  disabled={areInputsDisabled}
                  onClick={onCoBorrowerCheck}
                >
                  {borrowerGeneralDetails.hasCoBorrower
                    ? translate('loanApplication.step1.removeCoBorrower')
                    : translations.addCoBorrower}
                </Button>

                <Button
                  startIcon={
                    !borrowerGeneralDetails.sameWithServiceAddress ? (
                      <TrashIcon />
                    ) : (
                      <PlusIcon />
                    )
                  }
                  variant={
                    isTabletView
                      ? BUTTON_VARIANTS.CONTAINED
                      : BUTTON_VARIANTS.TEXT
                  }
                  color={
                    isTabletView
                      ? BUTTON_COLORS.SECONDARY
                      : BUTTON_COLORS.PRIMARY
                  }
                  sx={{
                    marginRight: { xs: 0, sm: '12px', lg: 0 },
                    marginTop: { xs: '16px', sm: 0 },
                    width: {
                      xs: '100%',
                      sm: 'auto',
                    },
                  }}
                  disabled={areInputsDisabled}
                  onClick={() => {
                    const isChecked =
                      borrowerGeneralDetails.sameWithServiceAddress

                    if (isChecked) {
                      // if checked reset borrower address, so addressId will also be null
                      setBorrowerAddress({
                        ...ADDRESS_DETAILS,
                      })
                    } else {
                      // if unchecked set it back to service address
                      setBorrowerAddress({
                        ...serviceAddress,
                      })
                    }
                    setBorrowerGeneralDetails((prevInputData) => {
                      return {
                        ...prevInputData,
                        sameWithServiceAddress: !isChecked,
                      }
                    })
                  }}
                >
                  {!borrowerGeneralDetails.sameWithServiceAddress
                    ? translate('loanApplication.step1.removeBillingAddress')
                    : translate('loanApplication.step1.addBillingAddress')}
                </Button>
              </div>
            </Paper>

            {isEdit && (
              <Paper className="paper">
                <div className="applicant-info--odd">
                  <IdentificationInfo
                    canEditFields={
                      canEditUWFields || !areFieldsDisabledAfterIfRequestedState
                    }
                    applicantTypeId={APPLICANT_TYPE.BORROWER}
                    setIdentificationData={setBorrowerIdentification}
                    identificationData={borrowerIdentification}
                    errors={allErrors.borrowerIdentification}
                    idTypes={idTypes}
                    stateList={stateList}
                    setErrors={(borrowerIdentification) =>
                      setAllErrors({ ...allErrors, borrowerIdentification })
                    }
                  />
                </div>
              </Paper>
            )}

            {/* Solar Data */}
            {canViewAdditionalFields && (
              <Paper className="paper">
                <div className="applicant-info--even">
                  <SolarDetails
                    canEditFields={
                      canEditUWFields || !areFieldsDisabledAfterIfRequestedState
                    }
                    solarDetails={solarData}
                    setSolarDetails={setSolarData}
                  />
                </div>
              </Paper>
            )}

            {/* Borrower - Additional income */}
            {borrowerGeneralDetails.hasAdditionalIncome && (
              <AdditionalIncomeDetails
                generalDetails={borrowerGeneralDetails}
                setGeneralDetails={setBorrowerGeneralDetails}
                errors={allErrors}
                setErrors={setAllErrors}
                coBorrower={false}
                additionalIncomeTypes={additionalIncomeTypes}
                isEdit={isEdit}
              />
            )}
            {/* Identification + Income */}
            {canViewAdditionalFields && (
              <>
                <Paper className="paper">
                  <div className="applicant-info--even">
                    <IncomeDetails
                      hasCoBorrower={borrowerGeneralDetails.hasCoBorrower}
                      setIncomeDetails={setIncomeData}
                      incomeDetails={incomeData}
                      areInputsDisabled={areInputsDisabled}
                      canUpdateActualDTIFIelds={canUpdateActualDTIFIelds}
                      areFieldsDisabledAfterIfRequestedState={
                        areFieldsDisabledAfterIfRequestedState
                      }
                      canEditUWFields={
                        canEditUWFields ||
                        !areFieldsDisabledAfterIfRequestedState
                      }
                    />
                  </div>
                </Paper>
              </>
            )}
            {/* Co-Borrower */}
            {borrowerGeneralDetails.hasCoBorrower && (
              <>
                <Paper className="paper">
                  <div className="applicant-info--even">
                    <div className="applicant-info__header">
                      <div
                        className={cx(
                          'applicant-info__form-title',
                          'scan-icon'
                        )}
                        ref={coBorrowerRef}
                      >
                        {translate('loanApplication.common.coborrowerDetails')}

                        <Button
                          variant={BUTTON_VARIANTS.OUTLINED}
                          color={BUTTON_COLORS.INHERIT}
                          startIcon={<ScanIcon />}
                          size={BUTTON_SIZES.SMALL}
                          onClick={handleOnClickScan(false)}
                        >
                          {translate('global.scanID')}
                        </Button>
                      </div>
                      {isEdit && (
                        <TrashIcon
                          onClick={() => setIsDeleteCoborrowerModalOpen(true)}
                          className="applicant-info__trash"
                        />
                      )}
                    </div>
                    <GeneralDetails
                      generalDetails={coBorrowerGeneralDetails}
                      setGeneralDetails={setCoBorrowerGeneralDetails}
                      errors={allErrors}
                      setErrors={setAllErrors}
                      coBorrower={true}
                      communicationMethods={communicationMethods}
                      employmentStatuses={employmentStatuses}
                      citizenships={citizenships}
                      militaryAffiliation={militaryAffiliation}
                      userData={userData}
                      residencyOccupation={residencyOccupation}
                      areInputsDisabled={areInputsDisabled}
                      onChangeHideEmploymentFields={handleHideEmploymentFields}
                      hideEmploymentFields={hideEmploymentFieldsCoBorrower}
                      onChangeHideIncomeField={handleHideIncomeField}
                      hideIncomeField={hideIncomeFieldCoBorrower}
                    />

                    <div style={{ marginTop: '24px' }}>
                      <Button
                        startIcon={
                          coBorrowerGeneralDetails.hasAdditionalIncome ? (
                            <TrashIcon />
                          ) : (
                            <PlusIcon />
                          )
                        }
                        variant={
                          isTabletView
                            ? BUTTON_VARIANTS.CONTAINED
                            : BUTTON_VARIANTS.TEXT
                        }
                        color={
                          isTabletView
                            ? BUTTON_COLORS.SECONDARY
                            : BUTTON_COLORS.PRIMARY
                        }
                        sx={{
                          marginRight: { xs: 0, sm: '12px', lg: 0 },
                          width: {
                            xs: '100%',
                            sm: 'auto',
                          },
                        }}
                        disabled={areInputsDisabled}
                        onClick={() =>
                          setCoBorrowerGeneralDetails((prevInputData) => {
                            return {
                              ...prevInputData,
                              hasAdditionalIncome:
                                !coBorrowerGeneralDetails.hasAdditionalIncome,
                            }
                          })
                        }
                      >
                        {!coBorrowerGeneralDetails.hasAdditionalIncome
                          ? translate(
                              'loanApplication.step1.addAdditionalIncome'
                            )
                          : translate(
                              'loanApplication.step1.removeAdditionalIncome'
                            )}
                      </Button>

                      <Button
                        startIcon={
                          !coBorrowerGeneralDetails.sameWithServiceAddress ? (
                            <TrashIcon />
                          ) : (
                            <PlusIcon />
                          )
                        }
                        variant={
                          isTabletView
                            ? BUTTON_VARIANTS.CONTAINED
                            : BUTTON_VARIANTS.TEXT
                        }
                        color={
                          isTabletView
                            ? BUTTON_COLORS.SECONDARY
                            : BUTTON_COLORS.PRIMARY
                        }
                        sx={{
                          marginRight: { xs: 0, sm: '12px', lg: 0 },
                          marginTop: { xs: '16px', sm: 0 },
                          width: {
                            xs: '100%',
                            sm: 'auto',
                          },
                        }}
                        disabled={areInputsDisabled}
                        onClick={() => {
                          const isChecked =
                            coBorrowerGeneralDetails.sameWithServiceAddress

                          if (isChecked) {
                            // if checked reset co-borrower address, so addressId will also be null
                            setCoBorrowerAddress({
                              ...ADDRESS_DETAILS,
                            })
                          } else {
                            // if unchecked set it back to service address
                            setCoBorrowerAddress({
                              ...serviceAddress,
                            })
                          }

                          setCoBorrowerGeneralDetails((prevInputData) => {
                            return {
                              ...prevInputData,
                              sameWithServiceAddress: !isChecked,
                            }
                          })
                        }}
                      >
                        {!coBorrowerGeneralDetails.sameWithServiceAddress
                          ? translate(
                              'loanApplication.step1.removeBillingAddress'
                            )
                          : translate(
                              'loanApplication.step1.addBillingAddress'
                            )}
                      </Button>
                    </div>
                  </div>
                </Paper>

                {canViewAdditionalFields && isEdit && (
                  <Paper className="paper">
                    <div className="applicant-info--odd">
                      <IdentificationInfo
                        canEditFields={
                          canEditUWFields ||
                          !areFieldsDisabledAfterIfRequestedState
                        }
                        applicantTypeId={APPLICANT_TYPE.COBORROWER}
                        identificationData={coBorrowerIdentification}
                        setIdentificationData={setCoBorrowerIdentification}
                        idTypes={idTypes}
                        stateList={stateList}
                        errors={allErrors.coBorrowerIdentification}
                        setErrors={(coBorrowerIdentification) =>
                          setAllErrors({
                            ...allErrors,
                            coBorrowerIdentification,
                          })
                        }
                      />
                    </div>
                  </Paper>
                )}

                {!coBorrowerGeneralDetails.sameWithServiceAddress ? (
                  <AddressDetails
                    addressDetails={coBorrowerAddress}
                    setAddressDetails={setCoBorrowerAddress}
                    errors={allErrors}
                    setErrors={setAllErrors}
                    coBorrower={true}
                    hasSameAddress={
                      coBorrowerGeneralDetails.sameWithServiceAddress
                    }
                  />
                ) : null}

                {coBorrowerGeneralDetails.hasAdditionalIncome && (
                  <AdditionalIncomeDetails
                    generalDetails={coBorrowerGeneralDetails}
                    setGeneralDetails={setCoBorrowerGeneralDetails}
                    errors={allErrors}
                    setErrors={setAllErrors}
                    coBorrower={true}
                    additionalIncomeTypes={additionalIncomeTypes}
                  />
                )}
              </>
            )}

            <LoanAppTopSection
              areInputsDisabled={areInputsDisabled}
              isEdit={isEdit}
              organizations={salesOrganizations}
              isCorporateOrg={isCorporateOrg}
              setServiceAddress={setServiceAddress}
              hasServiceAddrErrors={hasServiceAddrErrors}
              setAllErrors={setAllErrors}
              borrowerGeneralDetails={borrowerGeneralDetails}
              setBorrowerGeneralDetails={setBorrowerGeneralDetails}
              onAddressSelected={onAddressSelected}
              allErrors={allErrors}
              serviceAddress={serviceAddress}
              coBorrowerGeneralDetails={coBorrowerGeneralDetails}
              organization={organization}
              setOrganization={handleSetOrg}
              organizationHasSpecialFields={organizationHasSpecialFields}
              specialFieldsDetails={specialFieldsDetails}
              setSpecialFieldsDetails={setSpecialFieldsDetails}
              toggleBypassAddressValidation={toggleBypassAddressValidation}
              bypassValidateAddress={bypassValidateAddress}
              allLoanSignatures={allLoanSignatures}
              loanSignatures={loanSignatures}
              setLoanSignatures={setLoanSignatures}
            />

            {!borrowerGeneralDetails.sameWithServiceAddress ? (
              <AddressDetails
                addressDetails={borrowerAddress}
                setAddressDetails={setBorrowerAddress}
                errors={allErrors}
                setErrors={setAllErrors}
                coBorrower={false}
                hasSameAddress={borrowerGeneralDetails.sameWithServiceAddress}
                areInputsDisabled={areInputsDisabled}
              />
            ) : null}

            {areTermsRequired && (
              <TermsAndConditions
                onConfirmTerms={(value) => setAreTermsConfirmed(value)}
                termsAgreedChecked={areTermsConfirmed}
                hasCoBorrower={borrowerGeneralDetails.hasCoBorrower}
              />
            )}
            {canCreateEdit && (
              <div className={'loan-form__custom-button'}>
                <Button
                  onClick={onSubmit}
                  disabled={
                    (!areTermsConfirmed && areTermsRequired) || isSubmitDisabled
                  }
                >
                  {translations.saveLoanApp}
                </Button>
              </div>
            )}
          </ThemeProvider>
        </Grid>
      </div>

      <Modal
        isOpen={
          isDeleteAdditionalIncomeOpenCoBorrower ||
          isDeleteAdditionalIncomeOpenBorrower
        }
        onClose={() => {
          isDeleteAdditionalIncomeOpenCoBorrower
            ? setIsDeleteAdditionalIncomeOpenCoBorrower(false)
            : setIsDeleteAdditionalIncomeOpenBorrower(false)
        }}
        noWidth
        noMargin
        containerClass="user-modal__edit-user-modal"
      >
        <div className="delete-modal__title">
          {translations.deleteIncomeFor}{' '}
          {isDeleteAdditionalIncomeOpenCoBorrower
            ? translations.coBorrower
            : translations.borrower}
        </div>
        <div className="delete-modal__question">
          {translations.deleteIncomeAreYouSure}{' '}
          {isDeleteAdditionalIncomeOpenCoBorrower
            ? translations.coBorrower
            : translations.borrower}
          ?
        </div>
        <div className="delete-modal__all-actions">
          <Button
            className="delete-modal__action delete-modal__delete flex-center"
            onClick={() =>
              isDeleteAdditionalIncomeOpenCoBorrower
                ? handleRemoveAdditionalIncome(false)
                : handleRemoveAdditionalIncome(true)
            }
          >
            {translations.confirm}
          </Button>
          <Button
            className="delete-modal__action delete-modal__cancel flex-center"
            onClick={() =>
              isDeleteAdditionalIncomeOpenCoBorrower
                ? setIsDeleteAdditionalIncomeOpenCoBorrower(false)
                : setIsDeleteAdditionalIncomeOpenBorrower(false)
            }
          >
            {translations.cancel}
          </Button>
        </div>
      </Modal>

      <WarningModal
        onConfirm={() => {
          warningsAccepted.current = true
          onSubmit()
        }}
        warnings={warnings}
        setWarnings={setWarnings}
      />
      <Modal
        isOpen={isDeleteCoborrowerModalOpen}
        onClose={() => {
          setIsDeleteCoborrowerModalOpen(false)
        }}
        noWidth
        noMargin
        containerClass="user-modal__edit-user-modal"
      >
        <div className="delete-modal__title">
          {translations.delete} {translations.coBorrower}
        </div>
        <div className="delete-modal__question">
          {translations.deleteCoBorrower}
        </div>
        <div className="delete-modal__all-actions">
          <Button
            className="delete-modal__action delete-modal__delete flex-center"
            onClick={() => handleRemoveCoborrower(false)}
          >
            {translations.confirm}
          </Button>
          <Button
            className="delete-modal__action delete-modal__cancel flex-center"
            onClick={() => setIsDeleteCoborrowerModalOpen(false)}
          >
            {translations.cancel}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

CreateLoanApp.propTypes = {
  loanApplicationId: PropTypes.string,
  loanFormData: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
  isHardCreditPulled: PropTypes.bool.isRequired,
  userData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentState: PropTypes.string.isRequired,
  globalState: PropTypes.object.isRequired,
}

export default CreateLoanApp
