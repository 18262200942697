import { useState } from 'react'
import { PropTypes } from 'prop-types'
import { convertToRaw, EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'

import Button from 'components/button'
import Modal from 'components/modal'
import RichTextEditor from 'components/rich-text-editor'

import styles from './styles.module.scss'

export const EditModal = ({
  translationKey,
  keyPath,
  isOpen,
  onSubmit,
  onClose,
  content,
}) => {
  const [textEditorData, setTextEditorData] = useState(
    content
      ? EditorState.createWithContent(stateFromHTML(content))
      : EditorState.createEmpty()
  )

  /**
   * Helper function to dynamically decide if content is plaintext or html
   */
  const isContentPlainText = (content) => {
    const blocks = convertToRaw(content).blocks

    if (blocks?.length !== 1) {
      return false
    }

    if (
      blocks[0].type === 'unstyled' &&
      blocks[0].entityRanges.length === 0 &&
      blocks[0].inlineStyleRanges.length === 0
    ) {
      return true
    }

    return false
  }

  const handleOnSubmit = () => {
    const content = textEditorData.getCurrentContent()
    const data = isContentPlainText(content)
      ? content.getPlainText()
      : stateToHTML(content)

    onSubmit(keyPath, translationKey, data)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Edit translation for ${translationKey}`}
    >
      <div>
        <RichTextEditor value={textEditorData} onChange={setTextEditorData} />

        <Button
          onClick={handleOnSubmit}
          className={styles.button}
          data-testid="submit-button"
        >
          Submit
        </Button>
      </div>
    </Modal>
  )
}

EditModal.propTypes = {
  translationKey: PropTypes.string.isRequired,
  keyPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.any,
}
