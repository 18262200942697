import React from 'react'
import AdminWrapper from 'modules/admin'
import OrganizationsModule from 'modules/organizations'
import { AccessWrapper } from 'common/access'
import { TABS as ADMIN_TABS } from 'modules/admin/constants'
import OrganizationBreadcrumbs from 'modules/organizations/organization-details/breadcrumbs'

const AdminOrganizations = () => {
  return (
    <AccessWrapper claims={ADMIN_TABS.ORGANIZATIONS.access}>
      <AdminWrapper>
        <OrganizationBreadcrumbs />
        <OrganizationsModule />
      </AdminWrapper>
    </AccessWrapper>
  )
}

export default AdminOrganizations
