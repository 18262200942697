import React from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
const { Void, Card } = components

const EmptyList = ({
  title,
  description,
  actionButtonLabel,
  actionButtonOnClick,
  cardStyling,
  ...rest
}) => {
  return (
    <Card sx={{ padding: { xs: 0, sm: '60px' }, ...cardStyling }}>
      <Void
        title={title}
        description={description}
        actionButtonLabel={actionButtonLabel}
        actionButtonOnClick={actionButtonOnClick}
        sx={{ margin: '0 auto' }}
        {...rest}
      />
    </Card>
  )
}

EmptyList.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  actionButtonLabel: PropTypes.string,
  actionButtonOnClick: PropTypes.func,
  cardStyling: PropTypes.object,
}

export default EmptyList
