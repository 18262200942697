import React, { useState, useMemo } from 'react'
import { useStore } from 'store'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { assets } from '@ElementsCapitalGroup/enium-ui'

import { useMediaQuery } from 'common/hooks'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import Loader, { LOADER_SIZE } from 'components/loader'
import Button, { BUTTON_VARIANTS } from 'components/button'
import { ExpandableCard } from 'components/expandable-card'
import NTPDocuments from 'modules/loan-application/ntp/documents'
import NTPAddStipulations from 'modules/loan-application/ntp/stipulations-add-new'
import {
  areNtpSubmitted,
  areFundsRequested,
  areNtpsReadyForSubmission,
} from 'modules/loan-application/ntp/helpers'
import {
  addStipulationsNTP,
  submitNTPForApproval,
} from 'modules/loan-application/actions'
import {
  UW_HUB_ACTIONS,
  getLoanApplicationDetails,
  getStipulations,
  updateUserNtpStatus,
} from 'modules/uw-hub/actions'
import { expandableCard } from '../common/styles'

import './style.scss'

const { PlusIcon, FileCheck01Icon } = assets

const StipulationSection = ({
  stipulations,
  loanApplicationId,
  loanApplicationStateId,
  serviceAddress,
  estimatedCombinedIncomeToBeProven,
  isInitialNtp,
}) => {
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const { t: translate } = useTranslation()
  const { state, dispatch } = useStore()
  const { userData } = state.session
  const [loading, setLoading] = useState(false)
  const [addStipulationOpen, setAddStipulationOpen] = useState(false)

  const canSubmitForApproval = useMemo(() => {
    const uploadedAttachmentsForEachNtp = stipulations?.every(
      (a) => a.userNTPs.length > 0
    )

    return (
      uploadedAttachmentsForEachNtp &&
      areNtpsReadyForSubmission(loanApplicationStateId, isInitialNtp)
    )
  }, [loanApplicationStateId, isInitialNtp, JSON.stringify(stipulations)])

  const ntpSubmitted = useMemo(() => {
    return areNtpSubmitted(loanApplicationStateId, isInitialNtp)
  })

  const fundsRequested = useMemo(() => {
    return areFundsRequested(loanApplicationStateId, isInitialNtp)
  }, [isInitialNtp, loanApplicationStateId])

  const getNTPs = () => {
    setLoading(true)
    getStipulations(dispatch, loanApplicationId, !isInitialNtp).finally(() => {
      setLoading(false)
    })
  }

  const collapsibleItems = [
    <div key={0} className="uw-hub-page__stipulations-documents">
      {loading && <Loader size={LOADER_SIZE.MEDIUM} center={true} />}
      {!loading && (
        <div className="uw-hub-page__stipulations-actions">
          <AccessWrapper claims={CLAIMS.CAN_SUBMIT_NTPS}>
            <Button
              variant={BUTTON_VARIANTS.TEXT}
              startIcon={<FileCheck01Icon />}
              disabled={loading || !canSubmitForApproval || ntpSubmitted}
              onClick={() => {
                setLoading(true)
                submitNTPForApproval(dispatch, isInitialNtp, loanApplicationId)
                  .then((result) => {
                    if (result) {
                      dispatch({
                        type: UW_HUB_ACTIONS.SET_LOAN_APPLICATION_STATE,
                        payload: result.loanApplicationState.id,
                      })
                    }
                  })
                  .finally(() => setLoading(false))
              }}
            >
              {!ntpSubmitted
                ? translate('loanApplication.ntpStepsCommon.submitForApproval')
                : translate('loanApplication.ntpStepsCommon.alreadySubmitted')}
            </Button>
          </AccessWrapper>
          <AccessWrapper claims={CLAIMS.CAN_ADD_STIPULATIONS}>
            <Button
              variant={BUTTON_VARIANTS.TEXT}
              startIcon={<PlusIcon />}
              disabled={fundsRequested}
              onClick={() => !fundsRequested && setAddStipulationOpen(true)}
            >
              {translate('loanApplication.ntpStepsCommon.addStipulation')}
            </Button>
          </AccessWrapper>
        </div>
      )}
      <NTPDocuments
        ntps={stipulations}
        loanApplicationId={loanApplicationId}
        loading={loading}
        setLoading={setLoading}
        getNtps={getNTPs}
        serviceAddress={serviceAddress}
        userData={userData}
        estimatedCombinedIncomeToBeProven={estimatedCombinedIncomeToBeProven}
        updateUserNtpStatus={(result) => {
          updateUserNtpStatus(dispatch, result)
          getLoanApplicationDetails(dispatch, loanApplicationId)
        }}
        isInitial={isInitialNtp}
        loanApplicationStateId={loanApplicationStateId}
      />
    </div>,
  ]

  return (
    <div className="uw-hub-page__stipulations">
      <ExpandableCard
        headerProps={expandableCard.headerProps(isMobileView)}
        titleTypographyProps={expandableCard.titleProps(isMobileView)}
        title={translate(
          `uwHub.stipulations.${isInitialNtp ? 'initial' : 'final'}`
        )}
        collapsibleItems={collapsibleItems}
        isCollapsable={true}
        isCollapsed={isTabletView}
        alwaysCollapsible={true}
      />
      <NTPAddStipulations
        isOpen={addStipulationOpen}
        onClose={() => setAddStipulationOpen(false)}
        onAddStipulations={(newStips) => {
          setAddStipulationOpen(false)
          setLoading(true)
          addStipulationsNTP(
            dispatch,
            loanApplicationId,
            stipulations[0].ntpSectionId,
            newStips
          )
            .then(getNTPs)
            // adding a new stipulation might change the loan app state
            .then(() => getLoanApplicationDetails(dispatch, loanApplicationId))
        }}
        isInitial={isInitialNtp}
        isCommercial={false}
      />
    </div>
  )
}

StipulationSection.propTypes = {
  stipulations: PropTypes.array.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  loanApplicationStateId: PropTypes.number.isRequired,
  serviceAddress: PropTypes.object.isRequired,
  estimatedCombinedIncomeToBeProven: PropTypes.number,
  isInitialNtp: PropTypes.bool.isRequired,
}

export default StipulationSection
