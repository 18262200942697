const currentYear = new Date().getFullYear()

// From 2022 until current year
export const years = Array.from(
  { length: currentYear - 2022 + 1 },
  (_, i) => currentYear - i
)

const generateBorrowerState = (includeMonthly) => {
  const state = years.reduce((acc, year) => {
    acc[year] = {
      isChecked: false,
      from: new Date(year, 0, 1),
      through: year !== currentYear ? new Date(year, 11, 31) : '',
      totalEarnings: 0,
    }
    return acc
  }, {})
  if (includeMonthly) {
    state.monthly = {
      isChecked: false,
      rateOfPay: '',
      payFrequency: MONTHLY_OPTIONS.HOURLY,
      weeklyHours: '',
    }
  }
  return state
}

export const MONTHLY_OPTIONS = {
  HOURLY: {
    id: 'HOURLY',
    label: 'Hourly (H)',
    amountInMonth: 4.3333, // Same as weekly bc for hourly we have the weekly hours input text
  },
  WEEKLY: {
    id: 'WEEKLY',
    label: 'Weekly (W)',
    amountInMonth: 4.3333,
  },
  BI_WEEKLY: {
    id: 'BI_WEEKLY',
    label: 'Bi-Weekly (BW)',
    amountInMonth: 2.1767,
  },
  SEMI_MONTHLY: {
    id: 'SEMI_MONTHLY',
    label: 'Semi-Monthly (SM)',
    amountInMonth: 2,
  },
  MONTHLY: {
    id: 'MONTHLY',
    label: 'Monthly (M)',
    amountInMonth: 1,
  },
  QUARTERLY: {
    id: 'QUARTERLY',
    label: 'Quarterly (Q)',
    amountInMonth: 1 / 3,
  },
  SEMI_ANNUALLY: {
    id: 'SEMI_ANNUALLY',
    label: 'Semi-Annually (SA)',
    amountInMonth: 1 / 6,
  },
  ANNUALLY: {
    id: 'ANNUALLY',
    label: 'Annually (A)',
    amountInMonth: 1 / 12,
  },
  VARIES: {
    id: 'VARIES',
    label: 'Varies',
    amountInMonth: 0,
  },
}

export const EMPLOYER_STATE = {
  name: '',
  employer: '',
  basePay: generateBorrowerState(true),
  overtime: generateBorrowerState(),
  bonus: generateBorrowerState(),
  commission: generateBorrowerState(),
  other: generateBorrowerState(),
}

// Each borrower has an array of Employer objects
export const getBorrowerState = () => [{ ...EMPLOYER_STATE }]

export const INITIAL_STATE = {
  borrower: getBorrowerState(),
  coBorrower: null,
}

export const TABS = { BORROWER: 'borrower', CO_BORROWER: 'coBorrower' }

export const PERIOD_TABS = { MONTHLY: 'Monthly', YEARLY: 'Yearly' }

export const CALCULATIONS_INNER_STATE = {
  total: 0,
  basePay: { total: 0 },
  overtime: { total: 0 },
  bonus: { total: 0 },
  commission: { total: 0 },
  other: { total: 0 },
}

// Each calculation tab has an array of calculations, for each employer
export const CALCULATIONS_INITIAL_STATE = {
  [TABS.BORROWER]: {
    total: 0,
    employers: [{ ...CALCULATIONS_INNER_STATE }],
  },
  [TABS.CO_BORROWER]: {
    total: 0,
    employers: [{ ...CALCULATIONS_INNER_STATE }],
  },
}

export const INITIAL_INNER_TAB_STATE = {
  borrower: [
    {
      basePay: PERIOD_TABS.YEARLY,
    },
  ],
  coBorrower: [
    {
      basePay: PERIOD_TABS.YEARLY,
    },
  ],
}

export const WAGE_CALCULATOR_STATE = {
  data: { ...INITIAL_STATE },
  selectedBorrower: TABS.BORROWER,
  innerSelectedTabs: { ...INITIAL_INNER_TAB_STATE },
  calculations: { ...CALCULATIONS_INITIAL_STATE },
}

export const getYearLabel = (year) => {
  return year !== currentYear ? `Year ${year}` : 'Current Year'
}
