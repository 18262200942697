import React, {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import { sleep } from 'common/utils'

import { components, assets, colors } from '@ElementsCapitalGroup/enium-ui'
import moment from 'moment'
import { usePrevious, useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Button from 'components/button'
import Dropdown from 'components/dropdown'
import TextInput from 'components/input'
import DatePickerComponent from 'components/date-picker'
import { hasObjectValues } from 'modules/loan-applications/utils'
import { ReactComponent as ThreeDots } from 'assets/three-points-vertical.svg'
import { ReactComponent as CloseX } from 'assets/close-x.svg'
import { ReactComponent as SortAsc } from 'assets/sort-asc.svg'
import { ReactComponent as SortDesc } from 'assets/sort-desc.svg'
import './styles.scss'

const { Dropdown: DropdownEnium, Button: Button2 } = components
const { SearchLgIcon, FilterLinesIcon } = assets

/**
 * Renders a Search & Filters Module
 *
 * On Search change or Filters change it calls the "callback" param with a query string to be used for filtering
 */
const SearchAndFilterModule = forwardRef(
  (
    {
      callback,
      placeholder,
      loading,
      fieldsToSearch = [],
      extraOptions,
      noDefaultExtraOptions = false,
      noSearch = false,
      filtersClassName = '',
      fullWidth = false,
      noMargin = false,
      hasDateFilter = false,
      isSearchable,
      initialFiltersIds,
      ...rest
    },
    ref
  ) => {
    const anchorElementRef = useRef()
    const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
    const [numberOfSelectedFilters, setNumberOfSelectedFilters] = useState(0)
    const [dropdownValue, setDropdownValue] = useState([])
    const [searchText, setSearchText] = useState('')
    const [filters, setFilters] = useState({})
    const [selectedDate, setSelectedDate] = useState(null)
    const selectedDateRef = useRef()
    const searchTextRef = useRef()
    const filtersRef = useRef()

    useEffect(() => {
      selectedDateRef.current = selectedDate
    }, [selectedDate])

    useEffect(() => {
      searchTextRef.current = searchText
    }, [searchText])

    useEffect(() => {
      filtersRef.current = filters
    }, [filters])

    const anyFiltersSelected = useMemo(
      () =>
        Object.values(filters).some((filter) => {
          if (Array.isArray(filter)) {
            return filter.length
          } else {
            return !!filter || filter === 0
          }
        }),
      [filters]
    )
    const prevSearchText = usePrevious(searchText)

    const hasMultiFilters = extraOptions?.find((el) => el.isMultiSelect)

    useImperativeHandle(ref, () => ({
      resetComponentState() {
        resetAllFilters()
      },
    }))

    useEffect(() => {
      if (
        initialFiltersIds &&
        hasObjectValues(initialFiltersIds) &&
        extraOptions?.length
      ) {
        if (initialFiltersIds.searchBy?.length) {
          if (!searchText) {
            setSearchText(
              initialFiltersIds.searchBy[0].replace('multiple ', '')
            )
          }
        }

        if (initialFiltersIds.dateCreated) {
          if (!selectedDate) {
            setSelectedDate(moment(initialFiltersIds.dateCreated).toDate())
          }
        }

        // multiple options
        const initialFilters = {}
        extraOptions.forEach((option) => {
          if (initialFiltersIds[option.key]) {
            if (option.isMultiSelect) {
              option.options.forEach((item) => {
                if (initialFiltersIds[option.key].includes(item.id)) {
                  if (initialFilters[option.key]) {
                    initialFilters[option.key]?.push(item)
                  } else {
                    initialFilters[option.key] = [item]
                  }
                }
              })
            }
          }
        })

        if (initialFilters) {
          setFilters(initialFilters)

          const initialFiltersDropdownValue = convertFiltersToDropdownValue(
            initialFilters,
            extraOptions
          )
          setDropdownValue(initialFiltersDropdownValue)

          const nrOfSelectedFilters = Object.values(initialFilters).reduce(
            (sum, curr) => sum + curr?.length,
            0
          )
          if (
            nrOfSelectedFilters &&
            nrOfSelectedFilters !== numberOfSelectedFilters
          ) {
            setNumberOfSelectedFilters(nrOfSelectedFilters)
          }
        }
      }
    }, [initialFiltersIds])

    const convertFiltersToDropdownValue = (filters, extraOptions) => {
      const dropdownValue = []
      extraOptions.forEach((option) => {
        if (filters[option.key]?.length) {
          dropdownValue.push({
            label: option.placeholder,
            id: option.key,
            children: [...filters[option.key]],
          })
        }
      })
      return dropdownValue
    }

    const createInitialOptions = (extraOptions) => {
      return extraOptions.reduce((prev, option) => {
        const selectedValue =
          option.selectedValue !== undefined
            ? option.selectedValue
            : option.isMultiSelect
            ? []
            : null

        return {
          ...prev,
          [option.key]: selectedValue,
        }
      }, {})
    }

    const resetSearchTextFilter = async () => {
      if (prevSearchText && !searchText) {
        await sleep(100)
        resetFilterType('searchBy', null)
      }
    }

    useEffect(() => {
      resetSearchTextFilter()
    }, [searchText])

    /** Triggered on Search & Filter form submit. Calls the callback with the proper query */
    const callSearchAndFilter = (ev, selectedFilters = filters, date) => {
      ev?.preventDefault()
      if (!loading) {
        const { dateCreated, lastModified, ...rest } = selectedFilters
        const sortBy = []
        const searchBy = []
        const filtersToReturn = {}

        // Construct Sort By
        dateCreated && sortBy.push(`dateCreated ${dateCreated}`)
        lastModified && sortBy.push(`dateLastModified ${lastModified}`)

        // Construct Filter By
        Object.entries(rest).forEach(([filterKey, filterValue]) => {
          // Multi-select case
          if (Array.isArray(filterValue)) {
            if (filterValue.length) {
              filtersToReturn[filterKey] = filterValue.map((el) => el.id)
            }
          } else if (filterValue || filterValue === 0) {
            // Single-select case
            filtersToReturn[filterKey] = filterValue
          }
        })

        // Construct Search
        const search = searchTextRef.current && searchTextRef.current.trim()
        search &&
          fieldsToSearch.forEach((field) => searchBy.push(`${field} ${search}`))

        if (date !== null) {
          filtersToReturn.dateCreated = date || selectedDate
        }
        // Call the callback with the selected search & filters
        callback({ ...filtersToReturn, sortBy, searchBy })
      }
    }

    /** Triggered when the user selects some filters */
    const onFiltersSelected = async (filters) => {
      await sleep(100)

      setFilters(filters)
      callSearchAndFilter(null, filters, selectedDateRef.current)
    }

    /** Resets a full filter type - all filters of a certain type (e.g. OrganizationId) */
    const resetFilterType = (filterTypeName, ev) => {
      ev?.stopPropagation()

      onFiltersSelected({
        ...filtersRef.current,
        [filterTypeName]: Array.isArray(filtersRef.current[filterTypeName])
          ? []
          : null,
      })
    }

    /** Resets a multi-select type filter */
    const resetMultiSelectFilter = (filterType, id) => {
      const updatedFilter = [...filters[filterType]]
      const idx = updatedFilter.findIndex((filter) => filter.id === id)
      updatedFilter.splice(idx, 1)
      const allFilters = { ...filters, [filterType]: updatedFilter }

      setDropdownValue((prev) =>
        prev.map((item) => {
          if (item.id === filterType) {
            return {
              ...item,
              children: item.children.filter((child) => child.id !== id),
            }
          }
          return item
        })
      )

      onFiltersSelected(allFilters)
    }

    /** Resets all filters */
    const resetAllFilters = async () => {
      setSearchText('')
      setSelectedDate(null)
      setDropdownValue([])
      setNumberOfSelectedFilters(0)

      setFilters({})

      await sleep(100)
      callSearchAndFilter(null, {}, null)
    }

    const customTrigger = useMemo(() => {
      return (
        <Button
          loading={loading}
          variant="outlined"
          color={'inherit'}
          fullWidth
          startIcon={<FilterLinesIcon />}
        >
          Filters
          {numberOfSelectedFilters > 0 && ` (${numberOfSelectedFilters})`}
        </Button>
      )
    }, [loading, numberOfSelectedFilters])

    const filtersToRender = useMemo(() => {
      const options = extraOptions?.map((filter) =>
        filter.isMultiSelect
          ? {
              label: filter.placeholder,
              id: filter.key,
              children: filter.options,
            }
          : { label: filter.placeholder, id: filter.key }
      )

      return (
        <DropdownEnium
          label="Filters"
          placeholder="Choose an option"
          displayEmpty
          value={dropdownValue}
          options={options}
          multiple={hasMultiFilters}
          searchable={isSearchable}
          MenuProps={{
            anchorEl: anchorElementRef.current,
            PaperProps: {
              style: {
                maxHeight: 480,
                overflow: 'auto',
              },
            },
          }}
          onChange={(e) => {
            const selectedItems = e.target.value
            setDropdownValue(selectedItems)

            const updatedFilters = {}
            let numberOfSelectedFilters = 0
            const initialFilters = createInitialOptions(extraOptions)
            if (!selectedItems.length) {
              // re-create initial filters
              onFiltersSelected(initialFilters)
              setNumberOfSelectedFilters(0)
            } else {
              selectedItems.forEach((item) => {
                if (Object.keys(initialFilters).includes(item.id)) {
                  updatedFilters[item.id] = item.children
                  numberOfSelectedFilters += item.children.length
                }
              })
              onFiltersSelected(updatedFilters)
              setNumberOfSelectedFilters(numberOfSelectedFilters)
            }
          }}
          customTrigger={customTrigger}
        />
      )
    }, [extraOptions, numberOfSelectedFilters, filters, loading])

    const onlySearch = noDefaultExtraOptions && !extraOptions

    return (
      <form
        onSubmit={callSearchAndFilter}
        className={cx('search-module', {
          'search-module--no-search': noSearch,
          'search-module--no-margin': noMargin,
        })}
        ref={ref}
        {...rest}
      >
        {!noSearch ? (
          <div
            className={cx('search-module__search', {
              'search-module__search--full': fullWidth,
              flexColumn: isMobileView,
            })}
          >
            <div
              style={
                !isMobileView
                  ? { display: 'flex', alignItems: 'center', gap: '15px' }
                  : {
                      display: 'flex',
                      alignItems: 'center',
                      gap: 15,
                      flexDirection: 'column',
                      width: '100%',
                    }
              }
            >
              <TextInput
                onChange={setSearchText}
                value={searchText}
                placeholder={placeholder || 'Search'}
                type="search"
                disabled={loading}
                fullWidth={!!isMobileView}
                style={{
                  width: '100%',
                  minWidth: '300px',
                }}
                startIcon={
                  <SearchLgIcon
                    fontSize="small"
                    sx={{ stroke: colors.grey[400] }}
                  />
                }
                endIcon={
                  searchText && (
                    <CloseX
                      className={cx('clearButton', { disabled: loading })}
                      onClick={() => setSearchText('')}
                    />
                  )
                }
              />

              <Button2
                color="primary"
                variant="contained"
                onClick={callSearchAndFilter}
                sx={{ padding: '10px' }}
                fullWidth={!!isMobileView}
                disabled={loading}
              >
                Search
              </Button2>
            </div>

            <div className="search-date-filters">
              {hasDateFilter && (
                <div className="search-date-filters--date">
                  <DatePickerComponent
                    disabled={loading}
                    date={selectedDate}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    setDate={(date) => {
                      setSelectedDate(date)
                      callSearchAndFilter(null, filters, date)
                    }}
                  />
                </div>
              )}

              {!onlySearch && (
                <div
                  className="search-date-filters--filters"
                  ref={anchorElementRef}
                >
                  {filtersToRender}
                </div>
              )}
            </div>
          </div>
        ) : null}

        {!onlySearch && (
          <div className={`search-module__filters ${filtersClassName}`}>
            {!noDefaultExtraOptions && (
              <>
                <Dropdown
                  placeholder="Date created"
                  options={getDateOptions('Date Created')}
                  selected={filters.dateCreated}
                  setSelected={(dateCreated) =>
                    onFiltersSelected({ ...filters, dateCreated })
                  }
                  disabled={loading}
                  customSvg={
                    filters.dateCreated ? (
                      <CloseX
                        className="filter__x"
                        onClick={resetFilterType.bind(null, 'dateCreated')}
                      />
                    ) : (
                      <ThreeDots />
                    )
                  }
                  className={cx('filter', {
                    'filter--selected': !!filters.dateCreated,
                  })}
                />
                <Dropdown
                  placeholder="Last modified"
                  options={getDateOptions('Last Modified')}
                  selected={filters.lastModified}
                  setSelected={(lastModified) =>
                    onFiltersSelected({ ...filters, lastModified })
                  }
                  disabled={loading}
                  customSvg={
                    filters.lastModified ? (
                      <CloseX
                        className="filter__x"
                        onClick={resetFilterType.bind(null, 'lastModified')}
                      />
                    ) : (
                      <ThreeDots />
                    )
                  }
                  className={cx('filter', {
                    'filter--selected': !!filters.lastModified,
                  })}
                />
              </>
            )}

            {/* Clear all - Single Select */}
            {anyFiltersSelected && !hasMultiFilters ? (
              <span
                className="filter__clear"
                onClick={resetAllFilters}
                style={{ marginLeft: '20px' }}
              >
                Clear all
              </span>
            ) : null}
          </div>
        )}

        {/* Clear all - Multi select */}
        {(anyFiltersSelected || selectedDate || searchText) &&
        hasMultiFilters ? (
          <div className="filter__applied">
            {anyFiltersSelected ? (
              <div className="filter__list">
                <span style={{ marginRight: '8px' }}>Applied Filters: </span>
                {Object.entries(filters).map(
                  ([filterType, selectedFilters]) => {
                    // ignore single-select filters
                    if (!Array.isArray(selectedFilters)) {
                      return null
                    }

                    return selectedFilters.map((filter) => {
                      return (
                        <div
                          key={`${filterType}-${filter.id}`}
                          className={cx('filter__item', { disabled: loading })}
                        >
                          {filter.value}

                          <CloseX
                            onClick={() => {
                              resetMultiSelectFilter(filterType, filter.id)
                              setNumberOfSelectedFilters(
                                numberOfSelectedFilters - 1
                              )
                            }}
                          />
                        </div>
                      )
                    })
                  }
                )}
              </div>
            ) : (
              <div />
            )}

            <Button
              onClick={resetAllFilters}
              variant="text"
              className={'filter__clear'}
            >
              Reset All Filters
            </Button>
          </div>
        ) : null}
      </form>
    )
  }
)

// Helper method to get the special Date Options for the Sort Date Dropdowns
function getDateOptions(fieldLabel) {
  return [
    {
      id: 'ASC',
      value: (
        <div className="filter__date">
          Sort Ascending <SortAsc />
        </div>
      ),
      selectedValue: (
        <div className="filter__date">
          <SortAsc /> {fieldLabel}
        </div>
      ),
    },
    {
      id: 'DESC',
      value: (
        <div className="filter__date">
          Sort Descending <SortDesc />
        </div>
      ),
      selectedValue: (
        <div className="filter__date">
          <SortDesc /> {fieldLabel}
        </div>
      ),
    },
  ]
}

SearchAndFilterModule.propTypes = {
  callback: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  fieldsToSearch: PropTypes.arrayOf(PropTypes.string),
  extraOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      options: PropTypes.array,
      placeholder: PropTypes.string,
    })
  ),
  noDefaultExtraOptions: PropTypes.bool,
  noSearch: PropTypes.bool,
  filtersClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  noMargin: PropTypes.bool,
  hasDateFilter: PropTypes.bool,
  isSearchable: PropTypes.bool,
  initialFiltersIds: PropTypes.object,
}

export default SearchAndFilterModule
