import { useEffect, useState } from 'react'
import Cookie from 'js-cookie'
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr'
import { useStore } from 'store'
import { ACTIONS } from 'modules/session/actions'
import { BASIC_COOKIE_NAME } from 'common/constants'
/**
 * Returns a websocket connection
 * The connection is re-created when the user information changes
 * @return {WebSocket|null}
 */
export const useConnection = () => {
  const [connection, setConnection] = useState(null)

  const { state, dispatch } = useStore()
  const { userData } = state.session
  const userOrgId = userData?.organizationId

  const init = () => {
    if (!userOrgId) {
      return null
    }

    // If there is an already-existing connection, close it
    if (connection) {
      connection
        .stop()
        .then(() => console.log('[Socket] Closing existing socket connection'))
        .catch(console.error)
    }

    const newConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Error)
      .withUrl(process.env.REACT_APP_HOST_NOTIFICATIONS, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => Cookie.get(BASIC_COOKIE_NAME),
      })
      .withAutomaticReconnect()
      .build()

    newConnection.start().catch(console.error)

    setConnection(newConnection)
  }

  /**
   * Re-create the connection
   * - every time user organization changes
   * - every time the user cookie changes (login)
   */
  useEffect(() => {
    init()
  }, [userOrgId])

  useEffect(() => {
    if (state.session.shouldReconnectNotifications) {
      init()
    }

    dispatch({ type: ACTIONS.UPDATE_NOTIFICATION_RECONNECT, payload: false })
  }, [state.session.shouldReconnectNotifications])

  return connection
}
