import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { components } from '@ElementsCapitalGroup/enium-ui'
import PropTypes from 'prop-types'
import { useEffectOnUpdate } from 'common/hooks'
import EmptyList from 'components/empty-list'
import { LOAN_APPS_PAGE_SIZE } from 'modules/loan-applications/constants'

import { styles } from './style'

const { DataTable, Card, getOnSortingChangeValue } = components

const Table = forwardRef(
  (
    {
      columns,
      data,
      onRowClick,
      title,
      className,
      hasCheckboxes,
      defaultFilterableColumnIds,
      hasPagination,
      fetchData,
      totalPages,
      onRowsRemove,
      onRowsEdit,
      onTableRowsSelectChange,
      multiRowSelection,
      centerCellContent,
      columnsVisibilityData,
      onSortingChange,
      emptyTitle,
      emptyDescription,
      emptyActionButtonLabel,
      emptyActionButtonOnClick,
      showColumnSelector,
      style,
      dataLoading,
      hideEditButton = false,
      hideRemoveButton = false,
      toolbarRightContent,
      persistentSelection = false,
      getRowId,
      getVisibleColumns,
    },
    ref
  ) => {
    const [loading, setLoading] = useState(false)
    const [sorting, setSorting] = useState([])
    const [{ pageIndex, pageSize }, setPagination] = useState({
      pageIndex: 0,
      pageSize: LOAN_APPS_PAGE_SIZE,
    })

    const pagination = useMemo(
      () => ({ pageIndex, pageSize }),
      [pageIndex, pageSize]
    )

    const internalData = useMemo(
      () =>
        dataLoading
          ? Array.from({ length: 10 }, () =>
              Object.fromEntries(
                columns?.map((column) => [column.accessorKey, ''])
              )
            )
          : data,
      [data, dataLoading]
    )

    useEffect(() => {
      if (!sorting.length) {
        return
      }
      onSortingChange(sorting[0].id, sorting[0].desc ? 'DESC' : 'ASC')
    }, [sorting])

    useEffect(() => {
      typeof dataLoading !== 'undefined' && setLoading(dataLoading)
    }, [dataLoading])

    useEffectOnUpdate(() => {
      fetchData && fetchData({ pageIndex, pageSize })
    }, [pageIndex, pageSize])

    useImperativeHandle(ref, () => ({
      ...ref.current,
      setPageIndex(index) {
        setPagination((prev) => ({ ...prev, pageIndex: index }))
      },
    }))

    const handleOnSortingChange = (f) => {
      const value = getOnSortingChangeValue(f, sorting) // get raw value from onSortingChange

      setSorting(value)

      if (!value.length) {
        setLoading(true)
        fetchData({ pageIndex, pageSize }).finally(() => setLoading(false))
      }
    }

    if (!internalData?.length && !loading) {
      return (
        <EmptyList
          title={emptyTitle}
          description={emptyDescription}
          actionButtonLabel={emptyActionButtonLabel}
          actionButtonOnClick={emptyActionButtonOnClick}
        />
      )
    }

    return (
      <Card
        contentProps={{
          sx: {
            padding: 0,
            '&:last-child': {
              paddingBottom: '16px',
            },
          },
        }}
        style={style}
      >
        <DataTable
          ref={ref}
          loading={loading}
          columns={columns}
          data={internalData}
          selectable={hasCheckboxes}
          toolbarRightContent={toolbarRightContent}
          paginated={hasPagination}
          hideEditButton={hideEditButton}
          columnsVisibilityData={columnsVisibilityData}
          hideRemoveButton={hideRemoveButton}
          toolbarLeftContent={<div style={styles.tableTitle}>{title}</div>}
          hideColumnsVisibilityButton={!showColumnSelector}
          tableOptions={{
            enableMultiRowSelection: !!multiRowSelection,
            manualPagination: hasPagination,
            pageCount: totalPages,
            onPaginationChange: setPagination,
            state: { pagination, sorting },
            manualSorting: false,
            onSortingChange: handleOnSortingChange,
          }}
          onRowsEdit={onRowsEdit}
          onRowsRemove={onRowsRemove}
          onRowClick={onRowClick}
          onTableRowsSelectChange={onTableRowsSelectChange}
          persistentSelection={persistentSelection}
          getRowId={getRowId}
          getVisibleColumns={getVisibleColumns}
        />
      </Card>
    )
  }
)

Table.propTypes = {
  onRowClick: PropTypes.func,
  title: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      id: PropTypes.string,
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      Cell: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  hasCheckboxes: PropTypes.bool,
  defaultFilterableColumnIds: PropTypes.array,
  hasPagination: PropTypes.bool,
  fetchData: PropTypes.func,
  totalPages: PropTypes.number,
  hideEditButton: PropTypes.bool,
  hideRemoveButton: PropTypes.bool,
  centerCellContent: PropTypes.bool,
  pageSize: PropTypes.number,
  showColumnSelector: PropTypes.bool,
  onSortingChange: PropTypes.func,
  onRowsRemove: PropTypes.func,
  onRowsEdit: PropTypes.func,
  multiRowSelection: PropTypes.bool,
  emptyTitle: PropTypes.string,
  emptyDescription: PropTypes.string,
  emptyActionButtonLabel: PropTypes.string,
  emptyActionButtonOnClick: PropTypes.func,
  style: PropTypes.object,
  dataLoading: PropTypes.bool,
  toolbarRightContent: PropTypes.node,
  onTableRowsSelectChange: PropTypes.func,
  persistentSelection: PropTypes.bool,
  getRowId: PropTypes.func,
  getVisibleColumns: PropTypes.func,
  columnsVisibilityData: PropTypes.object,
}

export default Table
