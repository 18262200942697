import { colors, theme } from '@ElementsCapitalGroup/enium-ui'

const {
  lightTheme: { typography },
} = theme

const alertsWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
}

const alertsText = {
  color: colors.grey[700],
  fontWeight: typography.fontWeightBold,
  fontSize: '14px',
  marginRight: '8px',
}

const currentNextHeader = {
  fontWeight: typography.fontWeightMedium,
  fontSize: '0.625rem',
  color: colors.grey[500],
  lineHeight: 1.5,
}

const upcomingText = {
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: 1.5,
}

const currentText = { ...upcomingText, color: colors.blue[700] }

const currentNextStep = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  marginBottom: '24px',
}

const arrow = {
  background: '#f2f4f7',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 16px',
}

const applicantName = {
  fontWeight: typography.fontWeightBold,
  color: colors.grey[900],
  lineHeight: 1.5,
  fontSize: '1rem',
  marginBottom: '4px',
}

const applicantAddress = {
  color: colors.grey[600],
  fontSize: '0.75rem',
  lineHeight: 1.5,
  fontWeight: 400,
}

const statusBar = {
  background: 'white',
  padding: '32px 32px 0',
  borderBottom: `2px solid ${colors.grey[200]}`,
}

export const styles = {
  currentNextHeader,
  upcomingText,
  currentText,
  currentNextStep,
  arrow,
  applicantName,
  applicantAddress,
  statusBar,
  alertsWrapper,
  alertsText,
}
