import { theme, colors } from '@ElementsCapitalGroup/enium-ui'
const {
  lightTheme: { typography },
} = theme

const ntpWrapper = {
  width: '100%',
}

const commentsTitle = {
  fontSize: typography.body1.fontSize,
  color: colors.grey[700],
  fontWeight: typography.fontWeightBold,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '24px',
}

const cardTitle = {
  fontSize: typography.body1.fontSize,
  color: colors.grey[700],
  fontWeight: typography.fontWeightBold,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}

const cardTitleContent = {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}

const headerWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '8px',
  flexWrap: 'wrap',
}

const header = {
  fontSize: typography.h4.fontSize,
  fontWeight: typography.fontWeightBold,
  color: colors.grey[900],
}

const headerContent = {
  width: '100%',
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
}
const subHeader = {
  fontSize: typography.body2.fontSize,
  color: colors.grey[600],
  fontWeight: typography.fontWeightMedium,
  marginBottom: '16px',
}

const headerSubheaderFinal = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',
}

const ntpButtonsWrapper = {
  marginBottom: '16px',
  justifyContent: 'end',
  display: 'flex',
}

const headerSubheaderInitial = {
  display: 'flex',
  flexDirection: 'column',
}

const ntpContent = {
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gap: '32px',
}

const ntpContentMobile = {
  ...ntpContent,
  gridTemplateColumns: '1fr',
}

const ntpButtons = {
  display: 'grid',
  justifyItems: 'baseline',
}

const ntpActions = {
  display: 'flex',
  alignItems: 'center',
}

const loading = {
  pointerEvents: 'none',
  opacity: 0.7,
}

const ntpComment = {
  gap: '12px',
  marginBottom: '12px',
  marginTop: '12px',
}

const ntpCommentHeader = {
  display: 'flex',
  gap: '12px',
  marginBottom: '0',
}

const ntpCommentWrapper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}

const ntpCommentName = {
  fontSize: '0.875rem',
  color: colors.grey[700],
  fontWeight: 500,
  overflowWrap: 'break-word',
}

const ntpCommentDate = {
  fontSize: '0.75rem',
  color: colors.grey[400],
  marginLeft: '6px',
}

const ntpCommentContent = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: '52px',
}

const totalLoanAmountLabel = {
  marginTop: '24px',
  fontSize: '0.875rem',
  fontWeight: 'bold',
}

export const styles = {
  ntpWrapper,
  cardTitle,
  cardTitleContent,
  headerWrapper,
  header,
  headerContent,
  headerSubheaderFinal,
  headerSubheaderInitial,
  subHeader,
  ntpButtonsWrapper,
  ntpContent,
  ntpContentMobile,
  ntpButtons,
  ntpActions,
  loading,
  commentsTitle,
  ntpComment,
  ntpCommentHeader,
  ntpCommentName,
  ntpCommentDate,
  ntpCommentContent,
  ntpCommentWrapper,
  totalLoanAmountLabel,
}
