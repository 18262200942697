import React from 'react'
import PropTypes from 'prop-types'
import { renderCell } from '../render-cell'

const MobileContent = ({ columns, data }) => {
  return (
    <div style={{ width: '100%' }}>
      {columns.map((column, key) => (
        <div key={key} style={{ width: '100%' }}>
          <div className="collapsible__content-flex">
            <div className="align-center collapsible__content-item">
              {column.header}:
              <div className="collapsible__content-item--bold">
                {renderCell(
                  Object.entries(data[0])
                    .find(([key]) => key === column.accessorKey)
                    .slice(1)[0]
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

MobileContent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
}

export default MobileContent
