import React from 'react'
import PropTypes from 'prop-types'
import { assets } from '@ElementsCapitalGroup/enium-ui'
import { styles } from '../style'

const { PlusIcon, TrashIcon } = assets

const IncomeTabs = ({
  tabs,
  selectedTab,
  onTabChanged,
  onPlusClicked,
  onRemoveClicked,
}) => {
  return (
    <div style={styles.tabs}>
      {tabs.map((tab) => (
        <div
          key={tab.id}
          style={{
            ...styles.tab,
            ...(selectedTab === tab.id ? styles.selectedTab : {}),
          }}
          onClick={() => onTabChanged(tab.id)}
        >
          {tab.label}
        </div>
      ))}
      {onPlusClicked && (
        <div style={styles.plusTab} onClick={onPlusClicked}>
          <PlusIcon sx={styles.plusTabSvg} />
        </div>
      )}
      {onRemoveClicked && (
        <div style={styles.plusTab} onClick={onRemoveClicked}>
          <TrashIcon sx={styles.plusTabSvg} />
        </div>
      )}
    </div>
  )
}

IncomeTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
  selectedTab: PropTypes.string,
  onTabChanged: PropTypes.func.isRequired,
  onPlusClicked: PropTypes.func,
  onRemoveClicked: PropTypes.func,
}

export default IncomeTabs
