import React from 'react'
import AdminWrapper from 'modules/admin'
import PropTypes from 'prop-types'
import StipulationTemplatesSettings from 'modules/stipulation-templates/stipulation-templates-settings'

const AdminStipulationTemplatesSettings = ({ isCreate }) => (
  <AdminWrapper>
    <StipulationTemplatesSettings isCreate={isCreate} />
  </AdminWrapper>
)

export default AdminStipulationTemplatesSettings

AdminStipulationTemplatesSettings.propTypes = {
  isCreate: PropTypes.bool,
}
