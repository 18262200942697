import React from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button from 'components/button'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as CongratulationsTwoFactor } from 'assets/2fa-congrats.svg'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'

import { styles } from './style'

const { Dialog } = components

export const Congratulations2FA = () => {
  const navigate = useNavigate()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          maxWidth: '512px',
          height: 'auto',
          margin: isMobileView ? '12px' : 'inherit',
        },
      }}
    >
      <div style={styles.content}>
        <CongratulationsTwoFactor />
        <span style={styles.title}>Congratulations! </span>
        <span style={styles.text}>
          Your 2-Factor Authentication has been successfully set up.
        </span>
      </div>
      <Button
        onClick={() => {
          navigate('/dashboard')
        }}
        style={{ width: '100%' }}
      >
        Proceed
      </Button>
    </Dialog>
  )
}

Congratulations2FA.propTypes = {
  onPassedTwoFactor: PropTypes.func.isRequired,
  setIsPhoneSetup: PropTypes.func.isRequired,
  setIsReminder: PropTypes.func.isRequired,
  emailAddress: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
}
