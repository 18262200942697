import React from 'react'
import PropTypes from 'prop-types'

import PaymentDetailsElement from './payment-details-element'

/** Recursive component to render the Payment Details List */
const PaymentDetailsList = ({
  list,
  onListChange,

  financingPrincipleTypes,
  financingInterestTypes,
  errors,
}) => {
  /** Triggered when attributes of an element change */
  const onItemChange = (element) => {
    const newList = list.map((el) => {
      if (element.id) {
        if (element.id === el.id) {
          return element
        } else {
          return el
        }
      } else {
        if (element.guid === el.guid) {
          return element
        } else {
          return el
        }
      }
    })
    onListChange(newList)
  }

  /** Remove a payment details element from inside a template */
  const onItemRemove = (index) => {
    const newList = [...list]
    newList.splice(index, 1)
    onListChange(newList)
  }

  return (
    <div className="financial-details__list">
      {list.map((itm, index) => {
        return (
          <PaymentDetailsElement
            financingPrincipleTypes={financingPrincipleTypes}
            financingInterestTypes={financingInterestTypes}
            index={index}
            key={index}
            element={itm}
            onListChange={onListChange}
            errors={errors?.[index]}
            onElementChange={onItemChange}
            onElementRemove={() => onItemRemove(index)}
            canBeRemoved={list.length > 1}
          ></PaymentDetailsElement>
        )
      })}
    </div>
  )
}

PaymentDetailsList.propTypes = {
  list: PropTypes.array.isRequired,
  onListChange: PropTypes.func.isRequired,
  financingPrincipleTypes: PropTypes.array.isRequired,
  financingInterestTypes: PropTypes.array.isRequired,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  depth: PropTypes.number,
  errors: PropTypes.array.isRequired,
}

export default PaymentDetailsList
