import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { assets } from '@ElementsCapitalGroup/enium-ui'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'

import 'react-datepicker/dist/react-datepicker.css'
import '../date-range-picker/style.scss'

const { XCircleIcon } = assets

const DatePickerComponent = ({
  date,
  setDate,
  disabled,
  showTimePicker = false,
  showMonthDropdown = false,
  showYearDropdown = false,
  minDate,
  maxDate,
  dateFormat = 'MMMM dd, yyyy',
  customInput,
  placement,
  alwaysOpen,
  disableMobilePortal = false,
}) => {
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const withPortal = isTabletView && !disableMobilePortal

  return (
    <DatePicker
      selected={date}
      onChange={(date) => !disabled && setDate(date)}
      disabled={disabled}
      showTimeSelect={showTimePicker}
      dateFormat={showTimePicker ? 'Pp' : 'dd yyyy'}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      minDate={minDate || null}
      maxDate={maxDate || null}
      dateFormatCalendar={dateFormat}
      preventOpenOnFocus={true}
      scrollableYearDropdown={true}
      yearDropdownItemNumber={50}
      showPopperArrow={false}
      monthsShown={1}
      withPortal={withPortal}
      popperPlacement={placement}
      open={alwaysOpen}
      customInput={
        customInput || (
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            endIcon={
              date && (
                <XCircleIcon
                  onClick={(e) => {
                    setDate(null)
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                />
              )
            }
            color={BUTTON_COLORS.INHERIT}
          >
            {date ? moment(date).format('MMM. Do, YYYY') : 'Select Date'}
          </Button>
        )
      }
    />
  )
}

DatePickerComponent.propTypes = {
  date: PropTypes.instanceOf(Date),
  setDate: PropTypes.func.isRequired,
  showTimePicker: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
  dateFormat: PropTypes.string,
  customInput: PropTypes.node,
  placement: PropTypes.string,
  disableMobilePortal: PropTypes.bool,
}

export default DatePickerComponent
