import React, { useEffect, useState } from 'react'
import { useStore } from 'store'
import { fetchLanguages } from 'modules/translations'
import { switchTranslationsLanguage } from 'modules/loan-application/actions'
import { colors, components } from '@ElementsCapitalGroup/enium-ui'
import useSession from 'modules/session'

const { Dropdown } = components

export const SelectLanguage = () => {
  const { state, dispatch } = useStore()
  const [isLoggedIn] = useSession()
  const { selectedTranslationsLanguage } = state.flow
  const [languageOptions, setLanguageOptions] = useState([])

  const initLanguageDropdown = async () => {
    let languages
    if (isLoggedIn) {
      languages = await fetchLanguages()
    }
    if (languages?.length) {
      setLanguageOptions(
        languages.map((language) => ({
          id: language.id,
          abbreviation: language.abbreviation,
          label: language.name,
        }))
      )
    }
  }

  useEffect(() => {
    initLanguageDropdown()
  }, [])

  const handleSwitchTranslationsLanguage = (ev) => {
    const selectedLanguage = languageOptions.find(
      (o) => o.id === ev.target.value.id
    )
    switchTranslationsLanguage(dispatch, selectedLanguage)
  }

  return (
    <div className="select-role" style={{ color: colors.grey[700] }}>
      <Dropdown
        options={languageOptions}
        value={
          languageOptions.find(
            (itm) => itm.id === selectedTranslationsLanguage?.id
          ) || ''
        }
        onChange={(languageId) => handleSwitchTranslationsLanguage(languageId)}
      />
    </div>
  )
}
