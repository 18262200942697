import React, { useEffect, useMemo, useRef, useState } from 'react'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import Table from 'components/table'
import TextField from 'components/input'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import { useStore } from 'store'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { fetchTemplates } from './actions'
import { deleteTemplate } from './aus-templates-settings/actions'

const { Button } = components

const { createColumnHelper } = components
const columnHelper = createColumnHelper()
const { SearchLgIcon, PlusIcon } = assets

const AusTemplates = () => {
  const { dispatch, state: storeState } = useStore()
  const { lenders } = storeState.orgManagement
  const tableRef = useRef()
  const navigate = useNavigate()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const [isDataLoading, setDataLoading] = useState(true)

  const [state, setState] = useState({
    list: [],
    totalPages: 0,
  })

  const [filters, setFilters] = useState({
    sortBy: undefined,
    searchBy: '',
    pageNumber: 0,
    itemsPerPage: TEMPLATES_PAGE_SIZE,
  })

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'Template Title',
      }),
      columnHelper.accessor('lenderId', {
        id: 'lenderId',
        header: 'Lender',
        enableSorting: true,
        cell: (info) =>
          lenders?.find((itm) => itm.guid === info.getValue())?.name,
      }),
      columnHelper.accessor('dateCreated', {
        id: 'dateCreated',
        header: 'Date Created',
        enableSorting: true,
        cell: (info) => moment(info.getValue()).format('MMM. Do, YYYY'),
      }),
      columnHelper.accessor('dateLastModified', {
        id: 'dateLastModified',
        header: 'Last Modified',
        enableSorting: true,
        cell: (info) =>
          info.getValue() && moment(info.getValue()).format('MMM. Do, YYYY'),
      }),
    ]
  }, [lenders])

  useEffect(() => {
    handleFetchTemplates(filters)
  }, [filters])

  useEffect(() => {
    tableRef.current?.setPageIndex(0)
  }, [filters.searchBy])

  const onSearch = (value) => {
    setFilters({ ...filters, searchBy: value, pageNumber: 0 })
  }

  const removeTemplate = (decisioningTemplateId) => {
    try {
      deleteTemplate(decisioningTemplateId, dispatch).finally(() => {
        handleFetchTemplates(filters)
      })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleFetchTemplates = (filters) => {
    setDataLoading(true)
    fetchTemplates(filters)
      .then((res) => {
        setState({
          list: res?.decisioningTemplates,
          totalPages: Math.ceil(res.itemCount / TEMPLATES_PAGE_SIZE),
        })
      })
      .finally(() => {
        setDataLoading(false)
      })
  }

  // Define the fetchData function
  const fetchData = (options) => {
    return Promise.resolve().then(() => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        sortBy: filters.sortBy,
        pageNumber: options.pageIndex,
      }))
    })
  }

  const onSortingChange = (data, direction) => {
    tableRef.current?.setPageIndex(0)
    setFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: `${data} ${direction}`,
    }))
  }

  return (
    <div>
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">AUS Templates</div>
          <div>View all your AUS Templates in one place.</div>
        </div>
        <Button
          startIcon={<PlusIcon />}
          onClick={() => navigate('/admin/aus-templates/add-new-template')}
          fullWidth={isMobileView}
          sx={{ mt: isMobileView ? 2 : 0 }}
        >
          Add New Template
        </Button>
      </div>

      <TextField
        onChange={onSearch}
        value={filters.searchBy}
        placeholder={'Search templates'}
        type="search"
        className="input--search"
        disabled={false}
        style={{ marginBottom: '32px', minWidth: '300px' }}
        startIcon={<SearchLgIcon fontSize="small" />}
      />
      <Table
        data={state.list}
        columns={columns}
        ref={tableRef}
        title={'AUS Templates'}
        totalPages={state.totalPages}
        dataLoading={isDataLoading}
        hasPagination={true}
        fetchData={fetchData}
        hasCheckboxes={true}
        onSortingChange={onSortingChange}
        onRowsRemove={(data, info, row) => {
          removeTemplate(row?.[0]?.decisioningTemplateId)
        }}
        onRowsEdit={(ev, row) =>
          navigate(`/admin/aus-templates/${row?.[0]?.decisioningTemplateId}`)
        }
        onRowClick={(ev, row) => {
          navigate(
            `/admin/aus-templates/${row?.original.decisioningTemplateId}`
          )
        }}
        emptyTitle="No AUS Templates Found"
        emptyDescription="We couldn't find any data matching your criteria."
      />
    </div>
  )
}

export default AusTemplates

export const TEMPLATES_PAGE_SIZE = 7
