import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { unstable_components } from '@ElementsCapitalGroup/enium-ui'

const { Unstable_TextField: TextField } = unstable_components

export const INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  CURRENCY: 'currency',
  PERCENTAGE: 'percentage',
  EMAIL: 'email',
  PASSWORD: 'password',
  SENSITIVE: 'sensitive',
  SEARCH: 'search',
  PHONE: 'phone',
  SSN: 'ssn',
  DATEPICKER: 'date-picker',
  EIN: 'ein',
  EIN_CREATE: 'ein-create',
}

const Component = forwardRef(
  (
    {
      css,
      className,
      style,
      type = INPUT_TYPES.TEXT,
      label,
      placeholder,
      defaultValue,
      value,
      error,
      description,
      disabled,
      startAdornment,
      endAdornment,
      startIcon,
      onBlur,
      onFocus,
      endIcon,
      onChange,
      onEnterKey,
      onKeyDown,
      hideSecureValue,
      unmaskedSecuredValue,
      fullWidth,
      validate,
      validationBehavior = 'aria',
      decimalScale = 2,
      fixedDecimalScale,
      onSecureToggleChange,
      maxLength,
    },
    ref
  ) => {
    const onKeyUp = (ev) => {
      const code = ev.keyCode || ev.which
      if (code === 13) {
        ev.preventDefault()
        onEnterKey?.(ev)
      }
    }

    return (
      <TextField
        ref={ref}
        css={css}
        className={className}
        style={style}
        type={type}
        label={label}
        placeholder={placeholder}
        isInvalid={error}
        description={description}
        isDisabled={disabled}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        startIcon={startIcon}
        endIcon={endIcon}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        fullWidth={fullWidth}
        validate={validate}
        validationBehavior={validationBehavior}
        value={value}
        defaultValue={defaultValue}
        maxLength={maxLength}
        {...(type === INPUT_TYPES.CURRENCY && {
          onChange: null,
          maskProps: {
            value: value?.toString(),
            defaultValue: defaultValue?.toString(),
            mask: '$ num',
            lazy: false,
            unmask: true,
            blocks: {
              num: {
                mask: Number,
                expose: true,
                mapToRadix: ['.'],
                normalizeZeros: true,
                padFractionalZeros: fixedDecimalScale,
                radix: '.',
                scale: decimalScale,
                thousandsSeparator: ',',
              },
            },
            onAccept: onChange,
          },
        })}
        {...(type === INPUT_TYPES.PERCENTAGE && {
          onChange: null,
          maskProps: {
            value: value?.toString(),
            defaultValue: defaultValue?.toString(),
            mask: '% num',
            lazy: false,
            unmask: true,
            blocks: {
              num: {
                mask: Number,
                expose: true,
                mapToRadix: ['.'],
                normalizeZeros: true,
                padFractionalZeros: fixedDecimalScale,
                radix: '.',
                scale: decimalScale,
                thousandsSeparator: '',
              },
            },
            onAccept: onChange,
          },
        })}
        {...(type === INPUT_TYPES.PHONE && {
          onChange: null,
          maskProps: {
            value: value?.toString(),
            defaultValue: defaultValue?.toString(),
            unmask: true,
            mask: '(000) 000-0000',
            lazy: false,
            onAccept: onChange,
          },
        })}
        {...(type === INPUT_TYPES.DATEPICKER && {
          onChange: null,
          maskProps: {
            value: value?.toString(),
            defaultValue: defaultValue?.toString(),
            unmask: false,
            mask: '00/00/0000',
            onAccept: onChange,
          },
        })}
        {...([INPUT_TYPES.SSN, INPUT_TYPES.EIN, INPUT_TYPES.SENSITIVE].includes(
          type
        ) && {
          secureProps: {
            allowToggle: !hideSecureValue,
            unmaskedValue: unmaskedSecuredValue,
            enabled: true,
            onChange: onSecureToggleChange,
          },
        })}
      />
    )
  }
)

Component.propTypes = {
  css: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  error: PropTypes.bool,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onEnterKey: PropTypes.func,
  onKeyDown: PropTypes.func,
  hideSecureValue: PropTypes.bool,
  unmaskedSecuredValue: PropTypes.bool,
  fullWidth: PropTypes.bool,
  validate: PropTypes.func,
  validationBehavior: PropTypes.oneOf(['aria', 'native']),
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  onSecureToggleChange: PropTypes.func,
  maxLength: PropTypes.number,
}

export default Component
