import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import LoanDocumentSettings from 'modules/loan-document/loan-document-settings/index'
import { fetchLenders } from 'modules/organizations/actions'

import { useStore } from 'store'

const LoanDocumentCreatePage = ({ isCreate }) => {
  const { state, dispatch } = useStore()
  const { lenders } = state.orgManagement

  useEffect(() => {
    if (lenders.length < 1) {
      fetchLenders(dispatch)
    }
  }, [])

  return <LoanDocumentSettings isCreate={isCreate} />
}

export default LoanDocumentCreatePage

LoanDocumentCreatePage.propTypes = {
  isCreate: PropTypes.bool,
}
