import {
  ADDRESS_DETAILS,
  GENERAL_ADDRESS_ERRORS,
  SERVICE_ADDRESS_ERROR_MAP,
} from 'common/constants'
import { VALIDATION_TYPES } from 'components/validator'

export const DEFAULT_BUSINESS_OWNER = {
  principalId: '',
  firstName: '',
  lastName: '',
  ownership: '',
  dateOfBirth: '',
  last4SSN: '',
  annualGrossIncome: '',
  homeAddress: { ...ADDRESS_DETAILS },
  bypassAddressValidation: false,
}

export const DEFAULT_BUSINESS_OWNER_ERRORS = {
  firstName: '',
  lastName: '',
  ownership: '',
  dateOfBirth: '',
  last4SSN: '',
  annualGrossIncome: '',
  homeAddress: { ...GENERAL_ADDRESS_ERRORS },
}

export const INFO_SECTION_ERROR_MAP = {
  legalCompanyName: [VALIDATION_TYPES.REQUIRED],
  businessTypeId: [VALIDATION_TYPES.REQUIRED],
  businessStartDate: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.DATE],
  annualGrossRevenue: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.BIGGER_THAN_ZERO,
  ],
  einNumber: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.EIN],
  businessPhone: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.PHONE],
  mobilePhone: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.PHONE],
  emailAddress: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.EMAIL],
  natureOfBusiness: [VALIDATION_TYPES.REQUIRED],
  debtServiceSchedule: [VALIDATION_TYPES.REQUIRED],
  loanAmount: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.BIGGER_THAN_ZERO],
}

export const BUILDING_OWN_SECTION_ERROR_MAP = {
  lenderName: [VALIDATION_TYPES.REQUIRED],
  lenderPhoneNumber: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.PHONE],
  estimatedPropertyValue: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.BIGGER_THAN_ZERO,
  ],
  monthlyMortgagePayment: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.BIGGER_THAN_ZERO,
  ],
  remainingLoanBalance: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.BIGGER_THAN_ZERO,
  ],
}

export const BUILDING_LEASE_SECTION_ERROR_MAP = {
  landlordName: [VALIDATION_TYPES.REQUIRED],
  landlordAddress: [VALIDATION_TYPES.REQUIRED],
  landlordPhoneNumber: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.PHONE],
  monthlyRent: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.BIGGER_THAN_ZERO],
  monthsRemainingOnLease: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.BIGGER_THAN_ZERO,
  ],
}

export const BUSINESS_OWNER_SECTION_ERROR_MAP = {
  firstName: [VALIDATION_TYPES.REQUIRED],
  lastName: [VALIDATION_TYPES.REQUIRED],
  ownership: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.BIGGER_THAN_ZERO],
  dateOfBirth: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.DATE],
  last4SSN: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.BIGGER_THAN_ZERO],
  annualGrossIncome: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.BIGGER_THAN_ZERO,
  ],
  homeAddress: { ...SERVICE_ADDRESS_ERROR_MAP },
}

export const BUILDING_TYPES = [
  {
    id: 0,
    value: 'Own',
    label: 'Own',
  },
  {
    id: 1,
    value: 'Lease',
    label: 'Lease',
  },
]
