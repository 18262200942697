import React from 'react'
import { assets } from '@ElementsCapitalGroup/enium-ui'
import PropTypes from 'prop-types'
import TextField from 'components/input'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import './style.scss'

const { TrashIcon, PlusIcon } = assets

const LateRevFee = ({ state, onStateChange, errors = {}, clearErrors }) => {
  const updateField = (index, name) => (value) => {
    const newState = [...state]
    newState[index][name] = value
    onStateChange(newState)
    clearErrors && clearErrors()
  }

  const removeRow = (index) => () => {
    const newState = [...state]
    newState.splice(index, 1)
    onStateChange(newState)
    clearErrors && clearErrors()
  }

  const addRow = () => {
    const newState = [...state]
    newState.push({
      numberOfDays: '',
      percentageApplied: '',
    })
    onStateChange(newState)
  }

  return (
    <div className="afp__dam">
      {state.map((el, index) => (
        <div className="afp__dam__inputs" key={index}>
          <TextField
            label="No. of Days"
            value={el.numberOfDays}
            onChange={updateField(index, 'numberOfDays')}
            type="number"
            className="afp__dam__days"
            validate={() => errors[index]}
          />
          <TextField
            label="Fee"
            value={el.percentageApplied}
            onChange={updateField(index, 'percentageApplied')}
            prefix="%"
            type="number"
            className="afp__dam__fee"
          />

          {state.length > 1 && (
            <TrashIcon className="afp__dam__minus" onClick={removeRow(index)} />
          )}
        </div>
      ))}

      <Button
        startIcon={<PlusIcon />}
        onClick={addRow}
        color={BUTTON_COLORS.SECONDARY}
        variant={BUTTON_VARIANTS.OUTLINED}
      >
        Add another row
      </Button>
    </div>
  )
}

LateRevFee.propTypes = {
  state: PropTypes.array.isRequired,
  onStateChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  clearErrors: PropTypes.func,
}

export default LateRevFee
