import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import RichTextEditor from 'components/rich-text-editor'

import { ReactComponent as BlueClipperIcon } from 'assets/blue-clipper.svg'
import { ReactComponent as RemoveFileIcon } from 'assets/close-o.svg'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import FileUpload from 'components/file-upload/index'
import { addNotes } from 'modules/loan-application/actions'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import './index.scss'

const { Button, LoadingIndicator } = components
const { PaperclipIcon, CheckIcon } = assets

const AddNewNote = ({ loanApplicationId, getNotes }) => {
  const { dispatch } = useStore()
  const { t: translate } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [newNote, setNewNote] = useState(EditorState.createEmpty())
  const [filesToBeUploaded, setFilesToBeUploaded] = useState([])

  const onFileChange = (file) => {
    setFilesToBeUploaded([...filesToBeUploaded, file])
  }

  const onSubmit = () => {
    setLoading(true)

    addNotes({
      loanApplicationId: loanApplicationId,
      content: stateToHTML(newNote.getCurrentContent()),
      attachments: filesToBeUploaded,
    })
      .then(() => {
        showNotification(dispatch, {
          title: translate('loanApplication.notes.notifications.successSave'),
        })
        getNotes(loanApplicationId)
        setNewNote(EditorState.createEmpty())
        setFilesToBeUploaded([])
      })
      .catch(() => {
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: translate('global.notifications.error'),
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getStartIconForSubmit = () => {
    if (loading) {
      return <LoadingIndicator size={14} />
    }

    return <CheckIcon />
  }

  return (
    <>
      <RichTextEditor
        value={newNote}
        onChange={setNewNote}
        className="notes__textarea"
      ></RichTextEditor>
      {filesToBeUploaded?.length > 0 && (
        <div className="notes__files">
          {filesToBeUploaded.map((item, key) => (
            <div key={key} className="notes__files-item">
              <div className="notes__files-item-header">
                <BlueClipperIcon />
                {item.name}
              </div>

              {!loading && (
                <RemoveFileIcon
                  onClick={() =>
                    setFilesToBeUploaded((prevFiles) =>
                      prevFiles.filter((value, i) => i !== key)
                    )
                  }
                  className="notes__svg"
                />
              )}
            </div>
          ))}
        </div>
      )}
      <div className="notes__buttons">
        <FileUpload onChange={onFileChange} noWidth>
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            sx={{ lineHeight: { xs: '16px' } }}
            startIcon={<PaperclipIcon />}
            disabled={loading}
          >
            <label htmlFor="file-upload">
              {translate('buttons.attachFile')}
            </label>
          </Button>
        </FileUpload>
        <Button
          disabled={!newNote.getCurrentContent().hasText() || loading}
          onClick={() => onSubmit()}
          startIcon={getStartIconForSubmit()}
        >
          {translate('buttons.save')}
        </Button>
      </div>
    </>
  )
}

AddNewNote.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  getNotes: PropTypes.func.isRequired,
}

export default AddNewNote
