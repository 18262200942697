import React, { useState } from 'react'
import {
  listDealerSettings,
  listLateFees,
  listRevFees,
  updateDealerSettings,
  updateLateFees,
  updateRevFees,
} from 'modules/automated-funding/actions'
import DealerSettings from 'modules/automated-funding/dealer-settings'
import DealerSettingFees from 'modules/automated-funding/dealer-settings/fees'
import { AF_PAGE_SIZE } from 'modules/automated-funding/utils/constants'
import { showNotification } from 'modules/global/actions'
import FundingBreadcrumbs from 'modules/automated-funding/utils/breadcrumbs'
import { useStore } from 'store'
import { components } from '@ElementsCapitalGroup/enium-ui'

const { Tabs } = components

const AFPDealerSettings = () => {
  const { dispatch } = useStore()
  const [dealerProducts, setDealerProducts] = useState({
    list: [],
    totalPages: 0,
  })
  const [revFeeList, setRevFeeList] = useState([])
  const [lateFeeList, setLateFeeList] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchList = (query = {}) => {
    setLoading(true)
    listDealerSettings(query)
      .then((result) => {
        setDealerProducts({
          ...dealerProducts,
          list: result.dealerFundingSettings,
          totalPages: Math.ceil(result.itemCount / AF_PAGE_SIZE),
        })

        fetchLateRevFees(result.dealerFundingSettings)
      })
      .finally(() => setLoading(false))
  }

  const fetchLateRevFees = (dealerFundingSettings) => {
    const dealerIds = [
      ...new Set(dealerFundingSettings.map((el) => el.organizationId)),
    ]
    Promise.all([listRevFees(dealerIds), listLateFees(dealerIds)])
      .then(([revFees, lateFees]) => {
        setRevFeeList(revFees)
        setLateFeeList(lateFees)
      })
      .finally(() => setLoading(false))
  }

  const onUpdate = (data) => {
    setLoading(true)
    return updateDealerSettings(dispatch, data).catch((err) => {
      console.error(err)
      setLoading(false)
    })
  }

  const onUpdateFees = (dealerId, lateFees, revFees) => {
    setLoading(true)
    return Promise.allSettled([
      updateLateFees(dealerId, lateFees),
      updateRevFees(dealerId, revFees),
    ]).then(([resLateFees, resRevFees]) => {
      if (
        resLateFees?.status === 'fulfilled' &&
        resRevFees?.status === 'fulfilled'
      ) {
        showNotification(dispatch, {
          title: 'Dealer fees updated successfully',
          timeout: 3000,
        })
        fetchLateRevFees(dealerProducts.list)
      } else {
        setLoading(false)
      }
    })
  }

  return (
    <div className="admin-page afp">
      <FundingBreadcrumbs
        items={[{ id: 'dealer', label: 'Dealer Settings' }]}
      />
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">Dealer Settings</div>
          <div>View all your dealer settings in one place.</div>
        </div>
      </div>
      <div className="admin-page__content">
        <Tabs
          items={[
            {
              label: 'Products',
              value: 'PRODUCTS',
              children: (
                <DealerSettings
                  list={dealerProducts.list}
                  pageCount={dealerProducts.totalPages}
                  onFetchList={fetchList}
                  onUpdate={onUpdate}
                  loading={loading}
                />
              ),
            },
            {
              label: 'Deposits/Fees',
              value: 'FEES',
              children: (
                <DealerSettingFees
                  list={dealerProducts.list}
                  onFetchList={fetchList}
                  lateFeeList={lateFeeList}
                  revFeeList={revFeeList}
                  onUpdateFees={onUpdateFees}
                  loading={loading}
                />
              ),
            },
          ]}
          panelProps={{
            sx: {
              marginTop: '32px',
              padding: 0,
            },
          }}
        />
      </div>
    </div>
  )
}

export default AFPDealerSettings
