import Api from 'easy-fetch-api'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { mapToClientFormat, mapToServerFormat } from './utils'

/** Get list of templates */
export const fetchTemplate = (decisioningTemplateId) =>
  Api.get({
    url: `/DecisioningTemplate/${decisioningTemplateId}`,
  }).catch(console.error)

/** Get list of Stipulation Definitions */
export const fetchAUSTemplatesGuidAndName = (query = { itemsPerPage: 500 }) =>
  Api.get({
    url: '/DecisioningTemplate/list-name-guid',
    query,
  }).then((res) => res?.decisioningTemplates)

export const fetchDocumentsTemplatesGuidAndName = () =>
  Api.get({ url: '/Envelopes/list/simple' })

/** Get list of Stipulation Definitions */
export const getTemplate = (id) =>
  Api.get({ url: `/DecisioningTemplate/${id}` }).then((res) =>
    mapToClientFormat(res)
  )

/** Get list of Decision Criteria */
export const fetchCriteria = () =>
  Api.get({ url: '/DecisioningTemplate/decision-criterias' }).then((list) =>
    list.map((el) => ({ id: el.id, label: el.friendlyName }))
  )

/** Get list of Decision Comparators */
export const fetchComparators = () =>
  Api.get({ url: '/DecisioningTemplate/decision-comparitors' }).then((list) =>
    list.map((el) => ({ id: el.id, value: el.friendlyName }))
  )

/** Update a Template */
export const createTemplate = (template, dispatch) =>
  Api.post({
    url: `/DecisioningTemplate/create-decisioning-template`,
    data: mapToServerFormat(template),
  })
    .then((response) => {
      showNotification(dispatch, {
        message: `Successfully created aus template ${template.title}`,
      })
      return response
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        message: `An error occurred while creating aus template ${template.title}. Please refresh the page and try again. ${error.title}`,
      })
    )

/** Update a Template */
export const updateTemplate = (template, dispatch) => {
  // Before sending to the server bump the template's version
  template.version++

  const data = mapToServerFormat(template)

  return Api.put({
    url: `/DecisioningTemplate/update-decisioning-template`,
    data,
  })
    .then((response) => {
      showNotification(dispatch, {
        message: `Successfully updated aus template ${template.title}`,
      })
      return response
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        message: `An error occurred while updating aus template ${template.title}. Please refresh the page and try again. ${error.title}`,
      })
    )
}

/** Delete a Template */
export const deleteTemplate = (templateId, dispatch) =>
  Api.delete({
    url: `/DecisioningTemplate/delete-decisioning-template/${templateId}`,
  })
    .then((response) => {
      showNotification(dispatch, {
        message: `Successfully deleted aus template ${response.name}`,
      })
      return response
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        message: `An error occurred while deleting aus template. Please refresh the page and try again. ${error.title}`,
      })
    )
