import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { downloadMultipleAttachments } from 'modules/loan-application/actions'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import Loader from 'components/loader'

import ApplicantDetailsSection from '../common/applicant-details-section'
import { UW_HUB_ACTIONS } from '../actions'
import { renderCell } from '../common/render-cell'

const { createColumnHelper } = components
const { FileSearchIcon } = assets
const columnHelper = createColumnHelper()

const Employment = ({ loanApplicationId, data }) => {
  const { dispatch } = useStore()
  const [downloading, setDownloading] = useState(false)
  const { t: translate } = useTranslation()

  const downloadDocuments = (attachmentsIds) => {
    setDownloading(true)
    downloadMultipleAttachments(
      dispatch,
      loanApplicationId,
      attachmentsIds
    ).finally(() => setDownloading(false))
  }

  const columns = [
    columnHelper.accessor('employer', {
      enableSorting: false,
      cell: (info) => renderCell(info.getValue()),
    }),
    columnHelper.accessor('jobTitle', {
      enableSorting: false,
      cell: (info) => renderCell(info.getValue()),
    }),
    columnHelper.accessor('monthsEmployed', {
      cell: (info) => renderCell(info.getValue()),
      enableSorting: false,
    }),
    columnHelper.accessor('employedStatus', {
      cell: (info) => renderCell(info.getValue()),
      enableSorting: false,
    }),
    columnHelper.accessor('actions', {
      cell: (cell) => {
        const { attachmentsIds } = cell.row.original
        if (!attachmentsIds?.length) {
          return null
        }
        return (
          <Button
            onClick={() => downloadDocuments(attachmentsIds)}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            startIcon={downloading ? <Loader size={14} /> : <FileSearchIcon />}
            disabled={downloading}
            sx={{
              marginRight: '12px',
            }}
          >
            {translate('uwHub.income.previewVOI')}
          </Button>
        )
      },
      enableSorting: false,
    }),
  ]

  return (
    <ApplicantDetailsSection
      titleKey="uwHub.employment.title"
      loanApplicationId={loanApplicationId}
      data={data}
      columns={columns}
      updateApprovalStatusType={UW_HUB_ACTIONS.SET_EMPLOYMENT_DATA}
      loading={downloading}
    />
  )
}

Employment.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default Employment
