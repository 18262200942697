import React from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'

const { Checkbox: EniumUICheckbox } = components

function Checkbox({
  checked,
  onClick,
  label = '',
  disabled,
  size = 'small',
  labelProps,
  formControlLabelProps,
  style = {},
}) {
  return (
    <EniumUICheckbox
      checked={checked}
      onChange={(ev) => onClick(ev.target.checked)}
      size={size}
      label={label}
      disabled={disabled}
      labelProps={labelProps}
      formControlLabelProps={formControlLabelProps}
      sx={style}
    />
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  size: PropTypes.string,
  labelProps: PropTypes.object,
  formControlLabelProps: PropTypes.object,
  style: PropTypes.object,
}

export default Checkbox
