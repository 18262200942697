import React, { useEffect } from 'react'
import AdminWrapper from 'modules/admin'
import PropTypes from 'prop-types'
import RolesSettings from 'modules/admin-roles-management/roles-settings'
import { initRoles } from 'modules/admin-roles-management/actions'
import { useStore } from 'store'

const AdminRolesSettings = ({ isCreate }) => {
  const { state, dispatch } = useStore()

  const { roles, roleTypes, roleClaims } = state.adminRolesManagement

  useEffect(() => {
    if (!roles.length || !roleTypes.length || !roleClaims) {
      initRoles(dispatch)
    }
  }, [])
  return (
    <AdminWrapper>
      <RolesSettings isCreate={isCreate} />
    </AdminWrapper>
  )
}

export default AdminRolesSettings

AdminRolesSettings.propTypes = {
  isCreate: PropTypes.bool,
}
