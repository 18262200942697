import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/button/index'
import { components } from '@ElementsCapitalGroup/enium-ui/'
import { setLoading, showNotification } from 'modules/global/actions'
import { useStore } from 'store'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'

import { copyTokens, getAllEnvelopes, updateDocumentTokens } from '../actions'
import TokenElement from './token-element/index'

import './index.scss'
import '../index.scss'

/**
 * @param {Object} options
 * @param {String} options.className - Custom classnames
 * @param {Array} options.tokenTypes - Tokens Decisioning List
 * @param {Array} options.documents - Array of documents
 */

const { Dialog } = components

const LoanDocumentTokens = ({ className, tokenTypes, documents }) => {
  const [tokensData, setTokensData] = useState([])
  const [tokenModalOpen, setTokenModalOpen] = useState(false)
  const [envelopes, setEnvelopes] = useState([])
  const [selectedEnvelope, setSelectedEnvelope] = useState({})
  const [selectedDocument, setSelectedDocument] = useState({})
  const { dispatch } = useStore()

  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  useEffect(() => {
    getAllEnvelopes().then((res) => setEnvelopes(res.envelopeDefinitions))
  }, [])

  const editStaticText = (token, text) => {
    if (!tokensData.find((tokenData) => tokenData.guid === token.guid)) {
      setTokensData([
        ...tokensData,
        {
          guid: token.guid,
          label: token.key,
          tokenTypeIds: [],
          staticValue: text,
        },
      ])
    } else {
      setTokensData(
        tokensData.map((existing) => {
          if (existing.guid === token.guid) {
            return { ...existing, staticValue: text }
          }
          return existing
        })
      )
    }
  }

  const handleRemoveTokenType = (tokenType, token) => {
    if (!tokenType) {
      return
    }
    if (!tokensData.find((tokenData) => tokenData.guid === token.guid)) {
      setTokensData([
        ...tokensData,
        {
          guid: token.guid,
          tokenTypeIds: token.mapList
            ?.filter((item) => item.id !== tokenType.id)
            .map((item) => item?.id),
          label: token.key,
        },
      ])
    } else {
      setTokensData((tokensData) => {
        return tokensData.map((tokenData) => {
          if (tokenData.guid === token.guid) {
            return {
              ...tokenData,
              tokenTypeIds:
                tokenData.tokenTypeIds.filter((itm) => itm !== tokenType.id) ||
                [],
            }
          }
          return tokenData
        })
      })
    }
  }

  const handleSelectedData = (selected, token) => {
    if (!selected) {
      return
    }

    if (!tokensData.find((tokenData) => tokenData.guid === token.guid)) {
      setTokensData([
        ...tokensData,
        {
          guid: token.guid,
          tokenTypeIds: selected.map((item) => item.id),
          label: token.key,
        },
      ])
    } else {
      setTokensData(
        tokensData.map((tokenData) => {
          if (tokenData.guid === token.guid) {
            return {
              ...tokenData,
              guid: token.guid,
              tokenTypeIds: selected.map((item) => item.id),
              label: token.key,
            }
          }
          return tokenData
        })
      )
    }
  }

  const handleTokenUpdate = (document) => {
    if (tokensData.length > 0) {
      setLoading(dispatch, true)
      const filteredTokensData = tokensData.filter((item) =>
        document.tokens.map((token) => token.guid).includes(item?.guid)
      )

      const dataToBeSent = {
        documentId: document.guid,
        tokenUpdates: filteredTokensData,
      }

      updateDocumentTokens(dataToBeSent)
        .then(() => {
          showNotification(dispatch, {
            title: `Update complete!`,
          })
        })
        .catch(() =>
          showNotification(dispatch, {
            type: NOTIFICATION_TYPES.NEGATIVE,
            title: `Failed to update Document`,
          })
        )
        .finally(() => setLoading(dispatch, false))
    }
  }

  const handleCopyTokens = (documentToBePastedTo) => {
    setLoading(dispatch, true)
    const formattedData = {
      sourceDocumentId: selectedDocument.guid,
      sourceEnvelopeDefinitionId: selectedDocument.envelopeDefinitionId,
      destinationDocumentId: documentToBePastedTo.guid,
      destinationEnvelopeDefinitionId:
        documentToBePastedTo.envelopeDefinitionId,
    }
    copyTokens(formattedData, dispatch)
      .then(() => {
        setSelectedDocument({})
        setSelectedEnvelope({})
        setTokenModalOpen(false)
      })
      .finally(setLoading(dispatch, false))
  }

  return (
    <div className={className}>
      <div className="applicant-info__form-general loan-product__general">
        <Dialog
          title={`Select an envelope`}
          open={tokenModalOpen}
          onClose={() => setTokenModalOpen(false)}
        >
          {envelopes
            .filter((envelope) => envelope?.documents?.length > 0)
            .map((item, key) => (
              <div
                key={key}
                className="loan-document__envelope"
                onClick={() => {
                  setSelectedEnvelope(item)
                  setTokenModalOpen(false)
                }}
              >
                <div>{item.name}</div>
              </div>
            ))}
        </Dialog>
        <Dialog
          title="Select a document"
          open={Object.keys(selectedEnvelope).length > 0}
          onClose={() => setSelectedEnvelope({})}
        >
          {selectedEnvelope?.documents?.map((item, key) => (
            <div
              key={key}
              className="loan-document__envelope"
              onClick={() => {
                handleCopyTokens(item)
              }}
            >
              <div>{item.name}</div>
            </div>
          ))}
        </Dialog>

        {documents.map((item) => (
          <div key={item.guid}>
            <div className="token__header">
              <span className="token__name">{item.name}</span>
              <div className="token__buttons">
                <Button
                  onClick={() => {
                    setTokenModalOpen(true)
                    setSelectedDocument(item)
                  }}
                  sx={{ mr: !isTabletView ? 1 : 0, mt: isTabletView ? 1 : 0 }}
                >
                  Copy Tokens
                </Button>
                <Button
                  onClick={() => handleTokenUpdate(item)}
                  sx={{ mt: isTabletView ? 2 : 0 }}
                >
                  Save Document
                </Button>
              </div>
            </div>
            <div className="token__wrapper">
              <TokenElement
                tokens={item.tokens}
                tokenTypes={tokenTypes.map((item) => {
                  return {
                    id: item.id,
                    label: item?.friendlyName || item?.value,
                  }
                })}
                tokensData={tokensData}
                handleSelectedData={handleSelectedData}
                handleRemoveTokenType={handleRemoveTokenType}
                editStaticText={editStaticText}
                isMobileView={isTabletView}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

LoanDocumentTokens.propTypes = {
  tokenTypes: PropTypes.array.isRequired,
  className: PropTypes.string,
  documents: PropTypes.array,
}

export default LoanDocumentTokens
