import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'orgManagement'

const initialState = {
  organizations: [],
  organizationsCount: 0, // TODO Raul merge this with organizations
  allOrganizations: [], // List of ALL Organizations
  organizationTree: [], // Flat tree of all organizations
  organizationTypes: [],
  organizationStatuses: [],

  corporateNavHistory: [],

  lenders: [],
  ficoVersions: [],
  lenderOrganizations: [],

  userRoles: [],
  userStatuses: [],
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_ALL_ORGANIZATIONS]: (state, { data }) => ({
    ...state,
    allOrganizations: data,
  }),
  [ACTIONS.SET_ORGANIZATIONS_TREE]: (state, { data }) => ({
    ...state,
    organizationTree: data,
  }),
  [ACTIONS.SET_TYPES]: (state, { data }) => ({
    ...state,
    organizationTypes: data,
  }),
  [ACTIONS.SET_STATUSES]: (state, { data }) => ({
    ...state,
    organizationStatuses: data,
  }),
  [ACTIONS.SET_ORGANIZATIONS]: (state, { organizationUnits, itemCount }) => ({
    ...state,
    organizations: organizationUnits,
    organizationsCount: itemCount,
  }),
  [ACTIONS.SET_ORG_NAV_HISTORY]: (state, { data }) => {
    return { ...state, corporateNavHistory: data }
  },
  [ACTIONS.SET_LENDERS]: (state, { data }) => {
    if (!Array.isArray(data)) {
      data = []
    }
    return { ...state, lenders: data }
  },
  [ACTIONS.SET_FICO_VERSIONS]: (state, { ficoVersions }) => {
    if (!Array.isArray(ficoVersions)) {
      ficoVersions = []
    }
    return { ...state, ficoVersions: [...ficoVersions] }
  },
  [ACTIONS.SET_LENDER_ORGANIZATIONS]: (state, { data }) => {
    if (!Array.isArray(data)) {
      data = []
    }
    return {
      ...state,
      lenderOrganizations: data.map((el) => ({
        ...el,
        guid: el.organizationId,
      })),
    }
  },

  [ACTIONS.SET_ROLES]: (state, { data }) => {
    return { ...state, userRoles: data }
  },
}

export default { name, initialState, reduce }
