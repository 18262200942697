import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { fetchStipulationsGuidAndName } from 'modules/stipulation-templates/actions'
import {
  fetchAUSTemplatesGuidAndName,
  fetchDocumentsTemplatesGuidAndName,
} from 'modules/aus-templates/actions'
import Loader from 'components/loader/index'
import { components, assets, colors } from '@ElementsCapitalGroup/enium-ui'
import cx from 'classnames'
import TextField from 'components/input'

import {
  assignDecisioningTemplate,
  assignDocumentTemplate,
  assignStipulationTemplate,
} from '../actions'

const { Dialog, Checkbox, Button } = components
const { SearchLgIcon } = assets
const { grey } = colors

const LoanProductTemplates = ({
  activeTemplate,
  setActiveTemplate,
  loanProduct,
  lenders,
  setNeedsRefetch,
  isViewOnly,
}) => {
  const [selectedTemplates, setSelectedTemplates] = useState({
    name: '',
    id: '',
  })
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')

  const handleSelectTemplates = (itm) => {
    setSelectedTemplates(itm)
  }

  const handleChangeSearch = (value) => {
    if (value?.keyCode) {
      return // ignore if is event
    }

    setSearchValue(value)
  }

  const filteredTemplates = useMemo(() => {
    if (!searchValue || searchValue === '') {
      return templates
    }

    return templates.filter((t) =>
      t.name.toLowerCase().includes(searchValue?.toLowerCase())
    )
  }, [searchValue, activeTemplate, templates])

  const handleSelectedDocumentTemplates = (itm) => {
    if (selectedTemplates?.length > 0) {
      if (selectedTemplates.find((item) => item.guid === itm.guid)) {
        setSelectedTemplates((selectedTemplates) =>
          selectedTemplates.filter((item) => {
            return item.guid !== itm.guid
          })
        )
      } else {
        setSelectedTemplates([...selectedTemplates, itm])
      }
    } else {
      setSelectedTemplates([itm])
    }
  }

  const handleModalClose = () => {
    setActiveTemplate('')
    setSearchValue('')
  }

  useEffect(() => {
    setSelectedTemplates({})
    if (activeTemplate === TEMPLATE_TYPE.STIPULATION_TEMPLATE) {
      fetchStipulationsGuidAndName()
        .then((res) => {
          setTemplates([...res.stipulationTemplates])
          handleSelectedStipulations(res.stipulationTemplates)
        })
        .finally(() => setLoading(false))
    } else if (activeTemplate === TEMPLATE_TYPE.DECISIONING_TEMPLATE) {
      fetchAUSTemplatesGuidAndName()
        .then((res) => {
          setTemplates([...res.decisioningTemplates])
          handleSelectedDecisionings(res.decisioningTemplates)
        })

        .finally(() => setLoading(false))
    } else {
      fetchDocumentsTemplatesGuidAndName()
        .then((res) => {
          setTemplates([
            ...res.envelopeDefinitions.map((item) => {
              return {
                guid: item.envelopeDefinitionId,
                lenderId: item.lenderId,
                name: item.name,
              }
            }),
          ])
          handleSelectedDocuments(res)
        })
        .finally(() => setLoading(false))

        .catch((error) => console.error(error))
    }
  }, [activeTemplate])

  const handleSelectedStipulations = (res) => {
    const selectedStipulations = res.find(
      (item) =>
        item.guid === loanProduct?.stipulationTemplate?.stipulationTemplateId
    )
    if (selectedStipulations) {
      setSelectedTemplates(selectedStipulations)
    }
  }

  const handleSelectedDecisionings = (res) => {
    const selectedDecisionings = res.find(
      (item) =>
        item.guid === loanProduct?.decisioningTemplate?.decisioningTemplateId
    )

    if (selectedDecisionings) {
      setSelectedTemplates(selectedDecisionings)
    }
  }

  const handleSelectedDocuments = () => {
    const selectedDocuments = loanProduct?.envelopeDefinitions?.map((item) => {
      return {
        guid: item.envelopeDefinitionId,
        lenderId: item.lenderId,
        name: item.name,
      }
    })
    if (selectedDocuments) {
      setSelectedTemplates(selectedDocuments)
    }
  }

  const handleSubmit = () => {
    setLoading(true)

    if (activeTemplate === TEMPLATE_TYPE.STIPULATION_TEMPLATE) {
      assignStipulationTemplate({
        loanProductId: loanProduct.loanProductId,
        stipulationTemplateId: selectedTemplates.guid,
      })
        .then(() => setNeedsRefetch(true))
        .finally(() => setLoading(false))
    }

    if (activeTemplate === TEMPLATE_TYPE.DECISIONING_TEMPLATE) {
      assignDecisioningTemplate({
        loanProductId: loanProduct.loanProductId,
        decisioningTemplateId: selectedTemplates.guid,
      })
        .then(() => setNeedsRefetch(true))
        .finally(() => setLoading(false))
    }

    if (activeTemplate === TEMPLATE_TYPE.DOCUMENT_TEMPLATE) {
      assignDocumentTemplate({
        loanProductId: loanProduct.loanProductId,
        envelopeDefinitionIds: selectedTemplates.map(
          (item) => item.guid || item.envelopeDefinitionId
        ),
      })
        .then(() => setNeedsRefetch(true))
        .finally(() => setLoading(false))
    }
    setActiveTemplate('')
  }

  const _renderAusTemplates = (lender, key) => {
    if (filteredTemplates.find((itm) => lender.guid === itm.lenderId)) {
      return (
        <React.Fragment key={key}>
          <div className="loan-product__templates-lenders">{lender.name}</div>
          {_handleAusTemplatesMapping(lender)}
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  const _handleAusTemplatesMapping = (lender) => {
    return filteredTemplates.map((itm, idx) => {
      if (itm.lenderId === lender.guid) {
        return (
          <div
            key={idx}
            className={cx({
              'loan-product__templates-items': true,
              'loan-product__templates-items--checked':
                selectedTemplates?.guid === itm.guid,
            })}
          >
            <Checkbox
              checked={selectedTemplates?.guid === itm.guid}
              onClick={() => handleSelectTemplates(itm)}
              label={itm.name}
            />
          </div>
        )
      } else {
        return null
      }
    })
  }

  const _renderDocumentTemplates = (lender, key) => {
    if (filteredTemplates.find((itm) => lender.guid === itm.lenderId)) {
      return (
        <React.Fragment key={key}>
          <div className="loan-product__templates-lenders">{lender.name}</div>
          {_handleDocumentMapping(lender)}
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  const _handleDocumentMapping = (lender) => {
    return filteredTemplates.map((itm, idx) => {
      if (itm.lenderId === lender.guid) {
        return (
          <div
            key={idx}
            className={cx('loan-product__templates-items', {
              'loan-product__templates-items--checked':
                selectedTemplates.length > 0 &&
                selectedTemplates.some(
                  (selectedItem) => selectedItem.guid === itm.guid
                ),
            })}
          >
            <Checkbox
              checked={
                selectedTemplates.length > 0 &&
                selectedTemplates?.some(
                  (selectedItem) => selectedItem.guid === itm.guid
                )
              }
              sx={{ marginLeft: 2 }}
              label={itm.name}
              onClick={() => handleSelectedDocumentTemplates(itm)}
            />
          </div>
        )
      } else {
        return null
      }
    })
  }

  return (
    <>
      <Dialog
        title={`Select ${activeTemplate}`}
        open={activeTemplate.length > 0}
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            '& .MuiDialogContent-root': {
              '&::-webkit-scrollbar': {
                display: 'none' /* Hide scrollbar for Webkit browsers */,
              },
              '-ms-overflow-style': 'none' /* Hide scrollbar for IE and Edge */,
              scrollbarWidth: 'none' /* Hide scrollbar for Firefox */,
            },
          },
        }}
      >
        {activeTemplate !== TEMPLATE_TYPE.STIPULATION_TEMPLATE && (
          <div className="loan-product__searchbar--edit">
            <TextField
              placeholder="Search templates"
              onChange={(value) => handleChangeSearch(value)}
              fullWidth
              startIcon={<SearchLgIcon className="loan-product__icon" />}
            />
          </div>
        )}
        <div
          className={cx({
            'loan-product__modal': !isViewOnly,
            disabled: isViewOnly,
          })}
        >
          {!loading ? (
            activeTemplate === 'AUS Template' ? (
              lenders.map((lender, key) => {
                return _renderAusTemplates(lender, key)
              })
            ) : activeTemplate === 'Document Template' ? (
              lenders.map((lender, key) => {
                return _renderDocumentTemplates(lender, key)
              })
            ) : (
              templates.map((itm, idx) => (
                <div
                  key={idx}
                  className={
                    selectedTemplates?.guid !== itm.guid
                      ? 'loan-product__templates-items'
                      : 'loan-product__templates-items loan-product__templates-items--checked'
                  }
                >
                  <Checkbox
                    checked={selectedTemplates?.guid === itm.guid}
                    onClick={() => handleSelectTemplates(itm)}
                    label={itm.name}
                  />
                </div>
              ))
            )
          ) : (
            <Loader center />
          )}
        </div>
        <div className="loan-product__modal-buttons">
          <Button
            sx={{
              background: 'white',
              color: 'black',
              mr: 2,
              mb: 2,
              border: `1px solid ${grey[300]}`,
              '&:hover': {
                background: 'white',
              },
            }}
            fullWidth
            onClick={() => handleModalClose()}
          >
            Cancel
          </Button>
          <Button fullWidth onClick={() => handleSubmit()}>
            Save
          </Button>
        </div>
      </Dialog>
    </>
  )
}

LoanProductTemplates.propTypes = {
  activeTemplate: PropTypes.string,
  setActiveTemplate: PropTypes.func,
  loanProduct: PropTypes.object,
  lenders: PropTypes.array,
  setNeedsRefetch: PropTypes.func,
  isViewOnly: PropTypes.bool,
}

const TEMPLATE_TYPE = {
  STIPULATION_TEMPLATE: 'Stipulation Template',
  DECISIONING_TEMPLATE: 'AUS Template',
  DOCUMENT_TEMPLATE: 'Document Template',
}

export default LoanProductTemplates
