import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/button'
import { VALIDATION_TYPES, validate } from 'components/validator'
import { setLoading } from 'modules/global/actions'
import { useStore } from 'store'
import { v4 as uuidv4 } from 'uuid'

import PaymentDetailsList from './payment-details-list'

import {
  updatePaymentDetails,
  fetchFinancingPrincipalTypes,
  fetchFinancingInterestTypes,
} from '../actions'

const FinancialDetails = ({ loanProductDetails, setLoanProductDetails }) => {
  const { dispatch } = useStore()

  const [financingPrincipleTypes, setFinancingPrincipalTypes] = useState([])
  const [financingInterestTypes, setFinancingInterestTypes] = useState([])

  useEffect(() => {
    fetchFinancingPrincipalTypes().then(setFinancingPrincipalTypes)
    fetchFinancingInterestTypes().then(setFinancingInterestTypes)
  }, [])

  // If payment period details values are valid, make the call to update the loan product
  const handleUpdatePayments = () => {
    const [isValid, errors] = validatePaymentDetails(
      loanProductDetails.paymentPeriodsDetails
    )

    if (!isValid) {
      setLoanProductDetails({
        ...loanProductDetails,
        errors,
      })
    } else {
      setLoading(dispatch, true)
      updatePaymentDetails(
        {
          loanProductId: loanProductDetails.loanProductId,
          paymentPeriodsDetails: loanProductDetails.paymentPeriodsDetails,
        },
        dispatch
      )
        .then(() =>
          setLoanProductDetails({ ...loanProductDetails, errors: [] })
        )
        .finally(() => setLoading(dispatch, false))
    }
  }

  /** Validate template handler */
  const validatePaymentDetails = () => {
    let [isValid, errors] = [
      true,
      loanProductDetails.paymentPeriodsDetails.map(() => ({})),
    ]
    loanProductDetails.paymentPeriodsDetails.forEach((elem, idx) => {
      const [innerValid, innerErrors] = validate(
        PAYMENT_DETAILS_ERROR_MAP,
        elem
      )
      if (!innerValid) {
        isValid = false
        errors[idx] = innerErrors
      }
    })
    return [isValid, errors]
  }

  if (!loanProductDetails.paymentPeriodsDetails.length) {
    return
  }

  return (
    <div className="admin-template__decisions">
      <PaymentDetailsList
        financingPrincipleTypes={financingPrincipleTypes}
        financingInterestTypes={financingInterestTypes}
        list={loanProductDetails.paymentPeriodsDetails}
        onListChange={(paymentPeriodsDetails) => {
          setLoanProductDetails((prevState) => ({
            ...prevState,
            paymentPeriodsDetails,
          }))
        }}
        parentId={loanProductDetails.loanProductId}
        errors={loanProductDetails.errors || []}
      />

      <div style={{ textAlign: 'end' }}>
        <Button onClick={handleUpdatePayments}>Save Financial Details</Button>
      </div>
    </div>
  )
}

FinancialDetails.propTypes = {
  loanProductDetails: PropTypes.object.isRequired,
  setLoanProductDetails: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
}

const PAYMENT_DETAILS_ERROR_MAP = {
  months: VALIDATION_TYPES.POSITIVE_SAFE_INTEGER,
  order: VALIDATION_TYPES.POSITIVE_SAFE_INTEGER,
  amount: VALIDATION_TYPES.REQUIRED,
  interestAPR: VALIDATION_TYPES.REQUIRED,
  interestRate: VALIDATION_TYPES.REQUIRED,
  interestTypeId: VALIDATION_TYPES.REQUIRED,
  principalTypeId: VALIDATION_TYPES.REQUIRED,
}

export default FinancialDetails

export function generateMockedPayment() {
  return {
    months: 0,
    order: 0,
    principalType: 0,
    interestType: 0,
    interestRate: 0,
    interestAPR: 0,
    amount: 0,
    id: uuidv4(),
  }
}
