import React from 'react'
import PropTypes from 'prop-types'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import StatusComponent from 'components/status'
import { ExpandableCard } from 'components/expandable-card'
import { STATUS_ID_MAPPING } from '../../constants'
import { styles } from '../style'

const { Avatar, IconButton } = components
const { Edit01Icon, TrashIcon } = assets

const OrgDetailsCard = ({ orgData, onDeleteSelf, onEdit, canDeleteOrg }) => {
  return (
    <ExpandableCard
      title={
        <div style={styles.organizations.card.header}>
          <div>Organization Name</div>
          <div>Organization Type</div>
          <div>Users</div>
          <div>Sub Organizations</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Status
            <div style={{ marginLeft: 'auto' }}>
              <IconButton size="small" onClick={onEdit}>
                <Edit01Icon fontSize="small" />
              </IconButton>
              {canDeleteOrg && (
                <IconButton
                  size="small"
                  onClick={() => onDeleteSelf(orgData.guid)}
                >
                  <TrashIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      }
      items={[
        <div key={0} style={styles.organizations.card.header}>
          <div style={styles.organizations.card.avatarWrapper}>
            <Avatar style={styles.organizations.card.avatar}>
              {orgData.name}
            </Avatar>
            {orgData.name}
          </div>
          <div style={styles.organizations.card.rowCell}>
            {orgData.type.friendlyName}
          </div>
          <div style={styles.organizations.card.rowCell}>
            {orgData.userCount}
          </div>
          <div style={styles.organizations.card.rowCell}>
            {orgData.children?.length}
          </div>
          <div style={styles.organizations.card.rowCell}>
            <StatusComponent
              status={STATUS_ID_MAPPING[orgData.status.id]}
              label={orgData.status.friendlyName}
            />
          </div>
        </div>,
      ]}
    />
  )
}

OrgDetailsCard.propTypes = {
  orgData: PropTypes.object.isRequired,
  onDeleteSelf: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  canDeleteOrg: PropTypes.bool,
}

export default OrgDetailsCard
