import { colors } from '@ElementsCapitalGroup/enium-ui'

export const styles = {
  incomePage: { background: colors.grey[50] },
  title: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  section: {
    padding: '24px',
  },
  sectionLeft: {
    width: '72%',
  },
  sectionRight: {
    width: 'calc(28% - 24px)',
    marginLeft: '24px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: '15px',
  },
  hr: {
    margin: 0,
    backgroundColor: colors.grey[200],
    border: 0,
    height: '1px',
  },
  card: {
    marginTop: '16px',
    boxShadow: 'none',
    '& .MuiCardHeader-title': {
      fontSize: '1.125rem',
    },
    overflow: 'visible',
  },
  titleStyle: {
    sx: {
      flexDirection: 'row-reverse',
      '& .MuiStack-root': {
        width: '100%',
      },
      borderBottom: `1px solid ${colors.grey[200]}`,
    },
  },
  expandableCard: {
    header: {
      background: 'fff',
      '& .title': {
        fontSize: '1.125rem',
        fontWeight: 600,
        color: colors.grey[900],
      },
      '& .title--inline': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .description': {
        fontSize: '0.875rem',
        color: colors.grey[600],
        fontWeight: 400,
        marginTop: '4px',
        lineHeight: 1.4,
      },
      '& .MuiCardHeader-action': {
        marginTop: '7px',
      },
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 2fr 2fr 1fr',
      gridColumnGap: '16px',
      marginTop: '24px',
    },
    footer: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: colors.grey[900],
      textAlign: 'right',
      paddingBottom: '12px',
    },
    monthly: {
      fontSize: '0.875rem',
      color: colors.grey[500],
      marginTop: '24px',
      textAlign: 'center',
    },
  },

  tabs: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  tab: {
    fontSize: '1rem',
    fontWeight: 600,
    background: colors.grey[50],
    color: colors.grey[500],
    padding: '10px 12px',
    borderRadius: '6px',
    marginRight: '8px',
  },
  selectedTab: {
    background: colors.blue[50],
    color: colors.blue[500],
  },
  plusTab: {
    borderRadius: '8px',
    border: `1px solid ${colors.grey[200]}`,
    width: '44px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  plusTabSvg: {
    width: '20px',
    height: '20px',
  },
  paperProps: {
    sx: {
      width: '100vw',
      height: '100vh',
      margin: 0,
      borderRadius: 0,
      maxWidth: 'unset',
      maxHeight: 'unset',
      '& .MuiDialogContent-root': {
        padding: 0,
      },
    },
  },
  chart: {
    bg: {
      position: 'sticky',
      top: 0,
      backgroundColor: colors.grey[100],
      border: `1px solid ${colors.grey[200]}`,
      padding: '48px 24px',
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginTop: '60px',
    },
    label: {
      color: colors.grey[600],
      fontSize: '1.125rem',
      textAlign: 'center',
      marginTop: '40px',
      fontWeight: 500,
      width: '100%',
    },
    total: {
      color: colors.grey[900],
      fontSize: '2.25rem',
      fontWeight: 700,
      textAlign: 'center',
      marginTop: '12px',
      width: '100%',
    },
    legend: {
      marginTop: '24px',
      textAlign: 'left',
      width: '100%',
      padding: '0 24px',
      fontSize: '0.875rem',
    },
    legendLi: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    legendTotal: {
      marginLeft: 'auto',
      fontWeight: 600,
    },
    bullet: {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      marginRight: '6px',
    },
  },
  selfEmployed: {
    years: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      gap: '24px',
      padding: '24px 0',
    },
    yearsLabel: { fontWeight: 600, color: colors.grey[900] },
    row: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      color: colors.grey[900],
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    rowLabel: {
      paddingTop: '30px',
    },
    rowInputs: {
      marginLeft: 'auto',
      marginRight: '44px',
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
    },
    section: {
      width: '100%',
    },
    sectionHr: {
      border: 0,
      borderBottom: `1px solid ${colors.grey[300]}`,
      height: 0,
      margin: '24px 0',
    },
    sectionTitle: {
      color: colors.grey[900],
      fontWeight: 600,
      fontSize: '1.125rem',
      display: 'flex',
    },
    sectionSummary: {
      margin: '12px 0 36px',
      borderBottom: `1px solid ${colors.grey[300]}`,
    },
    sectionDescription: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: colors.grey[600],
      marginTop: '4px',
    },
    addMore: {
      marginLeft: 'auto',
      display: 'flex',
    },
    addMoreItem: {
      background: 'white',
      marginRight: '10px',
    },
    tooltip: {
      height: '20px',
      width: '20px',
      marginLeft: '10px',
      paddingTop: '15px',
    },
    svg: { width: '20px', height: '20px', color: colors.grey[500] },
    subTotal: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      fontWeight: 600,
      color: colors.grey[900],
      padding: '0 44px 12px',
      gap: '20px',
      fontSize: '1rem',
    },
    rowReadOnly: {
      width: '214px',
      paddingTop: '30px',
    },
    summaryRow: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      padding: '0px 24px',
      margin: '24px 0',
      fontSize: '0.875rem',
      fontWeight: 500,
      width: '330px',
      minWidth: '330px',
      boxSizing: 'border-box',
    },
    summaryRowBorder: {
      borderLeft: `1px solid ${colors.grey[300]}`,
    },
    summaryRowTotal: {
      width: '280px',
      minWidth: '280px',
    },
    summaryRowTitle: {
      flexGrow: 1,
    },
    summaryCell: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
    },
    summaryCellTitle: {
      color: colors.grey[500],
    },
    headerRow: {
      fontSize: '1.125rem',
      fontWeight: 600,
      color: colors.grey[900],
      margin: '36px 0 24px',
    },
  },
}
