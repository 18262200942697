import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { transformHistoryDataForTimeline } from 'components/history-tracking/utils'
import Loader from 'components/loader'

import {
  getEmailHistory,
  getLoanAppHistory,
} from 'modules/loan-application/actions'

import './index.scss'

const { Timeline } = components

const { ClockRewindIcon, MailIcon } = assets

const HistoryECG = ({ loanApplicationId, isEmailHistory }) => {
  const [history, setHistory] = useState([])
  const [loading, setLoading] = useState(false)
  const { t: translate } = useTranslation()
  const getHistory = (getHistoryFromServer) => {
    setLoading(true)

    getHistoryFromServer(loanApplicationId)
      .then((res) => {
        setHistory(transformHistoryDataForTimeline(res))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getHistory(isEmailHistory ? getEmailHistory : getLoanAppHistory)
  }, [isEmailHistory])

  return (
    <>
      {!isEmailHistory ? (
        <div className="history-ecg__title">
          <ClockRewindIcon sx={{ mr: 1 }} />
          {translate('loanApplication.ecgToolbox.sidebar.historyTracking')}
        </div>
      ) : (
        <div className="history-ecg__title">
          <MailIcon sx={{ mr: 1 }} />
          {translate('loanApplication.ecgToolbox.sidebar.emailHistory')}
        </div>
      )}
      {loading && <Loader center />}
      <div className="history-ecg__wrapper">
        <Timeline logs={history} />
      </div>
    </>
  )
}

HistoryECG.propTypes = {
  loanApplicationId: PropTypes.any.isRequired,
  isEmailHistory: PropTypes.bool,
}

export default HistoryECG
