import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { geocodeByAddress } from 'react-places-autocomplete'
import { ADDRESS_TYPES } from 'common/constants'
import SearchBar from 'components/search-bar'

const LocationSearchInput = ({
  defaultValue,
  callback,
  label,
  errorMessage,
  disabled,
  onSearchInputChange,
}) => {
  const googleAutocomplete = useRef()
  useEffect(() => {
    googleAutocomplete.current =
      new window.google.maps.places.AutocompleteService()
  }, [])
  const [value, setValue] = useState()
  const [places, setPlaces] = useState([])

  useEffect(() => {
    defaultValue && defaultValue !== value && setValue(defaultValue)
  }, [defaultValue])

  const handleQueryPlacesPredictions = (input) => {
    if (input) {
      googleAutocomplete.current?.getQueryPredictions(
        {
          input,
        },
        (predictions) => {
          if (predictions) {
            setPlaces(
              predictions.map((prediction) => ({
                ...prediction,
                guid: prediction.place_id,
                name: prediction.description,
              }))
            )
          } else {
            setPlaces([])
          }
        }
      )
    } else {
      setPlaces([])
    }

    onSearchInputChange && onSearchInputChange(input)
  }

  const handleOnSelect = (address) => {
    geocodeByAddress(address.description)
      .then((res) => {
        callback(mapAddress(res))
      })
      .catch(console.error)
  }

  return (
    <SearchBar
      fullWidth={true}
      label={label}
      disabled={disabled}
      error={errorMessage}
      initialValue={value}
      placeholder="Street Name"
      searchData={places}
      onSearchInputChange={handleQueryPlacesPredictions}
      manualSearchInputValue={value || ''}
      onChange={(value) => {
        if (value) {
          handleOnSelect(value)
        }
      }}
    />
  )
}

LocationSearchInput.propTypes = {
  defaultValue: PropTypes.string,
  callback: PropTypes.func.isRequired,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  onSearchInputChange: PropTypes.func,
}

export default LocationSearchInput

/** Map an address from the ReactPlaces' structure to ours */
function mapAddress(res) {
  const components = res?.[0]?.address_components

  const addressObj = {}
  let streetNumber = ''
  let route = ''

  if (!components || !components.length) {
    return null
  }

  components.forEach((item) => {
    const types = item.types
    if (
      types.includes(ADDRESS_TYPES.LOCALITY) ||
      types.includes(ADDRESS_TYPES.SUBLOCALITY)
    ) {
      addressObj.city = item.short_name
    }
    if (types.includes(ADDRESS_TYPES.COUNTRY)) {
      addressObj.country = item.short_name
    }
    if (types.includes(ADDRESS_TYPES.POSTAL_CODE)) {
      addressObj.zipCode = item.short_name
    }
    if (types.includes(ADDRESS_TYPES.STREET_NUMBER)) {
      streetNumber = item.short_name
    }
    if (types.includes(ADDRESS_TYPES.ROUTE)) {
      route = item.short_name
    }
    if (types.includes(ADDRESS_TYPES.ADMINISTRATIVE_AREA_LEVEL_1)) {
      addressObj.state = item.short_name
    }
    if (types.includes(ADDRESS_TYPES.ADMINISTRATIVE_AREA_LEVEL_2)) {
      addressObj.county = item.short_name
    }
    addressObj.addressFirstLine = `${streetNumber || ''} ${route || ''}`.trim()
  })

  return addressObj
}
