import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'

const { Button: EniumUIButton } = components

export const BUTTON_COLORS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ERROR: 'error',
  INHERIT: 'inherit',
  SUCCESS: 'success',
  WARNING: 'warning',
}

export const BUTTON_VARIANTS = {
  OUTLINED: 'outlined',
  CONTAINED: 'contained',
  TEXT: 'text',
}

export const BUTTON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
}

const Button = forwardRef(
  (
    {
      onClick,
      disabled,
      loading,
      children,
      className,
      color = BUTTON_COLORS.PRIMARY,
      variant = BUTTON_VARIANTS.CONTAINED,
      size = BUTTON_SIZES.MEDIUM,
      fullWidth,
      ...rest
    },
    ref
  ) => {
    return (
      <EniumUIButton
        ref={ref}
        fullWidth={fullWidth}
        className={className}
        color={color}
        variant={variant}
        disabled={!!disabled || loading}
        onClick={onClick}
        disableElevation={color === BUTTON_COLORS.SECONDARY}
        size={size}
        {...rest}
      >
        {children}
      </EniumUIButton>
    )
  }
)

Button.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.oneOf(Object.values(BUTTON_COLORS)),
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
}

export default Button
