/**
 * Attaches a sortBy filter to existing filters
 * @private
 */
export const attachSortBy = (filters, sortByFilters) => {
  const rawFilters = { ...filters }
  rawFilters.sortBy = []

  sortByFilters.forEach((toSort) => {
    if (toSort.value) {
      rawFilters.sortBy.push(`${toSort.key} ${toSort.value}`)
    }
  })

  return { ...rawFilters }
}
