import { colors, theme } from '@ElementsCapitalGroup/enium-ui'

const {
  lightTheme: { typography },
} = theme

const loanDocuments = {
  width: '100%',
}

const headerWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '8px',
  marginBottom: '16px',
  flexWrap: 'wrap',
}

const header = {
  fontSize: typography.h4.fontSize,
  fontWeight: typography.fontWeightBold,
  color: colors.grey[900],
}

const docsLoadingWrapper = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '20px',
}

const headerMobile = {
  ...header,
  fontSize: typography.h5.fontSize,
}

const subHeader = {
  fontSize: typography.body2.fontSize,
  color: colors.grey[600],
}

const subHeaderMobile = {
  ...subHeader,
  fontSize: typography.body2.fontSize,
}

const cardTitle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
}

const cardButtons = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '4px',
}

const cardButtonsDocs = {
  ...cardButtons,
  flexWrap: 'wrap',
}

export const styles = {
  loanDocuments,
  headerWrapper,
  header,
  subHeader,
  headerMobile,
  subHeaderMobile,
  cardTitle,
  cardButtons,
  cardButtonsDocs,
  docsLoadingWrapper,
}
