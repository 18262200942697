import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'

import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'
import { ExpandableCard } from 'components/expandable-card'

import './index.scss'

const { DataTable, Card } = components

const CollapsibleSection = ({ title, columns, data }) => {
  const { t: translate } = useTranslation()
  const isDesktopView = useMediaQuery(`(min-width:${DESKTOP_BREAKPOINT}px)`)

  const getElementValue = (formatFunction, accessor, dataItem) => {
    const value = formatFunction
      ? formatFunction(dataItem[accessor])
      : dataItem[accessor]

    return value ?? '-'
  }

  const translatedColumns = columns.map((column) => ({
    ...column,
    header: column.textKey ? translate(column.textKey) : column.header,
  }))

  const formatValuesForMobile = (columns, data) => {
    return data?.map((dataItem) => {
      return {
        source: dataItem.source,
        data: columns.map((column) => {
          return {
            header: column.header,
            textKey: column.textKey,
            value: getElementValue(
              column.formatFunction,
              column.accessorKey,
              dataItem
            ),
          }
        }),
      }
    })
  }

  const collapsibleItems = useMemo(() => {
    const valuesForMobileView = formatValuesForMobile(columns, data)

    return valuesForMobileView.map((item, key) => (
      <div key={key} style={{ width: '100%' }}>
        {item.source && (
          <div className="collapsible__content-row">{item.source}</div>
        )}
        <div className="collapsible__content-flex">
          {item.data.map((element, key) => (
            <span key={key} className={`collapsible__content-item`}>
              {element.textKey ? translate(element.textKey) : element.header}:
              <span className="collapsible__content-item--bold">
                {element.value ?? '-'}
              </span>
            </span>
          ))}
        </div>
      </div>
    ))
  }, [JSON.stringify(columns), JSON.stringify(data)])

  return (
    <div className="collapsible">
      {isDesktopView ? (
        <Card
          sx={{ boxShadow: 'none', border: 0 }}
          contentProps={{
            sx: {
              padding: 0,
              '&:last-child': {
                paddingBottom: 0,
              },
            },
          }}
        >
          <DataTable
            columns={translatedColumns}
            data={data}
            selectable={false}
            paginated={false}
            sortable={false}
            hideColumnsVisibilityButton={true}
            hideEditButton={true}
            hideRemoveButton={true}
          />
        </Card>
      ) : (
        <>
          <ExpandableCard
            headerProps={{
              sx: {
                backgroundColor: '#ffffff',
                padding: !isDesktopView ? '16px' : '24px',
              },
            }}
            titleTypographyProps={{
              sx: {
                fontSize: '0.875rem',
                fontWeight: '700',
              },
            }}
            title={title}
            collapsibleItems={collapsibleItems}
            isCollapsable={true}
            isCollapsed={false}
            alwaysCollapsible={true}
            collapsibleItemsStyles={{
              sx: {
                padding: !isDesktopView ? 0 : '24px',
              },
            }}
            items={[]}
          />
        </>
      )}
    </div>
  )
}

CollapsibleSection.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}

export default CollapsibleSection
