import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/loader'
import cx from 'classnames'
import { useEffectOnUpdate } from 'common/hooks'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import TextField from 'components/input'
import { fetchAUSTemplatesGuidAndName } from './actions'

const { Dialog, Button, Checkbox } = components
const { SearchLgIcon } = assets

const CopyDecisionsModal = ({ isOpen, onClose, onCopy }) => {
  const [search, setSearch] = useState('')
  const [templates, setTemplates] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  /** On Mount, fetch the list of all AUS templates */
  useEffect(() => {
    if (!templates) {
      fetchAUSTemplatesGuidAndName().then((list) =>
        setTemplates(list.map((el) => ({ id: el.guid, title: el.name })))
      )
    }
  }, [])

  // Filter the templates and memoize the filtered value
  const filteredList = useMemo(
    () =>
      templates?.filter((el) =>
        el.title.toLowerCase().includes(search.toLowerCase())
      ),
    [search, templates]
  )

  /** When the Modal is closed by the parent, reset the state */
  useEffectOnUpdate(() => {
    if (!isOpen) {
      setLoading(false)
      setSearch('')
      setSelectedTemplate(null)
    }
  }, [isOpen])

  /** Method to manually close the modal and reset the states */
  const closeModal = () => {
    onClose()
    setSearch('')
    setSelectedTemplate(null)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      title={'Select Template to Copy From'}
      PaperProps={{
        sx: {
          width: '400px',
          minHeight: '642px',
          '& .MuiDialogContent-root': {
            '&::-webkit-scrollbar': {
              display: 'none' /* Hide scrollbar for Webkit browsers */,
            },
            '-ms-overflow-style': 'none' /* Hide scrollbar for IE and Edge */,
            scrollbarWidth: 'none' /* Hide scrollbar for Firefox */,
          },
        },
      }}
    >
      <TextField
        placeholder="Search templates"
        value={search}
        onChange={(value) => setSearch(value)}
        fullWidth
        css={{ marginTop: 8 }}
        startIcon={
          <SearchLgIcon style={{ cursor: 'pointer', marginRight: '8px' }} />
        }
      />

      <div className="admin-template__copy-list">
        {filteredList ? (
          filteredList.map((template) => (
            <div
              key={template.id}
              className={cx('loan-product__templates-items', {
                'loan-product__templates-items--checked':
                  selectedTemplate?.id === template.id,
              })}
            >
              <Checkbox
                key={template.id}
                checked={selectedTemplate?.id === template.id}
                sx={{ pl: 0, ml: 1 }}
                label={template.title}
                onClick={() => setSelectedTemplate(template)}
              />
            </div>
          ))
        ) : (
          <Loader size={100} />
        )}
      </div>
      <div className="admin-template__copy-list-buttons">
        <Button
          variant="outlined"
          onClick={closeModal}
          fullWidth
          sx={{ mr: 2 }}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedTemplate}
          onClick={() => {
            onCopy(selectedTemplate)
            setLoading(true)
          }}
          loading={loading}
          fullWidth
        >
          Copy
        </Button>
      </div>
    </Dialog>
  )
}

CopyDecisionsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
}

export default CopyDecisionsModal
