import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import TextField, { INPUT_TYPES } from 'components/input'
import TextArea from 'components/textarea'
import { colors } from '@ElementsCapitalGroup/enium-ui'
import { dateFormatServer } from 'common/constants'

const ExpandedRowDealerSettings = ({ data, onSave, onCancel }) => {
  const expandedRef = useRef(null)
  const { loanCode, organizationName } = data
  const [state, setState] = useState({
    dealerFee: '',
    effectiveDate: '',
    productDescription: '',
    lateFees: [],
  })

  useEffect(() => {
    setState({
      ...data,
      effectiveDate: moment(data.effectiveDate).format(dateFormatServer),
    })
  }, [data])

  const updateField = (name) => (value) => setState({ ...state, [name]: value })

  return (
    <div className="afp__expanded" ref={expandedRef}>
      <div className="afp__expanded__header">
        <div className="afp__expanded__title">
          <p>{loanCode}</p>
          <span
            style={{
              fontSize: '0.875rem',
              color: colors.grey[600],
              fontWeight: 400,
            }}
          >
            {organizationName}
          </span>
        </div>
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          color={BUTTON_COLORS.INHERIT}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button onClick={() => onSave(state)}>Save Changes</Button>
      </div>
      <div className="afp__grid-inputs">
        <TextField
          label="Dealer Fee"
          value={state.dealerFee}
          onChange={updateField('dealerFee')}
          type="number"
        />
        <TextField
          label="Effective Date"
          value={state.effectiveDate}
          onChange={updateField('effectiveDate')}
          type={INPUT_TYPES.DATE}
        />
        <div></div>
        <div style={{ gridColumn: '1 / -1' }}>
          <TextArea
            fullWidth={true}
            label="Product Description"
            value={state.productDescription}
            onChange={updateField('productDescription')}
            resize="none"
          />
        </div>
        <div></div>
      </div>
    </div>
  )
}

ExpandedRowDealerSettings.propTypes = {
  data: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ExpandedRowDealerSettings
