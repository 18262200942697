import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { components, colors } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import TextArea from 'components/textarea'

const { Dialog } = components

const AFPApprovalDeclineModal = ({
  apps,
  isDeclined,
  onClose,
  onDecline,
  onRemoveReason,
}) => {
  const [text, setText] = useState('')
  if (!apps?.length) {
    return null
  }

  const viewDeclineReason = isDeclined && apps.length === 1

  const onModalClose = () => {
    setText('')
    onClose()
  }

  return (
    <Dialog
      title={
        viewDeclineReason ? 'Decline Reason' : `Decline ${apps.length} apps`
      }
      open={true}
      onClose={onModalClose}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '400px',
        },
      }}
      actions={
        <>
          <Button
            onClick={onModalClose}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
          >
            Cancel
          </Button>
          {viewDeclineReason ? (
            <Button
              onClick={() => {
                onRemoveReason(apps[0].dailyFundingLoanApplicationId)
                onModalClose()
              }}
            >
              Remove Override
            </Button>
          ) : (
            <Button
              onClick={() => {
                onDecline(
                  apps.map((app) => app.dailyFundingLoanApplicationId),
                  text
                )
                onModalClose()
              }}
              disabled={!text.trim().length}
            >
              Submit
            </Button>
          )}
        </>
      }
    >
      {isDeclined && apps.length === 1 ? (
        <>
          <div
            style={{
              color: colors.grey[600],
              fontSize: '0.875rem',
              marginTop: '-5px',
            }}
          >
            Please see below the reason this funding was declined.
          </div>
          <div
            style={{
              color: colors.grey[700],
              fontWeight: 500,
              fontSize: '0.875rem',
              margin: '24px 0 4px',
            }}
          >
            Description:
          </div>
          <div
            style={{
              color: colors.grey[900],
              fontSize: '1rem',
              lineHeight: 1.5,
            }}
            dangerouslySetInnerHTML={{
              __html: apps[0].reason?.replaceAll('\n', '<br/>'),
            }}
          ></div>
        </>
      ) : (
        <TextArea
          label="Description"
          rows={10}
          fullWidth={true}
          resize="none"
          onChange={setText}
          value={text}
        />
      )}
    </Dialog>
  )
}

AFPApprovalDeclineModal.propTypes = {
  apps: PropTypes.array,
  isDeclined: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onRemoveReason: PropTypes.func.isRequired,
}

export default AFPApprovalDeclineModal
