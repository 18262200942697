import React, { useEffect, useState } from 'react'
import Loader from 'components/loader'
import TextField, { INPUT_TYPES } from 'components/input'
import ProgressBar from 'components/progress-bar'
import Button, { BUTTON_COLORS } from 'components/button'
import DropdownMultiple from 'components/dropdown-multi-select'
import Dropdown from 'components/dropdown'
import SearchBar from 'components/search-bar'
import { showNotification } from 'modules/global/actions'
import { DateRangePickerComponent } from 'components/date-range-picker'
import InputDate from 'components/input-date'
import Notes from 'modules/loan-application/ecg-toolbox/notes/index'
import { loadGlobalTranslations } from 'modules/translations'
import { useStore } from 'store'
import './style.scss'

const PlaygroundModule = () => {
  const [loading, setLoading] = useState(true)
  const { state, dispatch } = useStore()
  const [value, setValue] = useState('')
  const [date, setDate] = useState('')
  const [sensitiveValue, setSensitiveValue] = useState('')
  const [showNotes, setShowNotes] = useState(false)
  const [multiDropdownItems, setMultiDropdownItems] = useState([])
  const [searchBarItems, setSearchBarItems] = useState([])
  const [dropdownItem, setDropdownItem] = useState(null)
  const [dateRangeValue, onChangeDateRangeValue] = useState({
    start: null,
    end: null,
  })

  useEffect(() => {
    loadGlobalTranslations().finally(() => setLoading(false))
  }, [])

  if (loading) {
    return <Loader center={true} />
  }

  return (
    <div className="playground">
      <h1>Playground Page</h1>

      <div className="playground__cols">
        {/* Left column */}
        <div className="playground__col">
          <h3>Notes</h3>
          <Button onClick={() => setShowNotes(true)}>Show Notes Modal</Button>
          <Notes
            isOpen={showNotes}
            onClose={() => setShowNotes(false)}
            loanApplicationId={'111'}
            dispatch={dispatch}
            userId={'222'}
          />
          <h3>Dropdown</h3>
          <Dropdown
            isSearchable={true}
            options={options}
            selected={dropdownItem}
            setSelected={setDropdownItem}
            label="Single select dropdown"
          />

          <h3>Multi-select Dropdown</h3>
          <DropdownMultiple
            options={options}
            label="Select multiple elements"
            selectedItems={multiDropdownItems}
            setSelectedItems={setMultiDropdownItems}
          />

          <h3>Search Bar</h3>
          <SearchBar
            multiple={true}
            searchData={options}
            onSearch={noop}
            onChange={setSearchBarItems}
            initialValue={searchBarItems}
            containerClass="applicant-info__select-loan"
          />
        </div>

        {/* Right Column */}
        <div className="playground__col">
          <h3>Button</h3>
          <Button onClick={noop}>Regular Button</Button>
          <Button onClick={noop} color={BUTTON_COLORS.SECONDARY}>
            Secondary
          </Button>
          <Button
            onClick={() =>
              showNotification(dispatch, {
                type: 'negative',
                title: 'Something went wrong! Something went wrong!',
              })
            }
            color={BUTTON_COLORS.ERROR}
          >
            Decline
          </Button>
          <Button onClick={noop} disabled={true}>
            Disabled
          </Button>
          <Button onClick={noop} loading={true} className="button">
            Loading
          </Button>

          <br />
          <br />
          <TextField
            label="Input type currency"
            prefix="$"
            value={value}
            type={INPUT_TYPES.CURRENCY}
            fixedDecimalScale={true}
            onChange={setValue}
          />

          <br />
          <br />
          <InputDate label="Input type date" value={date} onChange={setDate} />
          <br />
          <br />
          <InputDate
            label="Input type date (secure, editable)"
            value={date}
            onChange={setDate}
            isSensitiveField={true}
          />
          <br />
          <br />
          <InputDate
            label="Input type date (secure, non-editable)"
            value={date}
            onChange={setDate}
            isSensitiveField={true}
            hideSecureValue={true}
          />
          <br />
          <br />
          <TextField
            label="Input type SENSITIVE"
            type={INPUT_TYPES.SENSITIVE}
            value={sensitiveValue}
            onChange={setSensitiveValue}
          />

          <h3>Progress Bar</h3>
          <ProgressBar
            crtStep={state.flow.crtStep}
            possibleSteps={[]}
            possibleViewOnlySteps={[]}
            goToStep={() => {}}
          />
        </div>
      </div>

      <h3>Loader:</h3>
      <div className="flex-center">
        <Loader /> &nbsp;&nbsp;
        <Loader size={100} /> &nbsp;&nbsp;
        <Loader size={50} />
      </div>

      <h3>Range picker</h3>
      <div className="flex-center">
        <DateRangePickerComponent
          value={dateRangeValue}
          startDate={dateRangeValue.start}
          endDate={dateRangeValue.end}
          setDateRange={(start, end) => onChangeDateRangeValue({ start, end })}
          maxDate={new Date()}
        />
      </div>
    </div>
  )
}

const options = [
  { id: 1, value: 'Value 1' },
  { id: 2, value: 'Value 2' },
  { id: 3, value: 'Value 3' },
]

const noop = () => {}

export default PlaygroundModule
