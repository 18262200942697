import { SCHEMA_TYPES, ACTION_TYPES } from 'components/form-builder/constants'
import { ERRORS, isEmpty } from 'components/validator'
import { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'

const COUNTIES = [
  'loanApplication.step3.membership.keypoint.countyOptions.alameda',
  'loanApplication.step3.membership.keypoint.countyOptions.contraCosta',
  'loanApplication.step3.membership.keypoint.countyOptions.elDorado',
  'loanApplication.step3.membership.keypoint.countyOptions.placer',
  'loanApplication.step3.membership.keypoint.countyOptions.sacramento',
  'loanApplication.step3.membership.keypoint.countyOptions.sanMateo',
  'loanApplication.step3.membership.keypoint.countyOptions.santaBarbara',
  'loanApplication.step3.membership.keypoint.countyOptions.santaClara',
  'loanApplication.step3.membership.keypoint.countyOptions.ventura',
]

const QUALIFICATION_OPTIONS = [
  'loanApplication.step3.membership.keypoint.qualificationOptions.liveAndWork',
  'loanApplication.step3.membership.keypoint.qualificationOptions.employed',
  'loanApplication.step3.membership.keypoint.qualificationOptions.contracted',
  'loanApplication.step3.membership.keypoint.qualificationOptions.familiymember',
  'loanApplication.step3.membership.keypoint.qualificationOptions.ffa',
]

export default {
  titleKey: 'loanApplication.step3.membership.keypoint.title',
  fields: {
    qualification: '',
    county: '',
    employer: '',
    familyMember: '',
    relationshipToMember: '',
    familyMembersAccountNumber: '',
    contractOfEmployer: '',
  },
  methods: {
    /** Validation method triggered pre-submit */
    validate: (state, translate) => {
      const errors = {}
      if (isEmpty(state.qualification)) {
        errors.qualification = ERRORS.REQUIRED
      }
      if (_isFieldShown(state, 'county', translate) && isEmpty(state.county)) {
        errors.county = ERRORS.REQUIRED
      }
      if (
        _isFieldShown(state, 'employer', translate) &&
        isEmpty(state.employer)
      ) {
        errors.employer = ERRORS.REQUIRED
      }
      if (
        _isFieldShown(state, 'contractOfEmployer', translate) &&
        isEmpty(state.contractOfEmployer)
      ) {
        errors.contractOfEmployer = ERRORS.REQUIRED
      }
      if (_isFieldShown(state, 'family', translate)) {
        if (isEmpty(state.familyMember)) {
          errors.familyMember = ERRORS.REQUIRED
        }
        if (isEmpty(state.relationshipToMember)) {
          errors.relationshipToMember = ERRORS.REQUIRED
        }
        if (isEmpty(state.familyMembersAccountNumber)) {
          errors.familyMembersAccountNumber = ERRORS.REQUIRED
        }
      }
      const isValid = Object.keys(errors).length === 0
      return [isValid, errors]
    },

    /** Export the "isFieldShown" method to be used when constructing the payload */
    isFieldShown: (state, fieldName, translate) =>
      _isFieldShown(state, fieldName, translate),
  },
  sections: [
    {
      type: SCHEMA_TYPES.WRAPPER,
      extraClass: 'scrollable padding-24',
      sections: [
        {
          type: SCHEMA_TYPES.PARAGRAPH,
          textKey: 'loanApplication.step3.membership.keypoint.description',
        },
      ],
    },
    {
      type: SCHEMA_TYPES.WRAPPER,
      extraClass: 'scrollable padding-24',
      sections: [
        {
          type: SCHEMA_TYPES.DROPDOWN,
          field: 'qualification',
          extraClass: 'margin-top-12',
          labelKey: 'loanApplication.step3.membership.keypoint.qualification',
          options: QUALIFICATION_OPTIONS,
        },
        {
          type: SCHEMA_TYPES.DROPDOWN,
          options: COUNTIES,
          extraClass: 'margin-top-24',
          field: 'county',
          labelKey: 'loanApplication.step3.membership.keypoint.county',
          renderCondition: (state, translate) =>
            _isFieldShown(state, 'county', translate),
        },
        {
          type: SCHEMA_TYPES.INPUT,
          field: 'employer',
          labelKey: 'loanApplication.step3.membership.keypoint.employer',
          renderCondition: (state, translate) =>
            _isFieldShown(state, 'employer', translate),
        },
        {
          type: SCHEMA_TYPES.INPUT,
          field: 'contractOfEmployer',
          labelKey:
            'loanApplication.step3.membership.keypoint.contractOfEmployer',
          renderCondition: (state, translate) =>
            _isFieldShown(state, 'contractOfEmployer', translate),
        },
        {
          type: SCHEMA_TYPES.WRAPPER,
          renderCondition: (state, translate) =>
            _isFieldShown(state, 'family', translate),
          sections: [
            {
              type: SCHEMA_TYPES.INPUT,
              field: 'familyMember',
              className: 'margin-top-24',
              labelKey:
                'loanApplication.step3.membership.keypoint.familyMember',
            },
            {
              type: SCHEMA_TYPES.INPUT,
              className: 'margin-top-24',
              field: 'relationshipToMember',
              labelKey:
                'loanApplication.step3.membership.keypoint.relationshipToMember',
            },
            {
              type: SCHEMA_TYPES.INPUT,
              className: 'margin-top-24',
              field: 'familyMembersAccountNumber',
              labelKey: `loanApplication.step3.membership.keypoint.familyMembersAccountNumber`,
            },
          ],
        },
      ],
    },
    {
      type: SCHEMA_TYPES.WRAPPER,
      extraClass: 'sticky',
      sections: [
        {
          type: SCHEMA_TYPES.BUTTONS,
          buttons: [
            {
              action: ACTION_TYPES.SUBMIT,
              labelKey: 'loanApplication.step3.membership.agreed',
            },
            {
              action: ACTION_TYPES.DECLINE,
              labelKey: 'loanApplication.step3.membership.keypoint.decline',
              className: 'margin-left-10',
              color: BUTTON_COLORS.ERROR,
              variant: BUTTON_VARIANTS.OUTLINED,
            },
          ],
        },
      ],
    },
  ],
}

function _isFieldShown(state, fieldName, translate) {
  switch (fieldName) {
    case 'qualification':
      return true
    case 'county':
      return state.qualification === translate(QUALIFICATION_OPTIONS[0])
    case 'employer':
      return state.qualification === translate(QUALIFICATION_OPTIONS[1])
    case 'contractOfEmployer':
      return state.qualification === translate(QUALIFICATION_OPTIONS[2])
    case 'family':
    case 'familyMember':
    case 'relationshipToMember':
    case 'familyMembersAccountNumber':
      return state.qualification === translate(QUALIFICATION_OPTIONS[3])
    default:
      return false
  }
}
