import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components, assets, colors } from '@ElementsCapitalGroup/enium-ui'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import {
  getStatesToSteps,
  getStepsMap,
  isAppNotFinished,
} from '../../modules/loan-application/states'
import './style.scss'

const { Tabs, Dropdown } = components
const {
  CheckIcon,
  SmallDefaultCheckCircleIcon,
  SmallUnfilledCheckCircleIcon,
  SmallFilledCheckCircleIcon,
} = assets

const ProgressBar = ({
  crtStep,
  currentState,
  possibleSteps,
  possibleViewOnlySteps,
  goToStep,
  isCommercialApp,
  isPtoNotMandatory,
}) => {
  const { t: translate } = useTranslation()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const STEP_LABELS = {
    0: translate('loanApplication.navigation.step1'),
    1: translate('loanApplication.navigation.step2'),
    2: translate('loanApplication.navigation.step3'),
    3: translate('loanApplication.navigation.step4'),
    4: translate('loanApplication.navigation.step5Combined'),
    5: translate('loanApplication.navigation.step7'),
    6: translate('loanApplication.navigation.step8'),
  }
  const STEP_LABELS_COMMERCIAL = {
    0: translate('loanApplication.navigation.step1'),
    1: translate('loanApplication.navigation.step2'),
    2: translate('loanApplication.navigation.step3'),
    3: translate('loanApplication.navigation.step4Commercial'),
    4: translate('loanApplication.navigation.step5Commercial'),
  }

  const stepsMap = getStepsMap({ isPtoNotMandatory, isCommercialApp })
  const statesToSteps = getStatesToSteps({ isPtoNotMandatory, isCommercialApp })
  const lastCompletedStep = statesToSteps[currentState?.name]
  const appNotFinished = isAppNotFinished({
    currentState,
    isCommercialApp,
    isPtoNotMandatory,
  })

  const renderItems = () => {
    if (crtStep === -1) {
      return null
    }

    const tabsItems = []
    const dropdownOptions = []

    Object.values(stepsMap).forEach((stepIndex) => {
      if (stepIndex === stepsMap.CONGRATS && appNotFinished) {
        return null
      }

      const stepLabel = isCommercialApp
        ? STEP_LABELS_COMMERCIAL[stepIndex]
        : STEP_LABELS[stepIndex]

      const isValidStep =
        possibleSteps.includes(stepIndex) ||
        possibleViewOnlySteps.includes(stepIndex)
      const canGoToStep = isValidStep && stepIndex !== crtStep

      const isStepCompleted = lastCompletedStep > stepIndex

      tabsItems.push({
        label: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: isStepCompleted ? colors.blue[500] : 'inherit',
              fontWeight: 'bold',
            }}
          >
            {!isStepCompleted && stepIndex !== crtStep && (
              <SmallDefaultCheckCircleIcon
                sx={{
                  marginRight: '4px',
                  width: 18,
                  height: 18,
                }}
              />
            )}

            {stepIndex === crtStep && !isStepCompleted && (
              <SmallUnfilledCheckCircleIcon
                sx={{
                  marginRight: '4px',
                  width: 18,
                  height: 18,
                }}
              />
            )}

            {isStepCompleted && (
              <SmallFilledCheckCircleIcon
                sx={{
                  marginRight: '4px',
                  width: 22,
                  stroke: 'white',
                  height: 22,
                }}
              />
            )}

            {stepLabel}
          </div>
        ),
        index: crtStep === stepIndex,
        onClick: () => {
          canGoToStep && goToStep(stepIndex, isCommercialApp)
        },
        value: stepIndex.toString(),
        disabled: !canGoToStep && stepIndex !== crtStep,
      })

      dropdownOptions.push({
        label: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: stepIndex === crtStep ? colors.blue[700] : 'inherit',
            }}
          >
            {stepLabel}
            {isStepCompleted && (
              <CheckIcon fontSize="small" sx={{ marginLeft: '4px' }} />
            )}
          </div>
        ),
        id: stepIndex,
        disabled: !canGoToStep && stepIndex !== crtStep,
      })
    })

    if (isMobileView) {
      return (
        <Dropdown
          options={dropdownOptions}
          onChange={(e) => {
            const stepIndex = e.target.value.id

            const isValidStep =
              possibleSteps.includes(stepIndex) ||
              possibleViewOnlySteps.includes(stepIndex)
            const canGoToStep = isValidStep && stepIndex !== crtStep

            canGoToStep && goToStep(stepIndex, isCommercialApp)
          }}
          value={{ id: crtStep, label: STEP_LABELS[crtStep] }}
        />
      )
    }

    return (
      <Tabs
        items={tabsItems}
        listProps={{
          variant: 'scrollable',
          sx: {
            '& .MuiTab-root': {
              padding: '0 33px',
            },
            '& .MuiTab-root:nth-of-type(2)': {
              marginLeft: '33px',
            },
            '& .MuiTab-root:first-of-type': {
              padding: '0 0',
            },
          },
        }}
        sx={{ borderBottom: 0 }}
        panelProps={{
          sx: {
            display: 'none',
          },
        }}
      />
    )
  }

  return (
    <div
      style={{
        marginTop: '32px',
        marginBottom: isMobileView ? '32px' : '-2px',
        justifyContent: 'center',
      }}
    >
      {renderItems()}
    </div>
  )
}

ProgressBar.propTypes = {
  crtStep: PropTypes.number.isRequired,
  currentState: PropTypes.object,
  possibleSteps: PropTypes.arrayOf(PropTypes.number).isRequired,
  possibleViewOnlySteps: PropTypes.arrayOf(PropTypes.number).isRequired,
  goToStep: PropTypes.func.isRequired,
  isCommercialApp: PropTypes.bool,
  isPtoNotMandatory: PropTypes.bool,
}

export default ProgressBar
