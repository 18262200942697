import Api from 'easy-fetch-api'
import moment from 'moment'

export const getDashboardData = () =>
  Api.get({
    url: '/LoanApplication/dealer-dashboard',
  }).catch((err) => console.error(err))

export const getGraphData = (startDate, endDate) =>
  Api.get({
    url: '/LoanApplication/dealer-dashboard/sales-volume',
    query: {
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
      timezoneOffset: moment().format('Z'),
    },
  }).catch((err) => console.error(err))
