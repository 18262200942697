import { LOAN_APPLICATION_STATUSES } from 'modules/loan-application/constants'
import { STATUSES } from 'components/status'

export const LOAN_APPS_PAGE_SIZE = 20

export const LOAN_STATUS_ID_MAPPING = {
  [LOAN_APPLICATION_STATUSES.OnHold]: STATUSES.ON_HOLD,
  [LOAN_APPLICATION_STATUSES.Active]: STATUSES.ACTIVE,
  [LOAN_APPLICATION_STATUSES.Declined]: STATUSES.INACTIVE,
  [LOAN_APPLICATION_STATUSES.Canceled]: STATUSES.UNDEFINED,
  [LOAN_APPLICATION_STATUSES.LoanComplete]: STATUSES.ACTIVE,
  [LOAN_APPLICATION_STATUSES.ActionRequired]: STATUSES.INACTIVE,
}

export const AUTOPAY_STATUS_MAPPING = {
  Complete: STATUSES.ACTIVE,
  Incomplete: STATUSES.ON_HOLD,
  'Not Started': STATUSES.UNDEFINED,
  'Needs Review': STATUSES.ON_HOLD,
}
