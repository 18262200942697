import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { LOAN_APPS_PAGE_SIZE } from 'modules/loan-applications/constants'
import SearchAndFilterModule from 'components/search-filter-module'
import Table from 'components/table'
import { getLoanAppsForAddToDaily } from '../actions'
import { addToDayColumns } from '../utils/constants'

const DailyFundingAddToDay = ({ setAddToDayApps }) => {
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({
    searchBy: '',
    sortBy: undefined,
    pageNumber: 0,
  })
  const [apps, setApps] = useState({
    list: [],
    pageCount: 0,
  })
  const tableRef = useRef()

  /** On search/pagination/filters change, re-fetch the list */
  useEffect(() => {
    loadApplications(filters)
  }, [filters])

  const loadApplications = (
    filters = {},
    pageNumber = 0,
    pageSize = LOAN_APPS_PAGE_SIZE
  ) => {
    setLoading(true)
    return getLoanAppsForAddToDaily(filters, pageNumber, pageSize)
      .then((result) => {
        if (result && result.loanApplications) {
          const newApps = result.loanApplications.map((app) => ({
            ...app,
            id: app.loanApplicationId,
          }))
          setApps({
            list: newApps,
            pageCount: Math.ceil(result.itemCount / LOAN_APPS_PAGE_SIZE),
          })
          return newApps
        } else {
          return []
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  /** On search re-filter the list starting from page 0 */
  const onSearch = (params) => {
    tableRef.current?.setPageIndex(0)
    setFilters({
      ...filters,
      searchBy: params.searchBy,
      sortBy: params.sortBy,
      pageNumber: 0,
    })
  }

  const onTableFiltersChanged = ({ pageIndex, sort }) => {
    setFilters({ ...filters, pageNumber: pageIndex, sortBy: sort })
  }

  return (
    <>
      <div className="afp__header afp__sticky">
        <SearchAndFilterModule
          fieldsToSearch={['multiple']}
          placeholder="Search by loan app"
          noDefaultExtraOptions={true}
          callback={onSearch}
        />
      </div>

      <Table
        ref={tableRef}
        title="Loan Applications"
        data={apps.list}
        totalPages={apps.pageCount}
        columns={addToDayColumns}
        dataLoading={loading}
        hideEditButton={true}
        hideRemoveButton={true}
        centerCellContent={true}
        hasCheckboxes={true}
        showColumnSelector={true}
        hasPagination={true}
        fetchData={onTableFiltersChanged}
        multiRowSelection={true}
        onTableRowsSelectChange={setAddToDayApps}
      />
    </>
  )
}

DailyFundingAddToDay.propTypes = {
  setAddToDayApps: PropTypes.func.isRequired,
}

export default DailyFundingAddToDay
