import React, { useEffect } from 'react'
import LoanDocumentSettings from 'modules/loan-document/loan-document-settings/index'
import { fetchLenders } from 'modules/organizations/actions'
import { useStore } from 'store'

const LoanDocumentEditPage = () => {
  const { state, dispatch } = useStore()
  const { lenders } = state.orgManagement

  useEffect(() => {
    if (lenders.length < 1) {
      fetchLenders(dispatch)
    }
  }, [])

  return <LoanDocumentSettings />
}

export default LoanDocumentEditPage
