const black = '#000'
const borderPrimary = '#D0D5DD'
const bluePrimary = '#1570EF'

export const styles = {
  pinSetUpTitle: {
    marginTop: '12px',
    color: black,
    marginBottom: '6px',
    fontWeight: 800,
  },

  pinSetUpText: {
    marginBottom: '24px',
    fontSize: '0.875rem',
  },

  pinSetUpPinText: {
    marginTop: '24px',
    color: black,
    fontWeight: 500,
    fontSize: '0.875rem',
  },

  pinSetUpInputStyle: {
    width: '60px',
    marginRight: '12px',
    marginBottom: '-10px',
    marginTop: '-15px',
    border: `1px solid ${borderPrimary}`,
    fontSize: '3rem',
    borderRadius: '8px',
    height: '64px',
  },

  mobilePinSetUpInputStyle: {
    width: '40px',
    marginRight: '12px',
    marginBottom: '-10px',
    marginTop: '-15px',
    border: `1px solid ${borderPrimary}`,
    fontSize: '2rem',
    borderRadius: '8px',
    padding: '5px',
    height: '40px',
  },

  pinSetUpContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
  },

  pinSetUpButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
    marginBottom: '12px',
  },

  pinSetUpIcon: {
    position: 'absolute',
    top: '15px',
  },

  phoneNumber: {
    fontWeight: '700',
    color: black,
  },

  pinSetUpResendLink: {
    display: 'flex',
    fontSize: '0.875rem',
    alignItems: 'center',
  },

  resendButton: {
    color: bluePrimary,
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
}
