import { colors, theme } from '@ElementsCapitalGroup/enium-ui'

const {
  lightTheme: { typography },
} = theme

const headerWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '24px',
  marginBottom: '8px',
  flexWrap: 'wrap',
}

const header = {
  fontSize: '1.875rem',
  fontWeight: 600,
  color: colors.grey[900],
}
const subHeader = {
  fontSize: typography.body1.fontSize,
  color: colors.grey[600],
  fontWeight: 600,
}

const dashboard = {
  padding: '24px 32px',
}

const content = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}

const chartWrapper = {
  height: 'fit-content',
  minHeight: '250px',
  width: '100%',
  marginTop: '24px',
}

export const styles = {
  dashboard,
  content,
  headerWrapper,
  header,
  subHeader,
  chartWrapper,
}
