import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { unstable_components } from '@ElementsCapitalGroup/enium-ui'

const { Unstable_TextArea: Textarea } = unstable_components

const Component = forwardRef(
  (
    {
      css,
      className,
      style,
      label,
      placeholder,
      defaultValue,
      value,
      validate,
      onChange,
      onBlur,
      onEnterKey,
      disabled,
      description,
      error,
      cols,
      rows,
      fullWidth,
      resize,
      validationBehavior = 'aria',
    },
    ref
  ) => {
    const onKeyUp = (ev) => {
      const code = ev.keyCode || ev.which

      if (code === 13) {
        ev.preventDefault()

        onEnterKey?.(ev)
      }
    }

    return (
      <Textarea
        ref={ref}
        css={css}
        className={className}
        style={style}
        label={label}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        resize={resize}
        isInvalid={error}
        description={description}
        isDisabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        fullWidth={fullWidth}
        validate={validate}
        cols={cols}
        rows={rows}
        validationBehavior={validationBehavior}
      />
    )
  }
)

Component.propTypes = {
  css: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  resize: PropTypes.oneOf(['none', 'vertical', 'horizontal', 'both']),
  value: PropTypes.string,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onEnterKey: PropTypes.func,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  error: PropTypes.bool,
  cols: PropTypes.number,
  rows: PropTypes.number,
  fullWidth: PropTypes.bool,
  validationBehavior: PropTypes.oneOf(['aria', 'native']),
}

export default Component
