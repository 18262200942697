export const calcInterestRate = (loanProduct, calculateWithAutopay) => {
  const { interestRate, interestRateDiscount, interestRateFee } = loanProduct
  return calculateWithAutopay
    ? interestRate - interestRateDiscount
    : interestRate + interestRateFee
}

function calcMonthlyPayment(
  loanAmount,
  interestRate,
  selectedLoan,
  promoPayment = 0,
  additionalPaydown = 0
) {
  let { term, promoPeriod = 0, promoType } = selectedLoan
  if (promoType === 'No Interest No Payment') {
    promoPeriod = 0
  }

  if (typeof promoPayment === 'function') {
    promoPayment = promoPayment(0) * promoPeriod
  }
  if (additionalPaydown) {
    promoPayment = additionalPaydown
  } else {
    promoPayment = promoPayment * promoPeriod
  }
  if (!loanAmount || !interestRate || !term) {
    return 0
  }
  const noOfPaymentsAfterPromo = term - promoPeriod
  const rate = interestRate / 12

  return Math.abs(
    thingy.PMT(rate, noOfPaymentsAfterPromo, loanAmount - promoPayment)
  )
}

function calcLevelPaymentAfterPromo(
  loanAmount,
  interestRate,
  term,
  promoPayment,
  promoPeriod = 0
) {
  if (!loanAmount || !interestRate || !term || !promoPayment) {
    return 0
  }
  const noOfPaymentsAfterPromo = term - promoPeriod
  const rate = interestRate / 12
  const futurePayment = thingy.FV(rate, promoPeriod, -promoPayment, loanAmount)

  return Math.abs(thingy.PMT(rate, noOfPaymentsAfterPromo, futurePayment))
}

const thingy = {
  PMT: function (rate, nperiod, pv, fv, type) {
    if (!fv) {
      fv = 0
    }
    if (!type) {
      type = 0
    }

    if (rate === 0) {
      return -(pv + fv) / nperiod
    }

    const pvif = Math.pow(1 + rate, nperiod)
    let pmt = (rate / (pvif - 1)) * -(pv * pvif + fv)

    if (type === 1) {
      pmt /= 1 + rate
    }

    return pmt
  },

  IPMT: function (pv, pmt, rate, per) {
    const tmp = Math.pow(1 + rate, per)
    return 0 - (pv * tmp * rate + pmt * (tmp - 1))
  },

  PPMT: function (rate, per, nper, pv, fv, type) {
    if (per < 1 || per >= nper + 1) {
      return null
    }
    const pmt = this.PMT(rate, nper, pv, fv, type)
    const ipmt = this.IPMT(pv, pmt, rate, per - 1)
    return pmt - ipmt
  },

  FV: function (rate, nper, pmt, pv, type = 0) {
    const pow = Math.pow(1 + rate, nper)
    let fv
    if (rate) {
      fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow
    } else {
      fv = -1 * (pv + pmt * nper)
    }
    return fv
  },

  CUMPRINC: function (rate, periods, loanAmount, start, end, promoPayment = 0) {
    const monthlyPayment = this.PMT(rate, periods, loanAmount - promoPayment)

    let remainingBalance = loanAmount
    for (let i = start; i <= end; i++) {
      const interest = remainingBalance * rate
      const principalPaid = monthlyPayment + interest

      remainingBalance += principalPaid
    }
    return remainingBalance
  },
}

export { calcMonthlyPayment, calcLevelPaymentAfterPromo }
