import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  components,
  assets,
  colors,
  theme,
} from '@ElementsCapitalGroup/enium-ui'
import TextField from 'components/input'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import EmptyList from 'components/empty-list'
import { useIsComponentVisible } from 'common/hooks'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { ORGANIZATION_TYPE_IDS } from 'common/constants'
import { compareIgnoreCase } from 'common/utils'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import {
  getStates,
  getOrganizations,
  getOrgStatesAndLoansAssociations,
  saveAssociatedDealers,
  saveAssociatedProducts,
} from 'modules/organizations/actions'
import { fetchLoanProductsBasicInfo } from 'modules/loan-product/actions'
import DeleteModal from 'components/modal/delete-modal'

import ModalAssignLoans from './modal-assign-loans'
import ModalAssignStates from './modal-assign-states'
import { styles as orgDetailStyles } from '../../style.js'
import { styles } from './styles'
import ModalAssignDealers from './modal-assign-dealers'
import ModalRemoveStates from './modal-remove-states'

const { Chip, Card } = components
const {
  UserIcon,
  TrashIcon,
  PlusIcon,
  DotsVerticalIcon,
  SearchLgIcon,
  Edit01Icon,
} = assets
const {
  lightTheme: { typography },
} = theme

const OrganizationStatesAndLoans = ({
  orgData,
  userData,
  dispatch,
  setLoading,
}) => {
  const [state, setState] = useState({
    allStates: null,
    allLoans: null,
    somethingChanged: false,
    associations: null,
  })
  const [dealerOrgs, setDealerOrgs] = useState([])
  const [searchText, setSearchText] = useState('')
  const [defaultSelectedLoans, setDefaultSelectedLoans] = useState([])
  const [defaultSelectedDealers, setDefaultSelectedDealers] = useState([])
  const [selectedState, setSelectedState] = useState(null)
  const [selectedStateForDealer, setSelectedStateForDealer] = useState(null)
  const [showDeleteForState, setShowDeleteForState] = useState(null)
  const [isStatesModalOpen, setStatesModalOpened] = useState(null)
  const [statesWithoutAssociations, setStatesWithoutAssociations] = useState([])
  const { allStates, allLoans, associations, somethingChanged } = state
  const { ref, isComponentVisible, setIsComponentVisible } =
    useIsComponentVisible(null)
  const canEditAssociations = hasAccess(
    userData,
    CLAIMS.CAN_EDIT_INSTALLERS_DEALERS_ASSOCIATIONS
  )
  const canEditLoanProductAssociations = hasAccess(
    userData,
    CLAIMS.CAN_EDIT_INSTALLERS_LOAN_PRODUCTS_ASSOCIATIONS
  )
  const isInstaller = orgData.type.id === ORGANIZATION_TYPE_IDS.INSTALLER

  const filteredStates = useMemo(() => {
    if (!associations) {
      return []
    }
    const searchBy = searchText.toLowerCase()
    return associations.filter(
      (el) =>
        el.name.toLowerCase().includes(searchBy) ||
        el.associatedLoans?.find((loan) =>
          loan.label.toLowerCase().includes(searchBy)
        ) ||
        el.associatedDealers?.find((dealer) =>
          dealer.label.toLowerCase().includes(searchBy)
        ) ||
        el.installers?.find((installer) =>
          installer.installerName?.toLowerCase().includes(searchBy)
        )
    )
  }, [searchText, associations])

  const emptySearch = !filteredStates.length && associations?.length > 0

  const getAssociations = () => {
    return getOrgStatesAndLoansAssociations(orgData.guid, orgData.type.id).then(
      (res) => {
        if (!res?.stateAssociations) {
          return []
        }
        const associations = []
        res.stateAssociations.forEach((association) => {
          let crtAssociation = associations.find(
            (el) => el.stateId === association.stateId
          )
          if (!crtAssociation) {
            associations.push({
              id: association.state.id,
              name: association.state.name,
              friendlyName: association.state.friendlyName,
              installers: association.installers,
              associatedLoans: [],
              associatedDealers: [],
            })
            crtAssociation = associations[associations.length - 1]
          }
          association.loanProducts?.forEach((loanProduct) => {
            crtAssociation.associatedLoans.push({
              ...loanProduct,
              guid: loanProduct.loanProductId,
              id: loanProduct.loanProductId,
              label: loanProduct.loanProductName,
            })
          })
          association.dealers?.forEach((dealer) => {
            crtAssociation.associatedDealers.push({
              ...dealer,
              guid: dealer.dealerId,
              id: dealer.dealerId,
              label: dealer.dealerName,
            })
          })
        })
        return associations.sort((a, b) => compareIgnoreCase(a.name, b.name))
      }
    )
  }

  /** On mount, loan states & loans data */
  useEffect(() => {
    setLoading(true)
    Promise.all([getStates(), getAssociations(), fetchLoanProductsBasicInfo()])
      .then(([states, associations, loanProducts]) => {
        setState({
          allStates: states,
          associations,
          allLoans: loanProducts
            .map((el) => ({
              id: el.loanProductId,
              guid: el.loanProductId,
              label: el.name,
            }))
            .sort((a, b) => compareIgnoreCase(a.label, b.label)),
          somethingChanged: false,
        })
      })
      .finally(() => setLoading(false))

    if (isInstaller) {
      getOrganizations(null, {
        organizationTypeId: ORGANIZATION_TYPE_IDS.DEALER,
      }).then((res) =>
        setDealerOrgs(
          res.organizationUnits?.map((org) => ({
            guid: org.guid,
            id: org.guid,
            label: org.name,
          })) || []
        )
      )
    }
  }, [])

  const onAddNewStates = (newStates) => {
    setState({
      ...state,
      associations: newStates
        .map((el) => ({ ...el, associatedLoans: [], associatedDealers: [] }))
        .concat(state.associations),
      somethingChanged: true,
    })
  }

  const onRemoveState = () => {
    setState({
      ...state,
      associations: state.associations.filter(
        (el) => el.id !== showDeleteForState
      ),
      somethingChanged: true,
    })
    setShowDeleteForState(null)
  }

  const setItemsForState = (stateId, items, type = ITEM_TYPE.LOAN) => {
    setState({
      ...state,
      associations: state.associations.map((el) => {
        if (el.id !== stateId) {
          return el
        }
        return { ...el, [type]: items }
      }),
      somethingChanged: true,
    })
  }

  const removeItemFromState = (stateId, itemId, type = ITEM_TYPE.LOAN) => {
    setState({
      ...state,
      associations: state.associations.map((el) => {
        if (el.id !== stateId) {
          return el
        }
        return {
          ...el,
          [type]: el[type].filter((item) => item.guid !== itemId),
        }
      }),
      somethingChanged: true,
    })
  }

  const onLoansModalOpen = (stateId) => {
    setSelectedState(stateId)
    setDefaultSelectedLoans(
      associations.find((el) => el.id === stateId)?.associatedLoans || []
    )
  }
  const onLoanModalClose = () => setSelectedState(null)

  const onDealersModalOpen = (stateId) => {
    setSelectedStateForDealer(stateId)
    setDefaultSelectedDealers(
      state.associations.find((el) => el.id === stateId)?.associatedDealers ||
        []
    )
  }
  const onDealerModalClose = () => setSelectedStateForDealer(null)

  const toggleActionsCardId = (stateId) => {
    if (stateId === isComponentVisible) {
      setIsComponentVisible(null)
    } else {
      setIsComponentVisible(stateId)
    }
  }

  /** Triggered on confirm removal of empty states from the Modal. Triggers a form submit */
  const removeEmptyStates = () => {
    const newAssociations = state.associations.filter(
      (el) => el.associatedLoans.length > 0 || el.associatedDealers.length > 0
    )
    setState({ ...state, associations: newAssociations })
    submitForm(newAssociations)
  }

  /** Triggered on Submit. Validates that states do not have empty associations and saves dealer associations & loan product associations */
  const onSubmitClicked = () => {
    const statesWithoutAssociations = associations.filter(
      (el) => !el.associatedLoans.length
    )
    if (statesWithoutAssociations.length) {
      setStatesWithoutAssociations(statesWithoutAssociations)
    } else {
      submitForm(associations)
    }
  }

  /** Actual submit function */
  const submitForm = async (associations) => {
    setLoading(true)
    const dealerAssociations = []
    const loanAssociations = []
    associations.forEach((el) => {
      el.associatedDealers.forEach((dealer) => {
        dealerAssociations.push({
          stateId: el.id,
          dealerId: dealer.guid,
        })
      })
      el.associatedLoans.forEach((loan) => {
        loanAssociations.push({
          stateId: el.id,
          loanProductId: loan.guid,
        })
      })
    })

    try {
      await saveAssociatedDealers(orgData.guid, dealerAssociations)
      await saveAssociatedProducts(orgData.guid, loanAssociations)

      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        message: 'Associations saved successfully',
      })
      getAssociations()
        .then((associations) => {
          setState({ ...state, associations })
        })
        .finally(() => setLoading(false))
    } catch (err) {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        message: 'Failed to save associations',
      })
      setLoading(false)
    }
  }

  const _renderActions = (state, hasLoans, hasDealers) => {
    if (!isInstaller) {
      return null
    }

    return (
      <div className="flex-center" style={{ position: 'relative' }}>
        {canEditLoanProductAssociations && (
          <Button
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            startIcon={
              hasLoans ? <Edit01Icon sx={{ width: '16px' }} /> : <PlusIcon />
            }
            onClick={() => onLoansModalOpen(state.id)}
          >
            {hasLoans ? 'Edit Loans' : 'Add Loans'}
          </Button>
        )}
        <div ref={ref}>
          <DotsVerticalIcon
            style={{
              marginLeft: '10px',
              cursor: 'pointer',
              marginTop: '6px',
            }}
            onClick={() => toggleActionsCardId(state.id)}
          />
          <Card
            sx={{
              ...styles.actionCardProps,
              display: isComponentVisible === state.id ? 'block' : 'none',
            }}
            contentProps={{
              sx: {
                width: '200px',
                padding: 0,
                '&:last-child': {
                  paddingBottom: 0,
                },
              },
            }}
          >
            {canEditAssociations && (
              <div
                style={styles.actionItem}
                onClick={() => onDealersModalOpen(state.id)}
              >
                <UserIcon sx={{ marginRight: '8px', width: '16px' }} />
                {!hasDealers ? 'Add Dealer' : 'Edit Dealers'}
              </div>
            )}
            <div
              style={{ ...styles.actionItem, borderBottom: 0 }}
              onClick={() => setShowDeleteForState(state.id)}
            >
              <TrashIcon sx={{ marginRight: '5px', width: '16px' }} />
              Delete
            </div>
          </Card>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div
        style={{ ...orgDetailStyles.subOrgs.searchBar, marginBottom: '20px' }}
      >
        <TextField
          onChange={setSearchText}
          value={searchText}
          placeholder={'Search'}
          type="search"
          startIcon={
            <SearchLgIcon fontSize="small" sx={{ stroke: colors.grey[400] }} />
          }
        />

        {isInstaller && (
          <div className="align-center" style={{ marginLeft: 'auto' }}>
            <Button
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
              startIcon={<PlusIcon />}
              onClick={() => setStatesModalOpened(true)}
              style={{ marginRight: '16px' }}
            >
              Add States
            </Button>

            <Button
              disabled={!allStates || !somethingChanged}
              onClick={onSubmitClicked}
              sx={orgDetailStyles.loanAssociation.submitButton}
            >
              Submit
            </Button>
          </div>
        )}
      </div>

      {associations && (!associations?.length || emptySearch) ? (
        <EmptyList
          title={
            emptySearch
              ? 'No data matches your search'
              : 'No associations added yet'
          }
          description={
            emptySearch
              ? `We couldn't find any data matching your criteria.`
              : isInstaller
              ? 'Be the first to add a new state to your organization.'
              : ''
          }
          actionButtonLabel={
            emptySearch ? 'Reset search' : isInstaller ? '+ Add States' : null
          }
          actionButtonOnClick={
            emptySearch || !isInstaller
              ? () => setSearchText('')
              : () => setStatesModalOpened(true)
          }
        />
      ) : (
        filteredStates.map((state) => {
          const hasLoans = state.associatedLoans?.length > 0
          const hasDealers = state.associatedDealers?.length > 0
          const hasData = hasLoans || hasDealers
          return (
            <Card
              key={state.id}
              style={{ marginBottom: '20px', overflow: 'visible' }}
              title={state.name}
              titleTypographyProps={{
                sx: { ...typography.h6 },
              }}
              headerProps={{
                sx: {
                  borderBottom: hasData ? `1px solid ${colors.grey[200]}` : 0,
                  padding: '20px 24px',
                },
                action: _renderActions(state, hasLoans, hasDealers),
              }}
              contentProps={{
                sx: {
                  padding: !hasData ? 0 : '20px',
                  '&:last-child': {
                    paddingBottom: !hasData ? 0 : '20px',
                  },
                },
              }}
            >
              {hasDealers ? (
                <>
                  <div style={styles.sectionHeader}>Dealers</div>
                  {state.associatedDealers.map((dealerOrg) => (
                    <Chip
                      key={dealerOrg.guid}
                      label={dealerOrg.label}
                      variant="outlined-squared"
                      onDelete={() =>
                        removeItemFromState(
                          state.id,
                          dealerOrg.guid,
                          ITEM_TYPE.DEALER
                        )
                      }
                      sx={orgDetailStyles.loanAssociation.chip}
                    />
                  ))}
                </>
              ) : null}

              {state.installers ? (
                <>
                  <div style={styles.sectionHeader}>Installer</div>
                  {state.installers.map((installer) => (
                    <Chip
                      key={installer.installerId}
                      label={installer.installerName}
                      variant="outlined-squared"
                      sx={orgDetailStyles.loanAssociation.chip}
                    />
                  ))}
                </>
              ) : null}

              {hasLoans ? (
                <>
                  <div style={styles.sectionHeader}>Loan Products</div>
                  {state.associatedLoans.map((loan) => (
                    <Chip
                      key={loan.guid}
                      label={loan.label}
                      variant="outlined-squared"
                      onDelete={
                        isInstaller
                          ? () =>
                              removeItemFromState(
                                state.id,
                                loan.guid,
                                ITEM_TYPE.LOAN
                              )
                          : null
                      }
                      sx={orgDetailStyles.loanAssociation.chip}
                    />
                  ))}
                </>
              ) : null}
            </Card>
          )
        })
      )}

      <ModalAssignLoans
        isOpen={!!selectedState}
        onModalClose={onLoanModalClose}
        onConfirm={(selectedLoans) =>
          setItemsForState(selectedState, selectedLoans, ITEM_TYPE.LOAN)
        }
        allLoans={allLoans}
        defaultSelectedLoans={defaultSelectedLoans}
      />

      <ModalAssignDealers
        isOpen={!!selectedStateForDealer}
        onModalClose={onDealerModalClose}
        onConfirm={(selectedDealers) =>
          setItemsForState(
            selectedStateForDealer,
            selectedDealers,
            ITEM_TYPE.DEALER
          )
        }
        allDealers={dealerOrgs}
        defaultSelectedDealers={defaultSelectedDealers}
      />

      <ModalAssignStates
        isOpen={isStatesModalOpen}
        onModalClose={() => setStatesModalOpened(false)}
        onConfirm={onAddNewStates}
        allStates={allStates?.filter(
          (el) => !associations.find((state) => state.id === el.id)
        )}
        defaultSelectedStates={associations}
      />

      <DeleteModal
        title="Delete State"
        confirmationQuestion="Are you sure you want to delete this state?"
        isOpen={!!showDeleteForState}
        setModalOpen={() => setShowDeleteForState(null)}
        confirmButtonText="Remove"
        onSubmitModal={onRemoveState}
      />

      <ModalRemoveStates
        isOpen={statesWithoutAssociations.length > 0}
        statesWithoutAssociations={statesWithoutAssociations}
        onModalClose={() => setStatesWithoutAssociations([])}
        onConfirm={() => {
          removeEmptyStates()
        }}
      />
    </div>
  )
}

const ITEM_TYPE = { LOAN: 'associatedLoans', DEALER: 'associatedDealers' }

OrganizationStatesAndLoans.propTypes = {
  orgData: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default OrganizationStatesAndLoans
