import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'

import { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { downloadCreditReport } from 'modules/loan-application/actions'
import { PULL_TYPES } from 'modules/loan-application/constants'

const { Button, LoadingIndicator } = components
const { DownloadIcon } = assets

const CreditReportApplicantDetails = ({
  applicantType,
  applicantId,
  lenderId,
  softScore,
  hardScore,
}) => {
  const { t: translate } = useTranslation()
  const [loading, setLoading] = useState({
    [PULL_TYPES.SOFT]: false,
    [PULL_TYPES.HARD]: false,
  })

  const onCreditDownloadClick = (pullType, applicantId) => {
    const dataToBeSent = {
      pullType: pullType,
      applicantId: applicantId,
      lenderId: lenderId ?? '',
    }
    setLoading({ ...loading, [pullType]: true })
    downloadCreditReport(dataToBeSent)
      .then((res) => {
        if (!res) {
          return
        }
        const linkSource = `data:${res.mimeType};base64,${res.content}`
        const downloadLink = document.createElement('a')
        downloadLink.href = linkSource
        downloadLink.download = res.name
        downloadLink.click()
      })
      .finally(() => setLoading({ ...loading, [pullType]: false }))
  }

  const getStartIconForDownloadButton = (pullType) => {
    if (loading[pullType]) {
      return <LoadingIndicator size={14} />
    }

    return <DownloadIcon />
  }

  return (
    <div className="uw-hub-page__credit-reports-applicant">
      <div className="uw-hub-page__credit-reports-header">
        <span>{translate(`uwHub.creditReports.${applicantType}`)}</span>
        <div>
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            startIcon={getStartIconForDownloadButton(PULL_TYPES.SOFT)}
            onClick={() => onCreditDownloadClick(PULL_TYPES.SOFT, applicantId)}
          >
            {translate('uwHub.creditReports.downloadSoftCredit')}
          </Button>
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            startIcon={getStartIconForDownloadButton(PULL_TYPES.HARD)}
            onClick={() => onCreditDownloadClick(PULL_TYPES.HARD, applicantId)}
          >
            {translate('uwHub.creditReports.downloadHardCredit')}
          </Button>
        </div>
      </div>
      <div className="uw-hub-page__credit-reports-scores">
        <div>
          <span>{translate('uwHub.creditReports.softCreditScore')}</span>
          <span>{softScore ?? '-'}</span>
        </div>
        <div>
          <span>{translate('uwHub.creditReports.hardCreditScore')}</span>
          <span>{hardScore ?? '-'}</span>
        </div>
      </div>
    </div>
  )
}

CreditReportApplicantDetails.propTypes = {
  applicantType: PropTypes.string.isRequired,
  applicantId: PropTypes.string.isRequired,
  lenderId: PropTypes.string.isRequired,
  softScore: PropTypes.number.isRequired,
  hardScore: PropTypes.number.isRequired,
}

export default CreditReportApplicantDetails
