import React, { useEffect, useState } from 'react'
import {
  listLenderSettings,
  updateLenderSettings,
  listLenderFees,
  updateLenderFees,
  listFundingTypes,
  listRevFees,
  listLateFees,
  updateRevFees,
  updateLateFees,
} from 'modules/automated-funding/actions'
import LenderProducts from 'modules/automated-funding/lender-settings/products'
import LenderDepositFees from 'modules/automated-funding/lender-settings/fees'
import { AF_PAGE_SIZE } from 'modules/automated-funding/utils/constants'
import FundingBreadcrumbs from 'modules/automated-funding/utils/breadcrumbs'
import { useStore } from 'store'
import { components } from '@ElementsCapitalGroup/enium-ui'

const { Tabs } = components

const AFPLenderSettings = () => {
  const [lenderProducts, setLenderProducts] = useState({
    list: [],
    pageCount: 0,
  })
  const [lenderFees, setLenderFees] = useState({
    list: [],
    pageCount: 0,
  })
  const [revFeeList, setRevFeeList] = useState([])
  const [lateFeeList, setLateFeeList] = useState([])
  const [fundingTypes, setFundingTypes] = useState([])
  const [loading, setLoading] = useState(false)
  const { dispatch } = useStore()

  useEffect(() => {
    fetchLenderProducts()
  }, [])

  const fetchLenderProducts = (query = { pageNumber: 0 }) => {
    setLoading(true)
    listLenderSettings({ ...query, itemsPerPage: 10 })
      .then((result) =>
        setLenderProducts({
          list: result.lenderFundingSettings,
          pageCount: Math.ceil(result.itemCount / AF_PAGE_SIZE),
        })
      )
      .finally(() => setLoading(false))
  }

  const fetchLenderFees = (query = { pageNumber: 0 }) => {
    setLoading(true)
    listLenderFees({ ...query, itemsPerPage: 10 }).then((result) => {
      setLenderFees({
        list: result.lenderFundingFees,
        pageCount: Math.ceil(result.itemCount / AF_PAGE_SIZE),
      })
      fetchLateRevFees(result.lenderFundingFees)
    })
  }

  const fetchLateRevFees = (lenderFundingFees) => {
    const lenderIds = [...new Set(lenderFundingFees.map((el) => el.lenderId))]
    Promise.all([listRevFees(lenderIds), listLateFees(lenderIds)])
      .then(([revFees, lateFees]) => {
        setRevFeeList(revFees)
        setLateFeeList(lateFees)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    listFundingTypes().then((result) =>
      setFundingTypes(
        result.map((el) => ({ id: el.id, label: el.friendlyName }))
      )
    )
  }, [])

  /** Perform server-side update and after update the FE item */
  const onUpdateLenderSettings = (data) => {
    setLoading(true)
    return updateLenderSettings(dispatch, data).catch((err) => {
      console.error(err)
      setLoading(false)
    })
  }

  /** Perform server-side update and after update the FE item */
  const onUpdateLenderFees = (data, revFees, lateFees) => {
    setLoading(true)
    const { lenderId } = data
    return Promise.allSettled([
      updateLenderFees(dispatch, data),
      updateRevFees(lenderId, revFees, true),
      updateLateFees(lenderId, lateFees, true),
    ]).catch((err) => {
      console.error(err)
      setLoading(false)
    })
  }

  return (
    <div className="admin-page afp">
      <FundingBreadcrumbs
        items={[{ id: 'lender', label: 'Lender Settings' }]}
      />
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">Lender Settings</div>
          <div>View all your lender settings in one place.</div>
        </div>
      </div>
      <div className="admin-page__content">
        <Tabs
          listProps={{
            onChange: (e, value) => {
              if (value === 'PRODUCTS') {
                fetchLenderProducts()
              } else {
                fetchLenderFees()
              }
            },
          }}
          items={[
            {
              label: 'Products',
              value: 'PRODUCTS',
              children: (
                <LenderProducts
                  list={lenderProducts.list}
                  pageCount={lenderProducts.pageCount}
                  onFetchList={fetchLenderProducts}
                  onUpdate={onUpdateLenderSettings}
                  loading={loading}
                />
              ),
            },
            {
              label: 'Deposits/Fees',
              value: 'FEES',
              children: (
                <LenderDepositFees
                  list={lenderFees.list}
                  revFeeList={revFeeList}
                  lateFeeList={lateFeeList}
                  pageCount={lenderFees.pageCount}
                  fundingTypes={fundingTypes}
                  onFetchList={fetchLenderFees}
                  onUpdate={onUpdateLenderFees}
                  loading={loading}
                />
              ),
            },
          ]}
          panelProps={{
            sx: {
              marginTop: '32px',
              padding: 0,
            },
          }}
        />
      </div>
    </div>
  )
}

export default AFPLenderSettings
