import PropTypes from 'prop-types'
import { SCHEMA_TYPES } from 'components/form-builder/constants'

export const organizationPropType = (props) => {
  const initialOrg = props?.initialOrg
  if (!initialOrg) {
    return null
  } else {
    if (
      'name' in initialOrg &&
      ('emailAddresses' in initialOrg || 'email' in initialOrg) &&
      'phoneNumber' in initialOrg &&
      'address' in initialOrg
    ) {
      return null
    }
    return Error('Invalid initial organization structure')
  }
}

export const customSchemaSectionType = PropTypes.shape({
  // General Section fields
  type: PropTypes.oneOf(Object.values(SCHEMA_TYPES)).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extraClass: PropTypes.string,
  disabled: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),

  // Method used to determine if this field should be rendered. If absent it defaults to always render
  renderCondition: PropTypes.func,

  // User-input related fields
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
})

export const customSchemaType = PropTypes.shape({
  // Key-value object with field names and associated default values for them
  fields: PropTypes.object,
  // Array of sections
  sections: PropTypes.arrayOf(customSchemaSectionType).isRequired,
  title: PropTypes.string,
  // Map of custom methods
  methods: PropTypes.object,
})

export const uwItemPropType = PropTypes.shape({
  dataSource: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  areasToMatch: PropTypes.array.isRequired, // also of type uwItemPropType
})

export const uwDataPropType = PropTypes.arrayOf(
  PropTypes.shape({
    dataPoint: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      friendlyName: PropTypes.string,
    }).isRequired,
    sourcesOfTruth: PropTypes.arrayOf(uwItemPropType).isRequired,
  })
)
