import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import ConfigOutageMessagesModule from 'modules/outage-messages/config'
import { firebaseApp } from 'modules/outage-messages/firebase'
import Loader from 'components/loader'
import TextField from 'components/input'
import Button from 'components/button'

const OutageMessagesPage = () => {
  const [user, setUser] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    // Upon auth, set the user
    firebaseApp.auth().onAuthStateChanged((user) => {
      setUser(user)
      setLoading(false)
    })
  }, [])

  const firebaseSignIn = () => {
    setLoading(true)
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        setUser(userCredential.user)
      })
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false))
  }

  return (
    <div className={cx({ 'outage-message--loading': loading })}>
      {loading ? (
        <Loader center={true} />
      ) : user ? (
        <ConfigOutageMessagesModule />
      ) : (
        <div className="firebase-form__wrapper">
          <div className="firebase-form__title">Firebase Authentication</div>
          <div className="firebase-form__fields">
            <TextField
              value={email}
              type="email"
              onChange={setEmail}
              placeholder="Email"
              className="input--secondary"
              onEnterKey={firebaseSignIn}
            />
            <TextField
              value={password}
              type="password"
              onChange={setPassword}
              placeholder="Password"
              onEnterKey={firebaseSignIn}
              style={{ marginLeft: '12px' }}
            />
          </div>
          {error && <div className="error">{error}</div>}

          <Button onClick={firebaseSignIn} disabled={loading} sx={{ mt: 2 }}>
            Sign In
          </Button>
        </div>
      )}
    </div>
  )
}

export default OutageMessagesPage
