import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'adminRolesManagement'

const initialState = {
  roles: [],
  roleTypes: [],
  roleClaims: null,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_ROLES]: (state, { data }) => {
    return {
      ...state,
      roles: [...state.roles, ...data],
    }
  },
  [ACTIONS.SET_ROLE_TYPES]: (state, { data }) => {
    return {
      ...state,
      roleTypes: data,
    }
  },
  [ACTIONS.SET_ROLE_CLAIMS]: (state, { data }) => {
    return {
      ...state,
      roleClaims: data,
    }
  },

  [ACTIONS.ADD_NEW_USER]: (state, { data }) => {
    return {
      ...state,
      roles: [...state.roles, data],
    }
  },

  [ACTIONS.UPDATE_ROLE_CLAIMS]: (state, { data }) => {
    const prevRoles = [...state.roles]
    const roleToUpdateIndex = prevRoles.findIndex(
      (role) => data.roleId === role.roleId
    )
    prevRoles[roleToUpdateIndex].permissions = [...data.permissions]

    return {
      ...state,
      roles: [...prevRoles],
    }
  },
}

export default { name, initialState, reduce }
