import React from 'react'
import AdminWrapper from 'modules/admin'
import StipulationTemplates from 'modules/stipulation-templates/index'

const AdminStipulationTemplates = () => (
  <AdminWrapper>
    <StipulationTemplates />
  </AdminWrapper>
)

export default AdminStipulationTemplates
