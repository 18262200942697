import React, { useEffect, useState } from 'react'
import { components } from '@ElementsCapitalGroup/enium-ui'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  Filler,
} from 'chart.js'
import { useMediaQuery } from 'common/hooks'
import Zoom from 'chartjs-plugin-zoom'
import { DateRangePickerComponent } from 'components/date-range-picker'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import { CLAIMS } from 'common/claims'
import { hasAccess } from 'common/access'
import { formatIntToCurrency } from 'modules/loan-application/utils'
import { useStore } from 'store'
import { styles } from './style'

import { LineChart } from './line-chart'
import { SalesReps } from './sales-reps'
import { InfoCard } from './info-card'
import { DASHBOARD_CARDS_INFO, DASHBOARD_INFO_TYPES } from './contants'
import { getDashboardData, getGraphData } from './actions'
import style from './style.module.scss'

const { Card } = components

ChartJS.defaults.font.size = 10
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Legend,
  Title,
  Tooltip,
  Zoom
)

const Dashboard = () => {
  const { state } = useStore()
  const { userData } = state.session
  const [dateRangeValue, onChangeDateRangeValue] = useState({
    start: null,
    end: null,
  })
  const [graphData, setGraphData] = useState({
    data: [],
    labels: [],
    totalSalesVolume: 0,
  })
  const [dashboardData, setDashboardData] = useState(null)

  const canSeeExtraInfo = hasAccess(
    state.session.userData,
    CLAIMS.CAN_VIEW_DEALER_DASHBOARD_ADMIN
  )

  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const { allOrganizations } = state.orgManagement

  useEffect(() => {
    getDashboardData().then((data) => {
      setDashboardData(data)
    })

    getGraphData().then((data) => {
      setGraphData(data)
    })
  }, [])

  useEffect(() => {
    if (dateRangeValue) {
      getGraphData(dateRangeValue?.start, dateRangeValue?.end).then((data) => {
        setGraphData(data)
      })
    }
  }, [dateRangeValue])

  const renderInfoCards = () => {
    if (!dashboardData) {
      return
    }

    return Object.keys(DASHBOARD_INFO_TYPES).map((type, index) => {
      if (
        (DASHBOARD_CARDS_INFO[type].restricted && !canSeeExtraInfo) ||
        (DASHBOARD_CARDS_INFO[type].reverseRestricted && canSeeExtraInfo)
      ) {
        return null
      }

      return (
        <InfoCard
          key={index}
          title={DASHBOARD_CARDS_INFO[type].title}
          content={dashboardData[DASHBOARD_CARDS_INFO[type].name]}
          infoType={type}
        />
      )
    })
  }

  if (!allOrganizations) {
    return null
  }

  const renderTopContent = () => {
    const content = (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: isMobileView ? 'column-reverse' : 'row',
            gap: isMobileView ? '24px' : '0px',
            flex: 1,
          }}
        >
          <div>
            <div style={styles.subHeader}>Total Sales Volume</div>
            <div style={styles.header}>
              {formatIntToCurrency(graphData?.totalSalesVolume)}
            </div>
          </div>

          <DateRangePickerComponent
            startDate={dateRangeValue.start}
            endDate={dateRangeValue.end}
            setDateRange={(start, end) =>
              onChangeDateRangeValue({ start, end })
            }
            maxDate={new Date()}
          />
        </div>

        <div
          style={{
            ...styles.chartWrapper,
            minHeight: isMobileView ? 'auto' : styles.chartWrapper.minHeight,
          }}
        >
          <LineChart
            data={{
              labels: graphData?.labels,
              datasets: [
                {
                  data: graphData?.data,
                  borderColor: 'rgb(21, 112, 239)',
                  tension: 0.4,
                  fill: true,
                  backgroundColor: function (context) {
                    const chart = context.chart
                    const { ctx } = chart

                    const gradient = ctx.createLinearGradient(0, 0, 0, 800)
                    gradient.addColorStop(0, 'rgb(21, 112, 239,0.2)')
                    gradient.addColorStop(0.1, 'rgb(21, 112, 239,0.2)')
                    gradient.addColorStop(0.2, 'rgb(21, 112, 239,0.05)')
                    gradient.addColorStop(1, 'rgb(21, 112, 239,0)')

                    return gradient
                  },
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              elements: {
                point: {
                  radius: 0,
                },
              },
              scales: {
                y: {
                  border: {
                    display: false,
                  },
                  grid: {
                    display: true,
                  },
                  ticks: {
                    display: false,
                  },
                },
              },
            }}
          />
        </div>
      </>
    )

    if (isMobileView) {
      return content
    }

    return (
      <Card
        sx={{
          minWidth: '375px',
          width: '100%',
          flex: 2,
          maxHeight: '400px',
        }}
      >
        {content}
      </Card>
    )
  }

  const renderSalesList = () => {
    const content = (
      <>
        <SalesReps
          salesList={dashboardData.topSalesReps.map((rep) => ({
            ...rep,
            amount: rep.totalSalesVolume,
          }))}
        />
      </>
    )

    if (isMobileView) {
      return <div style={{ width: '100%' }}>{content}</div>
    }

    return (
      <Card
        sx={{
          minWidth: '350px',
          width: '100%',
          flex: 1,
          maxHeight: '400px',
        }}
        contentProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        {content}
      </Card>
    )
  }

  return (
    <div
      style={{ ...styles.dashboard, ...(isMobileView && { padding: '16px' }) }}
    >
      <div style={styles.content}>
        <div style={styles.header}>Welcome back, {userData.firstName}</div>

        <div
          style={{
            display: 'flex',
            gap: '24px',
            flexWrap: isTabletView ? 'wrap' : 'nowrap',
          }}
        >
          {renderTopContent()}

          {canSeeExtraInfo && dashboardData?.topSalesReps && renderSalesList()}
        </div>

        <div className={style.cardsGrid}>{renderInfoCards()}</div>
      </div>
    </div>
  )
}

export default Dashboard
