import React from 'react'
import RolesManagement from 'modules/admin-roles-management/index'
import AdminWrapper from 'modules/admin'

const AdminOrgManagement = () => {
  return (
    <AdminWrapper>
      <RolesManagement />
    </AdminWrapper>
  )
}

export default AdminOrgManagement
