import moment from 'moment'
import { dateFormatUSWithTimestamps } from 'common/constants'
import {
  fetchAUSTemplatesHistory,
  fetchLoanProductHistory,
  fetchOrganizationsHistory,
  fetchRolesHistory,
  fetchStipulationTemplatesHistory,
  fetchUserHistoryForLoanApps,
  fetchUsersHistory,
  fetchFundingHistoryTracking,
  fetchHistoryForUWHub,
} from 'modules/admin-history/actions'

export const renderActionMetadata = (item) => {
  let message = moment(item.dateAndTimeOfAction).format(
    dateFormatUSWithTimestamps
  )
  if (item.user) {
    message += ` ${item.user.firstName} ${item.user.lastName}`
  }
  return `${message}:`
}

export const transformHistoryDataForTimeline = (historyData) => {
  return historyData?.map((item, index) => {
    return {
      id: index + 1,
      timestamp: moment(item.dateAndTimeOfAction).toISOString(),
      message: item.actionMessage,
      createdBy: `${item?.user?.firstName} ${item?.user?.lastName}`,
    }
  })
}

export const fetchFunctions = {
  ORGANIZATIONS: fetchOrganizationsHistory,
  USERS: fetchUsersHistory,
  ROLES: fetchRolesHistory,
  STIPULATIONS: fetchStipulationTemplatesHistory,
  DECISIONINGS: fetchAUSTemplatesHistory,
  USER_DETAILS: fetchUserHistoryForLoanApps,
  LOAN_PRODUCT_DETAILS: fetchLoanProductHistory,
  AFP_FUNDING_HISTORY: fetchFundingHistoryTracking,
  UW_HUB_HISTORY: fetchHistoryForUWHub,
}
