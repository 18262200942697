import React, { useEffect, useRef, useState } from 'react'
import { useStore } from 'store'
import { assets } from '@ElementsCapitalGroup/enium-ui'
import { useNavigate, useLocation } from 'react-router-dom'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import { attachSortBy } from 'common/table'
import SearchAndFilterModule from 'components/search-filter-module'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'

import { deleteOrganization, getOrganizations } from './actions'
import OrganizationsTable from './table'
import { ORGS_PAGE_SIZE } from './constants'
import { styles } from './organization-details/style'

const { PlusIcon } = assets

const OrganizationsModule = () => {
  const { dispatch, state } = useStore()
  const { organizations, organizationsCount } = state.orgManagement
  const searchFilterRef = useRef(null)
  const [dataLoading, setDataLoading] = useState(false)
  const [filters, setFilters] = useState({ ...INITIAL_FILTERS })
  const tableRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const isBeta = location.pathname.indexOf('-beta') > -1

  /** Re-fetch data on filter change */
  useEffect(() => {
    setDataLoading(true)
    const filtersAndSort = attachSortBy({ ...filters }, [...filters.sortBy])
    getOrganizations(dispatch, filtersAndSort).finally(() =>
      setDataLoading(false)
    )
  }, [filters])

  const onSearch = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      searchBy: value.searchBy,
      pageNumber: 0,
    }))
    tableRef.current?.setPageIndex?.(0)
  }

  const onPageChange = ({ pageIndex }) => {
    setFilters((prevState) => ({
      ...prevState,
      pageNumber: pageIndex,
    }))
    return Promise.resolve()
  }

  const onSortByChange = (key, value) => {
    const sortByKey = SORT_BY_MAPPING[key] || key
    setFilters((prevState) => ({
      ...prevState,
      sortBy: [{ key: sortByKey, value }],
    }))
  }

  const onEdit = (ev, rows) => navigateToOrgRoute(`/${rows[0].guid}#edit`)

  const onCreate = () => navigateToOrgRoute('/new')

  const onRemove = (ev, rowNumber, rows) => {
    setDataLoading(true)
    const promises = []
    rows.forEach((row) => {
      promises.push(deleteOrganization(dispatch, row.guid))
    })
    Promise.all(promises)
      .then(() => {
        tableRef.current?.setPageIndex?.(0)
        setFilters((prevState) => ({
          ...prevState,
          pageNumber: 0,
        }))
      })
      .finally(() => setDataLoading(false))
  }

  const navigateToOrgRoute = (subPath) => {
    const path = isBeta ? '/admin/organization-beta' : '/admin/organization'
    navigate(`${path}${subPath}`)
  }

  return (
    <AccessWrapper
      claims={[
        CLAIMS.CAN_EDIT_CORPORATE_ORGANIZATIONS,
        CLAIMS.CAN_EDIT_SALES_ORGANIZATIONS,
        CLAIMS.CAN_EDIT_LENDER_ORGANIZATIONS,
      ]}
    >
      <div style={styles.users.searchBar}>
        <SearchAndFilterModule
          ref={searchFilterRef}
          fullWidth={true}
          callback={onSearch}
          fieldsToSearch={['name']}
          noDefaultExtraOptions
          isSearchable={true}
          style={styles.subOrgs.searchInput}
        />
        <AccessWrapper claims={[CLAIMS.CAN_CREATE_ORGANIZATIONS]}>
          <Button
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            onClick={onCreate}
            style={styles.subOrgs.createNewBtn}
            startIcon={<PlusIcon />}
          >
            Create Organization
          </Button>
        </AccessWrapper>
      </div>

      <OrganizationsTable
        ref={tableRef}
        organizations={organizations}
        orgCount={organizationsCount}
        fetchData={onPageChange}
        dataLoading={dataLoading}
        handleSortByChanged={onSortByChange}
        onEdit={onEdit}
        onRowClick={(e, row) => {
          navigateToOrgRoute(`/${row.original.guid}`)
        }}
        onRemove={onRemove}
        noDataText={'No data found'}
        noDataSubText={`We couldn't find any data matching your criteria.`}
        noDataActionText={'Reset search & filters'}
        noDataActionOnClick={() => {
          searchFilterRef && searchFilterRef.current?.resetComponentState()
          setFilters({ ...INITIAL_FILTERS })
        }}
      />
    </AccessWrapper>
  )
}

const INITIAL_FILTERS = {
  sortBy: [],
  searchBy: '',
  pageNumber: 0,
  itemsPerPage: ORGS_PAGE_SIZE,
}

const SORT_BY_MAPPING = {
  type: 'OrganizationTypeId',
  status: 'StatusId',
}

export default OrganizationsModule
