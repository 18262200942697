import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import {
  processApproval,
  downloadDocuments,
  listApprovalFundingApps,
  removeOverride,
  declineApps,
} from 'modules/automated-funding/actions'
import AFPApprovalModule from 'modules/automated-funding/approval'
import {
  APPROVAL_STATUS,
  FUNDING_TABS,
} from 'modules/automated-funding/utils/constants'
import FundingBreadcrumbs from 'modules/automated-funding/utils/breadcrumbs'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { ReactComponent as CheckIconSlim } from 'assets/check-icon-slim.svg'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'

const { DownloadIcon } = assets
const { Dialog } = components

const AFPApprovalPage = () => {
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const { dailyPendingApps: list } = state.automatedFunding

  const approvableApps = list.filter(
    (app) => !app.reason && app.approvalStatus.id !== APPROVAL_STATUS.APPROVED
  ) // Apps that can be approved (weren't overridden)
  const hasDeclinedApps = list.length !== approvableApps.length
  const [loading, setLoading] = useState(false)
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)

  useEffect(() => {
    fetchList()
  }, [])

  const fetchList = (query = {}) => {
    setLoading(true)
    listApprovalFundingApps(dispatch, query).finally(() => setLoading(false))
  }

  const onApprove = () => {
    setLoading(true)
    return processApproval(
      approvableApps.map((el) => el.dailyFundingLoanApplicationId)
    )
      .then(() => {
        fetchList()
        navigate(FUNDING_TABS.WIRES.url)
      })
      .finally(() => {
        setLoading(false)
        setIsApproveModalOpen(false)
      })
  }

  const onDecline = (loanApplicationIds, reason) => {
    setLoading(true)
    return declineApps(loanApplicationIds, reason)
      .then(fetchList)
      .finally(() => setLoading(false))
  }

  const onRemoveOverride = (loanApplicationId) => {
    setLoading(true)
    return removeOverride(loanApplicationId)
      .then(fetchList)
      .finally(() => setLoading(false))
  }

  return (
    <div className="admin-page afp">
      <FundingBreadcrumbs items={[{ id: 'approval', label: 'Approval' }]} />
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">Daily Funding Approval</div>
          <div>View all your daily funding loan applications in one place.</div>
        </div>
        <div>
          <Button
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            style={{ marginRight: '12px' }}
            onClick={downloadDocuments}
            startIcon={<DownloadIcon />}
            disabled={loading}
          >
            Download Lender Documents
          </Button>
          <Button
            disabled={!approvableApps.length || loading}
            startIcon={<CheckIconSlim style={{ width: '15px' }} />}
            onClick={() => setIsApproveModalOpen(true)}
          >
            {hasDeclinedApps ? 'Approve' : 'Approve All'}
          </Button>
        </div>
      </div>
      <div className="admin-page__content">
        <AFPApprovalModule
          list={list}
          onFetchList={fetchList}
          onDecline={onDecline}
          onRemoveOverride={onRemoveOverride}
          loading={loading}
        />
      </div>
      {isApproveModalOpen && (
        <Dialog
          title={`Approve ${approvableApps.length} apps`}
          open={true}
          onClose={() => setIsApproveModalOpen(false)}
          PaperProps={{
            sx: {
              maxWidth: '100%',
              width: '400px',
            },
          }}
          actions={
            <>
              <Button
                onClick={() => setIsApproveModalOpen(false)}
                color={BUTTON_COLORS.INHERIT}
                variant={BUTTON_VARIANTS.OUTLINED}
              >
                Cancel
              </Button>
              <Button onClick={onApprove}>Submit</Button>
            </>
          }
        >
          {approvableApps.map((app) => (
            <p key={app.applicationNumber}>#{app.applicationNumber}</p>
          ))}
        </Dialog>
      )}
    </div>
  )
}

export default AFPApprovalPage
