import React from 'react'
import { useTranslation } from 'react-i18next'

import { TABLET_BREAKPOINT } from 'common/constants'
import { useMediaQuery } from 'common/hooks'

import { ReactComponent as DocsLoading } from './docs-are-loading.svg'
import { styles } from './style.js'

const LoadingDocuments = () => {
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const { t: translate } = useTranslation('translation', {
    keyPrefix: 'loanApplication.step4.loading',
  })

  return (
    <div className="loan-document">
      <div style={styles.docsLoadingWrapper}>
        <DocsLoading />
        <div style={isMobileView ? styles.headerMobile : styles.header}>
          {translate('title')}
        </div>
        <div
          style={{
            maxWidth: isMobileView ? '330px' : 'inherit',
            textAlign: 'center',
          }}
        >
          {translate('text')}
        </div>
      </div>
    </div>
  )
}

export default LoadingDocuments
