import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Loader from 'components/loader'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import {
  downloadCreditReport,
  getCreditReport,
  rePullCreditReport,
} from 'modules/loan-application/actions'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { useMediaQuery } from 'common/hooks'

import { DESKTOP_BREAKPOINT, dateFormatUS } from 'common/constants'
import { formatSSN } from 'common/utils'
import { PULL_TYPES } from 'modules/loan-application/constants'

import './index.scss'

const { NoteIcon, ReverseLeftIcon, RefreshCWIcon, DownloadIcon } = assets

const { Button, Tabs } = components
const CreditReports = ({ loanApplicationId }) => {
  const { state } = useStore()
  const { t: translate } = useTranslation()
  const [creditReportData, setCreditReportData] = useState([])
  const [isHardCreditPulledForBorrower, setIsHardCreditPulledForBorrower] =
    useState(false)
  const [isHardCreditPulledForCoBorrower, setIsHardCreditPulledForCoBorrower] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [isCoBorrower, setIsCoBorrower] = useState(0)

  const handleTabChange = (event, newValue) => {
    setIsCoBorrower(newValue)
  }

  const borrowerId = state.flow.loanFormData.borrowerGeneralDetails.applicantId
  const coBorrowerId =
    state.flow.loanFormData?.coBorrowerGeneralDetails?.applicantId
  const canRerunReports = hasAccess(state.session?.userData, [
    CLAIMS.CAN_DO_EVERYTHING,
    CLAIMS.CAN_RERUN_CREDIT_REPORTS,
  ])

  const onCreditDownloadClick = (pullType, applicantId) => {
    const dataToBeSent = {
      pullType: pullType,
      applicantId: applicantId,
      lenderId: state.flow.loanFormData?.selectedLoanProduct?.lenderId ?? '',
    }
    setLoading(true)
    downloadCreditReport(dataToBeSent)
      .then((res) => {
        if (!res) {
          return
        }
        const linkSource = `data:${res.mimeType};base64,${res.content}`
        const downloadLink = document.createElement('a')
        downloadLink.href = linkSource
        downloadLink.download = res.name
        downloadLink.click()
      })
      .finally(() => setLoading(false))
  }

  const onCreditRePullClick = (pullType, applicantId) => {
    const data = {
      pullType: pullType,
      applicantId: applicantId,
      lenderId: state.flow.loanFormData?.selectedLoanProduct?.lenderId ?? '',
      force: true,
    }

    setLoading(true)
    rePullCreditReport(data)
      .then((res) => {
        if (!res) {
          return
        }
        initCreditReportData()
      })
      .finally(() => setLoading(false))
  }

  const getValuesForGeneralInfo = (item) => [
    {
      header: translate('loanApplication.ecgToolbox.creditReports.softCredit'),
      value: item.softFico || 'N/A',
    },
    {
      header: translate('loanApplication.ecgToolbox.creditReports.hardCredit'),
      value: item.hardFico || 'N/A',
    },
    {
      header: translate('loanApplication.ecgToolbox.creditReports.dateOfBirth'),
      value: moment(item.birthDate).format(dateFormatUS) || 'N/A',
    },
    {
      header: translate('loanApplication.ecgToolbox.creditReports.ssn'),
      value: formatSSN(item.ssn) || 'N/A',
    },
  ]

  const getValuesEstimates = (item) => [
    {
      header: translate(
        'loanApplication.ecgToolbox.creditReports.softAnnualEstimate'
      ),
      value: item.softEstimatedIncome
        ? item.softEstimatedIncome.annualEstimate.toFixed(2)
        : 'N/A',
    },
    {
      header: translate(
        'loanApplication.ecgToolbox.creditReports.softMonthlyEstimate'
      ),
      value: item.softEstimatedIncome
        ? item.softEstimatedIncome.monthlyEstimate.toFixed(2)
        : 'N/A',
    },
    {
      header: translate(
        'loanApplication.ecgToolbox.creditReports.hardAnnualEstimate'
      ),
      value: item.hardEstimatedIncome
        ? item.hardEstimatedIncome.annualEstimate.toFixed(2)
        : 'N/A',
    },
    {
      header: translate(
        'loanApplication.ecgToolbox.creditReports.hardMonthlyEstimate'
      ),
      value: item.hardEstimatedIncome
        ? item.hardEstimatedIncome.monthlyEstimate.toFixed(2)
        : 'N/A',
    },
  ]

  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  const renderButtons = () => (
    <div className="credit-reports__buttons">
      <Button
        sx={{
          border: 'none',
        }}
        variant="outlined"
        disabled={!canRerunReports}
        onClick={() => {
          onCreditRePullClick(
            PULL_TYPES.SOFT,
            !isCoBorrower ? borrowerId : coBorrowerId
          )
        }}
        startIcon={<ReverseLeftIcon />}
      >
        {translate('loanApplication.ecgToolbox.creditReports.repullCreditSoft')}
      </Button>
      <Button
        sx={{
          border: 'none',
        }}
        variant="outlined"
        disabled={!canRerunReports}
        onClick={() => {
          onCreditRePullClick(
            PULL_TYPES.HARD,
            !isCoBorrower ? borrowerId : coBorrowerId
          )
        }}
        startIcon={<RefreshCWIcon />}
      >
        {translate('loanApplication.ecgToolbox.creditReports.repullCreditHard')}
      </Button>
      <Button
        sx={{
          border: 'none',
          mr: !isTabletView ? 1 : 0,
          mb: isTabletView ? 1 : 0,
        }}
        variant="outlined"
        onClick={() =>
          onCreditDownloadClick(
            PULL_TYPES.SOFT,
            !isCoBorrower ? borrowerId : coBorrowerId
          )
        }
        startIcon={<DownloadIcon />}
      >
        Soft Credit
      </Button>
      <Button
        sx={{
          border: 'none',
        }}
        disabled={
          (isCoBorrower && !isHardCreditPulledForCoBorrower) ||
          (!isCoBorrower && !isHardCreditPulledForBorrower)
        }
        variant="outlined"
        onClick={() =>
          onCreditDownloadClick(
            PULL_TYPES.HARD,
            !isCoBorrower ? borrowerId : coBorrowerId
          )
        }
        startIcon={<DownloadIcon />}
      >
        Hard Credit
      </Button>
    </div>
  )

  const renderCreditReportsData = () =>
    creditReportData.map((item, idx) => {
      if (
        (!isCoBorrower && item.applicantType.name === 'Borrower') ||
        (isCoBorrower && item.applicantType.name === 'Coborrower')
      ) {
        return (
          <div key={idx} className="credit-reports__wrapper">
            {isTabletView ? (
              <div>
                <div className="applicant-verification__header">
                  <span
                    className="applicant-verification__content-item applicant-verification__content-item--no-height"
                    style={{ width: '100%' }}
                  >
                    {translate(
                      'loanApplication.ecgToolbox.creditReports.title'
                    )}
                  </span>
                </div>
                <div className="applicant-verification__content">
                  <div className="credit-reports__flex">
                    {getValuesForGeneralInfo(item).map((itm, key) => (
                      <span
                        key={key}
                        className="applicant-verification__header-item"
                      >
                        {itm.header}: {itm.value}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="applicant-verification__header">
                  {getValuesForGeneralInfo(item).map((itm, key) => (
                    <span
                      key={key}
                      className="applicant-verification__header-item"
                    >
                      {itm.header}
                    </span>
                  ))}
                </div>
                <div className="applicant-verification__content">
                  {getValuesForGeneralInfo(item).map((itm, key) => (
                    <span
                      key={key}
                      className="applicant-verification__content-item"
                    >
                      <span>{itm.value}</span>
                    </span>
                  ))}
                </div>
              </>
            )}
            {isTabletView ? (
              <div style={{ marginTop: '24px' }}>
                <div className="applicant-verification__header">
                  <span className="applicant-verification__content-item applicant-verification__content-item--no-height">
                    {translate(
                      'loanApplication.ecgToolbox.identityVerification.info'
                    )}
                  </span>
                </div>
                <div className="applicant-verification__content">
                  <div className="credit-reports__flex">
                    {getValuesEstimates(item).map((itm, key) => (
                      <span
                        key={key}
                        className="applicant-verification__header-item"
                      >
                        {itm.header}: {itm.value}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div
                  className="applicant-verification__header"
                  style={{ marginTop: '24px' }}
                >
                  {getValuesEstimates(item).map((itm, key) => (
                    <span
                      key={key}
                      className="applicant-verification__header-item"
                    >
                      {itm.header}
                    </span>
                  ))}
                </div>
                <div className="applicant-verification__content">
                  {getValuesEstimates(item).map((itm, key) => (
                    <span
                      key={key}
                      className="applicant-verification__content-item"
                    >
                      <span>{itm.value}</span>
                    </span>
                  ))}
                </div>
              </>
            )}
            <div className="credit-reports__paper">
              <div className="credit-reports__paper-wrapper">
                <span className="credit-reports__item">
                  {translate('loanApplication.ecgToolbox.creditReports.name')}
                </span>
                <span className="credit-reports__info">{`${item.firstName} ${item.lastName}`}</span>
              </div>
              <div className="credit-reports__paper-wrapper">
                <span className="credit-reports__item">
                  {' '}
                  {translate(
                    'loanApplication.ecgToolbox.creditReports.bankruptcies'
                  )}
                </span>
                <span className="credit-reports__info">
                  {item.bankruptcyCount}
                </span>
              </div>
              <div className="credit-reports__paper-wrapper">
                <span className="credit-reports__item">
                  {translate(
                    'loanApplication.ecgToolbox.creditReports.activeCount'
                  )}{' '}
                </span>
                <span className="credit-reports__info">
                  {item.bankruptcyCountActive}
                </span>
              </div>
              {item.addresses.map((itm, key) => {
                return (
                  <div
                    key={key}
                    className="credit-reports__paper-wrapper credit-reports__paper-wrapper--no-flex"
                  >
                    <div>
                      {translate(
                        'loanApplication.ecgToolbox.creditReports.address'
                      )}{' '}
                      {key + 1}{' '}
                    </div>
                    {!isTabletView ? (
                      <div className="credit-reports__info">
                        <div>{itm.address1},</div>
                        <div>{itm.city}</div>
                        <div>
                          {itm.state} {itm.zipCode}
                        </div>
                      </div>
                    ) : (
                      <div className="credit-reports__info">
                        <div>
                          {itm.address1}, {itm.city}, {itm.state} {itm.zipCode}
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            {isTabletView && renderButtons()}
          </div>
        )
      } else {
        return null
      }
    })

  const initCreditReportData = () => {
    getCreditReport(
      loanApplicationId,
      state.flow.loanFormData?.selectedLoanProduct?.lenderId ?? ''
    )
      .then(setCreditReportData)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const borrowerCreditReport = creditReportData.find(
      (_) => _.applicantId === borrowerId
    )
    setIsHardCreditPulledForBorrower(
      borrowerCreditReport?.hasHardCreditReportPulled
    )

    const coBorrowerCreditReport = creditReportData.find(
      (_) => _.applicantId === coBorrowerId
    )
    setIsHardCreditPulledForCoBorrower(
      coBorrowerCreditReport?.hasHardCreditReportPulled
    )
  }, [creditReportData.length])

  useEffect(() => {
    setLoading(true)
    initCreditReportData()
  }, [])

  const commonData = {
    label: translate('loanApplication.common.borrower'),
    value: 0,
    children: (
      <div>
        {loading ? (
          <Loader center />
        ) : (
          <div className="credit-reports__data">
            {renderCreditReportsData()}
          </div>
        )}
      </div>
    ),
  }

  const items =
    creditReportData?.length > 1
      ? [
          { ...commonData },
          {
            label: translate('loanApplication.common.coborrower'),
            value: 1,
            children: commonData.children,
          },
        ]
      : [commonData]

  return (
    <div>
      <div className="credit-reports__header">
        <div className="credit-reports__title">
          <NoteIcon sx={{ mr: 1 }} />
          {translate('loanApplication.ecgToolbox.creditReports.title')}
        </div>
        {!isTabletView && renderButtons()}
      </div>

      <Tabs
        panelProps={{
          sx: {
            padding: '0 0 0',
          },
        }}
        sx={{
          paddingBottom: '12px',
        }}
        items={items}
        value={isCoBorrower}
        listProps={{
          onChange: handleTabChange,
        }}
      />
    </div>
  )
}

CreditReports.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
}

export default CreditReports
