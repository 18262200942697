import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const LoanCalculatorResults = ({
  isSpecialBank,
  results,
  monthlyWrapperStyles = {},
}) => {
  return (
    <>
      <div
        className="loan-calculator__monthly-wrapper"
        style={monthlyWrapperStyles.wrapper}
      >
        <span style={monthlyWrapperStyles.asLowAs}>For as low as*</span>
        <span
          className="loan-calculator__monthly-amount"
          style={monthlyWrapperStyles.amount}
        >
          $
          {isSpecialBank
            ? results.paymentWithAutoPay
            : results.paymentWithAutoPayAndPaydown}
          /mo
        </span>
        <span
          className="loan-calculator__disclaimer"
          style={monthlyWrapperStyles.disclaimer}
        >
          *with {!isSpecialBank ? 'Paydown and' : ''} Auto Pay (
          {results.interestRate}%)
        </span>
      </div>

      <div
        className={cx('loan-calculator__table', {
          'loan-calculator__table--small': isSpecialBank,
        })}
      >
        <div></div>
        <div className="loan-calculator__table__header">Promo</div>
        {!isSpecialBank && (
          <div className="loan-calculator__table__header">Paydown</div>
        )}

        <div className="loan-calculator__table__header">
          {isSpecialBank ? 'Payment' : 'No Paydown'}
        </div>

        <div>
          <span className="loan-calculator__table__heading">
            {results.interestRate}%
          </span>{' '}
          (Auto Pay)
        </div>
        <div className="loan-calculator__table__value">
          {isSpecialBank || !results.promoPayment
            ? '-'
            : `$${results.promoPayment}`}
        </div>
        {!isSpecialBank && (
          <div className="loan-calculator__table__value">
            {results.paymentWithAutoPayAndPaydown
              ? `$${results.paymentWithAutoPayAndPaydown}`
              : '-'}
          </div>
        )}
        <div className="loan-calculator__table__value">
          {results.paymentWithAutoPay ? `$${results.paymentWithAutoPay}` : '-'}
        </div>

        <div className="loan-calculator__table__line"></div>

        <div>
          <span className="loan-calculator__table__heading">
            {results.interestRateWithoutAutopay}%
          </span>{' '}
          (No Auto Pay)
        </div>
        <div className="loan-calculator__table__value">
          {isSpecialBank || !results.promoPaymentWithoutAutopay
            ? '-'
            : `$${results.promoPaymentWithoutAutopay}`}
        </div>
        {!isSpecialBank && (
          <div className="loan-calculator__table__value">
            {results.paymentNoAutoPayAndPaydown
              ? `$${results.paymentNoAutoPayAndPaydown}`
              : '-'}
          </div>
        )}
        <div className="loan-calculator__table__value">
          {results.paymentNoAutoPay ? `$${results.paymentNoAutoPay}` : '-'}
        </div>
      </div>
    </>
  )
}

LoanCalculatorResults.propTypes = {
  isSpecialBank: PropTypes.bool,
  results: PropTypes.object.isRequired,
  monthlyWrapperStyles: PropTypes.object,
}

export default LoanCalculatorResults
