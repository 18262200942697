/** Sum of 2 numbers with max 2 decimals */
export const sumFloat = (value1 = 0, value2 = 0) => {
  const sum = (parseFloat(value1) + parseFloat(value2)).toFixed(2)
  if (!isNaN(sum)) {
    return sum
  }
  return 0
}

export const convertToFloat = (string) => {
  const number = parseFloat(string)
  return !isNaN(number) ? number : 0
}

/** Convert a number in a US-like string format (commas for thousands, and 2 decimal places) */
export const formatInUSFormat = (number) => {
  if (typeof number === 'undefined') {
    number = 0
  }
  if (typeof number === 'string') {
    number = parseFloat(number)
  }
  if (!number && number > 0) {
    return 'NaN'
  }
  return parseFloat(number.toFixed(2)).toLocaleString('en-US', {
    minimumFractionDigits: 2,
  })
}
