import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import PropertyTitle from 'modules/loan-application/ecg-toolbox/property-title'

const { Dialog } = components

const PropertyTitleFullDetailsModal = ({
  isOpen,
  onClose,
  propertyTitleReportId,
}) => {
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const { t: translate } = useTranslation()

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      title={translate(
        'loanApplication.ecgToolbox.sidebar.propertyTitleReport'
      )}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '850px',
          '& .MuiDialogContent-root': {
            padding: isMobileView
              ? '0 8px 18px'
              : isTabletView
              ? '0 16px 18px'
              : '10px 24px',
            '&::-webkit-scrollbar': {
              display: 'none' /* Hide scrollbar for Webkit browsers */,
            },
            '-ms-overflow-style': 'none' /* Hide scrollbar for IE and Edge */,
            scrollbarWidth: 'none' /* Hide scrollbar for Firefox */,
          },
          margin: '5px',
        },
      }}
    >
      <PropertyTitle
        hideHeader={true}
        propertyTitleReportId={propertyTitleReportId}
      />
    </Dialog>
  )
}

PropertyTitleFullDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  propertyTitleReportId: PropTypes.string.isRequired,
}

export default PropertyTitleFullDetailsModal
