import React, { useEffect, useMemo, useState } from 'react'
import { useStore } from 'store'
import {
  addToDaily,
  updateDaily,
  listDailyFundingApps,
  processDailyLoanApps,
  listDailyProcessedApps,
  listFundedApps,
  regenerateDailyFunding,
  exportDailyFundingAFP,
} from 'modules/automated-funding/actions'
import { showNotification } from 'modules/global/actions'
import DailyFundingModule from 'modules/automated-funding/daily-funding'
import DailyFundingProcess from 'modules/automated-funding/daily-funding/process-fundings'
import DailyFundingAddToDay from 'modules/automated-funding/daily-funding/add-to-day'
import FundingBreadcrumbs from 'modules/automated-funding/utils/breadcrumbs'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'

const { PlusIcon, RefreshCWIcon, CheckIcon, DownloadIcon } = assets
const { Dialog } = components

const AFPDailyFundingPage = () => {
  const { dispatch, state } = useStore()
  const { isProcessedForToday } = state.automatedFunding
  const [selectedApps, setSelectedApps] = useState([])
  const [appsToProcess, setAppsToProcess] = useState([])
  const [addToDayApps, setAddToDayApps] = useState([])
  const [showProcessScreen, setShowProcessScreen] = useState(false)
  const [showAddToDayScreen, setShowAddToDayScreen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showRegenerateModal, setShowRegenerateModal] = useState(false)
  const breadcrumbItems = useMemo(() => {
    const items = [{ id: 'daily', label: 'Daily Funding' }]
    if (showAddToDayScreen) {
      items.push({ id: 'add', label: 'Add to Daily Funding' })
    }
    if (showProcessScreen) {
      items.push({ id: 'process', label: 'Process Daily Funding' })
    }
    return items
  }, [showAddToDayScreen, showProcessScreen])

  /** Fetch the list of Pending Processed apps, with filters & pagination */
  const fetchProcessedApps = (isPending, query = {}) => {
    setLoading(true)
    listDailyProcessedApps(dispatch, isPending, query).finally(() =>
      setLoading(false)
    )
  }

  /** Fetch the list of Daily Funding apps, with filters & pagination */
  const fetchList = (query = {}) => {
    setLoading(true)
    listDailyFundingApps(dispatch, query).finally(() => setLoading(false))
  }

  const fetchFundedApps = (query = {}) => {
    setLoading(true)
    listFundedApps(dispatch, query).finally(() => setLoading(false))
  }

  const loadAllData = () => {
    setLoading(true)
    Promise.all([
      listDailyFundingApps(dispatch, { pageNumber: 0 }),
      listDailyProcessedApps(dispatch, true, { pageNumber: 0 }, true),
      listDailyProcessedApps(dispatch, false, { pageNumber: 0 }, true),
      listFundedApps(dispatch, { pageNumber: 0 }),
    ]).finally(() => setLoading(false))
  }

  /** On mount, fetch 1st page for all data */
  useEffect(() => {
    loadAllData()
    setAppsToProcess([])
  }, [])

  /** Triggered when the Process Apps button is clicked */
  const onProcessDailyFunding = () => {
    setLoading(true)
    processDailyLoanApps(appsToProcess.map((app) => app.id))
      .then(() => {
        showNotification(dispatch, {
          message: 'Successfully processed applications',
        })
        setAppsToProcess([])
        setShowProcessScreen(false)
        loadAllData()
      })
      .catch(() => setLoading(false))
  }

  /** Triggered when new loan apps are added to Daily Funding */
  const onAddToDailyFunding = () => {
    setLoading(true)
    addToDaily(addToDayApps.map((app) => app.id))
      .then(() => {
        showNotification(dispatch, {
          message: 'Applications added to Daily Funding',
        })
        setAddToDayApps([])
        setShowAddToDayScreen(false)
        fetchList({ pageNumber: 0 })
      })
      .catch(() => setLoading(false))
  }

  const onExport = () => {
    setLoading(true)
    exportDailyFundingAFP().finally(() => setLoading(false))
  }

  const _renderHeader = () => {
    const processMode = showProcessScreen || isProcessedForToday
    const title = processMode
      ? 'Process Daily Funding'
      : showAddToDayScreen
      ? 'Add Applications'
      : 'Daily Funding'

    return (
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">{title}</div>
          <div>View all your daily funding loan applications in one place.</div>
        </div>
        <div className="afp__header__buttons">
          {!showAddToDayScreen && !processMode && (
            <>
              <Button
                color={BUTTON_COLORS.INHERIT}
                variant={BUTTON_VARIANTS.OUTLINED}
                disabled={isProcessedForToday}
                startIcon={<PlusIcon />}
                onClick={() => setShowAddToDayScreen(true)}
                loading={loading}
              >
                Add to the Day
              </Button>
              <Button
                color={BUTTON_COLORS.INHERIT}
                variant={BUTTON_VARIANTS.OUTLINED}
                disabled={isProcessedForToday}
                style={{ marginLeft: '12px' }}
                onClick={() => setShowRegenerateModal(true)}
                startIcon={<RefreshCWIcon />}
                loading={loading}
              >
                Regenerate Daily Funding
              </Button>
              <Button
                color={BUTTON_COLORS.INHERIT}
                variant={BUTTON_VARIANTS.OUTLINED}
                disabled={isProcessedForToday}
                style={{ marginLeft: '12px' }}
                onClick={onExport}
                startIcon={<DownloadIcon />}
                loading={loading}
              >
                Export Daily Funding
              </Button>
              <Button
                disabled={
                  isProcessedForToday || !Object.keys(selectedApps).length
                }
                startIcon={<CheckIcon />}
                onClick={() => {
                  setAppsToProcess([...selectedApps])
                  setSelectedApps([])
                  setShowProcessScreen(true)
                }}
                loading={loading}
                style={{ marginLeft: '12px' }}
              >
                Process Daily Funding
              </Button>
            </>
          )}
          {processMode && (
            <Button
              disabled={isProcessedForToday || !appsToProcess.length}
              startIcon={<CheckIcon />}
              onClick={onProcessDailyFunding}
              loading={loading}
            >
              Finalize Process
            </Button>
          )}
          {showAddToDayScreen && (
            <Button
              disabled={!addToDayApps.length}
              onClick={onAddToDailyFunding}
              startIcon={<CheckIcon />}
              loading={loading}
            >
              Add to Daily Funding
            </Button>
          )}
        </div>
      </div>
    )
  }

  const onRegenerateDailyFunding = () => {
    setLoading(true)
    return regenerateDailyFunding()
      .then(() => {
        setShowRegenerateModal(false)
        showNotification(dispatch, {
          message: 'Successfully regenerated applications',
        })
        fetchList({ pageNumber: 0 })
      })
      .catch(() => setLoading(false))
  }

  return (
    <div className="admin-page afp">
      <FundingBreadcrumbs
        items={breadcrumbItems}
        onItemClick={() => {
          setShowProcessScreen(false)
          setShowAddToDayScreen(false)
        }}
      />

      {_renderHeader()}

      <div className="admin-page__content">
        {showProcessScreen ? (
          <DailyFundingProcess apps={appsToProcess} />
        ) : showAddToDayScreen ? (
          <DailyFundingAddToDay setAddToDayApps={setAddToDayApps} />
        ) : (
          <DailyFundingModule
            loading={loading}
            onFetchList={fetchList}
            onFetchProcessedApps={fetchProcessedApps}
            onFetchFundedApps={fetchFundedApps}
            onUpdateDaily={(data) => updateDaily(dispatch, data)}
            setSelectedApps={setSelectedApps}
          />
        )}
      </div>

      <Dialog
        title="Regenerate Daily Funding"
        open={showRegenerateModal}
        className="afp__modal"
        onClose={() => setShowRegenerateModal(false)}
        PaperProps={{
          sx: {
            width: '500px',
          },
        }}
        actions={
          <>
            <Button
              onClick={() => setShowRegenerateModal(false)}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              Cancel
            </Button>
            <Button onClick={onRegenerateDailyFunding}>Yes</Button>
          </>
        }
      >
        Are you sure you want to regenerate the daily funding list?
      </Dialog>
    </div>
  )
}

export default AFPDailyFundingPage
