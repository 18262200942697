import React, { useEffect, useMemo, useRef, useState } from 'react'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import Table from 'components/table'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import TextInput from 'components/input'
import { useStore } from 'store'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { fetchTemplates } from './actions'
import { deleteTemplate } from './stipulation-templates-settings/actions'

const { Button } = components

const { createColumnHelper } = components
const columnHelper = createColumnHelper()
const { SearchLgIcon, PlusIcon } = assets

const StipulationTemplates = () => {
  const { dispatch } = useStore()
  const tableRef = useRef()
  const navigate = useNavigate()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const [isDataLoading, setDataLoading] = useState(true)

  const [state, setState] = useState({
    list: [],
    totalPages: 0,
  })

  const [filters, setFilters] = useState({
    sortBy: undefined,
    searchBy: '',
    pageNumber: 0,
    itemsPerPage: TEMPLATES_PAGE_SIZE,
  })

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'Template Title',
      }),
      columnHelper.accessor('version', {
        id: 'version',
        header: 'Version Number',
        enableSorting: true,
      }),
      columnHelper.accessor('dateCreated', {
        id: 'dateCreated',
        header: 'Date Created',
        enableSorting: true,
        cell: (info) => moment(info.getValue()).format('MMM. Do, YYYY'),
      }),
      columnHelper.accessor('dateLastModified', {
        id: 'dateLastModified',
        header: 'Last Modified',
        enableSorting: true,
        cell: (info) =>
          info.getValue() && moment(info.getValue()).format('MMM. Do, YYYY'),
      }),
    ]
  }, [])

  useEffect(() => {
    handleFetchTemplates(filters)
  }, [filters])

  const onSearch = (value) => {
    setFilters({ ...filters, searchBy: value, pageNumber: 0 })
  }

  useEffect(() => {
    tableRef.current?.setPageIndex(0)
  }, [filters.searchBy])

  const handleFetchTemplates = (filters) => {
    setDataLoading(true)
    fetchTemplates(filters)
      .then((res) => {
        setState({
          list: res?.stipulationTemplates,
          totalPages: Math.ceil(res.itemCount / TEMPLATES_PAGE_SIZE),
        })
      })
      .finally(() => {
        setDataLoading(false)
      })
  }

  const removeTemplate = (stipulationTemplateId) => {
    deleteTemplate(stipulationTemplateId, dispatch).then(() => {
      handleFetchTemplates(filters)
    })
  }

  const fetchData = (options) => {
    return Promise.resolve().then(() => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        sortBy: filters.sortBy,
        pageNumber: options.pageIndex,
      }))
    })
  }

  const onSortingChange = (data, direction) => {
    tableRef.current?.setPageIndex(0)
    setFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: `${data} ${direction}`,
      pageNumber: 1,
    }))
  }

  return (
    <div>
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">Stipulation Templates</div>
          <div>View all your Stipulations Templates in one place.</div>
        </div>
        <Button
          startIcon={<PlusIcon />}
          onClick={() =>
            navigate('/admin/stipulation-templates/add-new-template')
          }
          fullWidth={isMobileView}
          sx={{ mt: isMobileView ? 2 : 0 }}
        >
          Add New Template
        </Button>
      </div>

      <TextInput
        onChange={onSearch}
        value={filters.searchBy}
        placeholder={'Search templates'}
        type="search"
        className="input--search"
        disabled={false}
        style={{
          marginBottom: '32px',
          minWidth: 300,
        }}
        startIcon={<SearchLgIcon fontSize="small" />}
      />
      <Table
        data={state.list}
        columns={columns}
        ref={tableRef}
        title={'Stipulation Templates'}
        hasCheckboxes={true}
        selectable={true}
        totalPages={state.totalPages}
        hasPagination={true}
        onSortingChange={onSortingChange}
        fetchData={fetchData}
        onRowsRemove={(data, info, row) => {
          removeTemplate(row?.[0]?.stipulationTemplateId)
        }}
        onRowsEdit={(ev, row) =>
          navigate(
            `/admin/stipulation-templates/${row?.[0]?.stipulationTemplateId}`
          )
        }
        onRowClick={(ev, row) => {
          navigate(
            `/admin/stipulation-templates/${row?.original.stipulationTemplateId}`
          )
        }}
        dataLoading={isDataLoading}
        emptyTitle="No Stipulation Templates Found"
        emptyDescription="We couldn't find any data matching your criteria."
      />
    </div>
  )
}

export default StipulationTemplates

export const TEMPLATES_PAGE_SIZE = 7
