import { INPUT_TYPES } from 'components/input'

const CURRENT_YEAR = new Date().getFullYear()

export const DEPRECIATION_RATES = {
  2023: 0.28,
  2022: 0.26,
  2021: 0.26,
}

export const YEARS = Array.from({ length: 3 }, (_, i) => CURRENT_YEAR - (i + 1))

export const { ...SUBTOTAL_DEFAULT } = { 0: 0, 1: 0 }

export const ROW_TYPES = {
  CURRENCY: INPUT_TYPES.CURRENCY,
  PERCENTAGE: INPUT_TYPES.PERCENTAGE,
  NUMBER: INPUT_TYPES.NUMBER,
  HEADER: 'HEADER',
  SUBTOTAL: 'SUBTOTAL',
}

export const ROWS = {
  1: {
    id: '1',
    label: 'Recurring Interest Income',
    tooltip: `LINE 1 or 1040 Line 2b. Input interest income only from the borrower's business. Review Schedule B, Part I and/or IRS Schedule K-1 or Form 1099-INT to confirm payer is the business.`,
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  2: {
    id: '2',
    label: 'Recurring Dividend Income',
    tooltip: `LINE 5 or 1040 Line 3b. Input dividend income only from the borrower's business. Review Schedule B, Part II and/or IRS Schedule K-1 or Form 1099-DIV to confirm payer is the business.`,
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  3: {
    id: '2',
    label: 'W-2 Income from Self-Employment',
    tooltip: 'W-2, Box 5 (in general)',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  4: {
    id: '4',
    label: 'Net Profit (Loss)',
    tooltip: 'LINE 31',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  5: {
    id: '5',
    label: 'Deduct nonrecurring income/add nonrecurring loss or expense',
    tooltip:
      'LINE 6. Other income should be subtracted unless it can be identified, there is a history of receipt, and it can be documented as ongoing. Losses that have been documented as one-time expenses can be added back.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  6: {
    id: '6',
    label: 'Depletion',
    tooltip: 'LINE 12',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  7: {
    id: '7',
    label: 'Depreciation',
    tooltip: 'LINE 13',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  8: {
    id: '8',
    label: 'Non-Deductible Meals and Entertainment Exclusion',
    tooltip:
      'LINE 24b. In general, borrowers deduct 50% of these out-of-pocket costs on the tax return. Adjust here for the other 50% by entering the number found on LINE 24b.',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  9: {
    id: '9',
    label: 'Business Use of Home',
    tooltip: 'LINE 30',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  10: {
    id: '10',
    label: 'Business Miles',
    tooltip:
      'If standard mileage was claimed, enter mileage claimed either on Page 2, Part IV, Line 44a, OR on related 4562, Line 30. This amount will be used to calculate the depreciation add back.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  '11a': {
    id: '11a',
    label: 'x Depreciation Rate',
    tooltip: '2023: $0.28, 2022: $0.26, 2021: $0.26',
    isNegative: false,
    isReadOnly: true,
    type: ROW_TYPES.PERCENTAGE,
  },
  '11b': {
    id: '11b',
    label: '= Total Mileage Depreciation',
    isNegative: false,
    isReadOnly: true,
    type: ROW_TYPES.NUMBER,
  },
  12: {
    id: '12',
    label: 'Amortization/Casualty Loss (only if noted)',
    tooltip: 'Page 2, Part V',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  13: {
    id: '13',
    label: 'Recurring Capital Gains (Loss)',
    tooltip: 'Page 2, LINE 16 (details on FORM 8949)',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  14: {
    id: '14',
    label: 'Royalty Income (Loss)',
    tooltip: 'LINE 4',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  15: {
    id: '15',
    label: 'Total Expenses',
    tooltip: 'LINE 20',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  16: {
    id: '16',
    label: 'Depreciation Expense or Depletion',
    tooltip: 'LINE 18',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  17: {
    id: '17',
    label: 'Net Profit (Loss)',
    tooltip: 'LINE 34',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  18: {
    id: '18',
    label: 'Non-Tax Portion Ongoing Co-op & CCC Pmts',
    tooltip: 'LINES 3a minus b through 6a minus b',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  19: {
    id: '19',
    label: 'Deduct nonrecurring income',
    tooltip: 'LINE 2-8',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  20: {
    id: '20',
    label: 'Add nonrecurring loss',
    tooltip: 'LINE 2-8',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  21: {
    id: '21',
    label: 'Depreciation',
    tooltip: 'LINE 14',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  22: {
    id: '22',
    label: 'Amortization/Casualty Loss/Depletion (only if noted)',
    tooltip: 'LINE 32',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  23: {
    id: '23',
    label: 'Business Use of Home (only if noted)',
    tooltip: 'LINE 32',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },

  24: {
    id: '24',
    label: 'Ordinary Income (Loss)',
    tooltip:
      'LINE 1. If > Distributions see additional requirements. Can be used if borrower has a history of receiving cash distributions of income(found on Line 19, code A) consistent with the ordinary income or if adequate business liquidity can be verified. See liquidity worksheet for liquidity test.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  25: {
    id: '25',
    label: 'Net Rental Income (Loss)',
    tooltip:
      'LINES 2 & 3. If > Distributions see additional requirements. Can be used if borrower has a history of receiving cash distributions of income consistent with the net rental income or if adequate business liquidity can be verified. See liquidity worksheet for liquidity test.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  26: {
    id: '26',
    label: 'Guaranteed Payments',
    tooltip: 'LINE 4c. Document two-year history of receipt.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  27: {
    id: '27',
    label: 'Wages',
    tooltip: 'W-2, Box 5 (in general)',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  28: {
    id: '28',
    label: 'Passthrough (Income) Loss from Other Partnerships',
    tooltip:
      'LINE 4. Subtract income reflected on LINE 4 unless proven to be recurring. Add back in losses.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  29: {
    id: '29',
    label: 'Deduct nonrecurring income/add nonrecurring loss',
    tooltip:
      'LINES 5, 6 & 7. Other income should be subtracted unless it can be identified, there is a history of receipt, and it can be documented as ongoing. Losses that have been documented as one-time expenses can be added back.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  30: {
    id: '30',
    label: 'Depreciation',
    tooltip: 'LINE 16c',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  31: {
    id: '31',
    label: 'Depreciation (FORM 8825)',
    tooltip: 'LINE 14',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  32: {
    id: '32',
    label: 'Depletion',
    tooltip: 'LINE 17',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  33: {
    id: '33',
    label: 'Amortization/Casualty Loss (only if noted)',
    tooltip: 'LINE 21 from attached statement',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  34: {
    id: '34',
    label: 'Mortgages or Notes Payable in Less Than 1 Year',
    tooltip:
      'Schedule L, LINE 16, Column D. In general, deduct the amount in Column D, however, if you determine that these obligations have a "rollover" clause or there are ample liquid assets to cover these obligations, a deduction may not be necessary.',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  35: {
    id: '35',
    label: 'Non-Deductible Travel and Entertainment Exclusion',
    tooltip:
      'Schedule M-1, LINE 4b. In general, borrowers deduct 50% of these out-of-pocket costs on the tax return. Adjust here for the other 50% by entering the number found on LINE 4b.',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  36: {
    id: '36',
    label: 'Multiplied by ownership percentage',
    isNegative: false,
    type: ROW_TYPES.PERCENTAGE,
  },
  '36b': {
    id: '36b',
    label: `Partner's Total Share of Income (Loss)`,
    hideId: true,
    isNegative: false,
    isReadOnly: true,
    type: ROW_TYPES.NUMBER,
  },

  37: {
    id: '37',
    label: 'Ordinary Income (Loss)',
    tooltip:
      'LINE 1. If > Distributions see additional requirements. Can be used if borrower has a history of receiving cash distributions of income(found on Line 19, code A) consistent with the ordinary income or if adequate business liquidity can be verified. See liquidity worksheet for liquidity test.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  38: {
    id: '38',
    label: 'Net Rental Income (Loss)',
    tooltip:
      'LINES 2 & 3. If > Distributions see additional requirements. Can be used if borrower has a history of receiving cash distributions of income consistent with the net rental income or if adequate business liquidity can be verified. See liquidity worksheet for liquidity test.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  39: {
    id: '39',
    label: 'Wages',
    tooltip: 'W-2, Box 5 (in general)',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  40: {
    id: '40',
    label: 'Deduct nonrecurring income/add nonrecurring loss',
    tooltip:
      'LINES 5, 6 & 7. Other income should be subtracted unless it can be identified, there is a history of receipt, and it can be documented as ongoing. Losses that have been documented as one-time expenses can be added back.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  41: {
    id: '41',
    label: 'Depreciation',
    tooltip: 'LINE 16c',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  42: {
    id: '42',
    label: 'Depreciation (FORM 8825)',
    tooltip: 'LINE 14',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  43: {
    id: '43',
    label: 'Depletion',
    tooltip: 'LINE 17',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  44: {
    id: '44',
    label: 'Amortization/Casualty Loss (only if noted)',
    tooltip: 'LINE 21 from attached statement',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  45: {
    id: '34',
    label: 'Mortgages or Notes Payable in Less Than 1 Year',
    tooltip:
      'Schedule L, LINE 16, Column D. In general, deduct the amount in Column D, however, if you determine that these obligations have a "rollover" clause or there are ample liquid assets to cover these obligations, a deduction may not be necessary.',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  46: {
    id: '35',
    label: 'Non-Deductible Travel and Entertainment Exclusion',
    tooltip:
      'Schedule M-1, LINE 4b. In general, borrowers deduct 50% of these out-of-pocket costs on the tax return. Adjust here for the other 50% by entering the number found on LINE 4b.',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  47: {
    id: '47',
    label: 'Multiplied by ownership percentage',
    isNegative: false,
    type: ROW_TYPES.PERCENTAGE,
  },
  '47b': {
    id: '47b',
    label: `Partner's Total Share of Income (Loss)`,
    hideId: true,
    isNegative: false,
    isReadOnly: true,
    type: ROW_TYPES.NUMBER,
  },
  48: {
    id: '48',
    label: 'Wages',
    tooltip: 'W-2, Box 5 (in general)',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  49: {
    id: '49',
    label: 'Taxable Income',
    tooltip: 'LINE 30',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  50: {
    id: '50',
    label: 'Total Tax',
    tooltip: 'LINE 31. ',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  51: {
    id: '51',
    label: 'Deduct nonrecurring gains/add nonrecurring losses',
    tooltip:
      'LINES 8 & 9. Other gains should be subtracted unless there is a history of receipt, and it can be documented as ongoing. Losses that have been documented as one-time expenses can be added back.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  52: {
    id: '52',
    label: 'Deduct nonrecurring income/add nonrecurring losses',
    tooltip:
      'LINE 10. Other income should be subtracted unless it can be identified, there is a history of receipt, and it can be documented as ongoing. Losses that have been documented as one-time expenses can be added back.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  53: {
    id: '53',
    label: 'Depreciation',
    tooltip: 'LINE 20',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  54: {
    id: '54',
    label: 'Depletion',
    tooltip: 'LINE 21',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  55: {
    id: '55',
    label: 'Amortization/Casualty Loss (only if noted)',
    tooltip: 'LINE 26 from attached schedule',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  56: {
    id: '56',
    label: 'Net Operating Loss and Special Deductions',
    tooltip:
      'LINES 29 a&b. NOL should only be added back if letter explaining nature of loss, date of loss and amount of loss is obtained and there is an expectation that this type of event will not reoccur.',
    isNegative: false,
    type: ROW_TYPES.CURRENCY,
  },
  57: {
    id: '57',
    label: 'Mortgages or Notes Payable in Less Than 1 Year',
    tooltip:
      'Schedule L, LINE 17, Column D. In general, deduct the amount in column D, however if you determine that these obligations have a "rollover" clause or there are ample liquid assets to cover these obligations, a deduction may not be necessary.',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  58: {
    id: '58',
    label: 'Non-Deductible Travel and Entertainment Exclusion',
    tooltip:
      'Schedule M-1, LINE 5c. In most cases, the IRS allows the business to claim 50% for meals and travel. You must account for the non-deductible portion by entering the number found on Schedule M-1, LINE 5c.',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  59: {
    id: '59',
    label: 'Multiplied by Ownership Percentage',
    tooltip:
      'Include corporate earnings in cash flow only if the borrower is a 100% owner, as generally required by investors.',
    isNegative: false,
    type: ROW_TYPES.PERCENTAGE,
  },
  60: {
    id: '60',
    label: 'Dividends Paid to Borrower',
    tooltip:
      'Form 1040, Schedule B, LINE 5. Dividends payed to borrower as reflected on Schedule M-2 of Form 1120 will be reported on Schedule B of Form 1040 and should be deducted from the business cash flow if previously included in Schedule B cash flow to avoid double counting this income.',
    isNegative: true,
    type: ROW_TYPES.CURRENCY,
  },
  '60b': {
    id: '60b',
    label: `Corporation's Total Share of Income (Loss)`,
    hideId: true,
    isNegative: false,
    isReadOnly: true,
    type: ROW_TYPES.NUMBER,
  },
  schedule_k1: {
    id: 'schedule_k1',
    label: 'Schedule K-1',
    type: ROW_TYPES.HEADER,
  },
  schedule_k1_subtotal: {
    id: 'schedule_k1_subtotal',
    type: ROW_TYPES.SUBTOTAL,
    rows: [24, 25, 26],
  },
  schedule_k1_s_corporation_subtotal: {
    id: 'schedule_k1_s_corporation_subtotal',
    type: ROW_TYPES.SUBTOTAL,
    rows: [37, 38],
  },
  form_w2: {
    id: 'form_w2',
    label: 'Form W-2',
    type: ROW_TYPES.HEADER,
  },
  form_1065: {
    id: 'form_1065',
    label: 'Form 1065',
    type: ROW_TYPES.HEADER,
  },
  form_1065_subtotal: {
    id: 'form_1065_subtotal',
    type: ROW_TYPES.SUBTOTAL,
    rows: [28, 29, 30, 31, 32, 33, 34, 35],
  },
  form_1120: {
    id: 'form_1120',
    label: 'Form 1120S',
    type: ROW_TYPES.HEADER,
  },
  form_1120_subtotal: {
    id: 'form_1120_subtotal',
    type: ROW_TYPES.SUBTOTAL,
    rows: [40, 41, 42, 43, 44, 45, 46],
  },
  form_1120_corporation_subtotal: {
    id: 'form_1120_corporation_subtotal',
    type: ROW_TYPES.SUBTOTAL,
    rows: [49, 50, 51, 52, 53, 54, 55, 56, 57, 58],
  },
}

export const SELF_EMPLOYED_STATE = {
  borrowerDetails: {
    name: '',
    dateOfCompletion: '',
  },
  years: [CURRENT_YEAR - 1],
  sections: [
    {
      type: 'schedule_b',
      label: 'Schedule B',
      description: 'Interest and dividends from self-employment',
      rows: [[1], [2]],
      rowValues: {},
      subTotal: { ...SUBTOTAL_DEFAULT },
    },
    {
      type: 'schedule_c',
      label: 'Schedule C',
      description: 'Sole Proprietorship',
      hasNameField: true,
      canAddMultiple: true,
      rows: [[4], [5], [6], [7], [8], [9], [10, '11a', '11b'], [12]],
      rowValues: {},
      subTotal: { ...SUBTOTAL_DEFAULT },
    },
    {
      type: 'schedule_c_llc',
      label: 'Single Member LLC',
      description: 'Sole Proprietorship',
      hasNameField: true,
      rows: [[3], [4], [5], [6], [7], [8], [9], [10, '11a', '11b'], [12]],
      rowValues: {},
      subTotal: { ...SUBTOTAL_DEFAULT },
    },
    {
      type: 'schedule_d',
      label: 'Schedule D',
      description: 'Capital Gains and Losses',
      hasNameField: false,
      rows: [[13]],
      rowValues: {},
      subTotal: { ...SUBTOTAL_DEFAULT },
    },
    {
      type: 'schedule_e',
      label: 'Schedule E',
      description: 'Supplemental Income and Loss',
      hasNameField: false,
      rows: [[14], [15], [16]],
      rowValues: {},
      subTotal: { ...SUBTOTAL_DEFAULT },
    },
    {
      type: 'schedule_f',
      label: 'Schedule F',
      description: 'Farm Income',
      hasNameField: false,
      rows: [[17], [18], [20], [19], [21], [22], [23]],
      rowValues: {},
      subTotal: { ...SUBTOTAL_DEFAULT },
    },
    {
      type: 'partnership',
      label: 'Partnership',
      title: 'Partnership Cash Flow',
      subTitle: 'Evaluate business income as required by your investor',
      hasNameField: true,
      rows: [
        [ROWS.schedule_k1.id, 24],
        [25],
        [26, ROWS.schedule_k1_subtotal.id],
        [ROWS.form_w2.id, 27],
        [ROWS.form_1065.id, 28],
        [29],
        [30],
        [31],
        [32],
        [33],
        [34],
        [35],
        [ROWS.form_1065_subtotal.id, 36, '36b'],
      ],
      rowValues: {},
      noSubtotal: true,
      subTotal: { ...SUBTOTAL_DEFAULT },
      partialSubtotals: [
        { id: ROWS.schedule_k1_subtotal.id, value: { ...SUBTOTAL_DEFAULT } },
        {
          id: ROWS.form_1065_subtotal.id,
          value: { ...SUBTOTAL_DEFAULT },
          takeSubtotalFromRow: '36b',
        },
      ],
      canAddMultiple: true,
    },
    {
      type: 's_corporation',
      label: 'S Corporation',
      title: 'S Corporation Cash Flow',
      subTitle: 'Evaluate business income as required by your investor',
      hasNameField: true,
      rows: [
        [ROWS.schedule_k1.id, 37],
        [38, ROWS.schedule_k1_s_corporation_subtotal.id],
        [ROWS.form_w2.id, 39],
        [ROWS.form_1120.id, 40],
        [41],
        [42],
        [43],
        [44],
        [45],
        [46],
        [ROWS.form_1120_subtotal.id, 47, '47b'],
      ],
      rowValues: {},
      noSubtotal: true,
      subTotal: { ...SUBTOTAL_DEFAULT },
      partialSubtotals: [
        {
          id: ROWS.schedule_k1_s_corporation_subtotal.id,
          value: { ...SUBTOTAL_DEFAULT },
        },
        {
          id: ROWS.form_1120_subtotal.id,
          value: { ...SUBTOTAL_DEFAULT },
          takeSubtotalFromRow: '47b',
        },
      ],
      canAddMultiple: true,
    },
    {
      type: 'corporation',
      label: 'Corporation',
      title: 'Corporation Cash Flow',
      subTitle: 'Evaluate business income as required by your investor',
      hasNameField: true,
      rows: [
        [ROWS.form_w2.id, 48],
        [ROWS.form_1120.id, 49],
        [50],
        [51],
        [52],
        [53],
        [54],
        [55],
        [56],
        [57],
        [58],
        [ROWS.form_1120_corporation_subtotal.id, 59, 60, '60b'],
      ],
      rowValues: {},
      noSubtotal: true,
      subTotal: { ...SUBTOTAL_DEFAULT },
      partialSubtotals: [
        {
          id: ROWS.form_1120_corporation_subtotal.id,
          value: { ...SUBTOTAL_DEFAULT },
          takeSubtotalFromRow: '60b',
        },
      ],
      canAddMultiple: true,
    },
  ],
}

export const PARTNERSHIP_SUMMARY = [
  { id: ROWS.schedule_k1_subtotal.id, label: ROWS.schedule_k1.label },
  { id: 27, label: 'W2 Wages' },
  { id: ROWS.form_1065_subtotal.id, label: ROWS.form_1065.label },
]
export const S_CORPORATION_SUMMARY = [
  {
    id: ROWS.schedule_k1_s_corporation_subtotal.id,
    label: ROWS.schedule_k1.label,
  },
  { id: 39, label: 'W2 Wages' },
  { id: ROWS.form_1120_subtotal.id, label: ROWS.form_1120.label },
]
export const CORPORATION_SUMMARY = [
  { id: 48, label: 'W2 Wages' },
  { id: ROWS.form_1120_corporation_subtotal.id, label: ROWS.form_1120.label },
]

export const MONTH_OPTIONS = Array.from({ length: 13 }, (_, i) => ({
  id: i,
  label: `${i} months`,
}))
