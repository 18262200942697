import React, { useState, useMemo, useEffect } from 'react'
import {
  assets,
  components,
  unstable_components,
} from '@ElementsCapitalGroup/enium-ui'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import { setLoading, showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'
import { VALIDATION_TYPES, validate } from 'components/validator'
import TextField from 'components/input'

import { createEnvelope, updateLoanDocument } from '../actions'

import '../index.scss'

/**
 * @param {Object} options
 * @param {Boolean} options.edit - Checks if edit mode is on
 * @param {String} options.callback - Custom classnames
 * @param {Function} options.setIsLoanCreateVisible - Sets the visibility of the modal
 * @param {Object} options.loanDocumentToEdit - Loan document to be edited
 * @param {Array} options.dropdownDocumentTypes - Document Types array
 * @param {Array} options.dropdownDocumentRoles - Document Roles array
 */

const { Dropdown, Button } = components
const { Unstable_Grid: Grid } = unstable_components
const { PlusIcon } = assets

const LoanDocumentCreateForm = ({
  edit,
  callback,
  setIsLoanCreateVisible,
  loanDocumentToEdit,
  dropdownOrganizations,
  dropdownDocumentTypes,
  dropdownDocumentRoles,
}) => {
  const { state, dispatch } = useStore()
  const { loading } = state.global
  const navigate = useNavigate()
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  const getDocumentsForInput = () =>
    (loanDocumentToEdit?.documents?.length > 0 &&
      loanDocumentToEdit?.documents) || [
      {
        name: '',
        url: 'envelope-definitions/documents/test3.docx',
        contentType: '',
        docuSignTemplateId: '',
        documentTypeId: '',
        roleId: '',
      },
    ]

  const prepareDocuTypeField = (rawDocuments) =>
    rawDocuments?.map((docu) => ({
      ...docu,
      documentTypeId:
        docu.documentType?.id === 0 ? 0 : docu.documentType?.id || '',
      roleId: docu.role?.id === 0 ? 0 : docu.role?.id || '',
    }))

  const documentsForInput = useMemo(
    () => prepareDocuTypeField(getDocumentsForInput()),
    [loanDocumentToEdit]
  )

  const [loanDocumentDetails, setLoanDocumentDetails] = useState({
    envelopeName: '',
    lenderId: '',
    documents: documentsForInput,
    guid: loanDocumentToEdit?.guid || null,
  })

  const [allErrors, setErrors] = useState({
    envelopeName: null,
    lenderId: null,
  })

  const [documentErrors, setDocumentsErorrs] = useState([])

  useEffect(() => {
    setLoanDocumentDetails({
      envelopeName: loanDocumentToEdit?.name || '',
      lenderId: loanDocumentToEdit?.lenderId || '',
      documents: documentsForInput,
      guid: loanDocumentToEdit?.guid || null,
    })
  }, [loanDocumentToEdit, documentsForInput])

  const handleAddNewDocument = () => {
    setLoanDocumentDetails({
      ...loanDocumentDetails,
      documents: [...loanDocumentDetails.documents, MOCK_DOCUMENT_TEMPLATE],
    })
  }

  useEffect(() => {
    setDocumentsErorrs(
      documentsForInput.map(() => ({
        typeId: '',
        roleId: '',
      }))
    )
  }, [documentsForInput])

  const handleDocumentsValidation = () => {
    let hasErrors = false
    const errors = loanDocumentDetails.documents.map((docu) => {
      const docErrors = {
        typeId: '',
        roleId: '',
      }
      if (docu.documentTypeId === '' || docu.documentTypeId === null) {
        hasErrors = true
        docErrors.typeId = 'This field is required.'
      }

      if (docu.roleId === '' || docu.roleId === null) {
        hasErrors = true
        docErrors.roleId = 'This field is required.'
      }

      return docErrors
    })

    setDocumentsErorrs(errors)

    return hasErrors
  }

  const onSubmit = () => {
    const [isEnvelopeValid, envelopeErrors] = validate(
      LOAN_DOCUMENT_ERROR_MAP,
      { ...loanDocumentDetails }
    )

    const hasDocumentsErrors = handleDocumentsValidation()

    if (isEnvelopeValid && !hasDocumentsErrors) {
      setLoading(dispatch, true)

      if (edit) {
        const newDocuments = loanDocumentDetails.documents.filter(
          (elem) =>
            !loanDocumentToEdit.documents.find(
              ({ guid }) => elem.guid === guid
            ) && elem
        )

        const formattedLoanDocuments = {
          envelopeId: loanDocumentDetails.guid,
          name: loanDocumentDetails.envelopeName,
          lenderId: loanDocumentDetails.lenderId,
          roleId: loanDocumentDetails.roleId,
          documentUpdates: loanDocumentDetails.documents.filter(
            (elem) =>
              !newDocuments.find(({ guid }) => elem.guid === guid && elem)
          ),
          newDocuments: newDocuments,
        }

        updateLoanDocument(formattedLoanDocuments)
          .then(() => {
            setIsLoanCreateVisible(false)
            callback(loanDocumentDetails.lenderId)
            showNotification(dispatch, {
              title: `Successfully updated Loan Document`,
            })
          })
          .catch(() => {
            showNotification(dispatch, {
              type: NOTIFICATION_TYPES.NEGATIVE,
              title: `Successfully update Loan Document`,
            })
          })
          .finally(() => setLoading(dispatch, false))
      } else {
        createEnvelope(loanDocumentDetails)
          .then(() => {
            callback(loanDocumentDetails.lenderId)
            setIsLoanCreateVisible(false)
            navigate('/admin/loan-document')
            showNotification(dispatch, {
              title: `Successfully created Loan Document`,
            })
          })
          .finally(() => setLoading(dispatch, false))
      }
    } else {
      setErrors(envelopeErrors)
    }
  }

  const handleArrayDocumentChange = (value, key, element) => {
    setLoanDocumentDetails({
      ...loanDocumentDetails,
      documents: loanDocumentDetails.documents.map((itm, idx) => {
        if (key === idx) {
          return {
            ...itm,
            [element]: value,
          }
        } else {
          return itm
        }
      }),
    })
  }

  const handleArrayDocumentDropdownChange = (event, key, element) => {
    setLoanDocumentDetails((prevDetails) => ({
      ...prevDetails,
      documents: prevDetails.documents.map((itm, idx) => {
        if (key === idx) {
          return {
            ...itm,
            [element]: event.target.value.id,
            role: element === 'roleId' ? event.target.value : itm.role,
          }
        }
        return itm
      }),
    }))
  }

  const formattedOrgs = useMemo(() => {
    return dropdownOrganizations.map((item) => ({
      id: item.id,
      label: item.value,
    }))
  }, [dropdownOrganizations])

  const formattedRoles = useMemo(() => {
    return dropdownDocumentRoles.map((itm) => {
      return {
        label: itm.value,
        id: itm.guid,
      }
    })
  }, [dropdownDocumentRoles])

  const formattedDocumentTypes = useMemo(() => {
    return dropdownDocumentTypes.map((itm) => {
      return {
        id: itm.guid,
        label: itm.value,
      }
    })
  }, [dropdownDocumentTypes])

  return (
    <div style={{ marginTop: '21px' }}>
      {!edit ? (
        <div className="loan-document__header">
          <div className="history__title" style={{ marginBottom: '16px' }}>
            Add Document
          </div>
          <Button startIcon={<PlusIcon />} onClick={onSubmit}>
            {edit ? 'Edit' : 'Add'} Document
          </Button>
        </div>
      ) : (
        <div className="loan-document__header">
          <div className="history__title">{loanDocumentToEdit.name}</div>
          <Button
            loading={loading}
            onClick={onSubmit}
            fullWidth={isTabletView}
            sx={{ mt: isTabletView ? 2 : 0 }}
          >
            {edit ? 'Edit' : 'Create'} Template
          </Button>
        </div>
      )}
      <Grid
        container
        flexDirection="column"
        gapY={24}
        className="loan-document__paper"
      >
        <Grid item className="loan-document__info">
          Template Info
        </Grid>
        <Grid container gap={24}>
          <Grid item mobile={12} tablet={6} desktop={6}>
            <TextField
              label={'Template Name'}
              value={loanDocumentDetails.envelopeName}
              onChange={(value) =>
                setLoanDocumentDetails({
                  ...loanDocumentDetails,
                  envelopeName: value,
                })
              }
              fullWidth
              validate={() => allErrors.envelopeName}
            />
          </Grid>
          <Grid item mobile={12} tablet={6} desktop={6}>
            <Dropdown
              label="Lender"
              options={formattedOrgs}
              error={!!allErrors?.lenderId}
              helperText={allErrors?.lenderId}
              value={
                formattedOrgs.find(
                  (itm) => itm.id === loanDocumentDetails.lenderId
                ) || ''
              }
              onChange={(item) =>
                setLoanDocumentDetails({
                  ...loanDocumentDetails,
                  lenderId: item.target.value.id,
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>

      {loanDocumentDetails.documents.map((item, key) => {
        return (
          <Grid container gapY={24} key={key} className="loan-product__paper">
            <span
              style={{
                fontSize: '20px',
                fontWeight: 600,
              }}
            >
              Document Info
            </span>
            <Grid container gap={24}>
              <Grid item mobile={12} tablet={6} desktop={4}>
                <TextField
                  label={`Document Name ${key + 1}`}
                  value={item.name}
                  disabled={loading}
                  onChange={(value) =>
                    handleArrayDocumentChange(value, key, 'name')
                  }
                  fullWidth
                />
              </Grid>
              <Grid item mobile={12} tablet={6} desktop={4}>
                <TextField
                  label={`Content Type ${key + 1}`}
                  value={item.contentType}
                  disabled={loading}
                  onChange={(value) =>
                    handleArrayDocumentChange(value, key, 'contentType')
                  }
                  fullWidth
                />
              </Grid>
              <Grid item mobile={12} tablet={6} desktop={4}>
                <Dropdown
                  error={!!documentErrors[key]?.roleId}
                  helperText={documentErrors[key]?.roleId}
                  label="Document Role"
                  options={formattedRoles}
                  value={
                    formattedRoles.find(
                      (itm) =>
                        itm.id === item.roleId || itm.id === item.role?.id
                    ) || ''
                  }
                  onChange={(event) => {
                    handleArrayDocumentDropdownChange(event, key, 'roleId')
                  }}
                  disabled={loading}
                />
              </Grid>
              <Grid item mobile={12} tablet={6} desktop={4}>
                <TextField
                  onChange={(value) =>
                    handleArrayDocumentChange(value, key, 'url')
                  }
                  label={'Url'}
                  disabled={loading}
                  fullWidth
                  css={{ marginRight: 24 }}
                  value={item.url}
                />
              </Grid>
              <Grid item mobile={12} tablet={6} desktop={4}>
                <TextField
                  onChange={(value) =>
                    handleArrayDocumentChange(value, key, 'docuSignTemplateId')
                  }
                  label={'Docusign Template Id'}
                  disabled={loading}
                  fullWidth
                  css={{ marginRight: 24 }}
                  value={item.docuSignTemplateId}
                />
              </Grid>
              <Grid item mobile={12} tablet={6} desktop={4}>
                <Dropdown
                  error={!!documentErrors[key]?.typeId}
                  helperText={documentErrors[key]?.typeId}
                  label="Document Type"
                  className="loan-form__dropdown"
                  fullWidth
                  options={formattedDocumentTypes}
                  value={
                    formattedDocumentTypes.find(
                      (itm) => itm.id === item.documentTypeId
                    ) || ''
                  }
                  onChange={(event) => {
                    handleArrayDocumentDropdownChange(
                      event,
                      key,
                      'documentTypeId'
                    )
                  }}
                  disabled={loading}
                />
              </Grid>
            </Grid>
          </Grid>
        )
      })}

      <Button
        sx={{ border: 'none', mb: isTabletView ? 2 : 0 }}
        variant="outlined"
        startIcon={<PlusIcon />}
        onClick={() => handleAddNewDocument()}
      >
        Add New Document
      </Button>
    </div>
  )
}

const MOCK_DOCUMENT_TEMPLATE = {
  name: '',
  contentType: '',
  url: '',
  docuSignTemplateId: '',
  documentTypeId: '',
}

LoanDocumentCreateForm.propTypes = {
  edit: PropTypes.bool,
  setIsLoanCreateVisible: PropTypes.func,
  dropdownOrganizations: PropTypes.array,
  dropdownDocumentTypes: PropTypes.array,
  dropdownDocumentRoles: PropTypes.array,
  callback: PropTypes.func,
  loanDocumentToEdit: PropTypes.object,
}

export default LoanDocumentCreateForm

export const LOAN_DOCUMENT_ERROR_MAP = {
  envelopeName: [VALIDATION_TYPES.REQUIRED],
  lenderId: [VALIDATION_TYPES.REQUIRED],
}
