import React from 'react'
import PropTypes from 'prop-types'

import { hasAccess } from 'common/access'
import { VIEW_LOAN_APP } from 'common/claims'
import Table from 'components/table'
import { useStore } from 'store'

import { extractUrl } from './utils'
import './style.scss'
import { LOAN_APPS_PAGE_SIZE } from './constants'

const LoanApplicationsTable = React.forwardRef(
  (
    {
      columns,
      list,
      itemCount,
      isCommercialList,
      fetchData,
      loading,
      onSortingChange,
      emptyTitle,
      emptyDescription,
      emptyActionButtonLabel,
      emptyActionButtonOnClick,
      getVisibleColumns,
      columnsVisibilityData,
    },
    ref
  ) => {
    const { state } = useStore()

    const canViewLoans = hasAccess(state.session.userData, VIEW_LOAN_APP)

    const handleOnRowClick = (e, row) => {
      const openInNewTab = e.button === 1 || e.ctrlKey || e.metaKey

      const { url } = extractUrl(row, canViewLoans, isCommercialList)
      url && window.open(url, openInNewTab ? '_blank' : '_self')
    }

    return (
      <Table
        ref={ref}
        columns={columns}
        data={list}
        title={'Loan Applications'}
        hasPagination={true}
        dataLoading={loading}
        totalPages={Math.ceil(itemCount / LOAN_APPS_PAGE_SIZE)}
        fetchData={fetchData}
        onRowClick={handleOnRowClick}
        onSortingChange={onSortingChange}
        showColumnSelector={true}
        emptyTitle={emptyTitle}
        emptyDescription={emptyDescription}
        getVisibleColumns={getVisibleColumns}
        columnsVisibilityData={columnsVisibilityData}
        emptyActionButtonLabel={emptyActionButtonLabel}
        emptyActionButtonOnClick={emptyActionButtonOnClick}
      />
    )
  }
)

LoanApplicationsTable.propTypes = {
  isCommercialList: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      customer: PropTypes.string.isRequired,
      loanApplicationNumber: PropTypes.string.isRequired,
      organization: PropTypes.string.isRequired,
      salesRepresentative: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      dateCreated: PropTypes.string.isRequired,
      lastModified: PropTypes.string.isRequired,
      loanApplicationStatusId: PropTypes.number.isRequired,
      fullAddress: PropTypes.string,
      expiresInDays: PropTypes.number,
    })
  ).isRequired,
  emptyTitle: PropTypes.string,
  emptyDescription: PropTypes.string,
  emptyActionButtonLabel: PropTypes.string,
  emptyActionButtonOnClick: PropTypes.func,
  loading: PropTypes.bool,
  getVisibleColumns: PropTypes.func,
  itemCount: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  columnsVisibilityData: PropTypes.object,
}

export default LoanApplicationsTable
