import { useEffect } from 'react'
import PropTypes from 'prop-types'

import StipulationList from './stipulation-list'

export const TemplateContent = ({
  template,
  updateTemplate,
  stipulationDefinitions,
  errors,
  isCommercial,
  selected,
  setSelected,
}) => {
  useEffect(() => {
    updateTemplate({ ...template, isCommercial: isCommercial })
  }, [isCommercial])

  useEffect(() => {
    stipulationDefinitions.forEach((item) => {
      const stipulationIndex = template?.initialStipulations?.findIndex(
        (stipulationItem) => stipulationItem.guid === item.id
      )
      const finalStipulationIndex = template?.finalStipulations?.findIndex(
        (stipulationItem) => stipulationItem.guid === item.id
      )

      const commercialStipulationIndex =
        template?.commercialStipulations?.findIndex(
          (stipulationItem) => stipulationItem.guid === item.id
        )

      setSelected((prev) => {
        if (stipulationIndex !== -1) {
          prev.initialStipulations[stipulationIndex] = item.id
        }
        if (finalStipulationIndex !== -1) {
          prev.finalStipulations[finalStipulationIndex] = item.id
        }
        if (commercialStipulationIndex !== -1) {
          prev.commercialStipulations[commercialStipulationIndex] = item.id
        }
        return prev
      })
    })
  }, [])

  const renderResidentialStipulationList = () => (
    <>
      <div className="stipulations__template">
        <span className="stipulations__title">Initial Stipulation</span>
        <div className="admin-template__decisions">
          <StipulationList
            list={template?.initialStipulations}
            onListChange={(stipulations) => {
              updateTemplate({ ...template, initialStipulations: stipulations })
              stipulations.forEach((stip, key) => {
                setSelected((prev) => {
                  prev.initialStipulations[key] = stip.guid
                  return prev
                })
              })
            }}
            parentId={template?.stipulationTemplateId}
            errors={errors?.initialStipulations || []}
            stipulationDefinitions={stipulationDefinitions}
            selected={selected}
            setSelected={setSelected}
            initialStipulation
          />
        </div>
      </div>
      <div className="stipulations__template">
        <span className="stipulations__title">Final Stipulation</span>
        <div className="admin-template__decisions">
          <StipulationList
            list={template?.finalStipulations}
            onListChange={(stipulations) => {
              stipulations.forEach((stip, key) => {
                setSelected((prev) => {
                  prev.finalStipulations[key] = stip.guid
                  return prev
                })
              })
              updateTemplate({ ...template, finalStipulations: stipulations })
            }}
            parentId={template?.stipulationTemplateId}
            errors={errors?.finalStipulations || []}
            stipulationDefinitions={stipulationDefinitions}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      </div>
    </>
  )

  const renderCommercialStipulationList = () => (
    <>
      <div className="stipulations__template">
        <span className="stipulations__title">Commercial Stipulation</span>
        <div className="admin-template__decisions">
          <StipulationList
            list={template?.commercialStipulations}
            onListChange={(stipulations) => {
              updateTemplate({
                ...template,
                commercialStipulations: stipulations,
              })
              stipulations.forEach((stip, key) => {
                setSelected((prev) => {
                  prev.commercialStipulations[key] = stip.guid
                  return prev
                })
              })
            }}
            parentId={template?.stipulationTemplateId}
            errors={errors?.commercialStipulations || []}
            stipulationDefinitions={stipulationDefinitions}
            selected={selected}
            setSelected={setSelected}
            commercialStipulation
          />
        </div>
      </div>
    </>
  )

  return (
    <>
      {isCommercial
        ? renderCommercialStipulationList()
        : renderResidentialStipulationList()}
    </>
  )
}

TemplateContent.propTypes = {
  template: PropTypes.shape({
    stipulationTemplateId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    details: PropTypes.string,
    lender: PropTypes.string,
    version: PropTypes.string,
    decisions: PropTypes.array,
    finalStipulations: PropTypes.array,
    initialStipulations: PropTypes.array,
    commercialStipulations: PropTypes.array,
  }).isRequired,
  updateTemplate: PropTypes.func.isRequired,
  stipulationDefinitions: PropTypes.array,
  addNew: PropTypes.bool,
  isCommercial: PropTypes.bool,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  errors: PropTypes.array,
  canSelectCommercialStipulation: PropTypes.bool,
}
