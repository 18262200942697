import { v4 as uuidv4 } from 'uuid'
import Api from 'easy-fetch-api'

import { ROLE_TYPES } from 'modules/admin/constants'
import { getPrefillData } from 'modules/loan-application/utils'

import {
  NOTIFICATION_TYPES,
  NOTIFICATION_ENTITY_TYPE_IDS,
} from './notifications'

/** Site-wide actions */
export const GLOBAL_ACTIONS = {
  SET_LOADING: 'global.setLoading',
  SHOW_NOTIFICATION: 'global.showNotification',
  HIDE_NOTIFICATION: 'global.hideNotification',
  GET_MESSAGE_TYPES: 'global.messageTypes',
  INITIALIZE_PREFILL_DATA: 'global.startPrefillData',
  PREFILL_USER_DATA: 'global.prefillUserData',
  RESET_PREFILL_DATA: 'global.resetPrefillData',
  RESET_PREFILL_FLAG: 'global.resetPrefillFlag',
  TOGGLE_LOAN_CALCULATOR: 'global.toggleLoanCalculator',
}

/**
 * Show/hide the Global Loading Overlay
 * @param {Function} dispatch
 * @param {Boolean} loading
 */
export const setLoading = (dispatch, loading) =>
  dispatch({ type: GLOBAL_ACTIONS.SET_LOADING, loading })

export const initializePrefillData = (dispatch) =>
  dispatch({ type: GLOBAL_ACTIONS.INITIALIZE_PREFILL_DATA })

/**
 * Show a global notification
 * @param {Function} dispatch
 * @param {Object} notification
 * @param {String} [notification.type] one of {@see NOTIFICATION_TYPES}. Defaults to NOTIFICATION_TYPES.POSITIVE
 * @param {String} notification.message message to display
 * @param {String} notification.title title to display
 * @param {String} notification.learnMoreUrl url for learn more button
 * @param {String} notification.viewChangesUrl url for view changes button

 * @param {Boolean} [notification.autoHide] defaults to true - auto-hides in 10 seconds
 * @param {String} [notification.redirectUrl]
 * @param {Boolean} [notification.hoverable]
 * @param {Number} [notification.timeout] timeout in milliseconds
 */
export const showNotification = (
  dispatch,
  {
    type = NOTIFICATION_TYPES.POSITIVE,
    message,
    autoHide = true,
    title,
    redirectUrl,
    hoverable = false,
    learnMoreUrl,
    viewChangesUrl,
    timeout = 10000,
  }
) => {
  const id = uuidv4()
  dispatch({
    type: GLOBAL_ACTIONS.SHOW_NOTIFICATION,
    notification: {
      type,
      message,
      id,
      redirectUrl,
      hoverable,
      title,
      learnMoreUrl,
      viewChangesUrl,
    },
  })
  // Auto-hide after 10 seconds
  autoHide && setTimeout(() => hideNotification(dispatch, id), timeout)
}

/**
 * Hide a notification by ID
 * @param {Function} dispatch
 * @param {String} id - notification ID
 */
export const hideNotification = (dispatch, id) =>
  dispatch({ type: GLOBAL_ACTIONS.HIDE_NOTIFICATION, id })

export const getMessageTypes = (dispatch) =>
  Api.get({ url: `/Notifications/message-types` }).then((res) =>
    dispatch({
      type: GLOBAL_ACTIONS.GET_MESSAGE_TYPES,
      messageTypes: res || [],
    })
  )

/**
 * Checks if a user should see a notification or not
 * @return {boolean}
 */
export const checkAccessToNotification = (userData, messageObj) => {
  const userId = userData.associations?.[0]?.userId
  // If the current user is the one that triggered the notification, don't show it
  if (messageObj.userId === userId) {
    return false
  }

  // If the entity type is loan application and the current user is not the sales rep of the loan, don't show it
  if (
    messageObj.entityTypeId === NOTIFICATION_ENTITY_TYPE_IDS.LOAN_APPLICATION &&
    userData.roleTypeId === ROLE_TYPES.SALES &&
    messageObj.receivingRoleTypesIds.includes(ROLE_TYPES.SALES) &&
    messageObj.salesRepresentativeId !== userId
  ) {
    return false
  }

  // Check if the current user has the necessary role & organization to see the notification
  if (!messageObj.receivingRoleTypesIds.includes(userData.roleTypeId)) {
    return false
  }

  return messageObj.salesOrganizationId === userData.organizationId
}

/** Final part of the ID Verification Process - hook called by the BE following a Stripe Verification session */
export const prefillUserDataFromStripe = async (dispatch, sessionId) => {
  const data = await getPrefillData(sessionId)

  if (data) {
    dispatch({
      type: GLOBAL_ACTIONS.PREFILL_USER_DATA,
      sessionId,
      applicantIndex: data.applicantIndex,
      data,
    })
    showNotification(dispatch, {
      type: NOTIFICATION_TYPES.POSITIVE,
      title: 'ID verification was successful',
    })
  } else {
    showNotification(dispatch, {
      type: NOTIFICATION_TYPES.NEGATIVE,
      title: 'Error verifying your ID. Please try again.',
    })
  }
}

export const resetPrefillData = (dispatch) =>
  dispatch({
    type: GLOBAL_ACTIONS.RESET_PREFILL_DATA,
  })

export const resetPrefillHappenedFlag = (dispatch) =>
  dispatch({ type: GLOBAL_ACTIONS.RESET_PREFILL_FLAG })

export const toggleLoanCalculator = (dispatch, isOpen) =>
  dispatch({
    type: GLOBAL_ACTIONS.TOGGLE_LOAN_CALCULATOR,
    isOpen,
  })
