import React, { useEffect, useMemo, useState } from 'react'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Table from 'components/table'
import TextArea from 'components/textarea'
import TextField from 'components/input'
import Button, { BUTTON_COLORS } from 'components/button'
import { validate } from 'components/validator'
import Loader from 'components/loader'
import { useStore } from 'store'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'

import { addMessage, loadFirebaseData, toggleOutageMessage } from './actions'

import {
  INITIAL_MESSAGE_STATE,
  INITIAL_CONFIG_STATE,
  MESSAGE_TYPES,
  ERROR_MAP,
} from './constants'
import './style.scss'

/**
 * The CMS Module used to configure Outage Messages
 * @constructor
 */

const { Dropdown, createColumnHelper, Switch } = components
const ConfigOutageMessagesModule = () => {
  const { dispatch } = useStore()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ ...INITIAL_MESSAGE_STATE })
  const [config, setConfig] = useState({ ...INITIAL_CONFIG_STATE })
  const [errors, setErrors] = useState({})
  const [list, setList] = useState(null)
  const columnHelper = createColumnHelper()
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  useEffect(() => {
    loadMessages()
  }, [])

  const loadMessages = async () => {
    setLoading(true)
    const { list, config } = await loadFirebaseData(dispatch)
    setList(list)
    setConfig(config)
    setLoading(false)
  }

  /** Shows/hides an Outage Message from the Website */
  const toggleMessage = (message, isDisplayed = true) => {
    setLoading(true)
    const fieldName =
      message.type === MESSAGE_TYPES[0].id
        ? 'displayedAlertId'
        : 'displayedBannerId'

    const valueToSet = isDisplayed ? message.id : ''

    toggleOutageMessage(fieldName, valueToSet)
      .then(loadMessages)
      .finally(() => setLoading(false))
  }

  /** Adds a message to the list of all Outage Messages */
  const addNewMessage = (toggleMessageCallback) => {
    const [isValid, errors] = validate(ERROR_MAP, message)
    setErrors(errors)
    if (isValid) {
      setLoading(true)
      addMessage(message)
        .then(async (doc) => {
          loadMessages()
          setMessage({ ...INITIAL_MESSAGE_STATE })
          const insertedItem = await doc.get()
          toggleMessageCallback &&
            toggleMessageCallback({ id: doc.id, ...insertedItem.data() })
        })
        .finally(() => setLoading(false))
    }
  }

  /** Adds a message to the list & also toggle it automatically */
  const addMessageAndDisplayIt = () => {
    addNewMessage(toggleMessage)
  }

  const updateState = (fieldName, fieldValue, isDropdown) => {
    setErrors({ ...errors, [fieldName]: null })
    setMessage({ ...message, [fieldName]: fieldValue })
  }

  const sortedList = useMemo(() => {
    if (!list) {
      return []
    }

    return list
      .map((item) => ({
        ...item,
        $children: [{ title: item.text }],
      }))
      .sort((a, b) =>
        isMessageDisplayed(a.id, config) && !isMessageDisplayed(b.id, config)
          ? -1
          : 1
      )
  }, [list])

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('title', {
        header: 'Title',
      }),
      columnHelper.accessor('type', {
        header: 'Type',
      }),
      columnHelper.accessor('dismissible', {
        header: 'Dismissible',
      }),
      columnHelper.accessor('toggle', {
        header: '',
        cell: (info) => {
          if (isMessageDisplayed(info.row.original.id, config)) {
            return (
              <Switch
                checked={true}
                onChange={() => toggleMessage(info.row.original, false)}
              />
            )
          } else {
            return (
              <Switch
                checked={false}
                onChange={() => toggleMessage(info.row.original, true)}
              />
            )
          }
        },
      }),
    ]
  }, [sortedList])

  return (
    <div className="admin-page">
      <div className="admin-page__header-wrapper">
        <div className="admin-page__header">Outage Notices</div>
      </div>
      <div>
        <div className="outage-message__paper">
          <div className="outage-message__subtitle">New Outage Notice</div>
          <div className="outage-message__fields-wrapper">
            <div className="outage-message__fields">
              <TextField
                label="Title"
                value={message.title}
                validate={() => errors.title}
                onChange={(title) => updateState('title', title)}
                fullWidth
                placeholder="Alert 1"
                style={{
                  marginRight: !isTabletView ? '12px' : 0,
                  marginTop: !isTabletView ? '2.5px' : 0,
                }}
              />
              <div className="outage-message__margin">
                <Dropdown
                  label="Type"
                  value={
                    MESSAGE_TYPES.find((itm) => itm.id === message.type) || ''
                  }
                  placeholder="Select Type"
                  options={MESSAGE_TYPES}
                  onChange={(ev) => {
                    updateState('type', ev.target.value.id, true)
                  }}
                  style={{
                    marginRight: !isTabletView ? '12px' : 0,
                  }}
                />
              </div>
              <div className="outage-message__margin">
                <Dropdown
                  label="Dismissible"
                  placeholder="Select"
                  value={
                    DISMISSIBLE_OPTIONS.find(
                      (itm) => itm.value === message.dismissible
                    ) || ''
                  }
                  options={DISMISSIBLE_OPTIONS}
                  onChange={(ev) =>
                    updateState('dismissible', ev.target.value.value)
                  }
                />
              </div>
            </div>
            <TextArea
              label="Message"
              value={message.text}
              placeholder="Enter a description..."
              validate={() => errors.text}
              onChange={(text) => updateState('text', text)}
              fullWidth
              style={{ marginTop: '24px' }}
            />
          </div>
          <div className="outage-message__buttons">
            <Button
              sx={{ mr: 1, mb: !isTabletView ? 0 : '16px' }}
              onClick={addMessageAndDisplayIt}
              disabled={loading}
            >
              Post Now
            </Button>

            <Button
              onClick={() => addNewMessage(null)}
              disabled={loading}
              color={BUTTON_COLORS.SECONDARY}
            >
              Add to List
            </Button>
          </div>
        </div>
      </div>
      <div className="outage-message__table">
        {loading ? (
          <Loader center={true} noLogo={true} />
        ) : (
          <Table
            data={sortedList}
            columns={columns}
            title={'Outage Notices'}
            hasCheckboxes={false}
            emptyTitle="No outage messages."
          />
        )}
      </div>
    </div>
  )
}

function isMessageDisplayed(msgId, config) {
  return msgId === config.displayedAlertId || msgId === config.displayedBannerId
}
export default ConfigOutageMessagesModule

const DISMISSIBLE_OPTIONS = [
  {
    id: 'yes',
    label: 'Yes',
    value: true,
  },
  {
    id: 'no',
    label: 'No',
    value: false,
  },
]
