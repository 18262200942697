import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Table from 'components/table'
import SearchAndFilterModule from 'components/search-filter-module'
import { useEffectOnUpdate } from 'common/hooks'
import { formatDate } from 'common/date'
import { useStore } from 'store'

import FundingDetails from '../utils/funding-details'
import { dailyFundingColumns } from '../utils/constants'
import { getFundingDetailsObject } from '../utils'
import ExpandedRowContents from './expanded-row-contents'
import '../style.scss'
import './style.scss'

const DailyFundingModule = ({
  loading,
  onFetchList,
  onFetchProcessedApps,
  onFetchFundedApps,
  onUpdateDaily,
  setSelectedApps,
}) => {
  const { state } = useStore()
  const tableRef = useRef()
  const {
    dailyFundingApps,
    dailyFundingPages,
    isProcessedForToday,
    dailyPendingApps,
    dailyPendingPages,
    dailyUnfundedApps,
    dailyUnfundedPages,
    fundedApps,
    fundedPages,
    hasFundedApps,
  } = state.automatedFunding
  const user = state.session.userData

  const [filters, setFilters] = useState({
    sortBy: undefined,
    searchBy: undefined,
    pageNumber: 0,
    itemsPerPage: 10,
    fundingDate: formatDate(new Date()),
  })
  const [filtersForPending, setFiltersForPending] = useState({
    searchBy: undefined,
    pageNumber: 0,
  })
  const [filtersForUnfunded, setFiltersForUnfunded] = useState({
    searchBy: undefined,
    pageNumber: 0,
  })
  const [filtersForFunded, setFiltersForFunded] = useState({
    searchBy: undefined,
    pageNumber: 0,
  })
  const fundingDetails = useMemo(() => {
    if (!isProcessedForToday || !dailyPendingApps.length) {
      return {}
    }
    return getFundingDetailsObject(dailyPendingApps)
  }, [dailyPendingApps, isProcessedForToday])

  /** On search/pagination/filters change, re-fetch the list */
  useEffectOnUpdate(() => {
    onFetchList(filters)
  }, [JSON.stringify(filters)])
  useEffectOnUpdate(() => {
    onFetchProcessedApps(true, filtersForPending)
  }, [JSON.stringify(filtersForPending)])
  useEffectOnUpdate(() => {
    onFetchProcessedApps(false, filtersForUnfunded)
  }, [JSON.stringify(filtersForUnfunded)])
  useEffectOnUpdate(() => {
    onFetchFundedApps(filtersForFunded)
  }, [JSON.stringify(filtersForFunded)])

  /** Called on searching via the Search Input */
  const onSearch = (payload) => setFilters({ ...filters, ...payload })
  const onSearchUnfundedLoans = (isPending) => (payload) =>
    isPending
      ? setFiltersForPending({ ...filtersForPending, ...payload })
      : setFiltersForUnfunded({ ...filtersForUnfunded, ...payload })
  const onSearchFundedLoans = (payload) =>
    setFiltersForFunded({ ...filtersForFunded, ...payload })

  /** Called whenever pagination/sorting/filter changes happen inside the table */
  const onTableFiltersChanged = ({ pageIndex, sort }) => {
    setFilters({ ...filters, pageNumber: pageIndex, sortBy: sort })
  }
  const onProcessedTableFiltersChanged =
    (isPending) =>
    ({ pageIndex, sort }) => {
      if (isPending) {
        setFiltersForPending({
          ...filtersForPending,
          pageNumber: pageIndex,
          sortBy: sort,
        })
      } else {
        setFiltersForUnfunded({
          ...filtersForUnfunded,
          pageNumber: pageIndex,
          sortBy: sort,
        })
      }
    }
  const onFundedTableFiltersChanged = ({ pageIndex, sort }) => {
    setFiltersForFunded({
      ...filtersForFunded,
      pageNumber: pageIndex,
      sortBy: sort,
    })
  }

  const apps = useMemo(() => {
    return dailyFundingApps.map((app, idx) => ({
      ...app,
      $children: () => (
        <ExpandedRowContents
          data={app}
          onSave={(data) =>
            onUpdateDaily({ dailyFundingLoanApplicationId: app.id, ...data })
          }
          onCancel={() => {
            tableRef.current?.table?.getRow(idx).toggleExpanded()
          }}
        />
      ),
    }))
  }, [dailyFundingApps, tableRef])

  /** List view - pre Process Daily Funding */
  const _renderPreProcessedView = () => (
    <>
      <Table
        ref={tableRef}
        title="Loan Applications"
        data={apps}
        columns={dailyFundingColumns}
        hideEditButton={true}
        hideRemoveButton={true}
        showColumnSelector={true}
        hasCheckboxes={true}
        centerCellContent={true}
        onTableRowsSelectChange={setSelectedApps}
        hasPagination={true}
        multiRowSelection={true}
        totalPages={dailyFundingPages}
        fetchData={onTableFiltersChanged}
        pageSize={filters.itemsPerPage}
        dataLoading={loading}
        persistentSelection={true}
        getRowId={(row) => row.dailyFundingLoanApplicationId}
      />
    </>
  )

  /** List view - post Process Daily Funding */
  const _renderPostProcessedView = () => (
    <>
      <FundingDetails
        fundedBy={{ friendlyName: `${user.firstName} ${user.lastName}` }}
        totalFunding={fundingDetails.totalFunding}
        totalDeposit={fundingDetails.totalDeposit}
        style={{ marginBottom: '40px' }}
      />

      {hasFundedApps && (
        <>
          <div className="afp__header">
            <SearchAndFilterModule
              fieldsToSearch={['multiple']}
              placeholder="Search by loan app"
              noDefaultExtraOptions={true}
              callback={onSearchFundedLoans}
            />
          </div>
          <Table
            title="Funded Loans"
            data={fundedApps}
            columns={dailyFundingColumns}
            showColumnSelector={true}
            centerCellContent={true}
            hasPagination={true}
            totalPages={fundedPages}
            fetchData={onFundedTableFiltersChanged}
            dataLoading={loading}
          />
        </>
      )}

      <div>
        <div className="afp__header">
          <SearchAndFilterModule
            fieldsToSearch={['multiple']}
            placeholder="Search by loan app"
            noDefaultExtraOptions={true}
            callback={onSearchUnfundedLoans(true)}
          />
        </div>
        <Table
          title="Pending Funded Loans"
          data={dailyPendingApps}
          columns={dailyFundingColumns}
          showColumnSelector={true}
          centerCellContent={true}
          hasPagination={true}
          totalPages={dailyPendingPages}
          fetchData={onProcessedTableFiltersChanged(true)}
          dataLoading={loading}
        />
      </div>

      <div style={{ marginTop: '32px' }}>
        <div className="afp__header">
          <SearchAndFilterModule
            fieldsToSearch={['multiple']}
            placeholder="Search by loan app"
            noDefaultExtraOptions={true}
            callback={onSearchUnfundedLoans(false)}
          />
        </div>
        <Table
          title="Unfunded Loans"
          hideEditButton={true}
          hideRemoveButton={true}
          data={dailyUnfundedApps}
          columns={dailyFundingColumns}
          showColumnSelector={true}
          centerCellContent={true}
          hasPagination={true}
          totalPages={dailyUnfundedPages}
          fetchData={onProcessedTableFiltersChanged(false)}
          dataLoading={loading}
        />
      </div>
    </>
  )

  return (
    <div className="daily-funding">
      {!isProcessedForToday && (
        <div className="afp__header">
          <SearchAndFilterModule
            fieldsToSearch={['multiple']}
            placeholder="Search by loan app"
            noDefaultExtraOptions={true}
            callback={onSearch}
          />
        </div>
      )}

      {!isProcessedForToday
        ? _renderPreProcessedView()
        : _renderPostProcessedView()}
    </div>
  )
}

DailyFundingModule.propTypes = {
  loading: PropTypes.bool.isRequired,
  onFetchList: PropTypes.func.isRequired,
  onFetchProcessedApps: PropTypes.func.isRequired,
  onFetchFundedApps: PropTypes.func.isRequired,
  onUpdateDaily: PropTypes.func.isRequired,
  setSelectedApps: PropTypes.func.isRequired,
}

export default DailyFundingModule
