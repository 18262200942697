import Api from 'easy-fetch-api'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { mapCreatedAndLastModifiedDates } from 'common/date'

export const createLoanProduct = (data, dispatch) => {
  return Api.post({
    url: '/LoanProduct/create-loan-product',
    data: data,
  })
    .then((response) => {
      showNotification(dispatch, {
        title: `Successfully created loan product ${data.name}`,
      })
      return Promise.resolve(response)
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while creating loan product ${data.name}. Please refresh the page and try again. ${error.title}`,
      })
    )
}

export const updatePaymentDetails = (data, dispatch) => {
  return Api.put({
    url: '/LoanProduct/update-payment-periods-details',
    data: data,
  })
    .then((response) => {
      showNotification(dispatch, {
        title: `Successfully updated financial details.`,
      })
      return Promise.resolve(response)
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while updating the loan product. Please refresh the page and try again. ${error.title}`,
      })
    )
}

export const updateLoanProduct = (data, dispatch) => {
  return Api.put({
    url: '/LoanProduct/update-loan-product',
    data: data,
  })
    .then((response) => {
      showNotification(dispatch, {
        title: `Successfully updated loan product ${data.name}`,
      })
      return Promise.resolve(response)
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while updating loan product ${data.name}. Please refresh the page and try again. ${error.title}`,
      })
    )
}

export const deleteLoanProduct = (loanProductId, dispatch) => {
  return Api.delete({
    url: `/LoanProduct/${loanProductId}`,
  })
    .then((response) => {
      showNotification(dispatch, {
        title: `Successfully deleted loan product ${response.name}`,
      })
      return Promise.resolve(response)
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while deleting loan product. Please refresh the page and try again. ${error.title}`,
      })
    )
}

/** Fetch list of Loan Products for a Lender */
export const fetchLoanProductList = (filters) => {
  return Api.get({
    url: `/LoanProduct/list`,
    query: {
      pageNumber: filters?.pageNumber,
      itemsPerPage: filters?.itemsPerPage,
      sortBy: filters?.sortBy,
      lenderId: filters?.lenderId,
      searchBy: filters?.searchBy ? `name ${filters.searchBy}` : undefined,
    },
  })
    .then((res) => {
      const { loanProducts, itemCount } = res
      return {
        loanProducts: mapCreatedAndLastModifiedDates(loanProducts),
        itemCount,
      }
    })
    .catch(console.error)
}

export const fetchLoanProductsBasicInfo = () => {
  return Api.get({ url: '/LoanProduct/list-basic' }).catch(console.error)
}

/** Fetch a list of Loan Products for a particular Organization */
export const fetchLoanProductsForOrganization = (orgId) =>
  Api.get({
    url: '/LoanProduct/list-loan-products-by-sales-organization',
    query: { salesOrganizationId: orgId },
  }).catch((err) => {
    console.error('Error fetching Loan Products for Organization', err)
    return []
  })

/** Fetch a list of Commercial Loan Products for a particular Organization */
export const fetchCommercialLoanProductsForOrganization = (orgId) =>
  Api.get({
    url: '/LoanProduct/list-commercial-loan-products-by-sales-organization',
    query: { salesOrganizationId: orgId },
  }).catch((err) => {
    console.error('Error fetching Loan Products for Organization', err)
    return []
  })

export const fetchSingleLoanProduct = (loanProductId) => {
  return Api.get({
    url: `/LoanProduct/${loanProductId}`,
  }).catch(console.error)
}

export const assignDecisioningTemplate = (data) => {
  return Api.put({
    url: `/LoanProduct/assign-decisioning-template`,
    data: data,
  }).catch(console.error)
}

export const assignStipulationTemplate = (data) => {
  return Api.put({
    url: `/LoanProduct/assign-stipulation-template`,
    data: data,
  }).catch(console.error)
}

export const assignDocumentTemplate = (data) => {
  return Api.put({
    url: `/LoanProduct/assign-envelopes`,
    data: data,
  }).catch(console.error)
}

export const fetchPromotionalTypes = () => {
  return Api.get({
    url: `/LoanProduct/promotional-types`,
  })
    .then((res) => res.map((el) => ({ id: el.id, label: el.friendlyName })))
    .catch(console.error)
}

export const fetchLoanStatuses = () => {
  return Api.get({
    url: `/LoanProduct/loan-status`,
  })
    .then((res) => res.map((el) => ({ id: el.id, label: el.friendlyName })))
    .catch(console.error)
}

export const fetchFinanceTypes = () => {
  return Api.get({
    url: `/LoanProduct/finance-types`,
  })
    .then((res) => res.map((el) => ({ id: el.id, label: el.friendlyName })))
    .catch(console.error)
}

export const fetchFinancingPrincipalTypes = () => {
  return Api.get({
    url: `/LoanProduct/financing-principal-types`,
  })
    .then((res) => res.map((el) => ({ id: el.id, label: el.friendlyName })))
    .catch(console.error)
}

export const fetchFinancingInterestTypes = () => {
  return Api.get({
    url: `/LoanProduct/financing-interest-types`,
  })
    .then((res) => res.map((el) => ({ id: el.id, label: el.friendlyName })))
    .catch(console.error)
}
