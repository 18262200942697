import React, { useState } from 'react'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import PropTypes from 'prop-types'
import Textarea from 'components/textarea'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import DeleteModal from 'components/modal/delete-modal'
import { ERRORS } from 'components/validator'

import { styles } from './style'

const { Paper, IconButton, Avatar, Divider, Button } = components
const { TrashIcon02, SendIcon } = assets

const CommentsSection = ({
  comments,
  onSubmit,
  canComment,
  removeComment,
  userId,
  canRemoveNTPComments,
}) => {
  const { t: translate } = useTranslation()
  const [comment, setComment] = useState('')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [commentToDelete, setCommentToDelete] = useState('')
  const [error, setError] = useState([])

  const addComment = () => {
    if (comment?.length) {
      onSubmit(comment)
      setComment('')
    } else {
      setError(ERRORS.REQUIRED)
    }
  }

  const handleRemoveComment = (commentId) => (e) => {
    e.stopPropagation()
    setIsDeleteModalOpen(true)
    setCommentToDelete(commentId)
  }

  const handleConfirmDelete = () => {
    removeComment(commentToDelete)
  }

  return (
    <Paper className="paper" sx={{ mb: '24px' }}>
      <div style={styles.commentsTitle}>
        {translate(
          'loanApplication.ntpStepsCommon.commentsSection.addAComment'
        )}
      </div>
      <div>
        <div style={{ marginBottom: '16px' }}>
          <Textarea
            value={comment}
            fullWidth
            rows={4}
            onChange={setComment}
            resize={'vertical'}
            onEnterKey={addComment}
            placeholder={'Enter your comment'}
            disabled={!canComment}
            validate={() => error}
          />
        </div>
        <Button
          startIcon={<SendIcon />}
          onClick={addComment}
          disabled={!canComment}
          sx={{ width: '100%', mb: 2 }}
        >
          {translate('buttons.submit')}
        </Button>
      </div>
      <span style={styles.commentsTitle}>
        {translate('loanApplication.ntpStepsCommon.commentsSection.comments')}
      </span>
      {comments.length > 0 && (
        <div>
          {comments.map((item, key) => (
            <React.Fragment key={key}>
              <div style={styles.ntpComment} key={key}>
                <div style={styles.ntpCommentHeader}>
                  <Avatar>{item.createdBy}</Avatar>
                  <div style={styles.ntpCommentWrapper}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={styles.ntpCommentName}>{item.createdBy}</div>
                      <div style={styles.ntpCommentDate}>
                        {moment(item.dateCreated).format('MMM Do, h:mm A')}
                      </div>
                    </div>
                    {(item.userId === userId || canRemoveNTPComments) && (
                      <IconButton rounded={true}>
                        <TrashIcon02
                          onClick={handleRemoveComment(item.ntpCommentId)}
                        />
                      </IconButton>
                    )}
                  </div>
                </div>

                <div style={styles.ntpCommentContent}>{item.content}</div>
              </div>
              {key < comments.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </div>
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          title={translate('loanApplication.step3.areYouSure')}
          confirmButtonText={translate('buttons.delete')}
          cancelButtonText={translate('buttons.cancel')}
          onSubmitModal={handleConfirmDelete}
          setModalOpen={setIsDeleteModalOpen}
        />
      )}
    </Paper>
  )
}

CommentsSection.propTypes = {
  comments: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  canComment: PropTypes.bool,
  removeComment: PropTypes.func.isRequired,
  userId: PropTypes.string,
  canRemoveNTPComments: PropTypes.bool,
}

export default CommentsSection
