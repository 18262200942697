import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components, unstable_components } from '@ElementsCapitalGroup/enium-ui'
import { useMediaQuery } from 'common/hooks'

import { DESKTOP_BREAKPOINT } from 'common/constants'
import { Link } from 'react-router-dom'

import { styles } from './style'

const { Checkbox, Paper, Dialog } = components
const { Unstable_Grid: Grid } = unstable_components

export const TermsAndConditions = ({
  termsAgreedChecked,
  onConfirmTerms,
  hasCoBorrower,
}) => {
  const { t: translate, i18n } = useTranslation()
  const TERMS_OF_USE_COUNT = 20
  const PRIVACY_POLICIES_COUNT = 11
  const phoneNumber = translate(
    'loanApplication.step1.termsAndConditions.phoneNumber'
  )
  let checkboxLabel = translate(
    'loanApplication.step1.termsAndConditions.termsAgreedCheckbox'
  )
  if (hasCoBorrower) {
    checkboxLabel += translate(
      'loanApplication.step1.termsAndConditions.termsAgreedCheckboxCob'
    )
  }
  const email = translate('loanApplication.step1.termsAndConditions.email')
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const title = translate('loanApplication.step1.termsAndConditions.title')
  const termsOfUse = Array.from(Array(TERMS_OF_USE_COUNT).keys())
    .map((index) => {
      const key = `loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.termsOfUse.info.${index}`

      return i18n.exists(key) ? translate(key) : null
    })
    .filter((termOfUse) => termOfUse)
  const privacyPolicies = Array.from(Array(PRIVACY_POLICIES_COUNT).keys())
    .map((index) => {
      const key = `loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.privacyPolicy.info.${index}`

      return i18n.exists(key) ? translate(key) : null
    })
    .filter((termOfUse) => termOfUse)
  const [termsAndCondOpened, setTermsAndConditions] = useState(false)
  const [acknowledgementOpened, setAcknowledgment] = useState(false)
  const [electronicRecordOpened, setElectronicRecord] = useState(false)

  return (
    <Paper className="paper">
      <Grid container flexDirection="column" gap={24}>
        <Grid item className="applicant-info__form-title">
          {title}
        </Grid>
        <Grid container flexDirection="column">
          <Grid item style={styles.linksWrapper}>
            <li>
              <Link onClick={() => setTermsAndConditions(!termsAndCondOpened)}>
                {'Terms of Use & Privacy Policy'}
              </Link>
            </li>
            <li>
              <Link onClick={() => setAcknowledgment(!acknowledgementOpened)}>
                {'Acknowledgement & Authorization'}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setElectronicRecord(!electronicRecordOpened)
                }}
              >
                {'Electronic Record and Signature Disclosure'}
              </Link>
            </li>
          </Grid>

          <Grid item style={styles.checkboxesWrapper}>
            <Checkbox
              onClick={(e) => onConfirmTerms(e.target.checked)}
              checked={termsAgreedChecked}
              label={checkboxLabel}
              labelProps={{
                fontSize: '16px',
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={electronicRecordOpened}
        onClose={() => setElectronicRecord(false)}
        title={'Electronic Record and Signature Disclosure'}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '850px',

            '& .MuiDialogContent-root': {
              padding: isTabletView ? '0 16px 16px' : '10px 24px',
              '&::-webkit-scrollbar': {
                display: 'none' /* Hide scrollbar for Webkit browsers */,
              },
              '-ms-overflow-style': 'none' /* Hide scrollbar for IE and Edge */,
              scrollbarWidth: 'none' /* Hide scrollbar for Firefox */,
            },
          },
        }}
      >
        <div className="applicant-info__modal">
          <div className="applicant-info__modal-text">
            <div className="t-and-c__block">
              <div
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.description'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.paperCopiesTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.paperCopiesInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.withdrawingConsentTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.withdrawingConsentInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.consequencesTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.consequencesInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.electronicDisclosuresTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.electronicDisclosuresInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.telephoneDisclosureTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.telephoneDisclosureInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.howToContactTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.howToContactInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toAdviseTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toAdviseInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toRequestPaperCopiesTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toRequestPaperCopiesInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toWithdrawConsentTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.toWithdrawConsentInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.requiredHardwareAndSoftwareTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.requiredHardwareAndSoftwareInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.browsersTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.browsersInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.mobileSigningTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.mobileSigningInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.securitySettingsTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.securitySettingsInfo'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.acknowledgementTitle'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.electronicRecordAndSignature.acknowledgementInfo'
                  ),
                }}
              ></div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={acknowledgementOpened}
        onClose={() => setAcknowledgment(false)}
        title={'Acknowledgement & Authorization'}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '850px',

            '& .MuiDialogContent-root': {
              padding: isTabletView ? '0 16px 16px' : '10px 24px',
              '&::-webkit-scrollbar': {
                display: 'none' /* Hide scrollbar for Webkit browsers */,
              },
              '-ms-overflow-style': 'none' /* Hide scrollbar for IE and Edge */,
              scrollbarWidth: 'none' /* Hide scrollbar for Firefox */,
            },
          },
        }}
      >
        <div className="applicant-info__modal">
          <div className="applicant-info__modal-text">
            <div
              dangerouslySetInnerHTML={{
                __html: translate(
                  'loanApplication.step1.termsAndConditions.acknowledgementAndAuthorization.info'
                ),
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: translate(
                  'loanApplication.step1.termsAndConditions.acknowledgementAndAuthorization.contactInfo',
                  { phoneNumber, email }
                ),
              }}
            ></div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={termsAndCondOpened}
        onClose={() => setTermsAndConditions(false)}
        title={'Terms of Use & Privacy Policy'}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '850px',

            '& .MuiDialogContent-root': {
              padding: isTabletView ? '0 16px 16px' : '10px 24px',
              '&::-webkit-scrollbar': {
                display: 'none' /* Hide scrollbar for Webkit browsers */,
              },
              '-ms-overflow-style': 'none' /* Hide scrollbar for IE and Edge */,
              scrollbarWidth: 'none' /* Hide scrollbar for Firefox */,
            },
          },
        }}
      >
        <div className="applicant-info__modal">
          <div className="applicant-info__modal-text">
            <div className="t-and-c__block">
              <div
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.welcomeText'
                  ),
                }}
              ></div>
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.termsOfUse.title'
                )}
              </div>
              <div className="t-and-c__description">
                <div
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.termsOfUse.info.text'
                    ),
                  }}
                ></div>
              </div>
              {termsOfUse.map((termOfUse, index) => {
                return (
                  <div className="t-and-c__description" key={index}>
                    <div dangerouslySetInnerHTML={{ __html: termOfUse }}></div>
                  </div>
                )
              })}
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.privacyPolicy.title'
                )}
              </div>
              {privacyPolicies.map((privacyPolicy, index) => {
                return (
                  <div className="t-and-c__description" key={index}>
                    <div
                      dangerouslySetInnerHTML={{ __html: privacyPolicy }}
                    ></div>
                  </div>
                )
              })}
            </div>

            <div className="t-and-c__block">
              <div className="t-and-c__subtitle">
                {translate(
                  'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.applicantCustomerIdentificationProgram.title'
                )}
              </div>
              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.applicantCustomerIdentificationProgram.info'
                  ),
                }}
              ></div>

              <div
                className="t-and-c__description"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'loanApplication.step1.termsAndConditions.termsOfUseAndPrivacy.contactInfo',
                    { phoneNumber, email }
                  ),
                }}
              ></div>
            </div>
          </div>
        </div>
      </Dialog>
    </Paper>
  )
}

TermsAndConditions.propTypes = {
  termsAgreedChecked: PropTypes.bool,
  onConfirmTerms: PropTypes.func,
  hasCoBorrower: PropTypes.bool,
}
