import moment from 'moment'
import { dateFormatServer } from './constants'

/** Formats a date to a desired moment.js format */
export const formatDate = (serverDate, format = dateFormatServer) =>
  moment(serverDate, moment.ISO_8601).format(format)

/**
 * Converts a date from the Server tz (UTC) to the client one
 * @param {String|Date} serverDate
 * @return {Date|null}
 */
export const convertDateToClient = (serverDate) => {
  if (!serverDate) {
    return null
  }
  const date = new Date(serverDate)
  return new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()))
}

/**
 * Converts a date from the Client tz to the Server one (UTC)
 * @param {String|Date} clientDate
 * @return {Date|null}
 */
export const convertDateToServer = (clientDate) => {
  if (!clientDate) {
    return null
  }
  const date = new Date(clientDate)
  return new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()))
}

/**
 * Converts an array of items that has "dateCreated" & "dateLastModified" to the client-format for these dates
 * Useful in most entity-listings since they follow this same pattern of fields
 */
export const mapCreatedAndLastModifiedDates = (arr) =>
  arr?.map((doc) => ({
    ...doc,
    dateCreated: convertDateToClient(doc.dateCreated),
    dateLastModified: convertDateToClient(doc.dateLastModified),
  }))
