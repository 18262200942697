import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Table from 'components/table'
import { components } from '@ElementsCapitalGroup/enium-ui'
import SearchAndFilterModule from 'components/search-filter-module'
import DatePicker from 'components/date-picker'
import FundingHistoryTracking from './funding-history-tracking'
import FundingDetails from '../utils/funding-details'

import './style.scss'

const { createColumnHelper } = components
const columnHelper = createColumnHelper()

const FundingHistoryModule = ({
  list,
  fundingDetails,
  pageCount,
  onFetchList,
  loading,
}) => {
  const [filters, setFilters] = useState({
    fundingDate: new Date(),
    sortBy: undefined,
    searchBy: undefined,
    pageNumber: 0,
  })

  /** On search/pagination/filters change, re-fetch the list */
  useEffect(() => {
    onFetchList(filters)
  }, [filters])

  const onSearch = (payload) => setFilters({ ...filters, ...payload })

  const onTableFiltersChanged = ({ pageIndex, sort }) => {
    setFilters({ ...filters, pageNumber: pageIndex, sortBy: sort })
  }

  const fundingHistory = useMemo(() => {
    return list.map((item) => ({
      ...item,
      $children: () => <FundingHistoryTracking loanApp={item} />,
    }))
  }, [list])

  return (
    <div className="funding-history">
      <div className="afp__header">
        <SearchAndFilterModule
          fieldsToSearch={['multiple']}
          placeholder="Search"
          noDefaultExtraOptions={true}
          callback={onSearch}
        />
        <DatePicker
          date={filters.fundingDate}
          setDate={(newDate) =>
            setFilters({ ...filters, fundingDate: newDate })
          }
          maxDate={new Date()}
          isClearable={false}
        />
        <FundingDetails
          totalFunding={fundingDetails.totalFunding}
          fundedByLabel="Last Funded by"
          totalDeposit={fundingDetails.totalDeposit}
          totalFee={fundingDetails.totalFee}
          fundedBy={fundingDetails.fundedBy}
        />
      </div>
      <Table
        title="Funding History"
        data={fundingHistory}
        columns={columns}
        hasPagination={true}
        showColumnSelector={true}
        fetchData={onTableFiltersChanged}
        totalPages={pageCount}
        emptyDescription="No funding history items found."
        dataLoading={loading}
      />
    </div>
  )
}

const columns = [
  columnHelper.accessor('fundingType', {
    header: 'Funding Type',
    cell: (cell) => cell.row.original.fundingType?.friendlyName,
  }),
  columnHelper.accessor('applicationNumber', {
    header: 'Application #',
  }),
  columnHelper.accessor('borrowerName', {
    header: 'Borrower Name',
  }),
  columnHelper.accessor('loanAmount', {
    header: 'Loan Amount $',
  }),
  columnHelper.accessor('fundingAmount', {
    header: 'Funding Amount $',
  }),
  columnHelper.accessor('lenderName', {
    header: 'Lender',
  }),
  columnHelper.accessor('totalFee', {
    header: 'Total Fee $',
  }),
  columnHelper.accessor('dealerDeposit', {
    header: 'Dealer Deposit $',
  }),
]

FundingHistoryModule.propTypes = {
  list: PropTypes.array.isRequired,
  fundingDetails: PropTypes.object.isRequired,
  pageCount: PropTypes.number.isRequired,
  onFetchList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default FundingHistoryModule
