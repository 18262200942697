import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Table from 'components/table'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Status, { STATUSES } from 'components/status'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'

import './index.scss'

const FILE_INDEX = 0
const VENDOR_INDEX = 1

const verificationSummaryFields = [
  'firstName',
  'middleName',
  'lastName',
  'suffix',
  'address',
  'ssn',
  'dateOfBirth',
  'phone',
]

const MATCH_RESULT_DETAILED = {
  NOT_FOUND: 0,
  NO_MATCH: 1,
  PARTIAL_MATCH: 2,
  MATCH: 3,
  INPUT_NOT_AVAILABLE: 4,
  LAST_FOUR_MATCH: 5,
  DATA_RESTRICTED: 6,
  MATCH_NOT_PERFORMED: 7,
}

const { createColumnHelper } = components

const ApplicantVerification = ({ applicantTypeId, verificationResults }) => {
  const { t: translate } = useTranslation()

  const parseVerification = (rawVerification) => {
    const verification = []

    verification[0] = rawVerification?.firstNameVerification?.id
    verification[1] = rawVerification?.middleNameVerification?.id
    verification[2] = rawVerification?.lastNameVerification?.id
    verification[3] = rawVerification?.nameSuffixVerification?.id
    verification[4] = rawVerification?.currentAddressVerification?.id
    verification[5] = rawVerification?.ssnVerification?.id
    verification[6] = rawVerification?.dateOfBirthVerification?.id
    verification[7] = rawVerification?.phoneNumberVerification?.id

    return [...verification]
  }

  const fileVerification = useMemo(
    () =>
      parseVerification(verificationResults.verificationAnalysis[FILE_INDEX]),
    [JSON.stringify(verificationResults)]
  )

  const vendorVerification = useMemo(
    () =>
      parseVerification(verificationResults.verificationAnalysis[VENDOR_INDEX]),
    [JSON.stringify(verificationResults)]
  )

  const {
    riskIndicator,
    bestMatch,
    verificationPoint,
    lowerThreshold,
    upperThreshold,
  } = verificationResults.verificationSummary

  const columnHelper = createColumnHelper()
  const isMobileView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  const getVerificationSummaryColorClass = (value) => {
    switch (value) {
      case MATCH_RESULT_DETAILED.NOT_FOUND:
        return STATUSES.ON_HOLD
      case MATCH_RESULT_DETAILED.PARTIAL_MATCH:
        return STATUSES.PARTIAL
      case MATCH_RESULT_DETAILED.MATCH:
        return STATUSES.ACTIVE
      case MATCH_RESULT_DETAILED.INPUT_NOT_AVAILABLE:
        return STATUSES.UNDEFINED
      case MATCH_RESULT_DETAILED.MATCH_NOT_PERFORMED:
        return STATUSES.INACTIVE
      default:
        return ''
    }
  }

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('identityRiskLevel', {
        header: translate(
          'loanApplication.ecgToolbox.identityVerification.identityRiskLevel'
        ),
      }),
      columnHelper.accessor('fileData', {
        id: 'fileData',
        header: translate(
          'loanApplication.ecgToolbox.identityVerification.fileData'
        ),
        cell: ({ row }) => {
          return (
            <Status
              size="small"
              variant="filled"
              status={row.original.fileData.props.className}
              label={row.original.fileData.props.children}
            />
          )
        },
      }),
      columnHelper.accessor('vendorFileData', {
        id: 'vendorFileData',
        header: translate(
          'loanApplication.ecgToolbox.identityVerification.vendorFileData'
        ),
        cell: ({ row }) => (
          <Status
            status={row.original.fileData.props.className}
            size="small"
            label={row.original.fileData.props.children}
          />
        ),
      }),
    ]
  }, [])

  const translateVerificationData = (verificationData) => {
    const colorClass = getVerificationSummaryColorClass(verificationData)
    return (
      <span className={colorClass}>
        {verificationData
          ? translate(
              `loanApplication.ecgToolbox.identityVerification.verificationAnalysisMatchResultDetailed.${verificationData}`
            )
          : '-'}
      </span>
    )
  }

  const dataForTable = useMemo(() => {
    return verificationSummaryFields.map((field, index) => ({
      identityRiskLevel: translate(
        `loanApplication.ecgToolbox.identityVerification.verificationSummaryFields.${field}`
      ),
      fileData: translateVerificationData(fileVerification[index]),
      vendorFileData: translateVerificationData(vendorVerification[index]),
    }))
  }, [verificationSummaryFields, fileVerification, vendorVerification])

  return (
    <>
      <div>
        {isMobileView ? (
          <div className="applicant-verification__padding">
            <div className="applicant-verification__header">
              <span className="applicant-verification__content-item applicant-verification__content-item--no-height">
                {translate(
                  'loanApplication.ecgToolbox.identityVerification.info'
                )}
              </span>
            </div>
            <div className="applicant-verification__content">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="applicant-verification__header-item">
                  {translate(
                    'loanApplication.ecgToolbox.identityVerification.identityRiskLevel'
                  )}
                  :{' '}
                  {riskIndicator
                    ? translate(
                        `loanApplication.ecgToolbox.identityVerification.riskIndicator.${riskIndicator.id}`
                      )
                    : '-'}
                </span>
                <span className="applicant-verification__header-item">
                  {translate(
                    'loanApplication.ecgToolbox.identityVerification.bestMatchDataSource'
                  )}
                  :{' '}
                  {bestMatch
                    ? translate(
                        `loanApplication.ecgToolbox.identityVerification.matchSource.${bestMatch.id}`
                      )
                    : '-'}
                </span>
                <span className="applicant-verification__header-item">
                  {translate(
                    'loanApplication.ecgToolbox.identityVerification.verificationPoint'
                  )}
                  : {verificationPoint || '-'}
                </span>
                <span className="applicant-verification__header-item">
                  {translate(
                    'loanApplication.ecgToolbox.identityVerification.riskLevelThresholds'
                  )}
                  : {`${lowerThreshold || '-'} / ${upperThreshold || '-'}`}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="applicant-verification__padding">
            <div className="applicant-verification__header">
              <span className="applicant-verification__header-item">
                {translate(
                  'loanApplication.ecgToolbox.identityVerification.identityRiskLevel'
                )}
              </span>
              <span className="applicant-verification__header-item">
                {translate(
                  'loanApplication.ecgToolbox.identityVerification.bestMatchDataSource'
                )}
              </span>
              <span className="applicant-verification__header-item">
                {translate(
                  'loanApplication.ecgToolbox.identityVerification.verificationPoint'
                )}
              </span>
              <span className="applicant-verification__header-item">
                {translate(
                  'loanApplication.ecgToolbox.identityVerification.riskLevelThresholds'
                )}
              </span>
            </div>
            <div className="applicant-verification__content">
              <span className="applicant-verification__content-item">
                {riskIndicator
                  ? translate(
                      `loanApplication.ecgToolbox.identityVerification.riskIndicator.${riskIndicator.id}`
                    )
                  : '-'}
              </span>
              <span className="applicant-verification__content-item">
                {bestMatch
                  ? translate(
                      `loanApplication.ecgToolbox.identityVerification.matchSource.${bestMatch.id}`
                    )
                  : '-'}
              </span>
              <span className="applicant-verification__content-item">
                {verificationPoint || '-'}
              </span>
              <span className="applicant-verification__content-item">{`${
                lowerThreshold || '-'
              } / ${upperThreshold || '-'}`}</span>
            </div>
          </div>
        )}
      </div>

      <div className="applicant-verification__padding">
        <Table
          title={'Verification Summary'}
          data={dataForTable}
          columns={columns}
        />
      </div>
    </>
  )
}

ApplicantVerification.propTypes = {
  verificationResults: PropTypes.object.isRequired,
  applicantTypeId: PropTypes.number.isRequired,
}

export default ApplicantVerification
