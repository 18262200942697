import { components } from '@ElementsCapitalGroup/enium-ui'

import Status from 'components/status'
import { formatInUSFormat } from 'common/number'
import { LOAN_STATUS_ID_MAPPING } from 'modules/loan-applications/constants'

const { createColumnHelper } = components
const columnHelper = createColumnHelper()

const getStatusChip = (value) => {
  return (
    <Status
      status={LOAN_STATUS_ID_MAPPING[value]}
      textKey={`loanApplication.statusCard.loanApplicationStatus.${value}`}
    />
  )
}

const getLoanAmountFormatted = (value) => {
  return `$${formatInUSFormat(value)}`
}

export const columns = [
  columnHelper.accessor('loanAmount', {
    textKey: 'uwHub.loanApplicationDetails.loanAmount',
    accessorKey: 'loanAmount',
    enableSorting: false,
    size: 150,
    cell: ({ row }) => {
      const { loanAmount } = row.original

      return getLoanAmountFormatted(loanAmount)
    },
    formatFunction: getLoanAmountFormatted,
  }),
  columnHelper.accessor('applicationNumber', {
    textKey: 'uwHub.loanApplicationDetails.applicationNumber',
    accessorKey: 'applicationNumber',
    enableSorting: false,
  }),
  columnHelper.accessor('lender', {
    textKey: 'uwHub.loanApplicationDetails.lender',
    accessorKey: 'lender',
    enableSorting: false,
  }),
  columnHelper.accessor('salesOrganization', {
    textKey: 'uwHub.loanApplicationDetails.salesOrganization',
    accessorKey: 'salesOrganization',
    enableSorting: false,
  }),
  columnHelper.accessor('salesRep', {
    textKey: 'uwHub.loanApplicationDetails.salesRep',
    accessorKey: 'salesRep',
    enableSorting: false,
  }),
  columnHelper.accessor('status', {
    textKey: 'uwHub.loanApplicationDetails.status',
    accessorKey: 'status',
    enableSorting: false,
    size: 130,
    cell: ({ row }) => {
      const { status } = row.original

      return getStatusChip(status)
    },
    formatFunction: getStatusChip,
  }),
]
