import React, { useEffect, useState } from 'react'
import { assets } from '@ElementsCapitalGroup/enium-ui'

import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { TABS as ADMIN_TABS, ROLE_TYPES } from 'modules/admin/constants'
import { useStore } from 'store'
import { toggleLoanCalculator } from 'modules/global/actions'

const {
  ClipboardCheckIcon,
  UserEditIcon,
  BarChartSquareUpIcon,
  DotsGridIcon,
  NotificationBoxIcon,
  BankIcon,
  CalculatorV2Icon,
} = assets

const allOrgClaims = Object.values(ADMIN_TABS).reduce((acc, tab) => {
  if (tab.access) {
    acc.add(...tab.access)
  }
  return acc
}, new Set())

const BOTTOM_NAV_ITEMS = {
  LOAN_CALCULATOR: {
    label: 'Payment Calculator',
    icon: <CalculatorV2Icon />,
    access: CLAIMS.CAN_VIEW_ECG_TOOLBOX_LOAN_CALCULATOR,
  },
  NOTIFICATIONS: {
    url: '/notifications',
    label: 'Notifications',
    access: CLAIMS.CAN_VIEW_NOTIFICATIONS,
    icon: <NotificationBoxIcon />,
  },
}

const NAV_ITEMS = {
  DASHBOARD: {
    url: '/dashboard',
    access: CLAIMS.CAN_VIEW_DEALER_DASHBOARD,
    label: 'Dashboard',
    icon: <DotsGridIcon />,
  },
  APPLICATIONS: {
    url: '/applications',
    activeOnURLs: ['/applications', '/loan-application/'],
    access: null,
    label: 'Applications',
    icon: <ClipboardCheckIcon />,
  },
  ADMIN: {
    activeOnURLs: ['/admin*'],
    access: [...allOrgClaims],
    label: 'Admin',
    icon: <UserEditIcon />,
    children: [
      {
        label: 'Users',
        url: '/admin/users',
        access: ADMIN_TABS.USERS.access,
      },
      {
        label: 'Roles',
        url: '/admin/roles',
        access: ADMIN_TABS.ROLES.access,
      },
      {
        label: 'History',
        url: '/admin/history',
        access: ADMIN_TABS.HISTORY.access,
      },
      {
        label: 'Products',
        url: '/loan-product',
        access: ADMIN_TABS.PRODUCTS.access,
        icon: <BarChartSquareUpIcon />,
      },
      {
        label: 'Documents',
        url: '/admin/loan-document',
        access: ADMIN_TABS.DOCUMENTS.access,
      },
      {
        label: 'Organizations',
        url: '/admin/organizations',
        access: ADMIN_TABS.ORGANIZATIONS.access,
      },
      // {
      //   label: 'Organizations BETA',
      //   url: '/admin/organizations-beta',
      //   access: ADMIN_TABS.ORGANIZATIONS.access,
      // },
      {
        label: 'Outage Notices',
        onItemClick: () => window.open('/outage-messages', '_blank'),
        access: ADMIN_TABS.OUTAGE_MESSAGES.access,
      },
      {
        label: 'AUS Templates',
        url: '/admin/aus-templates',
        access: ADMIN_TABS.AUS_TEMPLATES.access,
      },
      {
        label: 'Stipulation Templates',
        url: '/admin/stipulation-templates',
        access: ADMIN_TABS.STIPULATION_TEMPLATES.access,
      },
    ],
  },
  FUNDING: {
    activeOnURLs: ['/admin*'],
    access: CLAIMS.CAN_FUND_LOAN_APPLICATIONS,
    label: 'Funding',
    icon: <BankIcon />,
    children: [
      {
        label: 'Daily Funding',
        url: '/afp/daily',
      },
      {
        label: 'Approval',
        url: '/afp/approval',
      },
      {
        label: 'Wires',
        url: '/afp/wires',
      },
      {
        label: 'Funding History',
        url: '/afp/history',
      },
      {
        label: 'Lender Settings',
        url: '/afp/lender',
      },
      {
        label: 'Dealer Settings',
        url: '/afp/dealer',
      },
    ],
  },
}
export const useNavigationLinks = (userData) => {
  const { dispatch } = useStore()
  const [navLinks, setNavLinks] = useState([])
  const [bottomNavLinks, setBottomNavLinks] = useState([])

  useEffect(() => {
    if (!userData) {
      return
    }

    const generateLinks = (items) => {
      const links = []
      Object.values(items).forEach((navItem) => {
        const { hasAccess, isActive } = _hasAccessToMenuItem(
          navItem,
          pathname,
          userData
        )
        if (!hasAccess) {
          return []
        }
        let children
        if (navItem.children) {
          children = navItem.children
            .map((childItem) => {
              const { hasAccess, isActive } = _hasAccessToMenuItem(
                childItem,
                pathname,
                userData
              )
              return { ...childItem, selected: isActive, hasAccess }
            })
            .filter((childItem) => childItem.hasAccess)
        }
        links.push({
          icon: navItem.icon,
          label: navItem.label,
          url: navItem.url,
          children,
          onItemClick:
            navItem.onItemClick ||
            (navItem.label === 'Payment Calculator'
              ? () => toggleLoanCalculator(dispatch, true)
              : undefined),
          selected: isActive || children?.some((child) => child.selected),
          isOpen: isActive || children?.some((child) => child.selected),
        })
      })
      return links
    }

    const { pathname } = window.location

    const topLinks = generateLinks(NAV_ITEMS)
    const bottomLinks = generateLinks(BOTTOM_NAV_ITEMS, true)

    setNavLinks(topLinks)
    setBottomNavLinks(bottomLinks)
  }, [userData, window.location.pathname])

  return {
    navLinks,
    bottomNavLinks,
  }
}
function _hasAccessToMenuItem(navItem, pathname, userData) {
  if (userData?.isSuperAdmin && navItem.hideForSuperAdmin) {
    return { hasAccess: false }
  }
  if (!hasAccess(userData, navItem.access)) {
    return { hasAccess: false }
  }

  let isActive = false
  const isSales = isSalesUserType(userData)

  if (
    pathname === navItem.url ||
    (!isSales && pathname === '/' && navItem.url === '/applications') ||
    (isSales && pathname === '/' && navItem.url === '/dashboard')
  ) {
    isActive = true
  } else if (navItem.activeOnURLs) {
    isActive = navItem.activeOnURLs.some((url) => pathname.startsWith(url))
  }

  return { hasAccess: true, isActive }
}

export const isSalesUserType = (userData) => {
  const roleTypeId = userData.associations[0].role.roleTypeId

  return (
    roleTypeId === ROLE_TYPES.SALES ||
    roleTypeId === ROLE_TYPES.SALES_ADMIN ||
    roleTypeId === ROLE_TYPES.SALES_MANAGER
  )
}
