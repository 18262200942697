import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import PropTypes from 'prop-types'
import { convertToFloat, formatInUSFormat } from 'common/number'

import Loader from 'components/loader'
import { ExpandableCard } from 'components/expandable-card'
import TextField from 'components/input'

import {
  getDtiSummary,
  getDtiSummaryForSelectedLoanProduct,
} from 'modules/loan-application/actions'

import './index.scss'

const { Tabs, Dialog, Tooltip, Checkbox } = components
const { HelpCircleIcon } = assets

const DtiCalculator = ({
  loanApplicationId,
  evaluatedLoanProductId,
  borrowerId,
  coBorrowerId,
  isOpen = true,
  onClose,
}) => {
  const [loading, setLoading] = useState(true)
  const { t: translate } = useTranslation()

  // Debts & incomes
  const [borrowerDebts, setBorrowerDebts] = useState([])
  const [coBorrowerDebts, setCoborrowerDebts] = useState([])

  const [borrowerIncomes, setBorrowerIncomes] = useState([])
  const [coBorrowerIncomes, setCoBorrowerIncomes] = useState([])

  // Map of all unique debts + incomes. Marks selected ones with selected: true/false.
  // Values are read from here because they can be shared between borrower & co-borrower
  const [selectedDebts, setSelectedDebts] = useState({})
  const [selectedIncomes, setSelectedIncomes] = useState({})

  // Totals
  const [total, setTotal] = useState({
    borrowerDebt: 0,
    borrowerIncome: 0,
    borrowerDti: 0,
    coBorrowerDebt: 0,
    coBorrowerIncome: 0,
    coBorrowerDti: 0,
    combinedDebt: 0,
    combinedIncome: 0,
    combinedDti: 0,
  })

  /** On mount, fetch DTI details */
  useEffect(() => {
    setLoading(true)
    if (!evaluatedLoanProductId) {
      getDtiSummaryForSelectedLoanProduct(loanApplicationId)
        .then((summary) => setDti(summary))
        .finally(() => setLoading(false))
    } else {
      getDtiSummary(loanApplicationId, evaluatedLoanProductId)
        .then((summary) => setDti(summary))
        .finally(() => setLoading(false))
    }
  }, [])

  const setDti = (summary) => {
    let allDebts = []
    let allIncomes = []
    if (borrowerId) {
      const { debts, income } = setupBorrower(summary)
      allDebts = allDebts.concat(debts)
      allIncomes = allIncomes.concat(income)
    }

    if (coBorrowerId) {
      const { debts, income } = setupCoBorrower(summary)
      allDebts = allDebts.concat(debts)
      allIncomes = allIncomes.concat(income)
    }

    setupSelectedDebtsAndIncomes(allDebts, allIncomes)
  }

  /** On debts change, update total & percentages */
  useEffect(() => {
    const borrowerDebt = sumUpDebts(
      borrowerDebts
        .filter((debt) => selectedDebts[debt.id]?.selected)
        .map((debt) => selectedDebts[debt.id])
    )
    const borrowerIncome = sumUpDebts(
      borrowerIncomes
        .filter((income) => selectedIncomes[income.id]?.selected)
        .map((income) => selectedIncomes[income.id])
    )
    const coBorrowerDebt = sumUpDebts(
      coBorrowerDebts
        .filter((debt) => selectedDebts[debt.id]?.selected)
        .map((debt) => selectedDebts[debt.id])
    )
    const coBorrowerIncome = sumUpDebts(
      coBorrowerIncomes
        .filter((income) => selectedIncomes[income.id]?.selected)
        .map((income) => selectedIncomes[income.id])
    )
    const combinedDebt = sumUpDebts(
      Object.values(selectedDebts).filter((el) => el.selected)
    )
    const combinedIncome = sumUpDebts(
      Object.values(selectedIncomes).filter((el) => el.selected)
    )

    setTotal({
      ...total,
      borrowerDebt,
      borrowerIncome,
      borrowerDti: (borrowerDebt / (borrowerIncome || 1)) * 100,
      coBorrowerDebt,
      coBorrowerIncome,
      coBorrowerDti: (coBorrowerDebt / (coBorrowerIncome || 1)) * 100,
      combinedDebt,
      combinedIncome,
      combinedDti: (combinedDebt / combinedIncome || 1) * 100,
    })
  }, [
    JSON.stringify(selectedDebts),
    JSON.stringify(selectedIncomes),
    borrowerDebts,
    coBorrowerDebts,
    borrowerIncomes,
    coBorrowerIncomes,
  ])

  /** Parse the BE debts/income object of a borrower to the desired FE format */
  const parseBackendDetails = (valueFieldArray, isIncome = false) =>
    valueFieldArray.map((element) => {
      /**
        For income - we use the unique GUID assigned to each income
        For debt - we need to group them by accountNumber (both borrower and coBorrower can have the same debt)
                - Because some internal Enium loans don't have account number, we have to identify them by Title
       */
      const id = isIncome
        ? element.guid
        : element.accountNumber || element.title
      return {
        id,
        accountNumber: element.accountNumber || null,
        name: element.title,
        value: element.amount,
        isDisabled: element.isDisabled,
      }
    })

  /** Initial setup for the borrower details */
  const setupBorrower = (summary) => {
    const borrowerDTI = getDTIByApplicantId(summary, borrowerId)
    const borrowerDebts = parseBackendDetails(borrowerDTI.debtDetails, false)
    const borrowerIncome = parseBackendDetails(borrowerDTI.incomeDetails, true)
    setBorrowerDebts(borrowerDebts.map((el) => ({ id: el.id, name: el.name })))
    setBorrowerIncomes(
      borrowerIncome.map((el) => ({ id: el.id, name: el.name }))
    )

    return { debts: borrowerDebts, income: borrowerIncome }
  }

  /** Initial setup for the co-borrower details */
  const setupCoBorrower = (summary) => {
    const coBorrowerDTI = getDTIByApplicantId(summary, coBorrowerId)
    const coBorrowerDebts = parseBackendDetails(
      coBorrowerDTI.debtDetails,
      false
    )
    const coBorrowerIncome = parseBackendDetails(
      coBorrowerDTI.incomeDetails,
      true
    )
    setCoborrowerDebts(
      coBorrowerDebts.map((el) => ({
        id: el.id,
        name: el.name,
      }))
    )
    setCoBorrowerIncomes(
      coBorrowerIncome.map((el) => ({
        id: el.id,
        name: el.name,
      }))
    )

    return { debts: coBorrowerDebts, income: coBorrowerIncome }
  }

  /** Initial setup for the selected items details, for each borrower */
  const setupSelectedDebtsAndIncomes = (allDebts, allIncomes) => {
    const selectedDebtsMap = { ...selectedDebts }
    const selectedIncomesMap = { ...selectedIncomes }
    allDebts.forEach((debt) => {
      if (!selectedDebtsMap[debt.id]) {
        selectedDebtsMap[debt.id] = {
          id: debt.id,
          name: debt.name,
          value: debt.value,
          selected: !debt.isDisabled,
        }
      }
    })
    allIncomes.forEach((income) => {
      if (!selectedIncomesMap[income.id]) {
        selectedIncomesMap[income.id] = {
          id: income.id,
          name: income.name,
          value: income.value,
          selected: !income.isDisabled,
        }
      }
    })
    setSelectedDebts(selectedDebtsMap)
    setSelectedIncomes(selectedIncomesMap)
  }

  /** Triggered when a checkbox is checked/unchecked */
  const onItemChecked =
    (elementId, isDebt = true) =>
    () => {
      if (isDebt) {
        setSelectedDebts((prevState) => {
          const prevValue = prevState[elementId]
          return {
            ...prevState,
            [elementId]: { ...prevValue, selected: !prevValue.selected },
          }
        })
      } else {
        setSelectedIncomes((prevState) => {
          const prevValue = prevState[elementId]
          return {
            ...prevState,
            [elementId]: { ...prevValue, selected: !prevValue.selected },
          }
        })
      }
    }

  /** Trigger on input change - update the corresponding value inside the borrower/co-borrower debt/income, as well as in the selected map */
  const onInputChange =
    (elementId, isDebt = true, isCoBorrower = false) =>
    (stringValue) => {
      const value = stringValue ? parseFloat(stringValue) : 0
      const updateSelected = isDebt ? setSelectedDebts : setSelectedIncomes
      const selectedMap = isDebt ? selectedDebts : selectedIncomes
      updateSelected({
        ...selectedMap,
        [elementId]: { ...selectedMap[elementId], value },
      })
    }

  /** Partial renderer for individual items, both debts & incomes, both borrower & co-borrower */
  const _renderItems = (debts, income, isCoBorrower, isCombined) => {
    const translations = {
      borrower: translate('loanApplication.step1.borrower'),
      coBorrower: translate('loanApplication.step1.coBorrower'),
      combined: translate('loanApplication.step1.combined'),
    }

    const debtsElement = sortByName(debts).map((item, key) => {
      const value = selectedDebts[item.id]?.value || 0
      const elementExistsOnBorrower =
        isCoBorrower && borrowerDebts.some((el) => el.id === item.id)
      return (
        <div key={key} className="dti__debt-item">
          {!isCombined ? (
            <Checkbox
              checked={selectedDebts[item.id]?.selected}
              label={item.name}
              onClick={onItemChecked(item.id, true)}
            />
          ) : (
            <label className="checkbox__wrapper">{item.name}</label>
          )}

          <TextField
            prefix="$"
            value={!isCombined || item.selected ? value : 0}
            disabled={isCombined}
            type="currency"
            fixedDecimalScale={true}
            onChange={onInputChange(item.id, true, isCoBorrower)}
          />

          {elementExistsOnBorrower && (
            <Tooltip id={item.id} title={'Shared account'}>
              <div className="dti__tooltip">
                <HelpCircleIcon />
              </div>
            </Tooltip>
          )}
        </div>
      )
    })

    const incomeElement = income.map((item, key) => {
      const value = selectedIncomes[item.id]?.value || 0
      return (
        <div key={key} className="dti__debt-item">
          {!isCombined ? (
            <Checkbox
              checked={selectedIncomes[item.id]?.selected}
              label={item.name}
              onClick={onItemChecked(item.id, false)}
            />
          ) : (
            <label className="checkbox__wrapper">{item.name}</label>
          )}

          <TextField
            prefix="$"
            value={!isCombined || item.selected ? value : 0}
            disabled={isCombined}
            type="currency"
            fixedDecimalScale={true}
            onChange={onInputChange(item.id, false, isCoBorrower)}
          />
        </div>
      )
    })

    const totalDebts = isCoBorrower
      ? total.coBorrowerDebt
      : isCombined
      ? total.combinedDebt
      : total.borrowerDebt
    const totalIncome = isCoBorrower
      ? total.coBorrowerIncome
      : isCombined
      ? total.combinedIncome
      : total.borrowerIncome

    return (
      <>
        <div className="dti__shareholder-percentage">
          <span>
            {isCoBorrower
              ? translations.coBorrower
              : isCombined
              ? translations.combined
              : translations.borrower}
          </span>
          <span>
            {isCoBorrower
              ? total.coBorrowerDti.toFixed(2)
              : isCombined
              ? total.combinedDti.toFixed(2)
              : total.borrowerDti.toFixed(2)}
            %
          </span>
        </div>

        <ExpandableCard
          title={
            <div className="dti__card-title">
              <span>
                {translate('loanApplication.step2.dtiCalculator.debts')}
              </span>
              <span>
                {translate('loanApplication.step2.dtiCalculator.totalDebts')}: $
                {formatInUSFormat(totalDebts)}
              </span>
            </div>
          }
          items={debtsElement}
        />

        <ExpandableCard
          sx={{ mt: '16px' }}
          title={
            <div className="dti__card-title">
              <span>
                {translate('loanApplication.step2.dtiCalculator.income')}
              </span>
              <span>
                {translate('loanApplication.step2.dtiCalculator.totalIncome')}:
                ${formatInUSFormat(totalIncome)}
              </span>
            </div>
          }
          items={incomeElement}
        />
      </>
    )
  }

  const tabsItems = [
    {
      label: translate('loanApplication.step1.borrower'),
      value: 1,
      children: (
        <>{_renderItems(borrowerDebts, borrowerIncomes, false, false)}</>
      ),
    },
    {
      label: translate('loanApplication.step1.combined'),
      value: 3,
      children: (
        <>
          {_renderItems(
            Object.values(selectedDebts),
            Object.values(selectedIncomes),
            false,
            true
          )}
        </>
      ),
    },
  ]

  if (coBorrowerId) {
    tabsItems.splice(1, 0, {
      label: translate('loanApplication.step1.coBorrower'),
      value: 2,
      children: (
        <>{_renderItems(coBorrowerDebts, coBorrowerIncomes, true, false)}</>
      ),
    })
  }

  return (
    <div className="dti">
      <Dialog
        open={isOpen}
        onClose={onClose}
        title={translate('loanApplication.step2.dtiCalculator.dtiDetails')}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '800px',
          },
        }}
      >
        {loading ? (
          <Loader size={100} center noLogo={true} />
        ) : (
          <>
            <Tabs
              items={tabsItems}
              listProps={{
                variant: 'scrollable',
              }}
              panelProps={{
                sx: {
                  padding: 0,
                },
              }}
            />
          </>
        )}
      </Dialog>
    </div>
  )
}

const sortByName = (debts) =>
  debts.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))

const sumUpDebts = (debts) =>
  debts.reduce((sum, v) => sum + convertToFloat(v.value), 0)

const getDTIByApplicantId = (summary, applicantId) =>
  summary.find((dti) => dti.applicantId === applicantId)

DtiCalculator.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  evaluatedLoanProductId: PropTypes.string,
  borrowerId: PropTypes.string.isRequired,
  coBorrowerId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default DtiCalculator
