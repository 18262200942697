import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Api from 'easy-fetch-api'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'
import PropTypes from 'prop-types'
import Loader from 'components/loader'
import { formatInUSFormat } from 'common/number'
import TextField, { INPUT_TYPES } from 'components/input'
import { isLevelPayment, isSpecialCaseBank } from 'common/utils'
import { useStore } from 'store'

import {
  calcMonthlyPayment,
  calcLevelPaymentAfterPromo,
  calcInterestRate,
} from './calc-utils'
import './style.scss'
import LoanCalculatorResults from './loan-calculator-results'

const { Dropdown, Dialog, Button } = components
const { XCloseIcon } = assets
const DEFAULT_PERCENTAGE = 0.3

const LoanCalculator = ({ onClose }) => {
  const [filteredLoans, setFilteredLoans] = useState([])
  const [selectedLoan, setSelectedLoan] = useState(null)
  const [loanAmount, setLoanAmount] = useState(NaN)
  const [paydownAmount, setPaydownAmount] = useState(NaN)
  const [paydownPercentage, setPaydownPercentage] = useState(DEFAULT_PERCENTAGE) // currently hard-coded until BE is completed
  const { state } = useStore()
  const navigate = useNavigate()
  const { organizationId } = state.session.userData
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const isLevel = selectedLoan && isLevelPayment(selectedLoan)
  const isSpecialBank = selectedLoan && isSpecialCaseBank(selectedLoan.lenderId)

  /** On mount, fetch available loans */
  useEffect(() => {
    Api.get({
      url: '/LoanProduct/loan-calculator',
      query: { organizationId, itemsPerPage: 500 },
    }).then((availableLoans) => {
      const uniqueLoans = availableLoans.reduce((acc, loan) => {
        const existingLoan = acc.find((item) => item.label === loan.label)
        if (!existingLoan) {
          acc.push({
            ...loan,
            interestRate: loan.interestRate / 100,
            interestRateFee: loan.interestRateFee / 100,
            interestRateDiscount: loan.interestRateDiscount / 100,
          })
        }
        return acc
      }, [])

      setFilteredLoans(uniqueLoans)
    })
  }, [organizationId])

  /** On Loan Amount change, change both loan amount and paydown amount */
  const onLoanAmountChange = (value) => {
    const newAmount = parseFloat(value)
    setLoanAmount(newAmount)
    setPaydownAmount(newAmount * paydownPercentage)
  }

  const onPaydownAmountChange = (value) => {
    const newAmount = parseFloat(value)
    setPaydownAmount(newAmount)
    if (newAmount && loanAmount) {
      setPaydownPercentage((newAmount / loanAmount).toFixed(2))
    } else {
      setPaydownPercentage(0.3)
    }
  }

  const openNewLoanApp = () => {
    navigate(`/loan-application/new/new-loan-app`, {
      state: {
        selectedLoan: {
          signature: selectedLoan.signature,
          loanAmount,
        },
      },
    })
    onClose()
  }

  /** Compute results */
  const results = useMemo(() => {
    if (!selectedLoan) {
      return {
        promoPayment: 0,
        paymentWithAutoPay: 0,
        paymentWithAutoPayAndPaydown: 0,
      }
    }

    const paidDown = loanAmount
    const paidDownWithoutAutopay = loanAmount
    let promoPayment = selectedLoan.promoPayment
    let promoPaymentWithoutAutopay = selectedLoan.promoPayment

    const interestRateWithAutopay = calcInterestRate(selectedLoan, true)
    const interestRateWithoutAutopay = calcInterestRate(selectedLoan, false)

    let paymentWithAutoPay = calcMonthlyPayment(
      paidDown,
      interestRateWithAutopay,
      selectedLoan,
      promoPayment
    )
    const paymentWithAutoPayAndPaydown = calcMonthlyPayment(
      paidDown,
      interestRateWithAutopay,
      selectedLoan,
      promoPayment,
      paydownAmount > loanAmount - paidDown
        ? paydownAmount - (loanAmount - paidDown)
        : paydownAmount
    )

    let paymentNoAutoPay = calcMonthlyPayment(
      paidDownWithoutAutopay,
      interestRateWithoutAutopay,
      selectedLoan,
      promoPaymentWithoutAutopay
    )
    const paymentNoAutoPayAndPaydown = calcMonthlyPayment(
      paidDownWithoutAutopay,
      interestRateWithoutAutopay,
      selectedLoan,
      promoPaymentWithoutAutopay,
      paydownAmount > loanAmount - paidDownWithoutAutopay
        ? paydownAmount - (loanAmount - paidDownWithoutAutopay)
        : paydownAmount
    )

    if (isLevel) {
      promoPayment = calcMonthlyPayment(
        loanAmount,
        interestRateWithAutopay,
        selectedLoan,
        selectedLoan.promoPayment,
        loanAmount * paydownPercentage
      )
      paymentWithAutoPay = calcLevelPaymentAfterPromo(
        loanAmount,
        interestRateWithAutopay,
        selectedLoan.term,
        promoPayment,
        selectedLoan.promoPeriod
      )

      promoPaymentWithoutAutopay = calcMonthlyPayment(
        loanAmount,
        interestRateWithoutAutopay,
        selectedLoan,
        selectedLoan.promoPayment,
        loanAmount * paydownPercentage
      )
      paymentNoAutoPay = calcLevelPaymentAfterPromo(
        loanAmount,
        interestRateWithoutAutopay,
        selectedLoan.term,
        promoPaymentWithoutAutopay,
        selectedLoan.promoPeriod
      )
    } else if (isSpecialBank) {
      promoPayment = 0
    }

    return {
      promoPayment: formatInUSFormat(promoPayment),
      promoPaymentWithoutAutopay: formatInUSFormat(promoPaymentWithoutAutopay),
      paymentWithAutoPay: formatInUSFormat(paymentWithAutoPay),
      paymentWithAutoPayAndPaydown: formatInUSFormat(
        paymentWithAutoPayAndPaydown
      ),
      paymentNoAutoPay: formatInUSFormat(paymentNoAutoPay),
      paymentNoAutoPayAndPaydown: formatInUSFormat(paymentNoAutoPayAndPaydown),
      interestRate: formatInUSFormat(interestRateWithAutopay * 100),
      interestRateWithoutAutopay: formatInUSFormat(
        interestRateWithoutAutopay * 100
      ),
    }
  }, [selectedLoan, loanAmount, paydownAmount])

  const selected = filteredLoans.find(
    (loan) => loan.code === selectedLoan?.code
  )

  if (!filteredLoans) {
    return <Loader center={true} />
  }

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: isTabletView ? '90%' : 'auto',

          '& .MuiDialogContent-root': {
            padding: 0,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
          },
        },
      }}
    >
      <div className="loan-calculator__flex">
        <div className="loan-calculator__wrapper">
          <div className="loan-calculator__title">
            <strong>Loan Payment Calculator</strong>
          </div>
          <Dropdown
            label="Select a Loan"
            placeholder="Select a Loan"
            displayEmpty={true}
            options={filteredLoans.map((loan) => ({
              ...loan,
              id: loan.code,
              label: loan.label,
            }))}
            searchable={true}
            value={
              selected
                ? {
                    id: selected.code,
                    label: selected.label,
                  }
                : ''
            }
            sx={{
              width: isTabletView ? '100%' : '320px',
              marginBottom: '12px',
            }}
            onChange={(ev) => {
              setSelectedLoan(ev.target.value)
            }}
          />

          <TextField
            type={INPUT_TYPES.CURRENCY}
            label="Loan Amount"
            placeholder="$"
            value={loanAmount}
            fixedDecimalScale={true}
            style={{
              width: isTabletView ? '100%' : '320px',
              margin: '12px 0',
            }}
            onChange={onLoanAmountChange}
          />

          <TextField
            type={INPUT_TYPES.CURRENCY}
            label={`Paydown Amount (${paydownPercentage * 100}%)`}
            placeholder="$"
            prefix="$"
            value={paydownAmount}
            onChange={onPaydownAmountChange}
            fixedDecimalScale={true}
            style={{
              width: isTabletView ? '100%' : '320px',
              margin: '12px 0',
              visibility: isSpecialBank ? 'hidden' : 'visible',
            }}
          />
          <Button onClick={openNewLoanApp} sx={{ marginTop: '12px' }}>
            Apply Now
          </Button>
        </div>

        <div className="loan-calculator__results">
          {selectedLoan && (
            <LoanCalculatorResults
              isSpecialBank={isSpecialBank}
              results={results}
            />
          )}
        </div>

        <XCloseIcon className="loan-calculator__x" onClick={onClose} />
      </div>
    </Dialog>
  )
}

LoanCalculator.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default LoanCalculator
