import Api from 'easy-fetch-api'
import { formatTimeForHistoricalEntry } from '../loan-application/utils'

/** Get list of templates */
export const fetchStipulationTemplatesHistory = (pageNumber, itemsPerPage) => {
  return Api.get({
    url: '/StipulationTemplate/history',
    query: {
      pageNumber,
      itemsPerPage,
    },
  }).then((res) => res?.map(formatTimeForHistoricalEntry))
}

/** Get list of Stipulation Definitions */
export const fetchAUSTemplatesHistory = (pageNumber, itemsPerPage) => {
  return Api.get({
    url: '/DecisioningTemplate/history',
    query: {
      pageNumber,
      itemsPerPage,
    },
  }).then((res) => res?.map(formatTimeForHistoricalEntry))
}

/** Get list of Stipulation Definitions */
export const fetchRolesHistory = (pageNumber, itemsPerPage) => {
  return Api.get({
    url: '/Roles/history',
    query: {
      pageNumber,
      itemsPerPage,
    },
  }).then((res) => res?.map(formatTimeForHistoricalEntry))
}

export const fetchOrganizationsHistory = (pageNumber, itemsPerPage) => {
  return Api.get({
    url: '/Organizations/history',
    query: {
      pageNumber,
      itemsPerPage,
    },
  }).then((res) => res?.map(formatTimeForHistoricalEntry))
}

export const fetchUsersHistory = (pageNumber, itemsPerPage) => {
  return Api.get({
    url: '/Users/history',
    query: {
      pageNumber,
      itemsPerPage,
    },
  }).then((res) => res?.map(formatTimeForHistoricalEntry))
}

export const fetchFundingHistoryTracking = ({
  id,
  pageNumber,
  itemsPerPage,
}) => {
  return Api.get({
    url: `/FundingAutomation/list-funding-history`,
    query: { modifiedEntityId: id, pageNumber, itemsPerPage },
  }).catch(console.error)
}

export const fetchUserHistoryForLoanApps = ({
  id,
  pageNumber,
  itemsPerPage,
}) => {
  return Api.get({
    url: '/LoanApplication/history',
    query: { userId: id, pageNumber, itemsPerPage },
  })
    .then((res) => res?.map(formatTimeForHistoricalEntry))
    .catch(console.error)
}

export const fetchHistoryForUWHub = ({ id, pageNumber, itemsPerPage }) => {
  return Api.get({
    url: '/UnderwritingHub/history',
    query: { modifiedEntityId: id, pageNumber, itemsPerPage },
  })
    .then((res) => res?.map(formatTimeForHistoricalEntry))
    .catch(console.error)
}

/** Fetch list of Loan Products for a Lender */
export const fetchLoanProductHistory = ({ id: loanProductId }) => {
  return Api.get({
    url: `/LoanProduct/history`,
    query: { modifiedEntityId: loanProductId },
  }).catch(console.error)
}
