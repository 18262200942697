import 'firebaseui'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app'

export const firebaseApp = firebase.initializeApp({
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT,
})
export const firebaseDb = firebase.firestore()
