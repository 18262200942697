import { useState } from 'react'
import PropTypes from 'prop-types'

import { TABLET_BREAKPOINT } from 'common/constants'
import { useMediaQuery } from 'common/hooks'
import PropertyTitleDesktop from './property-title-content/property-title-desktop'
import PropertyTitleMobile from './property-title-content/property-title-mobile'
import PropertyTitleFullDetailsModal from './property-title-full-report-modal'

import './style.scss'

const PropertyTitle = ({ propertyTitle }) => {
  const isDesktopView = useMediaQuery(`(min-width:${TABLET_BREAKPOINT}px)`)
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div className="uw-hub-page__property-title">
      {isDesktopView ? (
        <PropertyTitleDesktop
          details={propertyTitle}
          viewFullDetails={() => setIsModalOpen(true)}
        />
      ) : (
        <PropertyTitleMobile
          details={propertyTitle}
          viewFullDetails={() => setIsModalOpen(true)}
        />
      )}
      <PropertyTitleFullDetailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        propertyTitleReportId={propertyTitle.propertyTitleReportId}
      />
    </div>
  )
}

PropertyTitle.propTypes = {
  propertyTitle: PropTypes.object.isRequired,
}

export default PropertyTitle
