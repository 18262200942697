import { LS_USER_PREFERENCES } from 'common/constants'
import { merge } from 'lodash'
import { ACTIONS } from './actions'
import {
  ACH_FORM,
  DEFAULT_COMMERCIAL_LOAN_FORM_DATA,
  DEFAULT_LOAN_FORM_DATA,
  STEPS_MAP,
} from './constants'
import { computePossibleSteps } from './states'

/** Name of the reducer */
const name = 'flow'

/** Initial state of the reducer */
const initialState = {
  loading: false,
  crtStep: -1,
  steps: Object.keys(STEPS_MAP).map(() => ({})),
  currentState: { name: '' }, // The current State the App is in
  possibleSteps: [], // Possible edit steps
  possibleEditStates: [], // Possible edit states
  possibleViewOnlySteps: [], // Possible steps - view only mode
  possibleViewOnlyStates: [], // Possible states - view only mode
  loanFormData: { ...DEFAULT_LOAN_FORM_DATA },
  achData: { ...ACH_FORM },
  selectedLoanProductId: null,
  selectedLoanProduct: {},
  loanApplicationStatus: {},
  envelopes: null, // Array of envelopes used on the Loan Documents step
  statuses: [],
  reasons: [],
  selectedTranslationsLanguage:
    JSON.parse(localStorage.getItem(LS_USER_PREFERENCES))
      ?.selectedTranslationsLanguage || {},
  reEvaluationCount: 0,
  isHardCreditPulled: false,
  ntps: [],
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.INIT_FLOW]: (
    state,
    { crtStep, loanApplicationId, isDefaultLoanFormData }
  ) => {
    return {
      ...state,
      crtStep,
      loanApplicationId,
      loanFormData: isDefaultLoanFormData
        ? DEFAULT_LOAN_FORM_DATA
        : state.loanFormData,
    }
  },
  [ACTIONS.INIT_FLOW_COMMERCIAL]: (
    state,
    { crtStep, loanApplicationId, isDefaultLoanFormData }
  ) => {
    return {
      ...state,
      crtStep,
      loanApplicationId,
      loanFormData: isDefaultLoanFormData
        ? DEFAULT_COMMERCIAL_LOAN_FORM_DATA
        : state.loanFormData,
    }
  },
  [ACTIONS.RESET_FLOW]: (state) => ({
    ...initialState,
    reasons: state.reasons,
    statuses: state.statuses,
    selectedTranslationsLanguage: state.selectedTranslationsLanguage,
  }),
  [ACTIONS.SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
  [ACTIONS.SET_STEP]: (state, { step }) => {
    return { ...state, crtStep: step }
  },
  [ACTIONS.SET_LOAN_DATA]: (state, { data }) => {
    return {
      ...state,
      loanApplicationId: data.loanApplicationId,
      loanFormData: { ...data },
      selectedLoanProductId: data.selectedLoanProductId,
      selectedLoanProduct: data.selectedLoanProduct,
      loanApplicationStatus: data.loanApplicationStatus,
      isHardCreditPulled: data.isHardCreditPulled,
      currentState: data.loanApplicationState,
    }
  },
  [ACTIONS.SET_ACH_DATA]: (state, { achData }) => {
    return {
      ...state,
      loanFormData: { ...state.loanFormData },
      achData,
    }
  },
  [ACTIONS.SET_POSSIBLE_STATES]: (
    state,
    {
      possibleStates,
      possibleViewOnlyStates,
      isCommercialApp,
      isPtoNotMandatory,
    }
  ) => ({
    ...state,
    possibleEditStates: possibleStates,
    possibleSteps: computePossibleSteps(
      possibleStates,
      isCommercialApp,
      isPtoNotMandatory
    ),
    possibleViewOnlyStates: possibleViewOnlyStates,
    possibleViewOnlySteps: computePossibleSteps(
      possibleViewOnlyStates,
      isCommercialApp,
      isPtoNotMandatory
    ),
  }),
  [ACTIONS.SET_ENVELOPES]: (state, { envelopes }) => ({ ...state, envelopes }),
  [ACTIONS.SET_STATUSES]: (state, { statuses }) => ({ ...state, statuses }),
  [ACTIONS.SET_REASONS]: (state, { reasons }) => ({ ...state, reasons }),
  [ACTIONS.SWITCH_TRANSLATIONS_LANGUAGE]: (state, { selectedLanguage }) => {
    // Update Local Storage
    let userPreferences =
      JSON.parse(localStorage.getItem(LS_USER_PREFERENCES)) || {}
    userPreferences = {
      ...userPreferences,
      selectedTranslationsLanguage: selectedLanguage,
    }
    localStorage.setItem(LS_USER_PREFERENCES, JSON.stringify(userPreferences))

    return {
      ...state,
      selectedTranslationsLanguage: selectedLanguage,
    }
  },
  [ACTIONS.INCREMENT_RE_EVALUATION_COUNT]: (state) => {
    return {
      ...state,
      reEvaluationCount: state.reEvaluationCount + 1,
    }
  },
  [ACTIONS.PREFILL_LOAN_DATA_FROM_QUERY]: (state, { loanFormData }) => {
    const mergedLoanFormData = merge(
      { prefilledFromQueryParams: true },
      state.loanFormData,
      loanFormData
    )

    return {
      ...state,
      loanFormData: mergedLoanFormData,
    }
  },
  [ACTIONS.SET_STIPULATIONS]: (state, { ntps }) => {
    return { ...state, ntps }
  },

  [ACTIONS.SET_STIPULATIONS_COMBINED]: (state, { ntps }) => {
    return { ...state, ntps }
  },

  [ACTIONS.UPDATE_STIPULATION_STATUS]: (state, { payload }) => {
    const { userNTPId, isApproved, needsReview, rejectedDate } = payload
    const updatedNtps = [...state.ntps].map((stipulation) => {
      const updatedUserNTPs = [...stipulation.userNTPs].map((userNTP) => {
        if (userNTP.userNTPId === userNTPId) {
          return {
            ...userNTP,
            isApproved,
            needsReview,
            rejectedDate,
          }
        }
        return userNTP
      })
      return { ...stipulation, userNTPs: updatedUserNTPs }
    })

    return {
      ...state,
      ntps: updatedNtps,
    }
  },
  [ACTIONS.UPDATE_STIPULATION_STATUS_FOR_COMBINED]: (state, { payload }) => {
    const { userNTPId, isApproved, needsReview, rejectedDate, isInitial } =
      payload
    const ntps = isInitial
      ? [...state.ntps.initialNtps]
      : [...state.ntps.finalNtps]
    const updatedNtps = [...ntps].map((stipulation) => {
      const updatedUserNTPs = [...stipulation.userNTPs].map((userNTP) => {
        if (userNTP.userNTPId === userNTPId) {
          return {
            ...userNTP,
            isApproved,
            needsReview,
            rejectedDate,
          }
        }
        return userNTP
      })
      return { ...stipulation, userNTPs: updatedUserNTPs }
    })

    return {
      ...state,
      ntps: {
        initialNtps: isInitial ? updatedNtps : state.ntps.initialNtps,
        finalNtps: !isInitial ? updatedNtps : state.ntps.finalNtps,
      },
    }
  },
  [ACTIONS.REQUEST_FUNDING_REVERSAL]: (state, { reason, requestDate }) => {
    return {
      ...state,
      loanFormData: {
        ...state.loanFormData,
        fundingReversalReason: reason,
        fundingReversalRequestDate: requestDate,
      },
    }
  },
  [ACTIONS.GRANT_FUNDING_REVERSAL]: (state, { fundingReversalDate }) => {
    return {
      ...state,
      loanFormData: {
        ...state.loanFormData,
        fundingReversalDate,
      },
    }
  },
}

export default { name, initialState, reduce }
