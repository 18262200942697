import cx from 'classnames'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'

import { getLanguageByOrgId } from 'modules/organizations/actions'
import { loadTranslationsForLoanApp } from 'modules/translations'
import DateInput from 'components/input-date'
import TextField, { INPUT_TYPES } from 'components/input'
import TextArea from 'components/textarea'
import SearchBar from 'components/search-bar'
import { GENERAL_ADDRESS_ERRORS } from 'common/constants'

import {
  setLoanInfoField,
  useCommercialAppContext,
  setErrors,
  toggleBypassAddressValidation,
  setOrganization,
} from '../../store'
import { AggregatedAddress } from '../aggregated-address'

const { Paper, Dropdown, Autocomplete } = components

export const CommercialLoanInfo = ({
  isCorporateOrg,
  organizations,
  isECG,
  canSeeSensitiveData,
  isEdit,
}) => {
  const { t: translate } = useTranslation()
  const {
    state: {
      typesOfBusiness,
      allLoanSignatures,
      bypassValidateAddress,
      areInputsDisabled,
      organization,
      loanInfo: {
        legalCompanyName,
        businessTypeId,
        einNumber,
        businessStartDate,
        annualGrossRevenue,
        mailingAddress,
        businessPhone,
        mobilePhone,
        emailAddress,
        natureOfBusiness,
        debtServiceSchedule,
        // top section
        loanAmount,
        serviceAddress,
        loanSignatures,
      },
      errors,
    },
    dispatch,
  } = useCommercialAppContext()

  const [displayExpandedAddressFields, setDisplayExpandedAddressFields] =
    useState(false)
  const [
    displayExpandedMailingAddressFields,
    setDisplayExpandedMailingAddressFields,
  ] = useState(false)

  const businessTypes = useMemo(
    () =>
      typesOfBusiness.map((el) => ({
        id: el.id,
        value: el.friendlyName,
        translationKey: `loanApplication.commercial.businessTypes.${el.id}`,
        label: translate(`loanApplication.commercial.businessTypes.${el.id}`),
      })),
    [typesOfBusiness]
  )

  const handleOnChange = (key) => (value) => {
    setLoanInfoField(dispatch, key, value)
  }

  const onAddressSelected = (objKey) => (addressObj) => {
    if (addressObj) {
      if ([objKey].addressId) {
        addressObj.addressId = [objKey].addressId
      }
      setLoanInfoField(dispatch, objKey, addressObj)
    }
    setErrors(dispatch, { ...GENERAL_ADDRESS_ERRORS }, 'loanInfo', objKey)
  }

  const toggleDisplayExpandedAddressFields = () => {
    setDisplayExpandedAddressFields(!displayExpandedAddressFields)
  }

  const handleSetServiceAddress = (addressDetails) => {
    setLoanInfoField(dispatch, 'serviceAddress', addressDetails)
  }

  const handleSetMailingAddress = (addressDetails) => {
    setLoanInfoField(dispatch, 'mailingAddress', addressDetails)
  }

  const handleSelectLoanProducts = (selected) => {
    setLoanInfoField(dispatch, 'loanSignatures', selected)
    setErrors(dispatch, null, 'loanInfo', 'loanSignatures')
  }

  const handleSetOrg = async (selected) => {
    const orgId = selected?.guid

    if (orgId && !isECG) {
      const language = await getLanguageByOrgId(orgId)
      // Load custom translations for the App (if found)
      loadTranslationsForLoanApp({
        language: language.abbreviation,
        orgId: orgId,
      })
    }

    setOrganization(dispatch, orgId)
    setErrors(dispatch, null, 'loanInfo', 'organization')
  }

  const hasServiceAddrErrors = useMemo(
    () =>
      Object.values(errors.loanInfo.serviceAddress || {}).some((el) => !!el),
    [errors.loanInfo?.serviceAddress]
  )

  const hasMailingAddrErrors = useMemo(
    () =>
      Object.values(errors.loanInfo.mailingAddress || {}).some((el) => !!el),
    [errors.loanInfo?.mailingAddress]
  )

  const organizationsOptions = useMemo(() => {
    return organizations.map((org) => {
      return {
        ...org,
        id: org.guid,
        label: org.name,
      }
    })
  }, [organizations])

  const organizationValue = useMemo(() => {
    if (!organization) {
      return null
    }

    const selected = organizations.find((org) => org.guid === organization)
    return {
      ...selected,
      id: selected?.guid,
      label: selected?.name,
    }
  }, [organization])

  const renderTopSection = () => {
    return (
      <>
        <SearchBar
          fullWidth
          label={translate('loanApplication.step1.loanProducts')}
          searchData={allLoanSignatures}
          initialValue={loanSignatures}
          onChange={handleSelectLoanProducts}
          error={errors.loanInfo.loanSignatures}
          disabled={!allLoanSignatures.length || areInputsDisabled}
          isSecondaryStyle={true}
          multiple={true}
          className="grid-item"
        />

        <TextField
          label={translate('loanApplication.step1.loanAmount')}
          validate={() => errors.loanInfo.loanAmount}
          prefix="$"
          type="currency"
          fixedDecimalScale={true}
          value={loanAmount}
          onChange={handleOnChange('loanAmount')}
          className="grid-item"
          disabled={areInputsDisabled}
        />

        {isCorporateOrg && (
          <div className="grid-item">
            <Autocomplete
              sx={{
                mt: 1,
              }}
              label={translate('loanApplication.step1.salesOrganisation')}
              options={organizationsOptions}
              value={organizationValue}
              onChange={(e, selected) => handleSetOrg(selected)}
              error={errors.loanInfo.organization}
              disabled={areInputsDisabled}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <Paper className="paper">
        <div className="commercial-form-grid">
          <div className="applicant-info__form-title grid-item-full-width">
            {translate('loanApplication.step1.loanDetails')}
          </div>

          {renderTopSection()}
          <TextField
            label={translate('loanApplication.commercial.legalCompanyName')}
            validate={() => errors.loanInfo.legalCompanyName}
            value={legalCompanyName || ''}
            onChange={handleOnChange('legalCompanyName')}
            className="grid-item"
          />

          <div className="grid-item">
            <Dropdown
              label={translate('loanApplication.commercial.typeOfBusiness')}
              options={businessTypes}
              value={businessTypeId}
              onChange={(e) => handleOnChange('businessTypeId')(e.target.value)}
              error={errors.loanInfo.businessTypeId}
              disabled={areInputsDisabled}
            />
          </div>

          <TextField
            label={translate('loanApplication.commercial.einNumber')}
            type={isEdit ? INPUT_TYPES.EIN : INPUT_TYPES.EIN_CREATE}
            validate={() => errors.loanInfo.einNumber}
            value={einNumber || ''}
            onChange={handleOnChange('einNumber')}
            disabled={areInputsDisabled}
            hideSecureValue={!canSeeSensitiveData}
            className="grid-item"
          />

          <div className="grid-item">
            <DateInput
              value={businessStartDate}
              onChange={handleOnChange('businessStartDate')}
              label={translate('loanApplication.commercial.businessStartDate')}
              validate={() => errors.loanInfo.businessStartDate}
              disabled={areInputsDisabled}
              fullWidth={true}
              maxDate={new Date()}
            />
          </div>

          <TextField
            label={translate('loanApplication.commercial.annualGrossRevenue')}
            type={INPUT_TYPES.CURRENCY}
            prefix="$"
            validate={() => errors.loanInfo.annualGrossRevenue}
            value={annualGrossRevenue || ''}
            onChange={handleOnChange('annualGrossRevenue')}
            disabled={areInputsDisabled}
            className="grid-item"
          />

          <TextField
            label={translate('loanApplication.commercial.businessPhone')}
            type={INPUT_TYPES.PHONE}
            validate={() => errors.loanInfo.businessPhone}
            value={businessPhone || ''}
            onChange={handleOnChange('businessPhone')}
            disabled={areInputsDisabled}
            className="grid-item"
          />

          <TextField
            label={translate('loanApplication.commercial.mobilePhone')}
            type={INPUT_TYPES.PHONE}
            validate={() => errors.loanInfo.mobilePhone}
            value={mobilePhone || ''}
            onChange={handleOnChange('mobilePhone')}
            disabled={areInputsDisabled}
            className="grid-item"
          />

          <TextField
            label={translate('loanApplication.commercial.emailAddress')}
            validate={() => errors.loanInfo.emailAddress}
            value={emailAddress || ''}
            onChange={handleOnChange('emailAddress')}
            disabled={areInputsDisabled}
            className="grid-item"
          />

          <div className={'grid-item--half-width'}>
            <TextArea
              rows={4}
              resize={false}
              fullWidth={true}
              label={translate('loanApplication.commercial.natureOfBusiness')}
              onChange={handleOnChange('natureOfBusiness')}
              value={natureOfBusiness}
              disabled={areInputsDisabled}
              validate={() => errors.loanInfo.natureOfBusiness}
            />
          </div>

          <div className={'grid-item--half-width'}>
            <TextArea
              rows={4}
              resize={false}
              fullWidth={true}
              label={translate(
                'loanApplication.commercial.debtServiceSchedule'
              )}
              onChange={handleOnChange('debtServiceSchedule')}
              value={debtServiceSchedule}
              disabled={areInputsDisabled}
              validate={() => errors.loanInfo.debtServiceSchedule}
            />
          </div>
        </div>
      </Paper>

      <Paper className="paper">
        <div className="applicant-info__form-title">
          {translate('loanApplication.step1.address.serviceAddress')}
        </div>
        <AggregatedAddress
          address={serviceAddress}
          label={translate('loanApplication.step1.address.serviceAddress')}
          errorObjectName={'serviceAddress'}
          errors={errors.loanInfo}
          bypassValidateAddress={bypassValidateAddress}
          areInputsDisabled={areInputsDisabled}
          hasErrors={hasServiceAddrErrors}
          displayExpandedAddressFields={displayExpandedAddressFields}
          handleSetAddress={handleSetServiceAddress}
          onAddressSelected={onAddressSelected('serviceAddress')}
          toggleDisplayExpandedAddressFields={
            toggleDisplayExpandedAddressFields
          }
          toggleBypassAddressValidation={() =>
            toggleBypassAddressValidation(dispatch)
          }
          setErrors={(errs) => {
            setErrors(dispatch, errs, 'loanInfo')
          }}
        />
      </Paper>

      <Paper className="paper">
        <div className="applicant-info__form-title">
          {translate('loanApplication.commercial.mailingAddress')}
        </div>

        {isCorporateOrg && (
          <div className={cx('loan-form__input', 'grid-item-full-width')}>
            <div className="input__label" style={{ marginBottom: '15px' }}>
              {translate('loanApplication.step1.salesOrganisation')}
            </div>
            <SearchBar
              placeholder={translate('loanApplication.step1.salesOrganisation')}
              searchData={organizations}
              initialValue={organization}
              onChange={handleSetOrg}
              error={errors.loanInfo.organization}
              disabled={areInputsDisabled}
              containerClass="applicant-info__select-loan"
              isSecondaryStyle={true}
            />
          </div>
        )}

        <AggregatedAddress
          address={mailingAddress}
          label={translate('loanApplication.commercial.mailingAddress')}
          errorObjectName={'mailingAddress'}
          errors={errors.loanInfo}
          areInputsDisabled={areInputsDisabled}
          hasErrors={hasMailingAddrErrors}
          displayExpandedAddressFields={displayExpandedMailingAddressFields}
          handleSetAddress={handleSetMailingAddress}
          onAddressSelected={onAddressSelected('mailingAddress')}
          toggleDisplayExpandedAddressFields={() =>
            setDisplayExpandedMailingAddressFields(
              !displayExpandedMailingAddressFields
            )
          }
          setErrors={(errs) => {
            setErrors(dispatch, errs, 'loanInfo')
          }}
          dispatch={dispatch}
          hideBypassValidation={true}
        />
      </Paper>
    </>
  )
}

CommercialLoanInfo.propTypes = {
  isCorporateOrg: PropTypes.bool.isRequired,
  organizations: PropTypes.array.isRequired,
  isECG: PropTypes.bool,
  canSeeSensitiveData: PropTypes.bool,
  isEdit: PropTypes.bool,
}
