import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { assets } from '@ElementsCapitalGroup/enium-ui'

import CommentsSection from 'modules/loan-application/ntp/comments-section.js'
import NTPDocuments from 'modules/loan-application/ntp/documents'
import NTPAddStipulations from 'modules/loan-application/ntp/stipulations-add-new'
import NTPButtons from 'modules/loan-application/ntp/buttons'
import Loader, { LOADER_SIZE } from 'components/loader'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'

import { AccessWrapper, hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import Button, { BUTTON_VARIANTS } from 'components/button'

import {
  downloadAllAttachments,
  submitNTPComment,
  addStipulationsNTP,
  removeNTPComment,
  updateUserNtpStatusForCombined,
} from '../actions'
import {
  areNtpsApproved,
  areNtpAttachmentsApproved,
  areNtpsReadyForSubmission,
} from './helpers'
import { STATES_ORDER, STATES_ORDER_COMMERCIAL } from '../constants'

import { styles } from './style'

const { Download02Icon } = assets

const NTPModule = ({
  commercialNTP,
  initialNTP,
  ntps,
  fetchNTPs,
  submitNTPForApproval,
  approveNTP,
  requestFunds,
  grantFunds,
  currentState,
  userData,
  loanAmount,
  loanStatusId,
  loanApplicationId,
  initialFundingAmount,
  isInitial,
  dispatch,
  serviceAddress,
  estimatedCombinedIncomeToBeProven,
}) => {
  const { t: translate } = useTranslation()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const statesOrder = commercialNTP ? STATES_ORDER_COMMERCIAL : STATES_ORDER
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(true)
  const [isEnhanced, setIsEnhanced] = useState(false)
  const [initialNtpsUploadedCount, setInitialNtpsUploadedCount] = useState(0)
  const [finalNtpsUploadedCount, setFinalNtpsUploadedCount] = useState(0)
  const [downloadAllLoading, setDownloadAllLoading] = useState(false)
  const [enhancedLoading, setEnhancedLoading] = useState(false)
  const [buttonsLoading, setButtonsLoading] = useState(false)
  const [addStipulationOpen, setAddStipulationOpen] = useState(false)
  const [nptsInitialLoading, setNtpsInitialLoading] = useState(false)
  const [ntpsFinalLoading, setNtpsFinalLoading] = useState(false)
  const [canSubmitForApproval, setCanSubmitForApproval] = useState(null)
  const [isInCorrespondingNtpStep, setIsInCorrespondingNtpStep] = useState(null)

  const ntpSectionId = isInitial
    ? ntps?.initialNtps?.[0]?.ntpSectionId
    : ntps?.finalNtps?.[0]?.ntpSectionId

  const state = useMemo(() => {
    return statesOrder[currentState]
  }, [currentState])

  const ntpApproved = useMemo(() => {
    return areNtpsApproved(state, isInitial, commercialNTP)
  }, [state, initialNTP])

  useEffect(() => {
    const isInCorrespondingNtpStep = areNtpsReadyForSubmission(
      state,
      isInitial,
      commercialNTP
    )

    const canSubmitForApproval = isInitial
      ? initialNtpsUploadedCount === ntps?.initialNtps?.length &&
        isInCorrespondingNtpStep
      : finalNtpsUploadedCount === ntps?.finalNtps?.length

    setIsInCorrespondingNtpStep(isInCorrespondingNtpStep)
    setCanSubmitForApproval(canSubmitForApproval)
  }, [
    state,
    isInitial,
    commercialNTP,
    ntps,
    initialNtpsUploadedCount,
    finalNtpsUploadedCount,
  ])

  const canCommentOnNTPs = hasAccess(userData, CLAIMS.CAN_COMMENT_NTP)

  const canRemoveNTPComments = hasAccess(
    userData,
    CLAIMS.CAN_REMOVE_NTP_COMMENTS
  )

  const userId = userData?.associations[0]?.userId

  /** Method to fetch the list of NTPs */
  const getNTPs = () => {
    setLoading(true)

    return fetchNTPs()
      .then((result) => {
        setComments(result.comments)
        setIsEnhanced(
          result.ntpSections.find((itm) => {
            return itm.hasEnhancedInitialFunding
          })
        )
        return result
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /** On mount get data */
  useEffect(() => {
    getNTPs().finally(() => setLoading(false))
  }, [])

  /** On Documents change, update the uploadedCount */
  useEffect(() => {
    if (ntps.initialNtps) {
      setInitialNtpsUploadedCount(
        ntps.initialNtps.filter((el) => el.userNTPs.length > 0).length
      )
    }
    if (ntps.finalNtps) {
      setFinalNtpsUploadedCount(
        ntps.finalNtps.filter((el) => el.userNTPs.length > 0).length
      )
    }
  }, [JSON.stringify(ntps)])

  /** canApprove is true if there is at least one NTP from each group that is approved, and all NTPs have a status */
  const canApprove = useMemo(() => {
    if (ntps?.initialNtps) {
      if (isInitial) {
        return areNtpAttachmentsApproved(ntps?.initialNtps)
      } else {
        return areNtpAttachmentsApproved(ntps?.finalNtps)
      }
    } else {
      return false
    }
  }, [JSON.stringify(ntps), currentState, isInitial])

  /** Download all attachments */
  const downloadAll = (ntpSectionId) => {
    setDownloadAllLoading(true)
    downloadAllAttachments(ntpSectionId).finally(() =>
      setDownloadAllLoading(false)
    )
  }

  /** Add a new comment */
  const submitComment = (comment) => {
    setLoading(true)
    submitNTPComment(loanApplicationId, comment)
      .then(() => getNTPs())
      .finally(() => setLoading(false))
  }

  const removeComment = (commentId) => {
    setLoading(true)
    removeNTPComment(commentId)
      .then(() => {
        setComments(comments.filter((c) => c.ntpCommentId !== commentId))
      })
      .finally(() => setLoading(false))
  }

  if (loading) {
    return (
      <>
        <div className="ntp__wrapper">
          <Loader size={130} center={true} />
        </div>
      </>
    )
  }

  return (
    <div style={styles.ntpWrapper}>
      <div style={isMobileView ? styles.ntpContentMobile : styles.ntpContent}>
        <div>
          <div style={styles.header}>
            <div style={styles.headerContent}>
              <div style={styles.headerSubheaderInitial}>
                Initial Stipulations{' '}
                <div style={styles.subHeader}>
                  {initialNtpsUploadedCount}/{ntps?.initialNtps?.length}{' '}
                  {translate(
                    'loanApplication.ntpStepsCommon.documentsUploaded'
                  )}
                </div>
              </div>
              <AccessWrapper claims={CLAIMS.CAN_DOWNLOAD_NTPS}>
                {downloadAllLoading ? (
                  <Loader noLogo size={30} />
                ) : (
                  <Button
                    onClick={() =>
                      downloadAll(ntps?.initialNtps?.[0]?.ntpSectionId)
                    }
                    startIcon={<Download02Icon />}
                    variant={BUTTON_VARIANTS.OUTLINED}
                    sx={{
                      background: 'white',
                    }}
                  >
                    {translate('global.download')}
                  </Button>
                )}
              </AccessWrapper>
            </div>
          </div>{' '}
          {nptsInitialLoading && (
            <Loader size={LOADER_SIZE.MEDIUM} center={true} />
          )}
          {ntps.initialNtps?.length && (
            <NTPDocuments
              ntps={ntps.initialNtps}
              commercialNTP={commercialNTP}
              loanApplicationId={loanApplicationId}
              estimatedCombinedIncomeToBeProven={
                estimatedCombinedIncomeToBeProven
              }
              loading={nptsInitialLoading}
              setLoading={setNtpsInitialLoading}
              getNtps={getNTPs}
              serviceAddress={serviceAddress}
              userData={userData}
              updateUserNtpStatus={(result) =>
                updateUserNtpStatusForCombined(
                  dispatch,
                  loanApplicationId,
                  result,
                  true
                )
              }
              isInitial={true}
              loanApplicationStateId={state}
            />
          )}
          <div>
            <div>
              <div style={styles.header}>
                <div style={styles.headerContent}>
                  <div style={styles.headerSubheaderFinal}>
                    Final Stipulations{' '}
                    <div style={styles.subHeader}>
                      {finalNtpsUploadedCount}/{ntps?.finalNtps?.length}{' '}
                      {translate(
                        'loanApplication.ntpStepsCommon.documentsUploaded'
                      )}
                    </div>
                  </div>
                  <AccessWrapper claims={CLAIMS.CAN_DOWNLOAD_NTPS}>
                    {downloadAllLoading ? (
                      <Loader noLogo size={30} />
                    ) : (
                      <Button
                        onClick={() =>
                          downloadAll(ntps?.finalNtps?.[0]?.ntpSectionId)
                        }
                        startIcon={<Download02Icon />}
                        variant={BUTTON_VARIANTS.OUTLINED}
                        sx={{
                          background: 'white',
                        }}
                      >
                        {translate('global.download')}
                      </Button>
                    )}
                  </AccessWrapper>
                </div>
              </div>
              {ntpsFinalLoading && (
                <Loader size={LOADER_SIZE.MEDIUM} center={true} />
              )}
              {ntps.finalNtps?.length && (
                <NTPDocuments
                  ntps={ntps.finalNtps}
                  commercialNTP={commercialNTP}
                  loanApplicationId={loanApplicationId}
                  estimatedCombinedIncomeToBeProven={
                    estimatedCombinedIncomeToBeProven
                  }
                  loading={ntpsFinalLoading}
                  setLoading={setNtpsFinalLoading}
                  getNtps={getNTPs}
                  serviceAddress={serviceAddress}
                  userData={userData}
                  updateUserNtpStatus={(result) =>
                    updateUserNtpStatusForCombined(
                      dispatch,
                      loanApplicationId,
                      result,
                      false
                    )
                  }
                  isInitial={false}
                  loanApplicationStateId={state}
                />
              )}
            </div>
          </div>
        </div>

        <div style={{ marginTop: '16px' }}>
          <div style={styles.ntpButtonsWrapper}>
            <NTPButtons
              isCommercial={commercialNTP}
              isInitial={isInitial}
              currentState={currentState}
              loading={buttonsLoading}
              setLoading={setButtonsLoading}
              submitNTPForApproval={submitNTPForApproval}
              approveNTP={approveNTP}
              requestFunds={requestFunds}
              grantFunds={grantFunds}
              canSubmitForApproval={canSubmitForApproval}
              canApprove={canApprove}
              loanAmount={loanAmount}
              initialFundingAmount={initialFundingAmount}
              setAddStipulationOpen={setAddStipulationOpen}
              loanStatusId={loanStatusId}
              representativeName={`${userData.firstName} ${userData.lastName}`}
              loanApplicationId={loanApplicationId}
              ntpApproved={ntpApproved}
              isInCorrespondingNtpStep={isInCorrespondingNtpStep}
              enhancedLoading={enhancedLoading}
              isEnhanced={isEnhanced}
              setEnhancedLoading={setEnhancedLoading}
              setIsEnhanced={setIsEnhanced}
              ntpSectionId={
                isInitial
                  ? ntps?.initialNtps?.[0]?.ntpSectionId
                  : ntps?.finalNtps?.[0]?.ntpSectionId
              }
            />
          </div>
          <AccessWrapper claims={CLAIMS.CAN_VIEW_NTP_COMMENTS}>
            <CommentsSection
              comments={comments}
              onSubmit={submitComment}
              canComment={canCommentOnNTPs}
              removeComment={removeComment}
              userId={userId}
              canRemoveNTPComments={canRemoveNTPComments}
            />
          </AccessWrapper>

          <NTPAddStipulations
            isOpen={addStipulationOpen}
            onClose={() => setAddStipulationOpen(false)}
            onAddStipulations={(stipulations) => {
              setAddStipulationOpen(false)
              addStipulationsNTP(
                dispatch,
                loanApplicationId,
                ntpSectionId,
                stipulations
              ).then(getNTPs)
            }}
            isInitial={isInitial}
            isCommercial={commercialNTP}
          />
        </div>
      </div>
    </div>
  )
}

NTPModule.propTypes = {
  commercialNTP: PropTypes.bool,
  initialNTP: PropTypes.bool,
  ntps: PropTypes.object.isRequired,
  fetchNTPs: PropTypes.func.isRequired,
  submitNTPForApproval: PropTypes.func.isRequired,
  approveNTP: PropTypes.func.isRequired,
  requestFunds: PropTypes.func.isRequired,
  grantFunds: PropTypes.func.isRequired,
  currentState: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
  loanAmount: PropTypes.number.isRequired,
  initialFundingAmount: PropTypes.number,
  loanApplicationId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  loanStatusId: PropTypes.number.isRequired,
  serviceAddress: PropTypes.object,
  isInitial: PropTypes.bool,
  estimatedCombinedIncomeToBeProven: PropTypes.number,
}

export default NTPModule
