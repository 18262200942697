const black = '#000'

export const styles = {
  reminderTitle: {
    marginTop: '12px',
    color: black,
    marginBottom: '6px',
    fontWeight: 800,
  },

  reminderButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
    marginBottom: '12px',
  },
}
