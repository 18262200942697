import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import { styles as loanAppStyles } from 'modules/loan-application/status/style'
import { useClickOutside, useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import { styles } from './style.js'

const { Chip } = components
const { HourglassIcon, PinIcon } = assets

const LoanApplicationIcons = (params) => {
  const { isDuplicateAddress, isDuplicateSsn, style = {} } = params
  const { t: translate } = useTranslation()
  const [showExtraChips, setShowExtraChips] = useState(false)
  const extraChipsRef = useRef(null)
  const isTabletView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:600px)`)

  const iconsToShow = []
  Object.entries(params).forEach(([key, value]) => {
    if (value === true) {
      if (key === 'isDuplicateSsn' && isDuplicateAddress) {
        iconsToShow.push({
          key: 'isDuplicateAddressAndSsn',
          label: translate(LABELS['isDuplicateAddressAndSsn']),
        })
      } else if (key === 'isDuplicateAddress' && isDuplicateSsn) {
        return
      } else {
        iconsToShow.push({ key: key, label: translate(LABELS[key]) })
      }
    }
  })

  const onClickOutside = () => setShowExtraChips(false)
  useClickOutside(extraChipsRef, onClickOutside)

  const _renderChip = (key, label, sx = {}) => {
    let severity = SEVERITY.WARNING
    let Icon = PinIcon
    if (key === 'cancelled') {
      severity = SEVERITY.ERROR
    }

    if (severity === SEVERITY.ERROR) {
      Icon = HourglassIcon
    }
    return (
      <Chip
        key={key}
        size="small"
        color={severity}
        icon={<Icon sx={{ width: '14px' }} />}
        label={label}
        sx={{ ...styles.chipStyle, ...sx }}
      />
    )
  }

  const _renderExtraChipsDropdown = (extraIcons) => {
    return (
      <div style={styles.extraChipsDropdown}>
        {extraIcons.map((item) =>
          _renderChip(item.key, item.label, {
            marginRight: '0',
            marginBottom: '4px',
          })
        )}
      </div>
    )
  }

  // Only show 2 items, the rest are show inside a "view more"
  const displayedIcons = iconsToShow.splice(0, 2)

  if (!displayedIcons.length) {
    return null
  }

  return (
    <div
      style={
        isTabletView
          ? { width: '100%', marginTop: '15px' }
          : { marginLeft: 'auto' }
      }
    >
      <div
        style={{
          ...loanAppStyles.currentNextHeader,
          marginBottom: isMobileView ? '-6px' : '4px',
        }}
      >
        {translate('loanApplication.common.alerts')}
      </div>
      <div style={{ ...styles.iconsWrapper, ...style }}>
        {displayedIcons.map((item) => _renderChip(item.key, item.label))}

        <div ref={extraChipsRef} style={styles.extraChipsWrapper}>
          {iconsToShow.length ? (
            <Chip
              size="small"
              label={`+${iconsToShow.length} more`}
              sx={styles.chipAddMore}
              onClick={() => setShowExtraChips(true)}
            />
          ) : null}

          {showExtraChips && _renderExtraChipsDropdown(iconsToShow)}
        </div>
      </div>
    </div>
  )
}

const SEVERITY = {
  WARNING: 'warning',
  ERROR: 'error',
}
const LABELS = {
  loanAmountDecreased: 'loanApplication.statusCard.icons.loanAmountDecreased',
  loanAmountIncreased: 'loanApplication.statusCard.icons.loanAmountIncreased',
  hasReversal: 'loanApplication.statusCard.icons.loanReversal',
  cancelled: 'loanApplication.statusCard.icons.loanCancelled',
  isOnHold: 'loanApplication.statusCard.icons.onHold',
  isDuplicateAddress: 'loanApplication.statusCard.icons.sameAddress',
  isDuplicateSsn: 'loanApplication.statusCard.icons.sameSsn',
  isDuplicateAddressAndSsn:
    'loanApplication.statusCard.icons.sameSsnAndAddress',
  agingTier: 'loanApplication.statusCard.icons.aging',
  isAddressOverriden: 'loanApplication.statusCard.icons.addressOverriden',
  isAdverseActionNoticesDelivered:
    'loanApplication.statusCard.icons.adverseActionNoticesDelivered',
}

LoanApplicationIcons.propTypes = {
  loanAmountDecreased: PropTypes.bool,
  loanAmountIncreased: PropTypes.bool,
  hasReversal: PropTypes.bool,
  cancelled: PropTypes.bool,
  isOnHold: PropTypes.bool,
  isDuplicateAddress: PropTypes.bool,
  isDuplicateSsn: PropTypes.bool,
  agingTier: PropTypes.bool,
  isAddressOverriden: PropTypes.bool,
  isAdverseActionNoticesDelivered: PropTypes.bool,
  style: PropTypes.object,
}

export default LoanApplicationIcons
