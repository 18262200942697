import { SCHEMA_TYPES } from 'components/form-builder/constants'
import { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'

export default {
  titleKey: 'loanApplication.step3.membership.glendale.title',
  fields: {
    gascuMembership: false,
    arbitrationClause: false,
    electronicCommunications: false,
  },
  sections: [
    {
      type: SCHEMA_TYPES.WRAPPER,
      extraClass: 'scrollable',
      sections: [
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.memberAcknowledgementAndAgreementParagraph',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.loanMembershipAgreementDisclosure',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.membershipEligibility',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.serviceLimitation',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.lienAndSecurityInterest',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey: 'loanApplication.step3.membership.glendale.enforcement',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey: 'loanApplication.step3.membership.glendale.governingLaw',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.proceduresForEstablishingMembership',
        },
        {
          type: SCHEMA_TYPES.HEADING,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.arbitrationAndActionReliefTitle',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.controversyOrClaimArising1',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.controversyOrClaimArising2',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.partyElectsArbitration',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey: 'loanApplication.step3.membership.glendale.optOutDisclosure',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.arbitrationAgreement',
        },
        {
          type: SCHEMA_TYPES.HEADING,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.electronicCommunicationsTitle',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.electronicCommunicationsConsent',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.electronicCommunicationsAuthorization',
        },
        {
          type: SCHEMA_TYPES.HTML,
          extraClass: 'padding-24',
          textKey:
            'loanApplication.step3.membership.glendale.electronicCommunicationsUnderstandment',
        },
      ],
    },
    {
      type: SCHEMA_TYPES.WRAPPER,
      sections: [
        {
          type: SCHEMA_TYPES.CHECKBOX,
          extraClass: 'padding-24',
          field: 'gascuMembership',
          labelKey: 'loanApplication.step3.membership.glendale.gascuMembership',
        },
        {
          type: SCHEMA_TYPES.CHECKBOX,
          extraClass: 'padding-24',
          field: 'arbitrationClause',
          labelKey:
            'loanApplication.step3.membership.glendale.arbitrationClause',
        },
        {
          type: SCHEMA_TYPES.CHECKBOX,
          extraClass: 'padding-24',
          field: 'electronicCommunications',
          labelKey:
            'loanApplication.step3.membership.glendale.electronicCommunications',
        },
      ],
    },
    {
      type: SCHEMA_TYPES.WRAPPER,
      extraClass: 'sticky',
      sections: [
        {
          type: SCHEMA_TYPES.BUTTONS,
          buttons: [
            {
              action: 'onClose',
              className: 'full-button',
              labelKey: 'Cancel',
              color: BUTTON_COLORS.INHERIT,
              variant: BUTTON_VARIANTS.OUTLINED,
            },
            {
              action: 'onSubmit',
              className: 'full-button',
              labelKey: 'loanApplication.step3.membership.agreed',
              disabled: (state) =>
                !state.gascuMembership ||
                !state.arbitrationClause ||
                !state.electronicCommunications,
            },
          ],
        },
      ],
    },
  ],
}
