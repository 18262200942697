import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useStore } from 'store'
import { assets } from '@ElementsCapitalGroup/enium-ui'

import { ReactComponent as NotesApostrophe } from 'assets/notes-apostrophe.svg'
import { ReactComponent as BlueClipperIcon } from 'assets/blue-clipper.svg'
import Loader from 'components/loader'
import DeleteModal from 'components/modal/delete-modal'
import { downloadFileBase64 } from 'common/utils'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import {
  downloadSingleAttachment,
  removeNote,
} from 'modules/loan-application/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { showNotification } from 'modules/global/actions'

import './index.scss'

const { TrashIcon } = assets

const NotesList = ({ notes, updateNotesOnDeletion, loading, setLoading }) => {
  const { t: translate } = useTranslation()
  const { state, dispatch } = useStore()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [noteToDelete, setNoteToDelete] = useState(null)
  const loggedInUserId = state.session.userData?.associations[0]?.userId

  const canRemoveNotes = hasAccess(
    state.session.userData,
    CLAIMS.CAN_REMOVE_LOAN_APPLICATION_NOTES
  )

  const handleRemoveNote = (noteId) => (e) => {
    e.stopPropagation()
    setIsDeleteModalOpen(true)
    setNoteToDelete(noteId)
  }

  const handleConfirmDelete = () => {
    setLoading(true)

    removeNote(noteToDelete)
      .then(() => {
        showNotification(dispatch, {
          title: translate('loanApplication.notes.notifications.successRemove'),
        })
        updateNotesOnDeletion(notes.filter((n) => n.noteId !== noteToDelete))
      })
      .catch(() => {
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: translate('global.notifications.error'),
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDownloadNoteAttachment = (attachmentId) => {
    downloadSingleAttachment(dispatch, attachmentId).then(
      (res) => res && downloadFileBase64(res)
    )
  }

  return (
    <div className="notes__items">
      {loading ? (
        <Loader center={true} />
      ) : (
        notes.map((note) => (
          <div key={note.noteId}>
            <div className="notes__item-wrapper">
              <div className="notes__item">
                <div className="notes__item-header">
                  <div className="notes__item-header-content">
                    <NotesApostrophe />
                    <div>
                      <span className="notes__item-name">
                        {note.userName ?? translate('global.defaultUser')}
                      </span>
                      <span className="notes__item-date">
                        {moment(note.dateCreated).format(
                          'MMMM Do, YYYY - h:mm A'
                        )}
                      </span>
                    </div>
                  </div>

                  {(note.userId === loggedInUserId || canRemoveNotes) && (
                    <TrashIcon
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={handleRemoveNote(note.noteId)}
                    />
                  )}
                </div>

                <div
                  className="notes__item-text"
                  dangerouslySetInnerHTML={{ __html: note.content }}
                ></div>

                {Array.isArray(note.attachments) &&
                  note.attachments.map((attachment, key) => (
                    <div
                      key={key}
                      className="notes__item-attachment"
                      onClick={() =>
                        handleDownloadNoteAttachment(attachment.attachmentId)
                      }
                    >
                      <div className="notes__item-attachment-name">
                        <BlueClipperIcon />
                        {attachment.name}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          title={translate('global.areYouSure')}
          confirmButtonText={translate('buttons.delete')}
          cancelButtonText={translate('buttons.cancel')}
          onSubmitModal={handleConfirmDelete}
          setModalOpen={setIsDeleteModalOpen}
        />
      )}
    </div>
  )
}

NotesList.propTypes = {
  notes: PropTypes.array.isRequired,
  updateNotesOnDeletion: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default NotesList
