import React from 'react'
import PropTypes from 'prop-types'
import DatePicker, { CalendarContainer } from 'react-datepicker'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import moment from 'moment'
import { BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from 'components/button'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'

import 'react-datepicker/dist/react-datepicker.css'
import './style.scss'

const { Button } = components
const { CalendarIcon, XCircleIcon } = assets

export const DateRangePickerComponent = ({
  startDate,
  endDate,
  setDateRange,
  disabled,
  showTimePicker = false,
  showMonthDropdown = true,
  showYearDropdown = true,
  dropdownMode = 'scroll',
  maxDate,
  dateFormat = 'MMMM dd, yyyy',
}) => {
  const calendarRef = React.useRef()
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  const onChange = (dates) => {
    const [start, end] = dates
    setDateRange(start, end)
  }

  const onClose = () => {
    calendarRef.current.setOpen(false)
  }

  const CalendarWrapper = ({ className, children }) => {
    const renderCustomRange = (label, start, end) => (
      <Button
        variant={BUTTON_VARIANTS.TEXT}
        color={BUTTON_COLORS.INHERIT}
        size={BUTTON_SIZES.SMALL}
        onClick={() => {
          setDateRange(start, end)
          onClose()
        }}
      >
        {label}
      </Button>
    )
    return (
      <div className="calendar-wrapper">
        <>
          <div className="calendar-wrapper__custom-ranges">
            {renderCustomRange(
              'This week',
              moment().startOf('week').toDate(),
              new Date()
            )}
            {renderCustomRange(
              'This month',
              moment().startOf('month').toDate(),
              new Date()
            )}
            {renderCustomRange(
              'This year',
              moment().startOf('year').toDate(),
              new Date()
            )}
            {renderCustomRange(
              'Last week',
              moment().subtract(1, 'weeks').startOf('isoWeek').toDate(),
              moment().subtract(1, 'weeks').endOf('isoWeek').toDate()
            )}
            {renderCustomRange(
              'Last month',
              moment().subtract(1, 'months').startOf('month').toDate(),
              moment().subtract(1, 'months').endOf('month').toDate()
            )}
            {renderCustomRange(
              'Last year',
              moment().subtract(1, 'year').startOf('year').toDate(),
              moment().subtract(1, 'year').endOf('year').toDate()
            )}
            {renderCustomRange('All time', null, null)}
          </div>
          <CalendarContainer className={className}>
            <div style={{ position: 'relative' }}>{children}</div>
          </CalendarContainer>
        </>
      </div>
    )
  }

  CalendarWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
  }

  return (
    <div>
      <DatePicker
        ref={calendarRef}
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        disabled={disabled}
        showTimeSelect={showTimePicker}
        dateFormat={showTimePicker ? 'Pp' : 'P'}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        scrollableYearDropdown={true}
        yearDropdownItemNumber={50}
        dropdownMode={dropdownMode}
        maxDate={maxDate || null}
        dateFormatCalendar={dateFormat}
        preventOpenOnFocus={true}
        showPopperArrow={false}
        monthsShown={2}
        calendarContainer={CalendarWrapper}
        withPortal={isTabletView}
        customInput={
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            sx={{
              mr: '8px',
              height: '40px',
              width: { xs: '100%', sm: 'inherit' },
            }}
            startIcon={<CalendarIcon />}
            endIcon={
              (startDate || endDate) && (
                <XCircleIcon
                  onClick={(e) => {
                    onChange([null, null])
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                />
              )
            }
          >
            {startDate && endDate
              ? `${moment(startDate).format('MM/DD/YYYY')} - ${moment(
                  endDate
                ).format('MM/DD/YYYY')}`
              : 'All time'}
          </Button>
        }
      />
    </div>
  )
}

DateRangePickerComponent.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  setDateRange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showTimePicker: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  dropdownMode: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  dateFormat: PropTypes.string,
}
