import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { modules } from '@ElementsCapitalGroup/enium-ui'
import AuthBg from 'assets/auth-bg-desktop.jpg'
import AuthBgMobile from 'assets/auth-bg-mobile.jpg'
import { sendResetPasswordEmail } from 'modules/organizations/actions'
const { ForgotPasswordContainer, CheckEmailContainer } = modules

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState(null)

  const goToLogin = () => navigate('/login')

  /** Submit form handler */
  const onSubmit = ({ email }, ev) => {
    ev?.preventDefault()
    setLoading(true)
    setEmail(email)

    sendResetPasswordEmail(email)
      .then(() => setSuccess(true))
      .catch(() => setError('Something went wrong'))
      .finally(() => setLoading(false))
  }

  if (success) {
    return (
      <CheckEmailContainer
        title="Check your email"
        supportingText={`We sent a password reset link to ${email}`}
        imageUrl={AuthBg}
        mobileImageUrl={AuthBgMobile}
        onOpenEmail={() => (window.location.href = 'mailto:?')}
        onResendEmail={() => onSubmit({ email })}
        onBack={goToLogin}
      />
    )
  }

  return (
    <ForgotPasswordContainer
      title="Forgot password?"
      supportingText="No worries, we’ll send you reset instructions."
      imageUrl={AuthBg}
      mobileImageUrl={AuthBg}
      errorMessages={{ email: error }}
      loading={loading}
      onBack={goToLogin}
      onSubmit={onSubmit}
      verbiage={{ signIn: 'Reset password' }}
    />
  )
}

export default ForgotPassword
