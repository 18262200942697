import React from 'react'
import { enrich } from 'modules/session/actions'
import { setLoading } from 'modules/global/actions'
import { ENIUM_X_ORGANIZATION_TYPE_ID } from 'common/constants'
import { useStore } from 'store'

import { colors } from '@ElementsCapitalGroup/enium-ui'

import './index.scss'

export const SelectRole = () => {
  const { state, dispatch } = useStore()
  const { userData } = state.session
  const { associations } = userData

  const filteredAssociations = associations.filter(
    (a) =>
      a.organizationUnit?.organizationTypeId !== ENIUM_X_ORGANIZATION_TYPE_ID
  )

  const handleSelectOrganization = (organization) => {
    setLoading(dispatch, true)
    enrich(dispatch, organization).finally(() => {
      window.location.reload(false)
    })
  }

  return (
    <div className="select-role" style={{ color: colors.grey[700] }}>
      {filteredAssociations.map((item, key, arr) => (
        <React.Fragment key={key}>
          {!(
            item.organizationId === userData.organizationId &&
            item.roleId === userData.roleId
          ) && (
            <div
              className="select-role__item"
              onClick={() => handleSelectOrganization(item)}
            >
              <span className="select-role__item-org">
                {item.organizationUnit.name}
              </span>
              <span className="select-role__item-role">{item.role.name}</span>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}
