import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Table from 'components/table'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'common/hooks'
import { APPLICANT_TYPE, TABLET_BREAKPOINT } from 'common/constants'
import { ExpandableCard } from 'components/expandable-card'
import {
  assets,
  colors,
  unstable_components,
  components,
} from '@ElementsCapitalGroup/enium-ui'
import { useStore } from 'store'
import { formatSSN } from 'common/utils'
import UWApprovalStatus from '../common/uw-approval-status'
import UwDeclineTooltip from '../common/uw-decline-tooltip'
import { columns } from './columns'
import { DATA_PROPERTY_TYPES, SOURCE_TYPE_IDS } from '../constants'
import { UW_HUB_ACTIONS } from '../actions'
import { expandableCard } from '../common/styles'
import DataReviewMobileContent from './mobile-content'

const { Unstable_ToggleButtonGroup: ToggleButtonGroup } = unstable_components
const { AlertCircleIcon } = assets
const { Tooltip } = components

const DataReview = ({ loanApplicationId, data }) => {
  const { dispatch } = useStore()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const [selectedSection, setSelectedSection] = useState(
    APPLICANT_TYPE.BORROWER.toString()
  )

  const { t: translate } = useTranslation()
  const translatedColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      header: (
        <div className="align-center">
          {translate(`uwHub.dataReview.${column.accessorKey}`)}
          {column.tooltip ? (
            <Tooltip title={column.tooltip} className="align-center">
              <AlertCircleIcon
                style={{ width: '16px', height: '16px', marginLeft: '4px' }}
              />
              &nbsp;
            </Tooltip>
          ) : null}
        </div>
      ),
    }))
  }, [])

  const updateApprovalStatus = (approvalStatusId, reason) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_DATA_REVIEW_DATA,
      payload: {
        ...data,
        uwApprovalStatusId: approvalStatusId,
        uwDeclineReason: reason,
      },
    })
  }

  const tableData = useMemo(() => {
    const sources = data.applicantSections.find(
      (el) => el.applicantTypeId.toString() === selectedSection
    )?.sources
    // For each property type we generate a row
    return Object.entries(DATA_PROPERTY_TYPES).map(
      ([propertyId, propertyLabel]) => {
        const row = { data: propertyLabel }
        Object.entries(SOURCE_TYPE_IDS).forEach(([sourceId, sourceLabel]) => {
          const sourceValue = sources
            .find((source) => source.sourceTypeId.toString() === sourceId)
            ?.properties.find(
              (property) => property.propertyTypeId.toString() === propertyId
            )
          let value = sourceValue?.stringValue
          if (value && propertyLabel === DATA_PROPERTY_TYPES['4']) {
            value = formatSSN(value)
          }
          row[sourceLabel] = {
            value: value,
            validationReason: sourceValue?.validationReason,
            dataValidationId: sourceValue?.dataValidationId,
          }
        })
        return row
      }
    )
  }, [data, selectedSection])

  const _renderToggle = () =>
    data.applicantSections?.length > 1 ? (
      <ToggleButtonGroup
        key="toggle"
        style={{ marginRight: '16px' }}
        active={APPLICANT_TYPE.BORROWER.toString()}
        buttons={[
          {
            id: APPLICANT_TYPE.BORROWER.toString(),
            children: 'Borrower',
            variant: 'text',
            color: colors.grey[700],
            activeColor: 'white',
          },
          {
            id: APPLICANT_TYPE.COBORROWER.toString(),
            children: 'Co-Borrower',
            variant: 'text',
            color: colors.grey[700],
            activeColor: 'white',
          },
        ]}
        onChange={setSelectedSection}
      />
    ) : null

  const _renderApprovalStatus = (renderApprovalStatusForMobile = false) => (
    <UWApprovalStatus
      loanApplicationId={loanApplicationId}
      portalApprovalStatusId={data.portalApprovalStatusId}
      uwApprovalStatusId={data.uwApprovalStatusId}
      sectionTypeId={data.sectionTypeId}
      updateSectionApprovalStatus={updateApprovalStatus}
      displayPortalApprovalStatus={!renderApprovalStatusForMobile}
      displayUwApprovalStatus={renderApprovalStatusForMobile || !isMobileView}
    />
  )

  const _renderTitle = () => (
    <div className="align-center">
      {translate('uwHub.dataReview.title')}
      <UwDeclineTooltip data={data} />
    </div>
  )

  const collapsibleItems = [
    _renderApprovalStatus(true),
    _renderToggle(),
    <DataReviewMobileContent
      key="data-review-mobile"
      columns={translatedColumns}
      data={data}
      selectedSection={selectedSection}
    />,
  ].filter((el) => !!el)

  return (
    <div style={{ marginBottom: '32px' }}>
      {!isMobileView ? (
        <Table
          title={_renderTitle()}
          columns={translatedColumns}
          data={tableData}
          toolbarRightContent={
            <>
              {_renderToggle()}
              {_renderApprovalStatus()}
            </>
          }
        />
      ) : (
        <ExpandableCard
          headerProps={expandableCard.headerProps(isMobileView)}
          titleTypographyProps={expandableCard.titleProps}
          title={
            <div className="uw-hub-page__income-card">
              {_renderTitle()}
              {_renderApprovalStatus()}
            </div>
          }
          isCollapsable={true}
          alwaysCollapsible={true}
          collapsibleItems={[
            <div style={{ width: '100%' }} key={0}>
              {collapsibleItems}
            </div>,
          ]}
        />
      )}
    </div>
  )
}

DataReview.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default DataReview
