import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as DownArrow } from 'assets/down-arrow.svg'
import { ReactComponent as SuccessIcon } from 'assets/success-icon.svg'
import { ReactComponent as FailIcon } from 'assets/fail-icon.svg'
import { ReactComponent as DangerTriangle } from 'assets/danger-triangle.svg'

import './index.scss'

const Card = React.forwardRef(
  (
    {
      children,
      footer,
      isCollapsible = false,
      isSuccessful,
      isError,
      isWarning,
      title,
      collapsed,
      onExpandCollapse,
      className,
      disabled,
    },
    ref
  ) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed)

    // Collapse/expand based on outside input
    useEffect(() => setIsCollapsed(collapsed), [collapsed])

    const cardClass = cx('card', {
      'card--collapsed': isCollapsed,
      'card--successfull': isSuccessful,
      'card--failed': isError,
      'card--warning': isWarning,
      'card--disabled': disabled,
    })

    return (
      <div className={cx(cardClass, className)} ref={ref}>
        {isCollapsible && (
          <div
            className="card__wrapper"
            onClick={() => {
              if (disabled) {
                return
              }
              if (isCollapsible) {
                if (onExpandCollapse) {
                  onExpandCollapse(!isCollapsed)
                } else {
                  setIsCollapsed(!isCollapsed)
                }
              }
            }}
          >
            <div>
              {isSuccessful ? <SuccessIcon /> : null}
              {isError ? <FailIcon /> : null}
              {isWarning ? <DangerTriangle /> : null}
            </div>

            {title ? <div className="card__title">{title}</div> : null}
            <DownArrow className="card__arrow" />

            {/* {footer ? <div className="card__footer">{footer}</div> : null} */}
          </div>
        )}

        <div className="card__content">{children}</div>
      </div>
    )
  }
)

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  footer: PropTypes.node, // footer content (sticky, does not collapse)
  isCollapsible: PropTypes.bool,
  isSuccessful: PropTypes.bool,
  isError: PropTypes.bool,
  isWarning: PropTypes.bool,
  collapsed: PropTypes.bool,
  onExpandCollapse: PropTypes.func, // control expand/collapse from the outside parent
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Card
