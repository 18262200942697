import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import SearchAndFilterModule from 'components/search-filter-module'
import Table from 'components/table'
import { useEffectOnUpdate } from 'common/hooks'
import ExpandedRowDealerFees from './fees-expanded-row-contents'

const { createColumnHelper } = components
const columnHelper = createColumnHelper()

const DealerSettingFees = ({
  list,
  onFetchList,
  revFeeList,
  lateFeeList,
  onUpdateFees,
  loading,
}) => {
  const [filters, setFilters] = useState({
    sortBy: undefined,
    searchBy: undefined,
    pageNumber: 0,
  })
  const tableRef = useRef(null)
  const lateFeesStringified = JSON.stringify(lateFeeList)
  const revFeesStringified = JSON.stringify(revFeeList)

  /** On search/pagination/filters change, re-fetch the list */
  useEffectOnUpdate(() => {
    onFetchList(filters)
  }, [filters])

  const stringifiedList = JSON.stringify(list)

  const dealersList = useMemo(
    () =>
      [...new Set(list.map((el) => el.organizationId))].map((id) => ({
        dealerId: id,
        organizationName: list.find((el) => el.organizationId === id)
          ?.organizationName,
      })),
    [stringifiedList]
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor('organizationName', {
        header: 'Dealer Name',
      }),
      columnHelper.accessor('revFees', {
        header: 'Rev Fees',
        id: 'revFees',
        cell: (cell) => {
          const revFeeHtml = getDealerFees(cell.row.original, revFeeList)
            ?.map((el) => `${el.numberOfDays} days - ${el.percentageApplied} %`)
            .join('<br />')
          return <div dangerouslySetInnerHTML={{ __html: revFeeHtml }} />
        },
      }),
      columnHelper.accessor('lateFees', {
        header: 'Late Fees',
        id: 'lateFees',
        cell: (cell) => {
          const lateFeeHtml = getDealerFees(cell.row.original, lateFeeList)
            ?.map((el) => `${el.numberOfDays} days - ${el.percentageApplied} %`)
            .join('<br />')
          return <div dangerouslySetInnerHTML={{ __html: lateFeeHtml }} />
        },
      }),
    ],
    [stringifiedList, lateFeesStringified, revFeesStringified]
  )

  const onSearch = (payload) => setFilters({ ...filters, ...payload })
  const onTableFiltersChanged = ({ pageIndex, sort }) => {
    setFilters({ ...filters, pageNumber: pageIndex, sortBy: sort })
  }

  /** On row save we have to re-fetch the list */
  const onRowSave = (dealerId, localLateFees, localRevFees, idx) => {
    onUpdateFees(dealerId, localLateFees, localRevFees).then(() => {
      setFilters({
        sortBy: undefined,
        searchBy: undefined,
        pageNumber: 0,
      })
      tableRef.current?.table?.getRow(idx).toggleExpanded()
      tableRef.current?.setPageIndex(0)
    })
  }

  const fees = useMemo(() => {
    return dealersList.map((fee, idx) => ({
      ...fee,
      $children: () => (
        <ExpandedRowDealerFees
          data={fee}
          onSaveFees={(dealerId, localLateFees, localRevFees) =>
            onRowSave(dealerId, localLateFees, localRevFees, idx)
          }
          onCancel={() => {
            tableRef.current?.table?.getRow(idx).toggleExpanded()
          }}
          lateFees={getDealerFees(fee, lateFeeList)}
          revFees={getDealerFees(fee, revFeeList)}
        />
      ),
    }))
  }, [dealersList, tableRef, lateFeesStringified, revFeesStringified])

  return (
    <div className="dealer-settings">
      <div className="afp__header">
        <SearchAndFilterModule
          fieldsToSearch={['multiple']}
          placeholder="Search"
          noDefaultExtraOptions={true}
          callback={onSearch}
        />
      </div>
      <Table
        title="Deposits/Fees"
        ref={tableRef}
        data={fees}
        columns={columns}
        fetchData={onTableFiltersChanged}
        dataLoading={loading}
      />
    </div>
  )
}

function getDealerFees(dealerObj, feesArray) {
  return [...feesArray].filter((el) => el.organizationId === dealerObj.dealerId)
}

DealerSettingFees.propTypes = {
  list: PropTypes.array.isRequired,
  onFetchList: PropTypes.func.isRequired,
  onUpdateFees: PropTypes.func.isRequired,
  lateFeeList: PropTypes.array.isRequired,
  revFeeList: PropTypes.array.isRequired,
  loading: PropTypes.bool,
}

export default DealerSettingFees
