import Api from 'easy-fetch-api'

/** Get list of all notifications */
export const fetchAllNotifications = (
  userId,
  roleTypeId,
  salesOrganizationId
) => {
  return Api.get({
    url: '/Notifications/list',
    query: { userId, roleTypeId, SalesOrganizationId: salesOrganizationId },
  }).catch(console.error)
}

export const fetchNotifications = (
  userId,
  roleTypeId,
  salesOrganizationId,
  pageNumber,
  itemsPerPage
) => {
  return Api.get({
    url: '/Notifications/list',
    query: {
      userId,
      roleTypeId,
      SalesOrganizationId: salesOrganizationId,
      pageNumber,
      itemsPerPage,
    },
  }).catch(console.error)
}
