import PropTypes from 'prop-types'
import { useState, useMemo } from 'react'
import { useStore } from 'store'
import { useTranslation } from 'react-i18next'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import TextField, { INPUT_TYPES } from 'components/input'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import {
  formatIntToCurrency,
  formatToPercentage,
} from 'modules/loan-application/utils'
import { downloadMultipleAttachments } from 'modules/loan-application/actions'
import {
  UW_HUB_PROPERTY_TYPES,
  PROPERTY_TYPE_TO_INPUT_TYPE,
} from '../constants'
import ValidationCheck from '../common/validation-check'

const { LoadingIndicator } = components
const { FileSearchIcon } = assets

const IncomeContent = ({
  data,
  updatedData,
  loanApplicationId,
  applicantTypeId,
  handleOnChange,
}) => {
  const { t: translate } = useTranslation()
  const { dispatch } = useStore()
  const [downloading, setDownloading] = useState(false)

  const properties = useMemo(() => {
    return data.properties.map((property) => {
      const inputType = PROPERTY_TYPE_TO_INPUT_TYPE[property.propertyTypeId]
      const isEditable =
        property.propertyTypeId === UW_HUB_PROPERTY_TYPES.YearlyActualIncome ||
        property.propertyTypeId === UW_HUB_PROPERTY_TYPES.ActualDti
      const value =
        inputType === INPUT_TYPES.CURRENCY
          ? formatIntToCurrency(property.value)
          : inputType === INPUT_TYPES.PERCENTAGE
          ? `${formatToPercentage(property.value)} %`
          : property.value
      const fullPropertyName = `${
        applicantTypeId === 0
          ? 'borrower'
          : applicantTypeId === 1
          ? 'coBorrower'
          : 'combined'
      }${property.propertyType.name}`

      return {
        ...property,
        value,
        inputType,
        isEditable,
        fullPropertyName,
      }
    })
  }, [JSON.stringify(data.properties)])

  const typeString = useMemo(() => {
    if (applicantTypeId === 0) {
      return 'borrower'
    }

    if (applicantTypeId === 1) {
      return 'coBorrower'
    }

    return 'combined'
  }, [applicantTypeId])

  const downloadAttachments = () => {
    setDownloading(true)
    downloadMultipleAttachments(
      dispatch,
      loanApplicationId,
      data.attachmentsIds
    ).finally(() => setDownloading(false))
  }

  return (
    <div className="uw-hub-page__income-wrapper">
      <div className="uw-hub-page__income-title">
        <span>{translate(`uwHub.income.${typeString}`)}</span>
        {data.attachmentsIds && data.attachmentsIds.length > 0 && (
          <Button
            onClick={() => downloadAttachments()}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            startIcon={
              downloading ? <LoadingIndicator size={14} /> : <FileSearchIcon />
            }
            disabled={downloading}
          >
            {translate('uwHub.income.previewVOI')}
          </Button>
        )}
      </div>
      <div className="uw-hub-page__income-grid">
        {properties.map((property, index) => {
          return (
            <div className="uw-hub-page__income-col" key={index}>
              {!property.isEditable ? (
                <>
                  <span className="uw-hub-page__income-label">
                    {translate(`uwHub.income.${property.propertyType.name}`)}
                  </span>
                  <div className="uw-hub-page__income-value">
                    <ValidationCheck
                      dataValidationId={property.dataValidationId}
                    />
                    {property.value ?? '-'}
                  </div>
                </>
              ) : (
                <TextField
                  type={property.inputType}
                  label={translate(
                    `uwHub.income.${property.propertyType.name}`
                  )}
                  value={updatedData[property.fullPropertyName]}
                  onChange={(val) =>
                    handleOnChange(property.fullPropertyName, val)
                  }
                  prefix="$"
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

IncomeContent.propTypes = {
  data: PropTypes.object.isRequired,
  updatedData: PropTypes.object.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  applicantTypeId: PropTypes.number,
  handleOnChange: PropTypes.func.isRequired,
}

export default IncomeContent
