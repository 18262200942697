import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/loader'
import Modal from 'components/modal'
import {
  getAccountTypes,
  getAchAgreement,
  submitAchData,
} from 'modules/loan-application/actions'
import 'components/modal/secondary.scss'

import AutoPayCard from '../lender-agreement/auto-pay-card'
import './not-opted-in-ach.scss'

const NotOptedInAch = ({
  dispatch,
  isOpen,
  onClose,
  loanApplicationId,
  achData,
  lenderId,
  title,
}) => {
  const [loading, setLoading] = useState(true)
  const [achLoading, setAchLoading] = useState(false)
  const [accountTypes, setAccountTypes] = useState([])
  const [achAgreement, setAchAgreement] = useState({
    isAccepted: false,
    text: '',
  })

  useEffect(() => {
    setLoading(true)
    Promise.all([getAchAgreement(loanApplicationId), getAccountTypes()])
      .then(([achRes, accountTypes]) => {
        setAchAgreement({
          isAccepted: achRes.isAccepted,
          text: achRes.achAgreement || '',
        })
        setAccountTypes(accountTypes)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="modal--secondary not-opted-in"
      title={title}
    >
      {loading ? (
        <Loader size={130} center={true} />
      ) : (
        <>
          <AutoPayCard
            agreementText={achAgreement.text}
            onSubmit={(data) => {
              if (data) {
                setAchLoading(true)
                submitAchData(dispatch, loanApplicationId, data)
                  .then(() => onClose())
                  .finally(() => setAchLoading(false))
              } else {
                onClose()
              }
            }}
            loading={achLoading}
            achData={achData}
            accountTypes={accountTypes}
            onCancel={onClose}
            lenderId={lenderId}
            secondaryUx={true}
          />
        </>
      )}
    </Modal>
  )
}

NotOptedInAch.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  achData: PropTypes.object.isRequired,
  lenderId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default NotOptedInAch
