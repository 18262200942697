import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CLAIMS } from 'common/claims'
import { hasAccess } from 'common/access'
import Loader from 'components/loader'
import Textarea from 'components/textarea'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import {
  overrideDecisionAndGetStatus,
  removeOverrideOnDecision,
} from 'modules/loan-application/actions'
import { useStore } from 'store'

const { Dialog } = components

const OverrideReasonModal = ({
  reasonModalData,
  setReasonModalData,
  currentLoans,
  reFetchDecisioning,
  userData,
}) => {
  const { t: translate } = useTranslation()
  const { dispatch } = useStore()
  const [reason, setReason] = useState('')
  const [loading, setLoading] = useState(false)
  const canOverrideDecision = hasAccess(
    userData,
    CLAIMS.CAN_OVERRIDE_EVALUATIONS
  )

  const onClose = () => setReasonModalData(null)

  /** Saves the reason for the Override for the currently edited element */
  const onSaveReason = async () => {
    if (!reason) {
      setReasonModalData(null)
      return
    }

    setLoading(true)
    const { check, loanIndex } = reasonModalData
    const { evaluatedLoanDecisionId } = check
    const evaluationSessionArray =
      currentLoans[loanIndex]?.evaluationSessionIds?.slice(-1) || []
    const evaluationSessionId =
      evaluationSessionArray[evaluationSessionArray.length - 1]

    const successfullyOverrode = await overrideDecisionAndGetStatus(
      {
        evaluatedLoanDecisionId,
        evaluationSessionId,
        reason,
      },
      dispatch
    )

    // Re-fetch the list of decisions to reflect updates
    successfullyOverrode && (await reFetchDecisioning())

    // Close the modal
    setReasonModalData(null)
    // Reset Reason
    setReason('')
    setLoading(false)
  }

  /** Called on confirming the Removal of the Override */
  const onRemoveOverride = async () => {
    setLoading(true)
    await removeOverrideOnDecision(reasonModalData, currentLoans)
    await reFetchDecisioning()
    setLoading(false)
    onClose()
  }

  const hasReasonSaved = reasonModalData?.check.reason

  return (
    <Dialog
      open={!!reasonModalData}
      onClose={onClose}
      onConfirm={onSaveReason}
      PaperProps={{
        sx: {
          minWidth: '400px',
        },
      }}
      title={translate('loanApplication.step2.overrideReason')}
      subTitle={translate('loanApplication.step2.overrideSubtitle')}
      actions={
        <>
          <Button
            onClick={() => {
              onClose()
              setReason('')
            }}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            disabled={loading}
            fullWidth
          >
            {translate('buttons.cancel')}
          </Button>
          <Button onClick={onSaveReason} disabled={loading} fullWidth>
            {translate(hasReasonSaved ? 'buttons.back' : 'buttons.submit')}
          </Button>
          {hasReasonSaved && canOverrideDecision ? (
            <Button
              color={BUTTON_COLORS.ERROR}
              style={{ marginLeft: '20px' }}
              onClick={onRemoveOverride}
              disabled={loading}
              fullWidth
            >
              {translate('loanApplication.step2.removeOverride')}
            </Button>
          ) : null}
        </>
      }
    >
      {loading ? (
        <Loader size={100} center={true} />
      ) : (
        <>
          {hasReasonSaved ? (
            <div>{reasonModalData.check.reason}</div>
          ) : (
            <Textarea
              value={reason}
              onChange={setReason}
              className="select-loan__reason"
              placeholder="Enter a description..."
              resize="vertical"
              rows={8}
              fullWidth
            />
          )}
        </>
      )}
    </Dialog>
  )
}

OverrideReasonModal.propTypes = {
  reasonModalData: PropTypes.object,
  setReasonModalData: PropTypes.func.isRequired,
  currentLoans: PropTypes.array.isRequired,
  reFetchDecisioning: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
}

export default OverrideReasonModal
