import Api from 'easy-fetch-api'
import { GLOBAL_ACTIONS, showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { PORTAL_ROLE_TYPES } from '../admin/constants'

export const ACTIONS = {
  SET_ROLES: 'adminRolesManagement.setRoles',
  ADD_NEW_USER: 'adminRolesManagement.addNewUser',
  SET_ROLE_TYPES: 'adminRolesManagement.setRolesTypes',
  SET_ROLE_CLAIMS: 'adminRolesManagement.setRolesClaims',
  UPDATE_ROLE_CLAIMS: 'adminRolesManagement.updateRoleClaims',
}

export const fetchRoles = (dispatch, pageNumber, itemsPerPage) =>
  Api.get({
    url: `/Roles`,
    query: {
      RoleTypeId: PORTAL_ROLE_TYPES.join(','),
    },
  })
    .then((roles) => {
      dispatch({
        type: ACTIONS.SET_ROLES,
        data: roles,
      })

      return roles
    })
    .catch(console.error)

export const initRoles = (dispatch, pageNumber, itemsPerPage) => {
  dispatch({ type: GLOBAL_ACTIONS.SET_LOADING, loading: true })

  const fetchRolesTypes = () =>
    Api.get({
      url: `/Roles/portal-types`,
    })

  const fetchRolesClaims = () =>
    Api.get({
      url: `/Roles/claims?includeWebPortalClaims=true&includeEniumXClaims=false`,
    })

  Promise.all([
    fetchRoles(dispatch, pageNumber, itemsPerPage),
    fetchRolesTypes(),
    fetchRolesClaims(),
  ]).then(([, roleTypes, roleClaims]) => {
    dispatch({
      type: ACTIONS.SET_ROLE_TYPES,
      data: roleTypes,
    })

    dispatch({
      type: ACTIONS.SET_ROLE_CLAIMS,
      data: roleClaims,
    })

    dispatch({ type: GLOBAL_ACTIONS.SET_LOADING, loading: false })
  })
}

export const addNewUser = (dispatch, newUser) => {
  dispatch({
    type: ACTIONS.ADD_NEW_USER,
    data: newUser,
  })
}

export const getRole = (roleId) => {
  return Api.get({
    url: `/Roles/${roleId}`,
  })
}

export const updateRoleClaims = (dispatch, updatedRole) => {
  dispatch({
    type: ACTIONS.UPDATE_ROLE_CLAIMS,
    data: updatedRole,
  })
}

export const editRole = (id, selectedPermissions, dispatch) => {
  return Api.put({
    url: `/Roles/update-role-permissions`,
    data: {
      roleId: id,
      permissions: selectedPermissions,
    },
  })
    .then((updatedUser) => {
      updateRoleClaims(dispatch, updatedUser)
      showNotification(dispatch, {
        title: `Successfully updated role claims`,
      })
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while updating role claims.`,
      })
    )
}

export const addRole = (newRole, dispatch) => {
  return Api.post({
    url: `/Roles/create-role`,
    data: newRole,
  })
    .then((recentlyAddedRole) => {
      showNotification(dispatch, {
        title: `Successfully created role ${newRole.name}`,
      })
      addNewUser(dispatch, recentlyAddedRole)
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while creating role ${newRole.name}. Please refresh the page and try again. ${error.title}`,
      })
    )
}
