import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import {
  components,
  colors,
  assets,
  unstable_components,
} from '@ElementsCapitalGroup/enium-ui'
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from 'components/button'
import Modal from 'components/modal'
import { ReactComponent as Arrow } from 'assets/down-arrow.svg'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { AccessWrapper, hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import Loader from 'components/loader'
import { useNavigate } from 'react-router-dom'
import { ExpandableCard } from 'components/expandable-card'
import { ReactComponent as BlueClipperIcon } from 'assets/blue-clipper.svg'

import NotOptedInAch from './not-opted-in-ach'
import LoadingDocuments from './loading-documents'
import {
  voidEnvelope,
  emailEnvelopes,
  generateEnvelopes,
  goToStep,
  checkForEnvelopeUpdates,
  getLoanApplication,
  resetFlow,
  getLoanAppStatuses,
  downloadDocuments,
  reAttachEnvelopes,
  getOldCompletedEnvelopes,
  sendCompletedEnvelopes,
} from '../actions'
import {
  STATES_ORDER,
  STEPS_MAP,
  DOCUMENT_ROLE_TYPES,
  DOCUMENT_RECIPIENT_STATUS,
  ENVELOPE_STATUS,
  STEPS_MAP_COMMERCIAL,
  STATES_ORDER_COMMERCIAL,
} from '../constants'

import { RegenerateModal } from './regenerate-modal'

import { styles } from './style'

const { Card, IconButton, Tooltip } = components
const { XCloseIcon, DownloadIcon, MailIcon } = assets
const { Menu, MenuItem } = unstable_components

const LoanDocuments = ({
  loanApplicationId,
  envelopes,
  loanFormData,
  selectedLoanProduct,
  currentState,
  dispatch,
  achData,
  userData,
  isCommercialApp,
  viewOnly,
}) => {
  const [loading, setLoading] = useState(true)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [buttonsLoading, setButtonsLoading] = useState(false)
  const [autoPayModalOpen, setAutoPayModalOpen] = useState(false)
  const [voidDocumentId, setVoidDocumentId] = useState(null)
  const [oldCompletedEnvelopes, setOldCompletedEnvelopes] = useState([])
  const [regenerateModalOpen, setRegenerateModalOpen] = useState(false)
  const statesOrder = isCommercialApp ? STATES_ORDER_COMMERCIAL : STATES_ORDER
  const loanDocumentsSigned =
    statesOrder[currentState] >= statesOrder.DocumentsSigned
  const canGenerateDocuments = hasAccess(
    userData,
    CLAIMS.CAN_GENERATE_DOCUMENTS
  )
  const canOptInAch = hasAccess(userData, CLAIMS.CAN_OPT_IN_ACH)
  const canSignDocuments = hasAccess(userData, CLAIMS.CAN_SIGN_DOCUMENTS)
  const canViewDocuments = hasAccess(userData, CLAIMS.CAN_VIEW_DOCUMENTS)
  const { t: translate } = useTranslation()
  const navigate = useNavigate()

  const nextStep = isCommercialApp
    ? STEPS_MAP_COMMERCIAL.COMMERCIAL_NTP
    : STEPS_MAP.NTP
  /**
   * If all Envelopes have been signed, auto-redirect to the NTP step
   * In case the user did not enroll in ACH, prompt a modal for opting in first
   */
  const allEnvelopesSigned = () => {
    if (!achData.achId && canOptInAch) {
      setAutoPayModalOpen(true)
    } else {
      goToStep(dispatch, navigate, nextStep, isCommercialApp)
    }
  }

  const initializeComponent = async () => {
    const params = new URLSearchParams(window.location.search)
    await getLoanApplication(dispatch, loanApplicationId)

    getOldCompletedEnvelopes(loanApplicationId).then((oldEnvelopes) =>
      setOldCompletedEnvelopes(oldEnvelopes)
    )

    // Check for envelope updates
    await checkForUpdates().then((envelopes) => {
      if (params.get('event') === 'signing_complete') {
        // If all Envelopes associated with the selected loan product have been signed (and there are none still voided), auto-redirect to the NTP step
        const currentLenderEnvelopes = envelopes.envelopesToRegenerate?.filter(
          (_) =>
            selectedLoanProduct.envelopeDefinitions.some(
              (ed) => ed.envelopeDefinitionId === _.envelopeDefinitionId
            )
        )
        if (
          currentLenderEnvelopes?.length === 0 &&
          envelopes.updatedEnvelopesDto.every(_isEnvelopeSigned)
        ) {
          allEnvelopesSigned()
        }

        // Remove the Query Param from the URL
        window.history.pushState(
          {},
          '',
          window.location.origin + window.location.pathname
        )
      }
    })
  }
  /**
   * On mount, get loan app details
   * If we have the event=signing_complete URL param it means we just came after a DocuSign signing
   * If we have the event=signing_complete -> auto-redirect to NTP if all docs are signed
   */
  useEffect(() => {
    initializeComponent()

    return () => {
      resetFlow(dispatch)
      setLoading(false)
      getLoanAppStatuses(dispatch)
    }
  }, [])

  /* Fetch updated envelopes */
  const checkForUpdates = () => {
    setLoading(true)
    return checkForEnvelopeUpdates(dispatch, loanApplicationId).finally(() =>
      setLoading(false)
    )
  }

  /* Method to re-send an envelope via email */
  const reSendEnvelope = (envelopeId) => {
    setButtonsLoading(true)
    emailEnvelopes(envelopeId)
      .then(() =>
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.POSITIVE,
          title: translate('loanApplication.step4.notifications.emailSent'),
        })
      )
      .catch(() =>
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: translate(
            'loanApplication.step4.notifications.errorSendingEmail'
          ),
        })
      )
      .finally(() => setButtonsLoading(false))
  }

  const sendCompletedEnvelope = (envelopeId) => {
    setButtonsLoading(true)
    sendCompletedEnvelopes(envelopeId)
      .then(() =>
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.POSITIVE,
          title: translate('loanApplication.step4.notifications.emailSent'),
        })
      )
      .catch(() =>
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: translate(
            'loanApplication.step4.notifications.errorSendingEmail'
          ),
        })
      )
      .finally(() => setButtonsLoading(false))
  }

  /* Method to re-generate envelopes */
  const reGenerate = (selectedEnvelopesIds) => {
    setLoading(true)
    return generateEnvelopes({
      dispatch,
      lenderId: selectedLoanProduct.lenderId,
      envelopeDefinitionIds: selectedEnvelopesIds,
      loanFormData,
      loanApplicationId,
    })
      .then(async () => {
        await getLoanApplication(dispatch, loanApplicationId)
        await checkForUpdates()
      })
      .catch(() => setLoading(false))
  }

  /* Void a document (envelope) */
  const voidDocument = async (envelopeId) => {
    setLoading(true)
    try {
      await voidEnvelope(loanApplicationId, envelopeId)
      await checkForUpdates()
      /* If Loan Documents were previously signed, re-fetch the Loan App to ensure we have up-to-date data */
      if (loanDocumentsSigned) {
        await getLoanApplication(dispatch, loanApplicationId)
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  const handleReAttachDocs = async () => {
    if (!oldCompletedEnvelopes.length) {
      return
    }

    const envelopesToReAttach = oldCompletedEnvelopes.map((e) => e.envelopeId)
    setLoading(true)

    try {
      await reAttachEnvelopes(loanApplicationId, envelopesToReAttach)
      await checkForUpdates()
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  if (loading) {
    return <LoadingDocuments />
  }

  if (!envelopes) {
    return null
  }

  const renderMenuItem = (applicantRole, envelope) => {
    const signer =
      applicantRole === DOCUMENT_ROLE_TYPES.BORROWER
        ? envelope.borrower
        : envelope.coBorrower

    const isSigned = signer.status?.id === DOCUMENT_RECIPIENT_STATUS.COMPLETED
    const isDeclined = signer.status?.id === DOCUMENT_RECIPIENT_STATUS.DECLINED
    const viewDocument = isSigned || isDeclined

    if (!isSigned && !canSignDocuments) {
      return []
    }

    return [
      canViewDocuments && {
        id: applicantRole,
        name:
          applicantRole === DOCUMENT_ROLE_TYPES.BORROWER
            ? viewDocument
              ? translate('loanApplication.common.viewBorrower')
              : translate('loanApplication.common.borrower')
            : viewDocument
            ? translate('loanApplication.common.viewCoborrower')
            : translate('loanApplication.common.coborrower'),
      },
    ]
  }

  const handleOnMenuAction = (envelope) => (item) => {
    const signer =
      item === DOCUMENT_ROLE_TYPES.BORROWER
        ? envelope.borrower
        : envelope.coBorrower
    const isSigned = signer.status?.id === DOCUMENT_RECIPIENT_STATUS.COMPLETED

    if (isSigned) {
      window.open(signer.url, '_blank')
    } else {
      canSignDocuments && window.open(signer.url, '_self')
    }
  }

  return (
    <div style={styles.loanDocuments}>
      {canGenerateDocuments && (
        <div style={styles.headerWrapper}>
          <div>
            <div style={styles.header}>
              {translate('loanApplication.navigation.step4')}
            </div>
            <div style={styles.subHeader}>
              {translate('loanApplication.step4.note')}
            </div>
          </div>

          {regenerateModalOpen && (
            <RegenerateModal
              onClose={() => setRegenerateModalOpen(false)}
              onSubmit={reGenerate}
              envelopes={envelopes?.envelopesToRegenerate}
            />
          )}

          <div>
            <AccessWrapper claims={CLAIMS.CAN_RE_ATTACH_DOCUMENTS}>
              {loading ? (
                <Loader noLogo size={30} />
              ) : (
                <span
                  className={cx('re-attach-docs', {
                    're-attach-docs--disabled': !oldCompletedEnvelopes.length,
                  })}
                  onClick={handleReAttachDocs}
                >
                  <Tooltip
                    bottom={true}
                    title={translate(
                      'loanApplication.step4.reAttachLastSignedDocuments'
                    )}
                  >
                    <IconButton size={BUTTON_SIZES.SMALL}>
                      <BlueClipperIcon width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                </span>
              )}
            </AccessWrapper>

            <Button
              onClick={() => setRegenerateModalOpen(true)}
              style={{ marginLeft: '8px' }}
              disabled={
                !envelopes?.envelopesToRegenerate?.length || buttonsLoading
              }
            >
              {translate('loanApplication.step4.regenerate')}
            </Button>
          </div>
        </div>
      )}

      <Card
        headerProps={{
          sx: {
            padding: '16px',
          },
        }}
        contentProps={{
          sx: {
            padding: 0,
            '&:last-child': {
              paddingBottom: 0,
            },
          },
        }}
        title={translate('global.documents')}
      >
        {envelopes?.updatedEnvelopesDto?.map((envelope, key) => {
          const hasCoBorrower = !!envelope.coBorrower

          return (
            <ExpandableCard
              sx={{
                mb: '0px',
                borderRadius: 0,
                backgroundColor: key % 2 === 0 ? colors.grey[50] : 'inherit',
                border: 0,
              }}
              headerProps={{
                sx: {
                  padding: '18px',
                  backgroundColor: key % 2 === 0 ? colors.grey[50] : '#ffffff',
                },
              }}
              key={key}
              items={[]}
              collapsibleItems={[]}
              isCollapsable={true}
              alwaysCollapsible={true}
              title={
                <div style={styles.cardTitle}>
                  <span>{envelope.envelopeName}</span>

                  <div style={styles.cardButtonsDocs}>
                    <AccessWrapper claims={CLAIMS.CAN_SEND_DOCUMENTS}>
                      <Button
                        disabled={buttonsLoading}
                        size={BUTTON_SIZES.SMALL}
                        variant={BUTTON_VARIANTS.TEXT}
                        onClick={() => {
                          if (_isEnvelopeSigned(envelope)) {
                            sendCompletedEnvelope(envelope.envelopeId)
                          } else {
                            reSendEnvelope(envelope.envelopeId)
                          }
                        }}
                        color={BUTTON_COLORS.INHERIT}
                        startIcon={<MailIcon />}
                      >
                        {!_isEnvelopeSigned(envelope)
                          ? translate('userDetails.email')
                          : translate(
                              'loanApplication.step4.sendCompletedEnvelopes'
                            )}
                      </Button>
                    </AccessWrapper>

                    <AccessWrapper claims={CLAIMS.CAN_VIEW_DOCUMENTS}>
                      <Button
                        size={BUTTON_SIZES.SMALL}
                        variant={BUTTON_VARIANTS.TEXT}
                        onClick={() => {
                          setDownloadLoading(true)
                          downloadDocuments(loanApplicationId).finally(() =>
                            setDownloadLoading(false)
                          )
                        }}
                        startIcon={<DownloadIcon />}
                        loading={downloadLoading}
                        color={BUTTON_COLORS.INHERIT}
                      >
                        {translate('loanApplication.step4.downloadDocuments')}
                      </Button>
                    </AccessWrapper>

                    <AccessWrapper claims={CLAIMS.CAN_VOID_DOCUMENTS}>
                      <Button
                        size={BUTTON_SIZES.SMALL}
                        variant={BUTTON_VARIANTS.TEXT}
                        disabled={buttonsLoading || viewOnly}
                        onClick={() => setVoidDocumentId(envelope.envelopeId)}
                        color={BUTTON_COLORS.INHERIT}
                        startIcon={<XCloseIcon />}
                      >
                        {translate('loanApplication.step4.void')}
                      </Button>
                    </AccessWrapper>
                    <Menu
                      onAction={handleOnMenuAction(envelope)}
                      label={
                        !loanDocumentsSigned
                          ? translate('loanApplication.step4.signDocuments')
                          : translate('loanApplication.step4.viewDocuments')
                      }
                      buttonTriggerProps={{
                        variant: 'tertiary',
                      }}
                      popupCss={(theme) => ({
                        gap: theme.spaces.xxs,
                      })}
                      itemCss={(theme) => ({
                        ...theme.typography.text.sm.semibold,
                        backgroundColor: theme.colors.primary[50],
                        color: theme.colors.primary[700],
                        '&:hover': {
                          backgroundColor: theme.colors.primary[100],
                        },
                      })}
                      items={[
                        ...renderMenuItem(
                          DOCUMENT_ROLE_TYPES.BORROWER,
                          envelope
                        ),
                        ...(hasCoBorrower
                          ? renderMenuItem(
                              DOCUMENT_ROLE_TYPES.COBORROWER,
                              envelope
                            )
                          : []),
                      ]}
                      // eslint-disable-next-line react/no-children-prop
                      children={(item) => (
                        <MenuItem onClick={item?.onClick}>{item.name}</MenuItem>
                      )}
                    />
                  </div>
                </div>
              }
            />
          )
        })}
      </Card>

      {loanDocumentsSigned && (
        <div
          className="flow__continue"
          onClick={() =>
            goToStep(dispatch, navigate, nextStep, isCommercialApp)
          }
        >
          {translate('loanApplication.step4.continueToNTP')} <Arrow />
        </div>
      )}

      <NotOptedInAch
        dispatch={dispatch}
        isOpen={autoPayModalOpen}
        onClose={() => goToStep(dispatch, navigate, nextStep, isCommercialApp)}
        loanApplicationId={loanApplicationId}
        achData={achData}
        lenderId={selectedLoanProduct?.lenderId}
        title={translate('loanApplication.step4.timeToEnroll')}
      />

      <Modal
        isOpen={!!voidDocumentId}
        onClose={() => setVoidDocumentId(null)}
        title={translate('loanApplication.step3.areYouSure')}
        onConfirm={() => {
          setVoidDocumentId(null)
          voidDocument(voidDocumentId)
        }}
        confirmButton={translate('buttons.yes')}
        cancelButton={translate('buttons.cancel')}
      ></Modal>
    </div>
  )
}

function _isEnvelopeSigned(envelope) {
  return envelope.status.id === ENVELOPE_STATUS.COMPLETED
}

LoanDocuments.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  envelopes: PropTypes.shape({
    envelopesToRegenerate: PropTypes.array,
    updatedEnvelopesDto: PropTypes.array,
  }),
  selectedLoanProduct: PropTypes.object.isRequired,
  loanFormData: PropTypes.object.isRequired,
  currentState: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  achData: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  isCommercialApp: PropTypes.bool,
  viewOnly: PropTypes.bool,
}

export default LoanDocuments
