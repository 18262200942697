import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import Table from 'components/table'
import { useEffectOnUpdate } from 'common/hooks'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { APPROVAL_STATUS, approvalColumns } from '../utils/constants'
import AFPApprovalDeclineModal from './decline-modal'

const { IconButton, Tooltip } = components
const { EyeIcon } = assets

const AFPApprovalModule = ({
  list,
  onFetchList,
  onDecline,
  onRemoveOverride,
  loading,
}) => {
  const navigate = useNavigate()
  const [filters, setFilters] = useState({
    sortBy: undefined,
  })
  const tableRef = useRef()

  const [reasonDialogForApp, setReasonDialogForApp] = useState(null)
  const [selectedApps, setSelectedApps] = useState([])
  const isSelectedRowDeclined =
    selectedApps[0]?.approvalStatus?.id === APPROVAL_STATUS.DECLINED &&
    selectedApps.length === 1

  /** On search/pagination/filters change, re-fetch the list */
  useEffectOnUpdate(() => {
    onFetchList(filters)
  }, [filters])

  const onTableFiltersChanged = ({ sort }) => {
    setFilters({ ...filters, sortBy: sort })
  }

  return (
    <>
      <Table
        ref={tableRef}
        title={'Funded'}
        columns={approvalColumns}
        data={list}
        fetchData={onTableFiltersChanged}
        hasPagination={false}
        showColumnSelector={true}
        centerCellContent={true}
        multiRowSelection={true}
        onTableRowsSelectChange={setSelectedApps}
        hasCheckboxes={true}
        hideRemoveButton={true}
        hideEditButton={true}
        emptyDescription="Thre are no loan applications ready for approval"
        emptyActionButtonLabel="Go to Daily Funding"
        emptyActionButtonOnClick={() => {
          navigate('/afp/daily')
        }}
        dataLoading={loading}
        persistentSelection={true}
        getRowId={(row) => row.dailyFundingLoanApplicationId}
        toolbarRightContent={
          <div>
            <Tooltip title="Show reason">
              <span>
                <IconButton
                  disabled={selectedApps.length !== 1 || !isSelectedRowDeclined}
                  variant={'outlined'}
                  sx={{ borderRadius: '10px', marginRight: '8px' }}
                  onClick={() => setReasonDialogForApp([selectedApps[0]])}
                >
                  <EyeIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Decline">
              <span>
                <Button
                  variant={BUTTON_VARIANTS.OUTLINED}
                  color={BUTTON_COLORS.INHERIT}
                  onClick={() => setReasonDialogForApp(selectedApps)}
                  disabled={!selectedApps.length || isSelectedRowDeclined}
                >
                  Decline
                </Button>
              </span>
            </Tooltip>
          </div>
        }
      />

      <AFPApprovalDeclineModal
        apps={reasonDialogForApp}
        onClose={() => {
          setReasonDialogForApp(null)
          tableRef.current?.resetAllSelected()
        }}
        isDeclined={isSelectedRowDeclined}
        onDecline={onDecline}
        onRemoveReason={onRemoveOverride}
      />
    </>
  )
}

AFPApprovalModule.propTypes = {
  list: PropTypes.array.isRequired,
  onFetchList: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onRemoveOverride: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default AFPApprovalModule
