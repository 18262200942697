import PropTypes from 'prop-types'
import { assets } from '@ElementsCapitalGroup/enium-ui'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import Button, { BUTTON_VARIANTS, BUTTON_COLORS } from 'components/button'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'

import { styles } from './style'

const { DownloadIcon, PlusIcon, ClipboardCheckIcon } = assets

export const LoanAppsHeader = ({ onCreate, onExport, onCopyToClipboard }) => {
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  return (
    <div style={styles.headerWrapper}>
      <div>
        <div style={styles.header}>Applications</div>
        <div style={styles.subHeader}>
          View all your applications in one place.
        </div>
      </div>

      <div
        style={{
          ...styles.buttonsWrapper,
          gridTemplateColumns: isMobileView
            ? 'repeat(2,auto)'
            : styles.buttonsWrapper.gridTemplateColumns,
          marginTop: isMobileView && '16px',
          width: isMobileView && '100%',
        }}
      >
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          color={BUTTON_COLORS.INHERIT}
          onClick={onCopyToClipboard}
          sx={{ lineHeight: { xs: '16px' } }}
          startIcon={<ClipboardCheckIcon />}
        >
          Copy to clipboard
        </Button>

        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          color={'inherit'}
          onClick={onExport}
          startIcon={<DownloadIcon />}
        >
          Export
        </Button>

        <AccessWrapper claims={CLAIMS.CAN_CREATE_LOAN_APPLICATIONS}>
          <Button
            startIcon={<PlusIcon />}
            onClick={onCreate}
            sx={{ textWrap: 'nowrap' }}
          >
            Create Application
          </Button>
        </AccessWrapper>
      </div>
    </div>
  )
}

LoanAppsHeader.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onCopyToClipboard: PropTypes.func.isRequired,
}
