import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { components, unstable_components } from '@ElementsCapitalGroup/enium-ui'

import TextField, { INPUT_TYPES } from 'components/input'
import InputDate from 'components/input-date'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { useTranslation } from 'react-i18next'
import { convertWordToTitleCase } from 'common/utils'
import { usePrevious } from 'common/hooks'

const { Dropdown } = components
const { Unstable_Grid: Grid } = unstable_components

const GeneralDetails = ({
  generalDetails,
  setGeneralDetails,
  errors,
  setErrors,
  coBorrower,
  communicationMethods,
  employmentStatuses,
  citizenships,
  militaryAffiliation,
  userData,
  residencyOccupation,
  areInputsDisabled,
  onChangeHideEmploymentFields,
  hideEmploymentFields,
  onChangeHideIncomeField,
  hideIncomeField,
}) => {
  const { t: translate } = useTranslation()
  const borrowerField = coBorrower ? 'coBorrower' : 'borrower'
  const errorObject = errors[borrowerField]
  const isEdit = !!generalDetails.applicantId

  const prevHideEmploymentFields = usePrevious(hideEmploymentFields)
  const prevHideIncomeField = usePrevious(hideIncomeField)

  const handleOnChange = (e, name) => {
    const value = typeof e === 'object' ? e.target.value.id : e

    setGeneralDetails({ ...generalDetails, [name]: value })
    setErrors({
      ...errors,
      [borrowerField]: { ...errors[borrowerField], [name]: null },
    })
  }

  const handleSSNChange = (val) => {
    if (!isNaN(val)) {
      handleOnChange(val, 'last4SSN')
    } else {
      setGeneralDetails({
        ...generalDetails,
        last4SSN: generalDetails.last4SSN,
      })
    }
  }

  const canSeeSensitiveData = hasAccess(
    userData,
    CLAIMS.CAN_DECRYPT_SENSITIVE_DATA
  )

  useEffect(() => {
    const employmentStatusesTypes = {}
    employmentStatuses.forEach((s) => {
      employmentStatusesTypes[s.value] = s.id
    })

    onChangeHideEmploymentFields(
      generalDetails.employmentStatus === employmentStatusesTypes['Retired'] ||
        generalDetails.employmentStatus ===
          employmentStatusesTypes['HomeMaker'],
      coBorrower
    )

    onChangeHideIncomeField(
      generalDetails.employmentStatus === employmentStatusesTypes['HomeMaker'],
      coBorrower
    )
  }, [generalDetails.employmentStatus])

  useEffect(() => {
    if (
      prevHideEmploymentFields === hideEmploymentFields ||
      prevHideEmploymentFields === undefined
    ) {
      return
    }

    if (hideEmploymentFields) {
      setGeneralDetails({
        ...generalDetails,
        monthsEmployed: undefined,
        jobTitle: undefined,
        employer: undefined,
      })
    }
  }, [hideEmploymentFields])

  useEffect(() => {
    if (
      prevHideIncomeField === hideIncomeField ||
      prevHideIncomeField === undefined
    ) {
      return
    }

    if (hideIncomeField) {
      setGeneralDetails({
        ...generalDetails,
        yearlyIncome: '0',
      })
    }
  }, [hideIncomeField])

  const employmentStatusesOptions = useMemo(() => {
    return employmentStatuses.map((v) => ({
      ...v,
      label: translate(v.translationKey),
    }))
  }, [employmentStatuses])
  const militaryAffiliationOptions = useMemo(() =>
    militaryAffiliation.map(
      (v) => ({
        ...v,
        label: translate(v.translationKey),
      }),
      [militaryAffiliation]
    )
  )
  const citizenshipsOptions = useMemo(() =>
    citizenships.map(
      (v) => ({
        ...v,
        label: translate(v.translationKey),
      }),
      [citizenships]
    )
  )
  const communicationMethodsOptions = useMemo(() =>
    communicationMethods.map(
      (v) => ({
        ...v,
        label: translate(v.translationKey),
      }),
      [communicationMethods]
    )
  )
  const residencyOccupationOptions = useMemo(() =>
    residencyOccupation.map(
      (v) => ({
        ...v,
        label: translate(v.translationKey),
      }),
      [residencyOccupation]
    )
  )

  const selectedEmploymentStatus = {
    label: employmentStatusesOptions.find(
      (v) => v.id === generalDetails.employmentStatus
    )?.label,
    id: generalDetails.employmentStatus,
  }

  const selectedMla = useMemo(() => {
    return {
      label: militaryAffiliationOptions.find(
        (v) => v.id === generalDetails.militaryAffiliationId
      )?.label,
      id: generalDetails.militaryAffiliationId,
    }
  }, [generalDetails.militaryAffiliationId, militaryAffiliationOptions])

  const selectedCitizenship = useMemo(() => {
    return {
      label: citizenshipsOptions.find(
        (v) => v.id === generalDetails.citizenshipTypeId
      )?.label,
      id: generalDetails.citizenshipTypeId,
    }
  }, [generalDetails.citizenshipTypeId, citizenshipsOptions])

  const selectedCommunicationMethod = useMemo(() => {
    return {
      label: communicationMethodsOptions.find(
        (v) => v.id === generalDetails.preferredCommunicationMethodId
      )?.label,
      id: generalDetails.preferredCommunicationMethodId,
    }
  }, [
    generalDetails.preferredCommunicationMethodId,
    communicationMethodsOptions,
  ])

  const selectedResidencyOccupation = useMemo(() => {
    return {
      label: residencyOccupationOptions.find(
        (v) => v.id === generalDetails.residencyOccupationTypeId
      )?.label,
      id: generalDetails.residencyOccupationTypeId,
    }
  }, [generalDetails.residencyOccupationTypeId, residencyOccupationOptions])

  return (
    <Grid container gap={24} style={{ marginTop: '24px' }}>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          fullWidth
          label={translate('userDetails.firstName')}
          placeholder={translate('userDetails.firstName')}
          onChange={(val) =>
            handleOnChange(convertWordToTitleCase(val), 'firstName')
          }
          value={generalDetails?.firstName}
          validate={() => errorObject.firstName}
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          fullWidth
          label={translate('userDetails.lastName')}
          placeholder={translate('userDetails.lastName')}
          onChange={(val) =>
            handleOnChange(convertWordToTitleCase(val), 'lastName')
          }
          value={generalDetails?.lastName}
          validate={() => errorObject.lastName}
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          fullWidth
          label={translate('userDetails.phone')}
          placeholder={translate('userDetails.phone')}
          type={INPUT_TYPES.PHONE}
          value={generalDetails?.phoneNumber}
          onChange={(val) => handleOnChange(val, 'phoneNumber')}
          validate={() => errorObject.phoneNumber}
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          fullWidth
          label={translate('userDetails.email')}
          placeholder={translate('userDetails.email')}
          value={generalDetails?.emailAddress || ''}
          onChange={(val) => handleOnChange(val, 'emailAddress')}
          validate={() => errorObject.emailAddress}
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          fullWidth
          label={translate(isEdit ? 'userDetails.ssn' : 'userDetails.last4ssn')}
          placeholder={translate(
            isEdit ? 'userDetails.ssn' : 'userDetails.last4ssn'
          )}
          value={generalDetails?.last4SSN || ''}
          type={INPUT_TYPES.SENSITIVE}
          onChange={handleSSNChange}
          validate={() => errorObject.last4SSN}
          hideSecureValue={!canSeeSensitiveData}
          unmaskedSecuredValue={!isEdit}
          disabled={(isEdit && !canSeeSensitiveData) || areInputsDisabled}
          maxLength={isEdit ? 9 : 4}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <InputDate
          label={translate('userDetails.dob')}
          value={generalDetails.dateOfBirth}
          onChange={(date) => {
            handleOnChange(
              {
                target: {
                  value: {
                    id: date,
                  },
                },
              },
              'dateOfBirth'
            )
          }}
          validate={() => errorObject.dateOfBirth}
          disabled={(isEdit && !canSeeSensitiveData) || areInputsDisabled}
          isSensitiveField={isEdit}
          hideSecureValue={!canSeeSensitiveData}
          fullWidth={true}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          fullWidth
          label={translate('userDetails.yearlyIncome')}
          placeholder={translate('userDetails.yearlyIncome')}
          value={
            generalDetails?.yearlyIncome === 0
              ? 0
              : generalDetails?.yearlyIncome || ''
          }
          type="currency"
          decimalScale={0}
          fixedDecimalScale={false}
          prefix="$"
          onChange={(val) => handleOnChange(val, 'yearlyIncome')}
          validate={() => errorObject.yearlyIncome}
          disabled={areInputsDisabled || hideIncomeField}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <Dropdown
          label={translate('userDetails.employmentStatus')}
          placeholder={translate('userDetails.employmentStatus')}
          options={employmentStatusesOptions}
          value={selectedEmploymentStatus}
          onChange={(val) => handleOnChange(val, 'employmentStatus')}
          error={!!errorObject.employmentStatus}
          helperText={errorObject.employmentStatus}
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          fullWidth
          label={translate('userDetails.monthsEmployed')}
          placeholder={translate('userDetails.monthsEmployed')}
          type="number"
          value={generalDetails?.monthsEmployed || ''}
          onChange={(val) => handleOnChange(val, 'monthsEmployed')}
          validate={() => errorObject.monthsEmployed}
          disabled={areInputsDisabled || hideEmploymentFields}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          fullWidth
          label={translate('userDetails.jobTitle')}
          placeholder={translate('userDetails.jobTitle')}
          value={generalDetails?.jobTitle || ''}
          onChange={(val) => handleOnChange(val, 'jobTitle')}
          validate={() => errorObject.jobTitle}
          disabled={areInputsDisabled || hideEmploymentFields}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          fullWidth
          label={translate('userDetails.employer')}
          placeholder={translate('userDetails.employer')}
          value={generalDetails?.employer || ''}
          onChange={(val) => handleOnChange(val, 'employer')}
          validate={() => errorObject.employer}
          disabled={areInputsDisabled || hideEmploymentFields}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <Dropdown
          label={translate('userDetails.mla')}
          placeholder={translate('userDetails.mla')}
          options={militaryAffiliationOptions}
          value={selectedMla}
          onChange={(val) => handleOnChange(val, 'militaryAffiliationId')}
          error={!!errorObject.militaryAffiliationId}
          helperText={errorObject.militaryAffiliationId}
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <Dropdown
          label={translate('userDetails.citizenship')}
          placeholder={translate('userDetails.citizenship')}
          options={citizenshipsOptions}
          value={selectedCitizenship}
          onChange={(val) => handleOnChange(val, 'citizenshipTypeId')}
          error={!!errorObject.citizenshipTypeId}
          helperText={errorObject.militaryAffiliationId}
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <Dropdown
          label={translate('userDetails.communicationMethod')}
          placeholder={translate('userDetails.communicationMethod')}
          options={communicationMethodsOptions}
          value={selectedCommunicationMethod}
          onChange={(val) =>
            handleOnChange(val, 'preferredCommunicationMethodId')
          }
          error={!!errorObject.preferredCommunicationMethodId}
          helperText={errorObject.preferredCommunicationMethodId}
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <Dropdown
          label={translate(
            'loanApplication.step1.howWillYouOccupyThisResidence'
          )}
          placeholder={translate(
            'loanApplication.step1.howWillYouOccupyThisResidence'
          )}
          options={residencyOccupationOptions}
          value={selectedResidencyOccupation}
          onChange={(val) => handleOnChange(val, 'residencyOccupationTypeId')}
          error={!!errorObject.residencyOccupationTypeId}
          helperText={errorObject.residencyOccupationTypeId}
          disabled={areInputsDisabled}
        />
      </Grid>
    </Grid>
  )
}

GeneralDetails.propTypes = {
  generalDetails: PropTypes.object.isRequired,
  setGeneralDetails: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  coBorrower: PropTypes.bool,
  communicationMethods: PropTypes.array.isRequired,
  employmentStatuses: PropTypes.array.isRequired,
  citizenships: PropTypes.array.isRequired,
  militaryAffiliation: PropTypes.array.isRequired,
  userData: PropTypes.object.isRequired,
  residencyOccupation: PropTypes.array.isRequired,
  areInputsDisabled: PropTypes.bool.isRequired,
  onChangeHideEmploymentFields: PropTypes.func.isRequired,
  hideEmploymentFields: PropTypes.any,
  onChangeHideIncomeField: PropTypes.func.isRequired,
  hideIncomeField: PropTypes.any,
}

export default GeneralDetails
